import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RLink } from 'react-router-dom';
import { RightChevronIcon } from '../Icons';
import { BookmarkButton } from '../Buttons';

// const Bookmark = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.darkPallet.light,
//   width: '30px',
//   height: '30px',
//   borderRadius: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   '& > svg': {
//     height: '15px',
//     width: '15px',
//     fill: theme.palette.lightPallet.lighter,
//   },
// }));

interface ProductProps {
  media?: string;
  price: string;
  oldPrice?: string;
  title: string;
  category?: string;
  id?: string;
}

const Product = (props: ProductProps) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ backgroundColor: '#423C37', p: 1 }}>
        <CardMedia component="img" image={props.media} alt="Product media" />
        <Typography
          component={RLink}
          to={`/shop/product/${props.id || 1}`}
          variant="body2"
          fontWeight="700"
          display="block"
          sx={{ mt: 1 }}
        >
          {props.title}
        </Typography>
        <Box sx={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography fontWeight="700" fontSize="11px" sx={{ display: 'flex', alignItems: 'baseline' }}>
              {props.price}
              {props?.oldPrice && (
                <Typography
                  variant="caption"
                  fontSize="11px"
                  color="grey.600"
                  sx={{ textDecoration: 'line-through', pl: 1 }}
                >
                  {props?.oldPrice}
                </Typography>
              )}
            </Typography>
            <Typography color="grey.400">{props.category}</Typography>
          </Box>
          <Box>
            <BookmarkButton />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

interface HeaderProps {
  title: string;
  hasMore?: boolean;
  arrow?: boolean;
  moreText?: string;
  link?: string;
}

const ArrowIcon = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  '& svg': {
    marginLeft: '5px',
    fill: theme.palette.primary.main,
    height: '12px',
    width: '12px',
  },
}));

Product.Header = ({ arrow = true, hasMore = true, ...props }: HeaderProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pb: 1 }}>
      <Typography variant="h3" fontSize="32px" fontWeight="700">
        {props.title}
      </Typography>
      {hasMore && (
        <Box>
          <Link
            component={RLink}
            to={props.link || ''}
            fontSize="12px"
            fontWeight="700"
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
            }}
          >
            {props.moreText ? props.moreText : ''}{' '}
            {arrow && (
              <ArrowIcon>
                <RightChevronIcon />
              </ArrowIcon>
            )}
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Product;
