import { Button, Typography, Link, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import { RightArrowIcon } from '../../components/Icons';
import { Page } from '../../components/Page/Page';
import EventInterest from './EventInterest';
import EventHeader from './EventHeader';
import StandingImage1 from '../../img/standing-image-1.png';
import StandingImage2 from '../../img/standing-image-2.png';
import BullRidingImg from '../../img/bull-riding.png';
import { Helmet } from 'react-helmet';

const numberStyle = {
  opacity: '1',
  position: 'absolute',
  top: '40%',
  left: '-15px',
};

const imageBoxStyle = { flex: 1, position: 'relative' };
const imageTitleStyle = {
  width: '100%',
  bottom: '6%',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
};

const AvatarSelectCard = () => (
  <Box
    sx={{
      display: 'flex',
      backgroundColor: 'darkPallet.dark',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}
  >
    <Box sx={{ width: '180px' }}>
      <CardMedia component="img" image={StandingImage2} alt="Standing Image" />
    </Box>
    <Typography
      variant="caption"
      fontSize="8em"
      fontFamily={'BourbonW00-Regular'}
      color="primary.main"
      sx={{ position: 'absolute', pr: 3 }}
    >
      1
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h3" fontFamily={'BourbonW00-Regular'} textTransform="uppercase">
        john smith
      </Typography>
      <Box sx={{ pb: 1, pt: 1 }}>
        <Typography variant="overline" fontWeight="700">
          $411.564.30 -
        </Typography>
        <Typography variant="overline"> Salado, TX</Typography>
      </Box>
      <Box>
        <Button variant="outlined">View Profile</Button>
      </Box>
    </Box>
  </Box>
);

const items = [
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TXBelville, TX',
    avatar: BullRidingImg,
    serial: 1,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TXBelville, TX',
    avatar: BullRidingImg,
    serial: 2,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TXBelville, TX',
    avatar: BullRidingImg,
    serial: 3,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TXBelville, TX',
    avatar: BullRidingImg,
    serial: 4,
  },
];

const RidingItem = (props: { title: string; price: string; avatar: string; serial: number; tx: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'darkPallet.main',
        borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 2,
        overflow: 'hidden',
        p: 1,
        mb: 2,
      }}
    >
      <Typography variant="caption" fontSize="2em" sx={{ pr: 1, color: 'primary.main' }}>
        {props.serial}
      </Typography>
      <Avatar src={props.avatar} alt={props.title} />
      <Box sx={{ pl: 1, flex: 1 }}>
        <Typography variant="subtitle1" fontWeight="700">
          {props.title}
        </Typography>
        <Typography variant="caption" fontWeight="500" color="primary.main" sx={{ pr: 1 }}>
          {props.price}
        </Typography>
        <Typography variant="caption">. {props.tx}</Typography>
      </Box>
      <Link
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            height: '15px',
            width: '15px',
            marginLeft: '15px',
            fill: (theme) => theme.palette.lightPallet.lighter,
          },
        }}
      >
        <RightArrowIcon />
      </Link>
    </Box>
  );
};

const EventRegistrationHorses = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event Registration Horses | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Registration Horses</title>
      </Helmet>
      <Page>
        <EventHeader />
        <EventInterest />
        <AvatarSelectCard />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2 }}>
          <Box
            sx={{
              ...imageBoxStyle,
              pr: 2,
            }}
          >
            <CardMedia component="img" image={StandingImage1} alt="Standing Image" />
            <Typography
              variant="caption"
              sx={{ ...numberStyle, color: 'primary.main' }}
              fontSize="5em"
              fontFamily={'BourbonW00-Regular'}
            >
              2
            </Typography>
            <Box sx={imageTitleStyle}>
              <Typography variant="subtitle1" fontFamily="BourbonW00-Regular" fontSize={20} lineHeight={0} sx={{ pl: 1 }}>
                JOHN SMITH
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ...imageBoxStyle }}>
            <CardMedia component="img" image={StandingImage1} alt="Standing Image" />
            <Typography
              variant="caption"
              sx={{ ...numberStyle, color: 'primary.main' }}
              fontSize="5em"
              fontFamily={'BourbonW00-Regular'}
            >
              3
            </Typography>
            <Box sx={imageTitleStyle}>
              <Typography variant="subtitle1" fontFamily="BourbonW00-Regular" fontSize={20} lineHeight={0} sx={{ pl: 1 }}>
                JOHN SMITH
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ pt: 2 }}>
          {items.map((item, index) => {
            return <RidingItem key={index} {...item} />;
          })}
        </Box>
      </Page>
    </>
  );
};

export default EventRegistrationHorses;
