import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
// /*eslint-disable */
// require('@silvermine/videojs-chromecast')(videojs, { preloadWebComponents: true });
// /*eslint-enable */


interface VideoJSProps {
  options: videojs.PlayerOptions;
  onReady?: (player: videojs.Player) => void;
}

export const VideoJS: React.FC<VideoJSProps> = (props) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const { options, onReady } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");
      videoRef.current?.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        // player.fluid(true);
        // player.controlBar.removeClass('vjs-control');
        // player.controlBar.removeClass('vjs-progress-control');
        onReady && onReady(player);
      });
    } else {
      const player = playerRef.current;
      if (player) {
        // player.userActive(false)
        player.autoplay(options.autoplay || false);
        player.src(options.sources || []);
      }
    }
  }, [options, videoRef, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        // player.controlBar.removeClass('vjs-progress-holder vjs-slider vjs-slider-horizontal')
        player.dispose();
        playerRef.current = null;
      }
    };
  });

  return (
    // <div data-vjs-player >
    <div>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJS;
