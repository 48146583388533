import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/system";

const PostLogoWrapper = styled(Box)(({ theme }) => ({
  height: '54px',
  width: '54px',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
}));

export const VideoPostHeader = ({
  title,
  logo,
  isLive,
  feature,
}: {
  title?: string
  logo?: string
  isLive?: boolean
  feature?: boolean
}) => {
  if (!logo && !isLive && !feature) return null

  return (
    <Box
      sx={{
        zIndex: 1,
        height: '50px',
        position: 'absolute',
        top: '10px',
        left: '10px',
        display: 'flex',
      }}
    >
      {logo && (
        <PostLogoWrapper>
          <Avatar sx={{ height: '40px', width: '40px' }} src={logo} alt={title} />
        </PostLogoWrapper>
      )}
      {(isLive || feature) && (
        <Stack direction="row" spacing={1} sx={{ pl: 1 }}>
          {isLive && <Chip color="error" size="small" label="Live" />}
          {feature && <Chip color="warning" size="small" label="Feature" />}
        </Stack>
      )}
    </Box>
  )
}