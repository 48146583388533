import { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { Page } from '../../components/Page/Page';
import Tabs, { Tab, TabPanel } from '../../components/Tabs';
import Navbar from '../../components/Navbar';
import IntertainmentTab from './IntertainmentTab';
import { _get } from '../../net/net';
import { Helmet } from 'react-helmet';
import { hardcodedInterests } from "../../api/interests.api";

interface VideoPost {
  id: number;
  attributes: VideoAttributes;
}
interface VideoAttributes {
  Title: string;
  Cover_Image?: CoverImage;
  MC_Shopify: string[];
  publishedAt: string;
  Video_Link: string;
}
interface CoverImage {
  data: CoverImageData;
}
interface CoverImageData {
  id: number;
  attributes: CoverImageAttributes;
}
interface CoverImageAttributes {
  alternativeText: string;
  url: string;
}

interface Category {
  category: string;
  id: number;
  tags: Interest[]
}
interface Interest {
  name: string;
  uuid: string;
  selected: boolean;
}

const categoriesList = [
  {
    category: 'Rodeo',
    id: 1,
    tags: hardcodedInterests.slice(0, 8).map((item) => ({
      ...item,
      selected: false
    })),
  },
  {
    category: 'Equine',
    id: 2,
    tags: hardcodedInterests.slice(8).map((item) => ({
      ...item,
      selected: false,
    }))
  }
]

const Watch = () => {
  const [value, setValue] = useState(0);
  const [videos, setVideos] = useState<VideoPost[]>([]);
  const [filteredVideos, setFilteredVideos] = useState<VideoPost[]>([]);
  const [filterTags, setFilterTags] = useState<Interest[]>([])

  useEffect(() => {
    setAllTags();
    _get(`${process.env.REACT_APP_CMS_BASE_URL}api/video-posts?populate=*`).then((response) => {
      const sortedPosts = sortByDate(response.data);
      setVideos(sortedPosts);
      setFilteredVideos(sortedPosts);
    });
  }, [])

  useEffect(() => {
    if (filterTags.some((tag: Interest) => {
      return tag.selected;
    })) {
      setFilteredVideos(filterByTags(videos));
    } else {
      if (value === 0) {
        setFilteredVideos(videos);
      } else {
        filterByCategoryTags(filterTags, videos).then(newVideos => {
          setFilteredVideos(newVideos)
        })
      }
    }
  }, [filterTags])

  const filterByTags = (posts: VideoPost[]) => {
    let newPosts = posts;
    newPosts = newPosts.filter((post: VideoPost) => {
      return filterTags.some((tag: Interest) => {
        return post.attributes.MC_Shopify ? post.attributes.MC_Shopify.includes(tag.name) && tag.selected : false;
      });
    });
    return newPosts;
  };

  const filterByCategoryTags = async (tags: Interest[], posts: VideoPost[]) => {
    let newPosts = posts;
    newPosts = newPosts.filter((post: VideoPost) => {
      return tags.some((tag: Interest) => {
        return post.attributes.MC_Shopify ? post.attributes.MC_Shopify.includes(tag.name) : false;
      })
    })
    return newPosts;
  }

  const sortByDate = (data: VideoPost[]): VideoPost[] => {
    return data.sort((postA: VideoPost, postB: VideoPost) => {
      const datePostA = new Date(postA.attributes.publishedAt).getTime();
      const datePostB = new Date(postB.attributes.publishedAt).getTime();
      return datePostB - datePostA;
    });
  };

  const onToggle = (id: string): any => {
    let newFilterTags = filterTags;
    newFilterTags = newFilterTags.map((tag) => {
      if (tag.uuid === id) {
        return { ...tag, selected: !tag.selected };
      } else {
        return { ...tag };
      }
    });
    setFilterTags(newFilterTags);
    return null;
  };

  const setAllTags = () => {
    const allTags = categoriesList.map((category) => {
      return category.tags
    }).flat()
    setFilterTags(allTags)
  }

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setAllTags();
    } else {
      const newCategory = categoriesList.find(category => {
        return category.id === newValue
      })
      if (newCategory) {
        setFilterTags(newCategory.tags);
        setFilteredVideos(await filterByCategoryTags(newCategory.tags, videos));
      } else {
        setFilteredVideos([])
      }
    }
    setValue(newValue)
  };
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Live Streaming | TetonRidge+"  />
        <meta name="twitter:description" content="Live Streaming provided by TetonRidge+" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'/>

        <meta property="og:title" content="Live Streaming | TetonRidge+" />
        <meta property="og:description" content="Live Streaming provided by TetonRidge+" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Live Streaming</title>
      </Helmet>
      <Page style={{ paddingBottom: '20px' }}>
        <Header />
        <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="Watch tab" sx={{ pt: 2 }}>
          <Tab label="All" />
          {categoriesList.map((category: Category) => {
            return <Tab label={category.category} value={category.id} />;
          })}
        </Tabs>
        <TabPanel index={0} value={value}>
          <IntertainmentTab videos={filteredVideos} tags={filterTags} onToggle={onToggle} />
        </TabPanel>
        {categoriesList.map((category: Category) => {
          return <TabPanel index={category.id} value={value}>
            <IntertainmentTab videos={filteredVideos} tags={filterTags} onToggle={onToggle} />
          </TabPanel>;
        })}
        <Navbar />
      </Page>
    </>
  );
};

export default Watch;