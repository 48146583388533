import { Button, Typography, Link, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { Link as RLink } from 'react-router-dom';
import { RightArrowIcon } from '../../components/Icons';
import { Page } from '../../components/Page/Page';
import EventInterest from './EventInterest';
import EventHeader from './EventHeader';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CurrentUser } from "../../api/auth.api";
import { checkIsAthlete } from "../../features/User/user.utils";

const getLink = (user: CurrentUser) => {
  const athlete = checkIsAthlete(user)
  if (!athlete) {
    return '/event-regsistration-compete';
  } else if (athlete) {
    return '/event-team-roping';
  } else {
    return '/event-regsistration';
  }
};

const Event = ({ user }: { user: CurrentUser }) => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => {
    navigate(link);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event</title>
      </Helmet>
      <Page>
        <EventHeader />
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a consectetur phasellus ut id. Lorem nibh purus
          sollicitudin gravida mattis.
        </Typography>
        <Typography variant="caption" display="block" sx={{ pb: 2, pt: 2 }}>
          Available registration from 6/10, 6:00 am to 18/10, 6:00 pm
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Link
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: '500',
              mr: 3,
              '& svg': { height: '15px', width: '15px', marginLeft: '15px', fill: (theme) => theme.palette.primary.dark },
            }}
          >
            Event Website <RightArrowIcon />{' '}
          </Link>

          <Link
            component={RLink}
            to={'/events/day-shift'}
            sx={{
              fontWeight: '500',
            }}
          >
            View Daysheet
          </Link>
        </Box>
        <EventInterest />
        <Divider sx={{ ml: '-1rem', mr: '-1rem' }} />
        <Box sx={{ pb: 2, pt: 1 }}>
          <Typography variant="overline" textTransform={'uppercase'} display="block" fontWeight="700" sx={{ pb: 1 }}>
            20 slots open
          </Typography>
          <Button variant="outlined" fullWidth onClick={onPage(getLink(user))} style={{
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            Register To Compete
          </Button>
        </Box>
        <Divider sx={{ ml: '-1rem', mr: '-1rem' }} />
        <Box sx={{ pb: 2, pt: 1 }}>
          <Typography variant="overline" textTransform={'uppercase'} display="block" fontWeight="700" sx={{ pb: 1 }}>
            Tickets start at $35
          </Typography>
          <Button variant="contained" style={{
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }} fullWidth onClick={onPage('/event-regsistration')}>
            BUY TICKETS TO ATTEND
          </Button>
        </Box>
      </Page></>
  );
};

export default Event;
