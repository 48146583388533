import { CustomFeedModule } from '../../../components/CustomFeed';
import { Post } from '../../../components/GamingPost/Post';
import { GamingVideoPost } from '../../../components/GamingPost/VideoPost/VideoPost';
import { Box } from "@mui/material";
import { GamingArticle } from "../../../api/gaming.api";
import { CustomFeed } from '../../../api/customFeed.api';
import { useEffect, useState } from 'react';
import { CollectionFeed } from '../../../api/collections.api';
import { FeedTypeEnum } from '../gameTypes';
import { Collection } from '../../../components/Collection/Collection';
import GameDescription from '../GameDetail/GameDescription';
import GameSponsors from '../GameDetail/GameSponsors';
import BigBonusGame from '../GameDetail/BigBonusGame';

type Props = {
  item: GamingArticle | (CustomFeed & { isCustomFeed: true }) | CollectionFeed | any;
  onLoad?(): void;
  row: number;
  itemRef?(el: HTMLDivElement, item: { id: number }): void
  onOpen?(item: { id: number }): void
  onPin?(item: any): void,
  pinned?: boolean,
  pinnedList?: boolean,
  includeWhatsNewTitle?: boolean,
};

export const FeedItem = ({
  item,
  onLoad,
  itemRef,
  onOpen,
  onPin,
  pinned,
  pinnedList = false,
  includeWhatsNewTitle,
}: Props) => {
  const [isPin, setIsPin] = useState(pinned ? pinned : false);
  const refFabricated = itemRef ? (el: HTMLDivElement) => itemRef(el, item) : undefined

  useEffect(() => {
    setIsPin(pinned ? pinned : false)
  }, [pinned])

  const onClick = () => {
    if (onPin) {
      setIsPin(true)
      onPin(item)
    }
  }

  if ('isCustomFeed' in item) {
    return (
      <Box sx={{ px: 2 }} ref={refFabricated}>
        <CustomFeedModule
          item={item}
          onLoad={onLoad}
        />
      </Box>
    )
  }

  const renderElement = () => {
    switch (item.feedType) {
      case FeedTypeEnum.ARTICLE: {
        const authorFullName = item.author && `${item.author.First_Name} ${item.author.Last_Name}`;
        const {
          videos: [video],
          muxVideos: [muxVideo],
        } = item;

        return (
          <>
            {muxVideo
              ?
              <GamingVideoPost
                isMux={true}
                media={muxVideo.attributes.playback_id}
                id={item.id}
                muxId={muxVideo.id}
                title={item.title}
                user={authorFullName}
                date={item.publishedAt}
                onLoad={onLoad}
                onOpen={onOpen}
                isPin={isPin}
                onPin={onClick}
                pinnedList={pinnedList}
              />
              : video
                ?
                <GamingVideoPost
                  key={video.id}
                  media={video.attributes.Video_Link}
                  date={item.publishedAt}
                  id={video.id}
                  title={video.attributes.Title}
                  user={authorFullName}
                  onLoad={onLoad}
                  onOpen={onOpen}
                  isPin={isPin}
                  onPin={onClick}
                  pinnedList={pinnedList}
                />
                :
                <Post
                  key={item.id}
                  title={item.title}
                  media={item.cover?.attributes?.url}
                  id={item.id}
                  date={item.publishedAt}
                  user={authorFullName}
                  onLoad={onLoad}
                  onOpen={onOpen}
                  isPin={isPin}
                  onPin={onClick}
                  pinnedList={pinnedList}
                />
            }
          </>
        )
      }
      case FeedTypeEnum.COLLECTION: {
        const {
          id,
          title,
          videoQuantity,
          articleQuantity,
          url
        } = item

        return (
          <Collection
            key={id}
            id={id}
            title={title}
            articleQuantity={articleQuantity}
            videoQuantity={videoQuantity}
            url={url}
            onLoad={onLoad}
          />
        )
      }
      case FeedTypeEnum.GAMEDESCRIPTION: {
        return (
          <GameDescription
            item={item}
            includeWhatsNewTitle={includeWhatsNewTitle}
          />
        )
      }
      case FeedTypeEnum.GAMESPONSORS: {
        return (
          <GameSponsors
            item={item}
          />
        )
      }
      case FeedTypeEnum.BIGBONUSGAME: {
        return (
          <BigBonusGame
            item={item}
          />
        )
      }
      default:
        return <></>
    }
  }

  return (
    <Box sx={{ px: 2 }}>
      {renderElement()}
    </Box>

  )
}