import { Typography, Box } from '@mui/material';
import { Page } from '../../components/Page/Page';
import pageBg from '../../img/home-page-bg.png';
import { Helmet } from 'react-helmet';

const LoadingPage = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />

      </Helmet>
      <Page style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            backgroundImage: `url(${pageBg})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }} />
        <Box>
          <Typography variant='h2' align='center' color='primary.main'>
            Loading...
          </Typography>
          <Typography fontWeight='700' align='center' sx={{ pt: 2 }} textTransform='uppercase'>
            Sign in or Sign up.
          </Typography>
        </Box>
      </Page>
    </>
  );
};

export default LoadingPage;
