import { Box, styled } from '@mui/system';
import { Typography, Chip, Button } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page/Page';
import Header, { CustomIconButton } from '../../components/Header';
import { MapIcon, EventIcon, FileIcon, CheckedIcon, AlertIcon } from '../../components/Icons';
import Fixedbox from '../../components/Fixedbox';
import { Helmet } from 'react-helmet';

const fileList = [
  { name: 'Discipline Waiver', desc: 'Desc.', status: 'signed' },
  { name: 'Discipline Waiver', desc: 'Desc.', status: 'signed' },
  { name: 'Discipline Waiver', desc: 'Desc.', status: 'signed' },
  { name: 'Discipline Waiver', desc: 'Desc.', status: 'unsigned' },
  { name: 'Discipline Waiver', desc: 'Desc.', status: 'unsigned' },
];

interface chipsProps {
  status?: string;
}

const ViewsChip = styled(Chip)<chipsProps>(({ status }) => ({
  backgroundColor: status === 'signed' ? '#393939' : '#151312',
  color: status === 'signed' ? '#99E5A4' : '#CDA932',
  fontSize: '12px',
  borderColor: 'transparent',
  borderRadius: '6px',
  width: '100px',
  '& .MuiChip-label': {
    textTransform: 'uppercase',
    paddingLeft: '3px',
    paddingRight: '3px',
  },
  '& svg': { height: '15px', width: '15px', marginRight: '3px', stroke: status === 'signed' ? '#99E5A4' : '#CDA932' },
}));

interface DocumentProps {
  name: string;
  desc: string;
  status: string;
}

const Document = (props: DocumentProps) => {
  return (
    <Box
      sx={{
        backgroundColor: props.status === 'signed' ? 'darkPallet.light' : 'darkPallet.main',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '80px',
        p: 2,
        mb: 2,
        borderRadius: '5px',
      }}
    >
      <Box sx={{ '& svg': { fill: (theme) => theme.palette.lightPallet.main, height: '25px', width: '25px' } }}>
        <FileIcon />
      </Box>
      <Box sx={{ flex: 1, pl: 3 }}>
        <Typography variant="subtitle1" fontWeight="700">
          {props.name}
        </Typography>
        <Typography variant="caption">{props.desc}</Typography>
      </Box>
      <Box>
        <ViewsChip
          label={props.status}
          variant="filled"
          status={props.status}
          icon={props.status === 'signed' ? <CheckedIcon /> : <AlertIcon />}
        />
      </Box>
    </Box>
  );
};

const EventDocuments = () => {
  const [searchParams] = useSearchParams();
  const header = searchParams.get('header');

  const navigate = useNavigate();

  const onPage = (link: string) => () => {
    navigate(link);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event Documents | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Documents</title>
      </Helmet>
      <Page>
        {header ? (
          <Header
            RightComponent={
              <>
                <CustomIconButton>
                  <MapIcon />
                </CustomIconButton>
                <CustomIconButton>
                  <EventIcon />
                </CustomIconButton>
              </>
            }
          />
        ) : (
          <Header.Light />
        )}
        <Typography variant="h2" textTransform="uppercase" align="center" color="primary.main" sx={{ mt: 4, mb: 1 }}>
          Documentation
        </Typography>
        <Typography align="center">
          For finishing your registration we need you to sign the following documents
        </Typography>
        <Box sx={{ mt: 8 }}>
          {fileList.map((file, index) => (
            <Document {...file} key={index} />
          ))}
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onPage(`/event-docusign${!header ? '?header=true' : ''}`)}
            >
              Sign Docs
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventDocuments;
