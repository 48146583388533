import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { Link as RLink, } from 'react-router-dom';
import { formatDateFromNow } from '../../utils/date.utils';
import { useEffect } from 'react';
import { Square } from "../Shapes/Square/Square";
// import PinIcon from "../../img/pin-post.png";
// import PinnedIcon from "../../img/pin-post-active.png";

export type PostProps = {
  title?: string;
  logo?: string;
  date?: string;
  media?: string;
  user?: string;
  category?: string;
  type?: string;
  comments?: number;
  id: number;
  isPin?: boolean;
  onLoad?(): void;
  onOpen?(item: { id: number }): void,
  onPin?(): void,
  pinnedList?: boolean,
}

window.addEventListener('resize', resize);

function resize() {
  if (window.innerWidth > 700) {
    return '650px';
  } else {
    return '100%'
  }
}

export const Post = ({
  id,
  media,
  onLoad,
  onOpen,
  // onPin,
  // isPin,
  pinnedList = false,
  ...props
}: PostProps) => {

  useEffect(() => {
    if (!media) onLoad?.();
  }, [])

  return !pinnedList ? (
    <Card
      sx={{
        mx: 'auto',
        position: 'relative',
        overflow: 'inherit',
        backgroundColor: '#423C37 !important',
        borderRadius: 3,
        width: resize(),
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 100,
          padding: '10px',
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          borderRadius: "50%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onPin}
      >
        <img style={{
          height: '1.5rem',
          width: '1.5rem',
        }} src={isPin ? PinnedIcon : PinIcon} />
      </div> */}
      <Link
        component={RLink}
        variant="subtitle1"
        sx={{ textDecoration: 'none' }}
        fontSize="28px"
        lineHeight="31px"
        color="primary.main"
        fontFamily="BourbonW00-Regular"
        to={`/article/${id}`}
        className="ga-article"
        onClick={() => {
          onOpen?.({ id })

          // send GA event
          window.dataLayer.push({
            event: 'click-article',
            value: `/article/${id}`
          });
        }}
      >
        <CardContent sx={{ p: 0 }}>
          {/* <PostLogoWrapper sx={{ position: 'absolute', top: '-16px', left: '10px' }}>
          <Avatar sx={{ height: '40px', width: '40px' }} src={props.logo} alt={props.title || ''} />
        </PostLogoWrapper> */}

          {media && (

            <Square>
              <CardMedia
                component="img"
                image={media}
                alt="Content media"
                sx={{
                  borderTopLeftRadius: '12px',
                  borderTopRightRadius: '12px',
                  width: '100%',
                  height: '100%',
                }}
                onLoad={onLoad}
              />
            </Square>
          )}
          <Box sx={{ p: 2, pb: 0 }}>
            {props?.date && <Typography variant="caption">{formatDateFromNow(new Date(props.date))}</Typography>}
            {props?.category && <Typography sx={{ fontSize: 10 }} >{props.category}</Typography>}
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  flex: 2,
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                {props.title}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 1 }}>
                  {props.user && (
                    <Typography variant="caption" color="grey.400">
                      By {props.user}
                    </Typography>
                  )}
                  {props.comments && (
                    <Typography variant="caption" color="grey.400">
                      {props.comments} comments
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Link>
    </Card>
  ) : (
    <Card
      sx={{
        mb: 2,
        position: 'relative',
        mt: 3, overflow: 'inherit',
        backgroundColor: '#423C37 !important',
        borderRadius: 3, mx: 'auto',
        height: '450px',
        width: '350px'
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 100,
          padding: '10px',
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          borderRadius: "50%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onPin}
      >
        <img style={{
          height: '1.5rem',
          width: '1.5rem',
        }} src={isPin ? PinnedIcon : PinIcon} />
      </div> */}
      <Link
        component={RLink}
        variant="subtitle1"
        sx={{ textDecoration: 'none' }}
        fontSize="28px"
        lineHeight="31px"
        color="primary.main"
        fontFamily="BourbonW00-Regular"
        to={`/article/${id}`}
        className="ga-article"
        onClick={() => {
          onOpen?.({ id })

          // send GA event
          window.dataLayer.push({
            event: 'click-article',
            value: `/article/${id}`
          });
        }}
      >
        <CardContent sx={{ p: 0, paddingBottom: '0 !important' }}>
          {/* <PostLogoWrapper sx={{ position: 'absolute', top: '-16px', left: '10px' }}>
            <Avatar sx={{ height: '40px', width: '40px' }} src={props.logo} alt={props.title || ''} />
          </PostLogoWrapper> */}

          {media && (
            <Square>
              <Box sx={{
                position: 'relative',
                height: '450px',
                width: '350px',
              }}>
                <div style={{
                  height: '450px',
                  width: '350px',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  position: 'absolute',
                  top: 0
                }}>
                </div>
                <CardMedia
                  component="img"
                  image={media}
                  alt="Content media"
                  sx={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    width: '100%',
                    height: '100%',
                  }}
                  onLoad={onLoad}
                />
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {props.title}
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      pt: 1,
                      mb: 3,
                    }}>
                      {props.user && (
                        <Typography variant="caption" color="grey.400" sx={{
                          fontSize: '15px',
                          paddingRight: '15px'
                        }}>
                          By {props.user}
                        </Typography>
                      )}
                      {props?.date && (
                        <Typography variant="caption" color="grey.400" sx={{
                          fontSize: '15px'
                        }}>
                          {formatDateFromNow(new Date(props.date))}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{
                  position: 'absolute',
                  top: '80%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box sx={{
                    border: '2px solid',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    width: '200px',
                  }}>
                    READ POST
                  </Box>
                </Box>
              </Box>
            </Square>
          )}
        </CardContent>
      </Link>
    </Card >
  )
};
