import { Box } from "@mui/material";
import cls from "./LoadingScreen.module.css";

export const LoadingScreen = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: '5rem' }}>
      <div>
        <img
          src={require("../../img/TR-loading-screen.png")}
          className={cls["logo"]}
          alt='' />
        <div className={cls["loading"]} style={{ fontFamily: "BourbonW00-Regular" }}>
          LOADING CONTENT...
        </div>
      </div>
      {/* <CircularProgress /> */}
    </Box>
  )
}