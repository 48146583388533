import { Divider, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
// import SectionTitle from '../../components/SectionTitle';
// import CommentBox from './CommentBox';
// import RelatedContents from './RelatedContents';
import Header from '../../components/Header';
// import SavedList from '../../components/SavedList';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ClockIcon } from '../../components/Icons';
import { formatDateFromNow } from '../../utils/date.utils';
import cls from './Article.module.css';
import { useArticleQuery } from "../../api/articles.api";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import { ShareMedias } from "./ShareMedias";
import { InstagramEmbed, YouTubeEmbed, TwitterEmbed, FacebookEmbed, TikTokEmbed } from 'react-social-media-embed';
import { useRef, useState, useEffect } from "react";
import { CurrentUser } from '../../api/auth.api';
import { subscribeUser } from '../../subscription';

// const Bookmark = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.grey['900'],
//   width: '40px',
//   height: '40px',
//   borderRadius: '100%',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   '& > svg': {
//     height: '20px',
//     width: '20px',
//     fill: theme.palette.grey['500'],
//   },
// }));

const TimeIcon = styled('span')(({ theme }) => ({
  paddingRight: '5px',
  '& > svg': {
    height: '10px',
    width: '10px',
    fill: theme.palette.lightPallet.main,
  },
}));

const ImgWrapper = styled(Box)(() => ({
  position: 'relative',
  padding: '15px 0',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

// width of the images must match the padding of the page
const PageWrapper = styled('div')(() => ({
  flex: 1,
  height: '100%',
  overflow: 'auto',
  overflowX: 'hidden',
}))

const ContentWrapper = styled('div')(() => ({
  '& img': {
    width: '100%',
    position: 'relative',
  },
}))

const InstagramEmbeds = ({ links }: { links: string[] }) => {
  if (links.length === 0) return null;

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url={link} />
          <Divider sx={{ mt: 6, mb: 6 }} />
        </div>
      ))}
    </div>
  );
};

const YouTubeEmbeds = ({ links }: { links: string[] }) => {
  if (links.length === 0) return null;

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
          <YouTubeEmbed url={link} />
          <Divider sx={{ mt: 6, mb: 6 }} />
        </div>
      ))}
    </div>
  );
};

const TwitterEmbeds = ({ links }: { links: string[] }) => {
  if (links.length === 0) return null;

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
          <TwitterEmbed url={link} />
          <Divider sx={{ mt: 6, mb: 6 }} />
        </div>
      ))}
    </div>
  );
};

const FacebookEmbeds = ({ links }: { links: string[] }) => {
  if (links.length === 0) return null;

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
          <FacebookEmbed url={link} />
          <Divider sx={{ mt: 6, mb: 6 }} />
        </div>
      ))}
    </div>
  );
};

const TikTokEmbeds = ({ links }: { links: string[] }) => {
  if (links.length === 0) return null;

  return (
    <div>
      {links.map((link, index) => (
        <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
          <TikTokEmbed url={link} />
          <Divider sx={{ mt: 6, mb: 6 }} />
        </div>
      ))}
    </div>
  );
};

const ArticleView = ({ user }: { user?: CurrentUser }) => {
  const { id } = useParams();
  const { data: article } = useArticleQuery({ id: parseInt(id as string) })
  const pageRef = useRef<HTMLDivElement>(null)

  const [instagramLinks, setInstagramLinks] = useState<string[]>([]);
  const [youtubeLinks, setYoutubeLinks] = useState<string[]>([]);
  const [tweetLinks, setTweetLinks] = useState<string[]>([]);
  const [facebookLinks, setFacebookLinks] = useState<string[]>([]);
  const [tiktokLinks, setTiktokLinks] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      subscribeUser(user.uuid);
    }
  }, [user]);

  interface SocialLink {
    id: number;
    IG_Link?: string;
    YT_Link?: string;
    Tweet_Link?: string;
    FBook_Link?: string;
    TikTok_Link?: string;
  }

  useEffect(() => {
    const igLinks: string[] = [];
    const ytLinks: string[] = [];
    const twLinks: string[] = [];
    const fbLinks: string[] = [];
    const tkLinks: string[] = [];

    article?.Social_Link?.forEach((link: SocialLink) => {
      if (link.IG_Link) igLinks.push(link.IG_Link);
      if (link.YT_Link) ytLinks.push(link.YT_Link);
      if (link.Tweet_Link) twLinks.push(link.Tweet_Link);
      if (link.FBook_Link) fbLinks.push(link.FBook_Link);
      if (link.TikTok_Link) tkLinks.push(link.TikTok_Link);
    });

    setInstagramLinks(igLinks);
    setYoutubeLinks(ytLinks);
    setTweetLinks(twLinks);
    setFacebookLinks(fbLinks);
    setTiktokLinks(tkLinks);
  }, [article?.Social_Link]);


  if (!article) {
    return <LoadingScreen />
  }

  const { author, title, content, cover, facebook_share_image } = article
  const cleanContent = content?.replace(/<[^>]+>/g, '')
  const fullName = author && `${author.First_Name} ${author.Last_Name}`
  const saved = true;


  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title ? title : 'Teton Ridge+'} />
        <meta name="twitter:description" content={cleanContent ? cleanContent : 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta name="twitter:image:src" content={facebook_share_image?.data?.attributes?.url ? facebook_share_image.data.attributes.url : cover?.attributes?.url || 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' } />

        <meta property="og:title" content={title ? title : 'Teton Ridge+'} />
        <meta property="og:description" content={cleanContent ? cleanContent : 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={facebook_share_image?.data?.attributes?.url ? facebook_share_image.data.attributes.url : cover?.attributes?.url || 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' } />
        <meta property="og:image:secure_url" content={facebook_share_image?.data?.attributes?.url ? facebook_share_image.data.attributes.url : cover?.attributes?.url || 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' } />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <title>Article | {title}</title>
      </Helmet>
      <PageWrapper ref={pageRef}>
        <Header backIcon floating={pageRef} />
        <Box sx={{ pt: 2, pb: 2, px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{ flex: 1, display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}
            >
              <Box>
                <TimeIcon>
                  <ClockIcon />
                </TimeIcon>
                <Typography variant="caption" textTransform="uppercase">
                  | {formatDateFromNow(new Date(article.publishedAt))}
                </Typography>
              </Box>
            </Box>
            {/* {saved ? (
              <Bookmark>
                <BookmarkStarIcon />
              </Bookmark>
            ) : (
              <BookmarkButton />
            )} */}
          </Box>
          <Box sx={{ pt: 4, pb: 3 }}>
            <Typography variant="h2" fontWeight="700" fontSize="40px" color="primary">
              {title}
            </Typography>
          </Box>
          <Typography variant="overline" textTransform={'uppercase'}>
            {fullName && `By ${fullName}`}
          </Typography>
          <Box sx={{ mt: 1 }} />
          <Box sx={{ maxWidth: 720, mx: 'auto' }}>
            <ImgWrapper>
              <img
                src={cover?.attributes.url}
                alt={cover?.attributes.alternativeText} />
            </ImgWrapper>
            <Typography variant='subtitle2' fontSize={12} color='#B1B3B3'>{cover?.attributes.caption}</Typography>
            {saved && <ShareMedias />}
          </Box>
          <Divider sx={{ mt: 6, mb: 6 }} />
          <Box>
            <Typography color="primary">
              <ContentWrapper
                className={cls['container']}
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              />
              <InstagramEmbeds links={instagramLinks} />
              <YouTubeEmbeds links={youtubeLinks} />
              <TwitterEmbeds links={tweetLinks} />
              <FacebookEmbeds links={facebookLinks} />
              <TikTokEmbeds links={tiktokLinks} />
            </Typography>
          </Box>
          {/* <CommentBox />
          <SectionTitle title="Related Contents" hasLine />
          <RelatedContents />
          <SavedList /> */}
        </Box>
      </PageWrapper>
    </>
  );
};

export default ArticleView;
