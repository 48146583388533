import Box from '@mui/material/Box';
import {
  Button,
  ButtonGroup,
  Divider,
  TextField,
  InputLabel,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';

import ProfilePoints from './ProfilePoints';
import SectionTitle from '../../components/SectionTitle';
import { PlusIcon, MinusIcon } from '../../components/Icons';
import LinksSlider from './LinksSlider';
import { styled } from '@mui/system';

const CustomIcon = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.darkPallet.dark,
  borderColor: theme.palette.darkPallet.dark,
  '& svg': {
    fill: theme.palette.lightPallet.lighter,
    height: '20px',
    width: '20px',
  },
  '&:hover': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.dark,
  },
  '&:active': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.dark,
  },
  '&:focus': {
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.dark,
  },
}));

const FirstStep = () => {
  return (
    <>
      <Typography variant="h1" textTransform="uppercase" color="primary.main">
        Barrel Racing
      </Typography>
      <Box sx={{ pb: 2 }}>
        <Typography>Jan 8, 2022 . 7:00 PM CST</Typography>
      </Box>
      <ProfilePoints />
      <LinksSlider />
      <Divider sx={{ mt: 4, mb: 7 }} />
      <Box sx={{ display: 'flex', mt: 2 }}>
        <Box sx={{ width: '200px' }}>
          <Typography variant="h6" fontWeight="700">
            Number of Entries
          </Typography>
          <Typography>
            Entries Remaining: <strong> 3 </strong>
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }}>
          <ButtonGroup sx={{ display: 'flex' }}>
            <CustomIcon fullWidth>
              <MinusIcon />
            </CustomIcon>
            <CustomIcon fullWidth>
              <PlusIcon />
            </CustomIcon>
          </ButtonGroup>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="700">
          Entry Fee
        </Typography>
        <Typography variant="subtitle1" fontWeight="700">
          $250
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
        <Box>
          <Typography variant="caption">Prize Type</Typography>
          <Typography variant="subtitle1" fontWeight="700">
            Prize Type
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption">Current Pot</Typography>
          <Typography variant="subtitle1" fontWeight="700">
            $750,000
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="700">
          1st Place Payout
        </Typography>
        <Typography variant="subtitle1" fontWeight="700">
          $300
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
        <Typography variant="subtitle1" fontWeight="700">
          2nd Place Payout
        </Typography>
        <Typography variant="subtitle1" fontWeight="700">
          $100
        </Typography>
      </Box>
      <Divider sx={{ mt: 4, mb: 3 }} />
      <Typography variant="h3" color="primary.main" textTransform="uppercase">
        Add Horses
      </Typography>
      <SectionTitle title="Horse 1" />
      <Box sx={{ pt: 1 }}>
        <InputLabel>Horse Name</InputLabel>
        <TextField fullWidth placeholder="Johnny Appleseed" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Birth Year</InputLabel>
            <TextField fullWidth placeholder="11/11/2007" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Sex</InputLabel>
            <FormControl fullWidth>
              <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'sex' }}>
                <MenuItem value="">SEX</MenuItem>
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Registration Number</InputLabel>
            <TextField fullWidth placeholder="000000000" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Registering Entity</InputLabel>
            <TextField fullWidth placeholder="Orchard Ranch" />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Sex</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'COLOR' }}>
          <MenuItem value="">COLOR</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Sire</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'SIRE' }}>
          <MenuItem value="">SIRE</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Damsire</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'Damsire' }}>
          <MenuItem value="">SIRE</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
      <SectionTitle title="Horse 2" />
      <Box sx={{ pt: 1 }}>
        <InputLabel>Horse Name</InputLabel>
        <TextField fullWidth placeholder="Johnny Appleseed" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Birth Year</InputLabel>
            <TextField fullWidth placeholder="11/11/2007" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Sex</InputLabel>
            <FormControl fullWidth>
              <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'sex' }}>
                <MenuItem value="">SEX</MenuItem>
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Registration Number</InputLabel>
            <TextField fullWidth placeholder="000000000" />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Registering Entity</InputLabel>
            <TextField fullWidth placeholder="Orchard Ranch" />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Sex</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'COLOR' }}>
          <MenuItem value="">COLOR</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Sire</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'SIRE' }}>
          <MenuItem value="">SIRE</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Damsire</InputLabel>
        <Select fullWidth value="" displayEmpty inputProps={{ 'aria-label': 'Damsire' }}>
          <MenuItem value="">SIRE</MenuItem>
          <MenuItem value={1}>Blue</MenuItem>
          <MenuItem value={2}>Yello</MenuItem>
          <MenuItem value={3}>Orange</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default FirstStep;
