import DefaultTheme from './defaultTheme';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
  }

  interface ButtonPropsSizeOverrides {
    extraSmall: true;
  }
}

const Components = {
  MuiButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        paddingBottom: '15px',
        paddingTop: '15px',
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 700,
      },
    },
    variants: [
      {
        props: { color: 'primary' },
        style: {
          backgroundImage: DefaultTheme.palette.background.gradient,
          color: DefaultTheme.palette.darkPallet.dark,
          border: `1px solid ${DefaultTheme.palette.primary.dark}`,
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          backgroundColor: DefaultTheme.palette.darkPallet.main,
          backgroundImage: 'none',
          border: `1px solid ${DefaultTheme.palette.primary.main}`,
          color: DefaultTheme.palette.primary.main,
        },
      },
      {
        props: { color: 'black' },
        style: {
          backgroundColor: DefaultTheme.palette.darkPallet.darker,
          border: `1px solid ${DefaultTheme.palette.darkPallet.darker}`,
          color: DefaultTheme.palette.lightPallet.lighter,
          backgroundImage: 'none',
        },
      },
      {
        props: { variant: 'text' },
        style: {
          backgroundColor: 'transparent',
          backgroundImage: 'none',
          border: `none`,
          color: DefaultTheme.palette.lightPallet.lighter,
        },
      },
      {
        props: { variant: 'outlined', color: 'black' },
        style: {
          backgroundColor: 'transparent',
          border: `1px solid ${DefaultTheme.palette.darkPallet.darker}`,
          color: DefaultTheme.palette.darkPallet.darker,
          backgroundImage: 'none',
        },
      },
      {
        props: { size: 'small' },
        style: {
          paddingTop: '5px',
          paddingBottom: '5px',
          fontSize: 12,
        },
      },
      {
        props: { size: 'extraSmall' },
        style: {
          paddingTop: '3px',
          paddingBottom: '3px',
        },
      },
    ],
  },
  MuiInputLabel: {
    styleOverrides: {
      // Name of the slot
      root: {
        paddingBottom: DefaultTheme.spacing(1),
        fontSize: '12px',
        color: DefaultTheme.palette.lightPallet.lighter,
        textTransform: 'uppercase',
        fontWeight: '700',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        backgroundColor: DefaultTheme.palette.darkPallet.main,
        borderColor: 'transparent',
        fieldset: {
          borderColor: 'transparent',
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        backgroundColor: DefaultTheme.palette.darkPallet.main,
        '& .MuiOutlinedInput-root': {
          // - The Input-root, inside the TextField-root
          '& fieldset': {
            // - The <fieldset> inside the Input-root
            borderColor: 'transparent', // - Set the Input border
          },
          '&:hover fieldset': {
            borderColor: 'transparent', // - Set the Input border when parent has :hover
          },
          '&.Mui-focused fieldset': {
            // - Set the Input border when parent is focused
            borderColor: 'transparent',
          },
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      // Name of the slot
      root: {
        backgroundColor: DefaultTheme.palette.darkPallet.main,
      },
    },
  },
};

export default Components;
