import { ReactElement, useEffect, useRef, useState } from "react";
import style from './InfiniteListV2.module.css';

const getElIndex = (el: HTMLDivElement) => {
  return parseInt(el.dataset.index as string)
}

// This component was created because existing libraries for endless scroll require setting fixed elements' height,
// and the libraries that don't require it are screwing up the spacing between elements.
// It may seem that in this component we recalculate elements heights more often than needed,
// but that's because some elements (collection specifically) may change their height over time because of loading or something.
// Try not to change it (as it happened to the previous infinite list) and it's going to be alright.
// eslint-disable-next-line
export const InfiniteListV2 = <Item extends unknown>({
  ids,
  items,
  render,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  renderBefore = 10,
  renderCount = 25,
  offsetToLoadNext = 500,
  virtual = true,
}: {
  // array of item ids to detect changing of order or the items themselves
  // if user changes some filter, ids are changed and the feed is refreshed, feed to scrolled up
  // if no ids are provided, it won't be refreshed and won't scroll up
  ids?: number[],
  items: Item[]
  render(props: { item: Item, index: number }): ReactElement
  fetchNextPage(): void
  isFetchingNextPage: boolean
  hasNextPage: boolean
  // render elements before the top of the screen to prevent flickering when scrolling up
  renderBefore?: number
  // only a batch of elements is rendered at the given moment, default batch size is 10, it should be enough for most use cases
  renderCount?: number
  // offset in px from the bottom when to start loading next page
  offsetToLoadNext?: number
  // adding/removing elements from the DOM when scrolling to not display too much elements at a time
  virtual?: boolean
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const currentStateRef = useRef({
    // to determine if scrolling up or down
    prevScroll: 0,
    // index of items to start rendering from
    startIndex: -renderBefore,
    // top px of first rendering item
    startTop: 0,
    // heights and tops of all items
    heightsAndTops: [] as { height: number, top: number }[],
    // to track last item index
    itemsLength: items.length,
    // to track change of items
    ids,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  })
  const [startIndex, setStartIndex] = useState(currentStateRef.current.startIndex)

  // once these props are updated, set them to the state immediately
  currentStateRef.current.fetchNextPage = fetchNextPage
  currentStateRef.current.isFetchingNextPage = isFetchingNextPage
  currentStateRef.current.hasNextPage = hasNextPage
  currentStateRef.current.itemsLength = items.length

  useEffect(() => {
    const current = currentStateRef.current.ids
    if (!virtual || !current || !ids) return;

    for (let i = 0; i < current.length; i++) {
      if (current[i] !== ids[i]) {
        refresh();
        break;
      }
    }
    currentStateRef.current.ids = ids
  }, [...(ids || []), virtual])

  // when startIndex is increased, we render one more element
  // this will set heights and top of newly rendered elements
  useEffect(() => {
    if (!virtual) return

    setHeightsAndTops()
  }, [startIndex, virtual])

  useEffect(() => {
    if (!virtual) return;

    const onResize = refresh
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize)
  }, [virtual])

  const refresh = () => {
    const wrapper = wrapperRef.current
    if (!wrapper) return

    const { heightsAndTops } = currentStateRef.current

    // abort if heightsAndTops were already cleared and weren't filled yet
    if (heightsAndTops.length === 0) return

    // clear all previous heights and tops
    heightsAndTops.length = 0
    // calculate new heights and tops
    setHeightsAndTops()

    // the only way to calculates the top is to begin counting from the first element which has top 0
    wrapper.scrollTop = 0
    setStartIndex(-renderBefore)
  }

  const onScroll = () => {
    const wrapper = wrapperRef.current
    const state = currentStateRef.current
    if (!wrapper) return

    if (state.prevScroll > wrapper.scrollTop) {
      onScrollUp()
    } else {
      onScrollDown()
    }

    state.prevScroll = wrapper.scrollTop
  }

  // decrement startIndex when scrolling to top and the first element is below top border of wrapper
  const onScrollUp = () => {
    const wrapper = wrapperRef.current

    // first el is renderBefore children means it's the first el after the offset
    let i = renderBefore
    let elementAbove = contentRef.current?.children[i]
    if (!wrapper || !elementAbove) return

    const top = elementAbove.getBoundingClientRect().top - 5 // subtract few px to fix problem on mobile
    if (top > wrapper.offsetTop) {
      let el: Element | undefined = elementAbove
      while (el && el.getBoundingClientRect().top - 5 > wrapper.offsetTop) {
        elementAbove = el
        i--
        el = contentRef.current?.children[i]
      }

      setStartIndex(getElIndex(elementAbove as HTMLDivElement) - 1 - renderBefore)
    }
  }

  const onScrollDown = () => {
    const index = getFirstVisibleIndex()
    if (index === undefined) return

    setStartIndex(index - renderBefore)
    loadMoreIfCloseToBottom()
  }

  // recalculate and save all heights and top positions of currently visible elements
  const setHeightsAndTops = () => {
    const content = contentRef.current
    if (!content) return

    const { children } = content
    const { heightsAndTops } = currentStateRef.current

    if (
      // if it's a first time counting and the first rendered element is not the first item in array
      // it the scroll has happened after resizing screen
      // abort calculations, since the `prev` variable below will be undefined
      heightsAndTops.length === 0 &&
      children[0] &&
      getElIndex(children[0] as HTMLDivElement) !== 0
    ) return

    for (let i = 0; i < children.length; i++) {
      const el = children[i] as HTMLDivElement
      const index = getElIndex(el)

      // top is 0 for first element, and is based on previous element for all further elements
      let top
      if (index === 0) {
        top = 0
      } else {
        const prev = heightsAndTops[index - 1]
        top = prev.top + prev.height
      }

      if (!heightsAndTops[index]) {
        heightsAndTops[index] = {
          height: el.offsetHeight,
          top,
        }
        el.style.top = `${top}px`
        el.style.visibility = 'visible'
      }
    }
  }

  const getFirstVisibleIndex = () => {
    const wrapper = wrapperRef.current
    const content = contentRef.current
    if (!wrapper || !content) return

    const { offsetTop } = wrapper
    const { children } = content

    for (let i = 0; i < children.length; i++) {
      const el = children[i] as HTMLDivElement
      const index = getElIndex(el)

      const rect = el.getBoundingClientRect()
      if (rect.bottom > offsetTop) {
        return index
      }
    }
  }

  const loadMoreIfCloseToBottom = () => {
    const wrapper = wrapperRef.current
    const children = contentRef.current?.children
    const state = currentStateRef.current
    if (!wrapper || !children?.length) return

    const lastEl = children[children.length - 1] as HTMLDivElement

    if (state.isFetchingNextPage || !state.hasNextPage) return

    if (virtual) {
      const lastIndex = getElIndex(lastEl)
      // return if last element is not yet rendered
      if (state.itemsLength - 1 !== lastIndex) return
    }

    const lastBottom = lastEl.getBoundingClientRect().bottom
    const { scrollTop, offsetHeight } = wrapper
    if (scrollTop + offsetHeight > lastBottom - offsetToLoadNext) {
      state.isFetchingNextPage = true
      state.fetchNextPage()
    }
  }

  return (
    <div className={style.wrapper} ref={wrapperRef} onScroll={onScroll}>
      <div className={style.content} ref={contentRef}>
        {virtual
          ? renderVirtual({
              startIndex,
              renderCount,
              items,
              heightsAndTops: currentStateRef.current.heightsAndTops,
              render,
            })
          : renderNonVirtual({
              items,
              render,
            })
        }
      </div>
    </div>
  )
}

const renderVirtual = ({
  startIndex,
  renderCount,
  items,
  heightsAndTops,
  render: Render,
}: {
  startIndex: number
  renderCount: number
  items: unknown[]
  heightsAndTops: { height: number, top: number }[],
  render(props: { item: unknown, index: number }): ReactElement
}) => {
  const fromIndex = Math.max(startIndex, 0)
  const renderItems = items.slice(fromIndex, fromIndex + renderCount)

  return renderItems.map((item, i) => {
    const index = fromIndex + i

    // info is absent on first render of the element
    const info = heightsAndTops[index]
    const style = info
      // when top info is present, set the top and make it visible
      ? { top: `${info.top}px`, visibility: 'visible' as const }
      // if not present, the element remains invisible
      : undefined

    return (
      <div key={index} data-index={index} style={style}>
        <Render item={item} index={index} />
      </div>
    )
  })
}

const nonVirtualStyle = { position: 'static' as const, visibility: 'visible' as const }
const renderNonVirtual = ({
  items,
  render: Render,
}: {
  items: unknown[]
  render(props: { item: unknown, index: number }): ReactElement
}) => {
  return items.map((item, i) => (
    <div key={i} style={nonVirtualStyle}>
      <Render item={item} index={i} />
    </div>
  ))
}