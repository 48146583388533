import { Page } from "../../components/Page/Page";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton"
import cls from "./ErrorPage.module.css"
import { useNavigate } from 'react-router-dom';

const reload = () => window.location.reload();

export const errorPage = (e: any) => {
  const status = Number(e.status) || 500;
  const message = String(e.message);
  return <ErrorPage status={status} message={message} />
}

export const ErrorPage = ({ status, message, showRetry = true, resetErrorBoundary }: { showRetry?: boolean, status: number, message: string, resetErrorBoundary?(): void }) => {
  const navigate = useNavigate();
  const goHome = () => {
    resetErrorBoundary?.();
    navigate('/')
  }
  return (
    <>
      <Page className={cls["error-page"]}>
        <div className={cls["title"]}>
          {status}
        </div>
        <div className={cls["desc"]}>
          {message}
        </div>


        <div className={cls["btns"]}>

          {showRetry &&
            <div className={cls["retry"]}>
              <PrimaryButton onClick={reload}>
                Retry
              </PrimaryButton>
            </div>}

          <div className={cls["back"]}>
            <PrimaryButton onClick={goHome}>
              Take me back to home
            </PrimaryButton>
          </div>
        </div>
      </Page>
    </>
  )
}