import { MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import cls from './PrimaryButton.module.css';

export type Props = {
  href?: string | null;
  className?: string | null;
  onClick?: MouseEventHandler;
  type?: 'submit';
  direct?: boolean;
  children: React.ReactNode;
};

const PrimaryButton = (props: Props) => {
  return props.href ? (
    props.direct ? (
      <a
        href={props.href}
        className={`${cls['primary-button']} ${props.className ?? ''}`.trim()}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    ) : (
      <Link
        to={props.href}
        className={`${cls['primary-button']} ${props.className ?? ''}`.trim()}
        onClick={props.onClick}
      >
        {props.children}
      </Link>
    )
  ) : (
    <button
      className={`${cls['primary-button']} ${props.className ?? ''}`.trim()}
      type={props.type}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default PrimaryButton;
