import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { StringDecoder } from "string_decoder";
import { Horse } from "./AthleteSetup/CompetitionBackgroundSetupPage";

export type SignUpContextValue = {
  firstName: string
  lastName: string
  email: string
  profileImage?: string | StringDecoder
  bio?: string;
  preferredName?: string;
  emergencyFirstName?: string;
  emergencyLastName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  emergencyPhone2?: string;
  heightFeet?: number;
  heightInches?: number;
  weight?: number;
  isoCountry?: string;
  city?: string;
  state?: string;
  location?: string;
  status?: string;
  rodeoWin?: string;
  degree?: string;
  college?: string;
  children?: number
  fatherName?: string;
  motherName?: string;
  partnerName?: string;
  nfrQualificationsYear?: string[];
  worldTitlesYear?: string[];
  biggestRodeoWin?: string;
  highestScoreYear?: string;
  highestScoreLoc?: string;
  sponsors?: string;
  favoriteHorseDraw?: string;
  scariestHorse?: string;
  rankestHorse?: string;
  travellingPartners?: string;
  rodeoRig?: string;
  qstEventRodeo?: string;
  qstSuccessfulEvent?: string;
  favoriteFood?: string;
  favoriteBeverage?: string;
  favoriteMovie?: string;
  qstOccupation?: string;
  favoriteSeries?: string;
  favoriteQuote?: string;
  favoriteAthlete?: string;
  favoriteSportTeam?: string;
  favoriteSong?: string;
  horses?: Horse[]
} | undefined

const SignUpContext = createContext<[SignUpContextValue, Dispatch<SetStateAction<SignUpContextValue>>] | null>(null);

export const SignUpContextProvider = SignUpContext.Provider

export const useSignUpContext = () => {
  const value = useContext(SignUpContext)
  if (!value) throw new Error(`SignUpContext value is not set`)
  return value
}