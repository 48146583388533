import { Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import { RightChevronIcon } from '../../components/Icons';
import { FiExternalLink } from 'react-icons/fi';
import { Box, Theme } from "@mui/system";

interface itemObj {
	title: string;
	link?: string;
	onClick?: () => void;
}

interface DashboardMenuProp {
	item: itemObj,
	mailTo?: boolean
	external?: boolean
}

const style = {
	display: 'flex',
	color: (theme: Theme) => theme.palette.lightPallet.lighter,
	fontWeight: '700',
	justifyContent: 'space-between',
	alignItems: 'center',
	textDecoration: 'none',
	textTransform: 'uppercase',
	p: 3,
	mt: '2px',
	backgroundColor: (theme: Theme) => theme.palette.darkPallet.main,
}

const linkWithChevronStyle = {
	...style,
	'& svg': {
		width: '10px',
		fill: (theme: Theme) => theme.palette.lightPallet.lighter,
	},
}

const DashboardMenuItem = ({ item, mailTo = false, external }: DashboardMenuProp) => {
	if (mailTo) {
		return (
			<Link
				component={RLink}
				sx={linkWithChevronStyle}
				to="#"
				onClick={(e) => {
					item.onClick?.()
					window.location.href = item.link || '';
					e.preventDefault();
				}}
			>
				{item.title}
				<RightChevronIcon/>
			</Link>
		)
	}

	if (external) {
		return (
			<Box
				component='a'
				sx={style}
				href={item.link}
				target='_blank'
			>
				{item.title}
				<FiExternalLink size={20} />
			</Box>
		)
	}

	if (item.onClick) {
		return (
			<Link
				component={RLink}
				sx={linkWithChevronStyle}
				to={item.link || ''}
				onClick={(e) => {
					if (item.onClick) item.onClick();
					e.preventDefault();
				}}
			>
				{item.title}
				<RightChevronIcon/>
			</Link>
		)
	}

	return (
		<Link
			component={RLink}
			sx={linkWithChevronStyle}
			to={item.link || ''}
		>
			{item.title}
			<RightChevronIcon/>
		</Link>
	);
}

export default DashboardMenuItem;
