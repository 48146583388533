import { Box, Typography } from '@mui/material';
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/system";
import { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from './CardWithImage.module.css';

const Placeholder = styled('div')(({ theme }) => ({
  background: theme.palette.darkPallet.main,
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: theme.palette.darkPallet.lighter,
  height: '100%',
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  }
}));

const PlaceholderInner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    opacity: 0.1,
  },
}));

export const CardWithImage = ({
  id,
  image,
  link,
  onImageLoad,
  title,
  subTitle,
  button,
  placeholderImage: PlaceholderImage,
  defaultLogo,
  onClick,
}: {
  id?: string;
  image?: string;
  link?: string;
  onImageLoad?(): void;
  title?: ReactNode;
  subTitle?: ReactNode;
  button?: ReactNode;
  defaultLogo?: string;
  placeholderImage?: ReactNode;
  onClick?: () => void;
}) => {
  useEffect(() => {
    if (!image || image?.slice(-10) === '/undefined') onImageLoad?.();
  }, []);

  return (
    <div className={classes.container} {...id && { id }}>
      <Box component={link ? Link : 'div'} style={{ display: 'block', position: 'relative' }} to={link} {...onClick && { onClick }}>
        <div className={classes.imgContainer}>
          {image && (
            <>
              <CardMedia
                component="img"
                image={image?.slice(-10) === '/undefined' ? defaultLogo : image}
                alt="Event media"
                sx={{ mb: 1, borderRadius: 1.5, height: '100%' }}
                onLoad={onImageLoad}
              />
              <div className={classes.imageOverlay} />
            </>
          )}
          {!image && (
            <Placeholder sx={{ borderRadius: 1.5, mb: 1, height: "88vw" }}>
              <PlaceholderInner>
                {PlaceholderImage}
              </PlaceholderInner>
            </Placeholder>
          )}
        </div>
        <div className={classes.cardContent}>
          <Typography variant='h6' textTransform='uppercase' className={classes.title}>
            {title}
          </Typography>
          <Typography variant='subtitle2' sx={{ height: 24 }} className={classes.subTitle}>
            {subTitle}
          </Typography>
        </div>
      </Box >
      {button}
    </div >
  );
};
