import React, { CSSProperties } from 'react'
import { styled } from "@mui/system"

const Outer = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
}));

const Inner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))

export const Rectangle = ({
  children,
  ratio,
  style
}: {
  children: React.ReactNode,
  ratio: number,
  style?: CSSProperties
}) => {
  return (
    <Outer style={style}>
      <div style={{ paddingBottom: `${100 / ratio}%` }} />
      <Inner>{children}</Inner>
    </Outer>
  )
}
