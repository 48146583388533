import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Page } from '../../components/Page/Page';
import Fixedbox from '../../components/Fixedbox';
import Header from '../../components/Header';
import { Helmet } from 'react-helmet';

const EventDocusign = () => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);

  const [searchParams] = useSearchParams();
  const header = searchParams.get('header');

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Document Sign | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Document Sign</title>
      </Helmet>
      <Page
        style={{
          alignItems: 'start',
          display: 'flex',
          justifyContent: header ? 'space-between' : 'center',
          flexDirection: 'column',
        }}
      >
        {header && <Header.Light />}

        <Typography variant="h1" align="center" color="primary.main" sx={{ width: '100%' }}>
          DocuSign <br /> Flow
        </Typography>

        <div>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage('/event-checkout')}>
              Checkout
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventDocusign;
