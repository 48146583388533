import request from '../utils/request';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { buildApiInfiniteQueryHook, buildApiMutationHook } from '../utils/api.utils';
import { queryClient } from '../config/queryClient';
import { CurrentUser } from './auth.api';
import { StringDecoder } from 'string_decoder';

export const fixAvatar = <T extends string | undefined>(avatar?: T): T => {
  return (avatar?.startsWith('https:/s3') ? avatar.replace(/https:\/s3/, 'https://s3') : avatar) as T;
};

// athlete type returned from API
type ApiAthlete = {
  id: number;
  uuid: string;
  name: string;
  avatar?: string;
  back_number?: string;
  bio?: string;
  city?: string;
  disciplines: string[];
  email?: string;
  event_registered?: string;
  registered_at?: string;
  sources: string[];
  state?: string;
  isFollowed?: boolean;
};

// mapped Athlete type with camelCase names
export type Athlete = Omit<ApiAthlete, 'back_number' | 'event_registered' | 'registered_at'> & {
  backNumber?: string;
  eventRegistered?: string;
  registeredAt?: string;
};

export const useAthletesInfiniteQuery = buildApiInfiniteQueryHook<
  { limit: number; filters: string[] },
  ApiAthlete[],
  Athlete[]
>(
  'athletes',
  ({ limit, pageParam, filters }) =>
    request.get('/users/athletes', {
      params: {
        limit,
        offset: (pageParam - 1) * limit,
        disciplineFilter: filters,
      },
    }),
  {
    // map data from ApiAthlete to Athlete
    select(data) {
      return data.map(({ back_number, event_registered, registered_at, ...item }) => ({
        ...item,
        // dev server returns urls that are starting with https:/
        avatar: fixAvatar(item.avatar),
        backNumber: back_number,
        eventRegistered: event_registered,
        registeredAt: registered_at,
      }));
    },
  },
);

export const useToggleAthleteFollowMutation = buildApiMutationHook<{ athleteUUID: string; follow: boolean }, void>(
  ({ athleteUUID, follow }) =>
    request.post(`/users/athletes/${athleteUUID}/toggle-follow`, {
      follow,
    }),
  (options) => ({
    ...options,
    onSuccess(data, params, rest) {
      options?.onSuccess?.(data, params, rest);

      const { follow, athleteUUID } = params;

      // update `isFollowed` in a users cache
      queryClient.setQueriesData(['athletes'], (data) => {
        const result = data as UseInfiniteQueryResult<ApiAthlete[]>['data'];
        if (!result) return result;

        return {
          ...result,
          pages: result.pages.map((page) =>
            page.map((user) => (user.uuid === athleteUUID ? { ...user, isFollowed: follow } : user)),
          ),
        };
      });
    },
  }),
);

export type UpdateUserParams = {
  uuid: string;
  firstName?: string;
  lastName?: string;
  isProfileSetUp?: boolean;
  email?: string;
  profileImage?: string | StringDecoder;
  bio?: string;
  preferredName?: string;
  phone?: string;
  emergencyFirstName?: string;
  emergencyLastName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  emergencyPhone2?: string;
  city?: string;
  state?: string;
  location?: string;
  status?: string;
  rodeoWin?: string;
  degree?: string;
  college?: string;
  children?: number;
  fatherName?: string;
  motherName?: string;
  partnerName?: string;
  interests?: string[];
  disciplines?: string[];
};

export const useUpdateUserMutation = buildApiMutationHook<UpdateUserParams, CurrentUser>(
  ({ uuid, ...data }) => request.patch(`/users/${uuid}`, { uuid, ...data }),
  (options) => ({
    ...options,
    onSuccess(updatedUser, ...rest) {
      // send GA event
      window.dataLayer.push({
        event: 'edit-profile',
      });

      // currentUser query has request parameters, so setQueriesData (plural) must be used
      queryClient.setQueriesData(['currentUser'], updatedUser);

      options?.onSuccess?.(updatedUser, ...rest);
    },
  }),
);
