import { styled } from '@mui/system';

const Fixedbox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.darkPallet.dark,
  width: '100%',
  bottom: 0,
  left: 0,
  right: 0,
  position: 'fixed',
  padding: '15px 30px',
  textAlign: 'center',
  zIndex: 9,
}));

export default Fixedbox;
