import { useStandingCategoriesQuery } from "../../../api/standings.api";
import { useParams, useNavigate } from "react-router-dom";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { Helmet } from "react-helmet";
import { Layout } from "../../../components/Layout/Layout";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import InterestSlider from "../../../pages/Feeds/InterestSlider";
import { NotFoundPage } from "../../../components/Errors/NotFoundPage";
import { routes } from "../../../routes";
import { StandingCategory } from "./StandingCategory";

export const StandingViewPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const folderId = params.id as string

  const { data } = useStandingCategoriesQuery({ folderId })

  if (!data) return <LoadingScreen />

  const firstCategory = data.categories[0]
  if (!firstCategory) return <NotFoundPage />

  const category = params.category || firstCategory.name

  const openCategory = (category: string) => {
    navigate(routes.standingCategoryView(folderId, category))
  }

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Standings | Teton Ridge+`} />
        <meta name="twitter:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content={`Standings | Teton Ridge+`} />
        <meta property="og:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Results</title>
      </Helmet>
      <Layout
        contentSx={{ pl: 0 }}
        header={{ backIcon: true, backTo: routes.standings }}
      >
        <Box sx={{
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Box sx={{ textAlign: 'center', my: 2, flexShrink: 0 }}>
            <Typography variant='h3' color='#BD9B60' sx={{ fontSize: '28px' }}>
              {data.name}
            </Typography>
          </Box>
          <Box sx={{ px: 2, mb: 2, flexShrink: 0 }}>
            <InterestSlider
              tags={data.categories.map((item) => ({
                ...item,
                selected: item.name === category,
              }))}
              onToggle={openCategory}
            />
          </Box>
          <StandingCategory folderId={folderId} category={category} />
        </Box>
      </Layout>
    </>
  )
}
