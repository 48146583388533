import React, { useEffect, useRef, useState, Children } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_MAP_API_KEY } from '../../config/constants';
import Marker from './marker';
import activeMarkerIcon from '../../img/marker-active.png';
import markerIcon from '../../img/marker.png';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const MapComponent = ({ onClick, onIdle, style, children, ...options }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, options));
    }
  }, [ref, map, options]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName));

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (onIdle) {
        map.addListener('idle', () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  return (
    <>
      <div ref={ref} id="map" style={style} />
      {Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

interface EventMapProps {
  height: string;
  locations: any;
  latLng?: any;
}

const EventMap = ({
  height = '200px',
  latLng = {
    lat: 47.609414458375674,
    lng: -122.33897030353548,
  },
  locations = [],
}: EventMapProps) => {
  const zoom = 12;
  const mapProps = {
    center: latLng,
    zoom,
    mapTypeId: 'terrain',
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };

  const mapTypeStyle = [
    { elementType: 'geometry', stylers: [{ color: '#E4ECC3' }] },

    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#f6828e' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#e6f6a4' }],
    },
  ];

  return (
    <Wrapper apiKey={GOOGLE_MAP_API_KEY}>
      <MapComponent styles={mapTypeStyle} {...mapProps} style={{ height }}>
        {locations.map((location: any, i: number) => (
          <Marker
            key={i}
            position={location.latLng}
            icon={location.active ? activeMarkerIcon : markerIcon}
            zIndex={location.active ? 99 : 1}
          />
        ))}
      </MapComponent>
    </Wrapper>
  );
};

export { EventMap };
