import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { useState, useEffect } from 'react';
import { _get } from '../../net/net';
interface Collection {
  attributes: CollectionAttributes;
  id: number;
  onView: (id: number) => void;
}

interface CollectionAttributes {
  Collection_Image: CollectionImage;
  Collection_Title: string;
  article_posts: ArticlePost;
  video_posts: VideoPost;
}

interface CollectionImage { }
interface ArticlePost {
  data: ArticlePostData[];
}
interface ArticlePostData { }
interface VideoPost {
  data: VideoPostData[];
}
interface VideoPostData { }

const CollectionItem = (props: Collection) => (
  <>
    <Box
      sx={{
        mt: 2,
        p: 2,
        display: 'flex',
        justifyContent: 'end',
        flexDirection: 'column',
        minHeight: '350px',
      }}
    >
      <Box sx={{ textAlign: 'center', pb: 10 }}>
        <Typography variant="h3" align="center" fontSize={24}>
          {props.attributes.Collection_Title}
        </Typography>
        <Typography variant="caption" sx={{ pt: 1 }}>
          {props.attributes.article_posts.data.length} Articles . {props.attributes.video_posts.data.length} Videos
        </Typography>
      </Box>
      <Button fullWidth variant="outlined" sx={{ textTransform: 'uppercase' }} onClick={() => props.onView(props.id)}>
        View Collection
      </Button>
    </Box>
  </>
);

const SliderItem = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#423C37', width: '320px', m: 1, borderRadius: 2, p: 2 }}>{props.children}</Box>
    </Box>
  );
};

const CollectionSlider = () => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };

  const navigate = useNavigate();

  const onView = (id: number) => navigate(`/collections/${id}`);

  const [collections, setCollections] = useState<Collection[] | null>(null);

  useEffect(() => {
    _get(`${process.env.REACT_APP_CMS_BASE_URL}api/custom-collections?populate=*`).then((response) => {
      setCollections(response.data);
    });
  }, []);

  return (
    <Box sx={{ pb: 3, marginLeft: '-15px', marginRight: '-15px' }}>
      <Slider {...settings}>
        {collections &&
          collections.map((collection) => {
            return (
              <SliderItem key={collection.id}>
                <CollectionItem {...collection} onView={onView} />
              </SliderItem>
            );
          })}
      </Slider>
    </Box>
  );
};

export default CollectionSlider;
