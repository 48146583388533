import Box from '@mui/material/Box';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Page } from '../../components/Page/Page';
import Navbar from '../../components/Navbar';
import MapView from './MapView';
import Header, { CustomIconButton } from '../../components/Header';
import { MapIcon, EventIcon } from '../../components/Icons';
import EventView from './EventView';
import Interest from '../../components/Interest';
import { Helmet } from 'react-helmet';
import { hardcodedInterests } from "../../api/interests.api";

const Events = () => {
  const [searchParams] = useSearchParams();
  const view = searchParams.get('view');
  const navigate = useNavigate();
  const onPage = () => {
    navigate(`/events?view=${view === 'map' ? 'events' : 'map'}`);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Events | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Events</title>
      </Helmet>
      <Page sx={{
        justifyContent: 'space-between'
      }}>
        <Box>
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              width: '100%',
              backgroundColor: 'darkPallet.darker',
              zIndex: 1,
              pr: '1rem',
              pl: '1rem',
            }}
          >
            <Header
              RightComponent={<CustomIconButton onClick={onPage}>{view === 'map' ? <EventIcon /> : <MapIcon />}</CustomIconButton>} />
            <Interest.Slider list={hardcodedInterests} />
          </Box>
          <Box sx={{ height: '170px' }} />
          {view === 'map' ? <MapView /> : <EventView />}
        </Box>
        <Navbar />
      </Page>
    </>
  );
};

export default Events;
