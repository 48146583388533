import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme, { Root } from './theme';
import { User as TUser } from './definitions';
import { Logo } from './components/Logo/Logo';
import strapi from './net/api';
import cls from './App.module.css';
import { AppErrorContext, UserContext } from './context/context';
import Routes from './Router';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
import { useAuth0 } from "@auth0/auth0-react";
import { setUserToDatalogRum } from "./config/datadogRum";

export const App = () => {
  const [pending, setPending] = useState(false);
  const [user, setUser] = useState<TUser | null>(null);
  const [appError, setAppError] = useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    (async () => {
      setPending(true);
      try {
        await strapi.load();
        setPending(false);
        if (strapi.getUser()) {
          setUser(strapi.getUser() as TUser);
        }
      } catch (e: any) {
        setPending(false);
        setError(e);
      }
    })();
  }, []);

  const { user: auth0User } = useAuth0()
  useEffect(() => {
    setUserToDatalogRum(auth0User)
  }, [auth0User])

  if (pending) {
    return (
      <div className={cls['app']}>
        <Pending />
      </div>
    );
  }

  return (
    <AppErrorContext.Provider value={[error, setAppError]}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={user}>
          <ErrorBoundary fallbackRender={({ error, resetErrorBoundary }) => {
            return <ErrorPage status={500} message={error.message} resetErrorBoundary={resetErrorBoundary} />
          }} onReset={() => { }}>
            <Root className={cls['app']}>
              <Routes appError={appError} />
            </Root>
          </ErrorBoundary>
        </UserContext.Provider>
      </ThemeProvider>
    </AppErrorContext.Provider>
  );
};

export const Pending = () => {
  return (
    <div className={cls['pending']}>
      <Logo />
    </div>
  );
};

export default App;
