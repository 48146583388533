import React, { useState } from 'react'
import { SignUpContextProvider, SignUpContextValue } from "./SignUpContext";
import { PhoneContextProvider, PhoneContextValue } from "./PhoneContext";
import { DisciplineContextProvider, DisciplineContextValue } from "./DisicplineContext";
import { CurrentUser } from "../../../api/auth.api";

// currentUser is present in the athlete flow, but not in fan user flow
export const SignUpContainer = ({ currentUser, element }: { currentUser?: CurrentUser, element: React.ReactNode }) => {
  const state = useState<SignUpContextValue>(undefined)
  const phoneState = useState<PhoneContextValue>(
    currentUser?.phone ? { phone: currentUser.phone } : undefined
  )
  const disciplineState = useState<DisciplineContextValue>(undefined)

  return <SignUpContextProvider value={state}>
    <PhoneContextProvider value={phoneState}>
      <DisciplineContextProvider value={disciplineState}>
        {element}
      </DisciplineContextProvider>
    </PhoneContextProvider>
  </SignUpContextProvider>
};
