import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import { PostProps } from "../Post";
import useWindowDimensions from "../../../hooks/useWindowDimentions";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { Link as RLink } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { formatDateFromNow } from "../../../utils/date.utils";
import { EyeLineIcon } from "../../Icons";
import { Rectangle } from "../../Shapes/Rectangle/Rectangle";
import { Video } from './Video'
import { VideoPostHeader } from "./VideoPostHeader";
// import PinIcon from "../../../img/pin-post.png";
// import PinnedIcon from "../../../img/pin-post-active.png";
import { Square } from "../../Shapes/Square/Square";
import videoPostThumb from '../../../img/video-post-thumb.png';

import CardMedia from '@mui/material/CardMedia';


const ViewsChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.darkPallet.darker,
  color: theme.palette.lightPallet.lighter,
  fontSize: '12px',
  height: '20px',
  borderColor: 'transparent',
  borderRadius: '3px',
  '& .MuiChip-label': {
    paddingLeft: '3px',
    paddingRight: '4px',
  },
  '& svg': { height: '15px', width: '15px', fill: theme.palette.lightPallet.lighter, paddingLeft: '5px' },
}));

type VideoPostProps = PostProps & {
  isLive?: boolean;
  feature?: boolean;
  views?: string;
  isMux?: boolean;
  muxId?: number;
  isPin?: boolean;
  pinnedList?: boolean,
  onLoad?(): void;
  onOpen?(item: { id: number }): void,
  onPin?(): void,
  refFirstPost?: any
}

export const VideoPost = ({
  id,
  title,
  media,
  isMux,
  onLoad,
  onOpen,
  isLive,
  feature,
  logo,
  muxId,
  date,
  category,
  user,
  views,
  // onPin,
  // isPin,
  // refFirstPost,
  pinnedList = false,
}: VideoPostProps) => {
  const { width } = useWindowDimensions();
  useEffect(() => onLoad?.(), [])

  const mediaId = (media: any) => {
    const regEx = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
    const match = media.match(regEx);
    if (match && match.length == 7) {
      return match[6];
    }
    else {
      return null
    }
  }

  return !pinnedList ? (
    <Card
      sx={{
        mx: 'auto',
        position: 'relative',
        overflow: 'inherit',
        backgroundColor: '#423C37 !important',
        borderRadius: 3,
        width: (width > 700) ? '650px' : '100%'
      }}
    >
      {/* <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 100,
          padding: '10px',
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          borderRadius: "50%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onPin}
      >
        <img style={{
          height: '1.5rem',
          width: '1.5rem',
        }} src={isPin ? PinnedIcon : PinIcon} />
      </div> */}
      <VideoPostHeader title={title} logo={logo} isLive={isLive} feature={feature} />
      {media && (
        <Rectangle
          ratio={16 / 9}
          style={{
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            background: 'black',
          }}
        >
          <Video isMux={isMux} media={media} title={title} />
        </Rectangle>
      )}
      <Link
        component={RLink}
        variant="subtitle1"
        sx={{ textDecoration: 'none' }}
        fontSize="20px"
        lineHeight="22px"
        color="primary.main"
        fontFamily="BourbonW00-Regular"
        to={isMux ? `/video/${id}/${muxId}` : `/video/${id}`}
        onClick={() => onOpen?.({ id })}
      >
        <CardContent>
          {date && (
            <Typography variant="caption" color='white' sx={{ pb: 1 }}>
              {formatDateFromNow(new Date(date))}
            </Typography>
          )}
          {category && <Typography>{category}</Typography>}

          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                flex: 2,
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Typography variant='h3' color='white' sx={{ fontSize: '28px' }}>
                  {title}
                </Typography>
                {user && (
                  <Typography variant="caption" color="grey.400">
                    By {user}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          {views && (
            <Box sx={{ mt: 1 }}>
              <ViewsChip icon={<EyeLineIcon />} label={views} variant="outlined" size="small" />
            </Box>
          )}
        </CardContent>
      </Link>
    </Card>
  ) : (
    <Card
      sx={{
        mb: 2,
        position: 'relative',
        mt: 3, overflow: 'inherit',
        backgroundColor: '#423C37 !important',
        borderRadius: 3, mx: 'auto',
        height: '450px',
        width: '350px'
      }}
    >
      {/* <div
        ref={refFirstPost}
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 100,
          padding: '10px',
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          borderRadius: "50%",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onPin}
      >
        <img style={{
          height: '1.5rem',
          width: '1.5rem',
        }} src={isPin ? PinnedIcon : PinIcon} />
      </div> */}
      <Link
        component={RLink}
        variant="subtitle1"
        sx={{ textDecoration: 'none' }}
        fontSize="28px"
        lineHeight="31px"
        color="primary.main"
        fontFamily="BourbonW00-Regular"
        className="ga-article"
        to={isMux ? `/video/${id}/${muxId}` : `/video/${id}`}
        onClick={() => onOpen?.({ id })}
      >
        <CardContent sx={{ p: 0, paddingBottom: '0 !important' }}>
          {/* <PostLogoWrapper sx={{ position: 'absolute', top: '-16px', left: '10px' }}>
            <Avatar sx={{ height: '40px', width: '40px' }} src={props.logo} alt={props.title || ''} />
          </PostLogoWrapper> */}

          {media && (

            <Square>
              <Box sx={{
                position: 'relative',
                height: '450px',
                width: '350px',
              }}>
                <div style={{
                  height: '450px',
                  width: '350px',
                  backgroundColor: 'rgba(0,0,0,0.3)',
                  position: 'absolute',
                  top: 0
                }}>
                </div>
                <CardMedia
                  component="img"
                  image={mediaId(media) ? 'https://vumbnail.com/' + mediaId(media) + '.jpg' : videoPostThumb}
                  alt="Content media"
                  sx={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    width: '100%',
                    height: '100%',
                  }}
                  onLoad={onLoad}
                />
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {title}
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      pt: 1,
                      mb: 3,
                    }}>
                      {user && (
                        <Typography variant="caption" color="grey.400" sx={{
                          fontSize: '15px',
                          paddingRight: '15px'
                        }}>
                          By {user}
                        </Typography>
                      )}
                      {date && (
                        <Typography variant="caption" color="grey.400" sx={{
                          fontSize: '15px'
                        }}>
                          {formatDateFromNow(new Date(date))}
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  {/* <Box sx={{
                    border: '2px solid',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    width: '200px',
                  }}>
                    WATCH NOW
                  </Box> */}
                </Box>
                <Box sx={{
                  position: 'absolute',
                  top: '80%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box sx={{
                    border: '2px solid',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    width: '200px',
                  }}>
                    WATCH NOW
                  </Box>
                </Box>
              </Box>
            </Square>
          )}
        </CardContent>
      </Link>
    </Card >
  )
};
