import { ReactNode } from "react";
import Header, { HeaderProps } from "../Header";
import Tabs, { Tab } from "../Tabs";
import { Box, SxProps } from "@mui/material";
import Navbar from "../Navbar";

type Props = {
  children: ReactNode
  header?: HeaderProps
  tabs?: {
    value: number
    options: string[]
    onChange(value: number): void
  }
  contentSx?: SxProps
  backTo?: string
}

export const Layout = ({ children, tabs, contentSx, backTo, header }: Props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Header style={{ margin: 0 }} backTo={backTo || header?.backTo} backIcon={!!backTo || header?.backIcon} />

      {tabs && (
        <Tabs
          value={tabs.value}
          onChange={(_, value) => tabs.onChange(value)}
          variant="fullWidth"
          sx={{ py: 2, px: 1, flexShrink: 0 }}
        >
          {tabs.options.map((label) => (
            <Tab sx={{ py: 1.75 }} label={label} />
          ))}
        </Tabs>
      )}

      <Box sx={{ flexGrow: 1, ...contentSx }}>
        {children}
      </Box>

      <Navbar sx={{ position: 'static' }} offset={false} />
    </div>
  )
}
