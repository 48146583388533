import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from "@mui/system";
import { Logo2 } from '../../../components/Logo/Logo';
import { Page } from '../../../components/Page/Page';
import pageBg from '../../../img/welcome-page/welcome-page-athletes-bg.png';
import pageBgXl from '../../../img/welcome-page/welcome-page-athletes-xl.png';
import useWindowDimensions from '../../../hooks/useWindowDimentions';
import { useAuth0Login } from "../auth.service";
import { useState } from 'react';
import CookieBanner from '../../../components/CookieBanner';

const Content = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    backgroundImage: `url(${pageBgXl})`,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${pageBg})`,
  },
  backgroundImage: `url(${pageBgXl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 1,
}))

export const WelcomeAthletesPage = () => {
  const { width } = useWindowDimensions();
  const [isCookieBanner, setCookieBanner] = useState(true)

  const isCookiesDisabled = !navigator.cookieEnabled;

  const login = useAuth0Login({ isAthlete: true })

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Welcome | Teton Ridge+</title>
      </Helmet>
      <Page
        style={{
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          height: (width > 600) ? '100%' : '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Content>
          <Box sx={{ position: 'relative', height: '100px', mt: 2 }}>
            <Logo2 />
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              fontFamily={'Barlow Condensed Bold'}
              align='center'
              fontWeight='700'
              color='white'
              sx={{ pb: 2, pt: 5, px: '50px', fontSize: '44px' }}
              textTransform='uppercase'
              lineHeight={1.2}
            >
              Register and build your <br />
              athlete profile now!<br />
            </Typography>
          </Box>
          <Box sx={{
            mt: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => { isCookiesDisabled ? setCookieBanner(true) : login('signup') }}
              style={{ width: '112px' }}
              sx={{ mb: 1 }}
            >
              REGISTER
            </Button>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => { isCookiesDisabled ? setCookieBanner(true) : login('login') }}
              style={{ width: '112px' }}
              sx={{ mb: 1 }}
            >
              LOG IN
            </Button>
          </Box>
          {isCookieBanner && isCookiesDisabled && <CookieBanner setCookieBanner={setCookieBanner} />}

        </Content>
      </Page>
    </>
  );
};
