import Interest from '../../components/Interest';
import { InterestItem } from "../../components/Interest/InterestItem";

type SliderProps = {
  tags: InterestItem[];
  onToggle(id: string): void;
}

const InterestSlider = (props: SliderProps) => {
  const { tags, onToggle } = props;
  return <Interest.Slider list={tags} onToggle={onToggle} />;
};

export default InterestSlider;
