// We use underscore prefixed names because the names are very
// common and we don't want them to clash with user's variables names

export class HttpError extends Error {
  status: number;
  constructor(status: number, message: string) {
    super(message);
    this.status = status;
  }
}

export const _request = async (url: string, req: RequestInit) => {
  const res = await fetch(url, req).catch((e) => {
    throw new HttpError(502, `Failed to fetch: (${e.message})`);
  });

  const json = await res.json().catch(() => {
    throw new HttpError(res.status, 'Failed to fetch: Invalid JSON');
  });

  if (json.error) {
    throw new HttpError(res.status, String(json.error.message));
  }

  return json;
};

export const _get = async (url: string) => {
  return await _request(url, {});
};

export const _delete = async (url: string) => {
  return await _request(url, {
    method: 'DELETE',
  });
};

export const _post = async (url: string, body: any) => {
  return await _request(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
};

export const _put = async (url: string, body: any) => {
  return await _request(url, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(body),
  });
};
