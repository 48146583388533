import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Box, styled } from '@mui/system';
import { Button, Card, CardMedia, Typography, IconButton } from '@mui/material';
import Fixedbox from '../../components/Fixedbox';
import { Page } from '../../components/Page/Page';
import Header from '../../components/Header';
import TShirt from '../../img/t-shirt.png';
import { TimesIcon } from '../../components/Icons';

const Products = [
  {
    title: 'BUCK’D Tee',
    price: '$29.95',
    media: TShirt,
    size: 'l',
    id: '01',
    items: 2,
  },
  {
    title: 'BUCK’D Tee',
    price: '$29.95',
    media: TShirt,
    size: 's',
    id: '01',
    items: 2,
  },
];

const RemoveButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  top: '0',
  '& svg': {
    fill: theme.palette.grey[400],
    height: '20px',
    width: '20px',
    opacity: 0.56,
  },
}));

const ActionButton = styled(Button)(() => ({
  paddingTop: '10px',
  paddingButtom: '10px',
  fontSize: '1.5em',
}));

const Cart = () => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Cart | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Cart</title>
      </Helmet>
      <Page>
        <Header backIcon={false} disableSearch cartIcon={false} />
        <Box sx={{ mb: 2 }} />
        <Box>
          {Products.map(
            (
              item: { media: string; title: string; price: string; size: string; id: string; items: number; },
              index: number
            ) => {
              return (
                <Box key={index} sx={{ mt: 2 }}>
                  <Card sx={{ backgroundColor: 'darkPallet.main' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 2, position: 'relative' }}>
                      <RemoveButton>
                        <TimesIcon />
                      </RemoveButton>
                      <Box sx={{ flex: 1, pr: 2 }}>
                        <CardMedia
                          component="img"
                          sx={{ mr: 2, borderRadius: '5px' }}
                          src={item.media}
                          alt={item.title} />
                      </Box>
                      <Box>
                        <Typography variant="h3" fontSize="16px" color="primary.main">
                          {item.title}
                        </Typography>
                        <Typography fontWeight="500">
                          Size
                          <Typography variant="caption" fontSize="14px" textTransform="uppercase" sx={{ pl: 1 }}>
                            {item.size}
                          </Typography>
                        </Typography>
                        <Typography fontWeight="700">{item.price}</Typography>
                        <Box
                          sx={{
                            backgroundColor: 'darkPallet.main',
                            display: 'flex',
                            borderRadius: '5px',
                            alignItems: 'center',
                            mt: 1,
                          }}
                        >
                          <ActionButton variant="text">-</ActionButton>
                          <Typography variant="caption" fontSize="18px" fontWeight="700">
                            2
                          </Typography>
                          <ActionButton variant="text">+</ActionButton>
                        </Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              );
            }
          )}
        </Box>
        <Box sx={{ display: 'flex', mt: 2, alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography>Total</Typography>
          <Typography variant="subtitle1" fontWeight="700">
            $59.90
          </Typography>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage('/checkout')}>
              BUY
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default Cart;
