import { StandingResults } from "../../../api/standings.api";
import { Table, FirstColumn, Position } from "./TableElements";
import { styled } from "@mui/system";

export const Wrapper = styled('div')(() => ({
  width: '100%',
  overflow: 'auto',
  background: '#423C37',
  flexGrow: 1,
}))

export const FirstHeaderColumn = styled('th')(({theme}) => ({
  textAlign: 'left',
  borderRight: '3px solid #2D2926',
  padding: '0 24px 0 38px',
  borderBottom: '1px solid #6B6B6B',
  fontWeight: 'normal',
  [theme.breakpoints.up('sm')]: {
    position: 'sticky',
    left: 0,
    background: '#423C37',
  },
}))

export const HeaderCell = styled('th')(() => ({
  height: '46px',
  padding: '0 12px',
  borderBottom: '1px solid #6B6B6B',
  fontSize: 13,
}))

export const Cell = styled('td')(() => ({
  borderBottom: '1px solid #6B6B6B',
  height: 56,
  textAlign: 'left',
  padding: '0 20px',
}))

export const ResultsTable = ({
  item: { titles, data }
}: {
  item: StandingResults.Values
}) => {
  const maxCells = Math.max(...data.map((row) => row.length))
  const emptyRow = Array.from({ length: maxCells - 2 }) // -2 is the row without place and team

  return (
    <Wrapper>
      <Table>
        <thead>
          <tr>
            <FirstHeaderColumn><b>{titles[1]}</b></FirstHeaderColumn>
            {titles.slice(2).map((title, i) => (
              <HeaderCell key={i}>{title}</HeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
        {data.map((row, i) => (
          <tr key={i}>
            <FirstColumn>
              <Position>{row[0]}.</Position> {row[1]}
            </FirstColumn>
            {emptyRow.map((_, i) => (
              <Cell key={i}>{row[i + 2] || '--'}</Cell>
            ))}
          </tr>
        ))}
        </tbody>
      </Table>
    </Wrapper>
  )
}
