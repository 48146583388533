import { Alert, Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from "react";
import { Navigate } from 'react-router-dom';
import { interestImages, useInterestsQuery } from "../../../../api/interests.api";
import Fixedbox from '../../../../components/Fixedbox';
import Interest from '../../../../components/Interest';
import { Page } from '../../../../components/Page/Page';
import { Auth0User, useRegisterUser, useUpdateAthlete } from "../../auth.service";
import { usePhoneContext } from "../PhoneContext";
import { useSignUpContext } from "../SignUpContext";
import { Helmet } from 'react-helmet';
import { CurrentUser } from '../../../../api/auth.api';
import { routes } from '../../../../routes';
import { useDisciplineContext } from '../DisicplineContext';

const MIN_INTERESTS_TO_SELECT = 1;

const ChooseInterest = ({ user, currentUser: athleteUser }: { user: Auth0User; currentUser?: CurrentUser }) => {
  const { data: interests } = useInterestsQuery();
  const [selectedIds, setSelectedIds] = useState<Record<string, true>>({});
  const [showMinError, setShowMinError] = useState(false);
  const { mutate: register, isLoading } = useRegisterUser();
  const { mutate: registerAthlete } = useUpdateAthlete({ lastStep: true });

  const [phone] = usePhoneContext();
  if (!phone?.phone) {
    return <Navigate to={athleteUser ? routes.athleteAuth.phoneVerification : '/profile-setup'} />
  }

  const [disciplines] = useDisciplineContext();
  if (!disciplines?.disciplineIds && athleteUser) {
    return <Navigate to={routes.athleteAuth.disciplines} />
  }

  const [profile] = useSignUpContext();
  if (!profile) {
    return <Navigate to={athleteUser ? routes.athleteAuth.profileSetup : '/profile-setup'} />;
  }

  const interestsWithSelect = interests && interests.length && interests.map((item) => {
    const imageUrl = interestImages[item.iconUrl as keyof typeof interestImages];
    return selectedIds[item.uuid] ? { ...item, selected: true, imageUrl } : { ...item, imageUrl };
  });

  const toggleInterest = (id: string) => {
    setShowMinError(false);
    setSelectedIds(({ ...ids }) => {
      if (ids[id]) {
        delete ids[id];
      } else {
        ids[id] = true;
      }
      return ids;
    });
  };

  const submit = () => {
    const ids = Object.keys(selectedIds);
    if (ids.length <= (MIN_INTERESTS_TO_SELECT - 1)) {
      setShowMinError(true);
      return;
    }
    // send event to analytics
    window.dataLayer.push({
      event: 'sign-up-complete',
    });
    if (athleteUser) {
      registerAthlete({
        ...profile,
        uuid: athleteUser.uuid,
        isProfileSetUp: true,
        disciplines: disciplines?.disciplineIds,
        interests: Object.keys(selectedIds),
        phone: phone.phone,
      })
    } else {
      register({
        ...profile,
        idAuth0: user.sub,
        interests: Object.keys(selectedIds),
        phone: phone.phone,
      });
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Choose Interests | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Choose Interests</title>
      </Helmet>
      <Page sx={{ pt: 2 }}>
        <Box>
          <Box>
            <Typography variant="h3" align="center" textTransform="uppercase" color="primary.main" marginBottom={3}>
              Select your Interests
            </Typography>
          </Box>

          <Box>

          </Box>
          {showMinError && (
            <Container maxWidth='xs'>
              <Box mb={4}>
                <Alert severity="error">Please select at least {MIN_INTERESTS_TO_SELECT}</Alert>
              </Box>
            </Container>
          )}
          <Box>
            <Interest list={interestsWithSelect || []} onToggle={toggleInterest} />
          </Box>
        </Box>
        <Fixedbox>
          <Button disabled={isLoading} variant="contained" color="primary" fullWidth onClick={submit}>
            Finish
          </Button>
        </Fixedbox>
      </Page>
    </>
  );
};

export default ChooseInterest;
