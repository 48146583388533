import React, { useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { Auth0User, useAuthTokenQuery, useLogOut } from "../auth.service";
import { setRequestAuthToken } from "../../../utils/request";

type Props = {
  element: (props: { user: Auth0User }) => React.ReactElement
}

export const AuthorizedButNotRegisteredRoute = withAuthenticationRequired(({ element }: Props) => {
  useAuthTokenQuery({
    onSuccess: setRequestAuthToken,
  })

  const { user } = useAuth0<Auth0User>()
  const { logout } = useLogOut()

  useEffect(() => {
    if (!user) logout()
  }, [user])

  return user ? element({ user }) : null
}, { onRedirecting: LoadingScreen });
