import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './features/Auth/RouteGuards/PrivateRoute';
import { AuthorizedButNotRegisteredRoute } from "./features/Auth/RouteGuards/AuthorizedButNotRegisteredRoute";
import { Main } from './components/Main/Main';
import { ErrorPage } from './pages/ErrorPage/ErrorPage';
// import SignIn from './legacy/pages/SignIn';
// import SignUp from './legacy/pages/SignUp';
import ProfileSetupPage from './features/Auth/SignUpFlow/ProfileSetup/ProfileSetupPage';
import VerifyNumber from './features/Auth/SignUpFlow/VerifyPhoneNumber/VerifyNumber';
import ChooseDisciplines from './features/Auth/SignUpFlow/AthleteSetup/AthleteDiscipline';
import ChooseInterest from './features/Auth/SignUpFlow/ChooseInterest/ChooseInterest';
import Dashboard from './pages/Dashboard/Dashboard';
import Settings from './pages/Settings';
import Notifications from './pages/Notifications';
import PaymentSettings from './pages/PaymentSettings';
import NotificationSettings from './pages/NotificationSettings';
import { FeedPage } from './pages/Feeds/FeedPage';
import ArticleView from './pages/ArticleView/ArticleView';
import GamingView from './pages/GamingView/GamingView';
import Collections from './pages/Collections';
import Watch from './pages/Watch';
import VideoView from './pages/VideoView';
import Shop from './pages/Shop';
import AllShop from './pages/Shop/AllShop';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Profile from './pages/Profile';
import JoinFanbase from './pages/JoinFanbase';
import Events from './pages/Events';
import Event from './pages/Event';
import EventRegistration from './pages/EventRegistration';
import EventRegistrationCompete from './pages/EventRegistration/EventRegistrationCompete';
import EventRegistrationHorses from './pages/Event/EventRegistrationHorses';
import EventDocuments from './pages/EventDocuments';
import EventDocusign from './pages/EventDocuments/EventDocusign';
import EventCheckout from './pages/EventCheckout';
import EventThankyou from './pages/EventThankyou';
import EventDayShift from './pages/Events/EventDayShift';
import EventTeamSelect from './pages/EventTeamSelect';
import TeamAdding from './pages/EventTeamSelect/TeamAdding';
import TeamPartnerSelection from './pages/EventTeamSelect/TeamPartnerSelection';
import { Articles } from './pages/Articles/Articles';
import { Standings } from './legacy/pages/Standing/Standings'
import StandingView from './legacy/pages/StandingView';
import AthleteProfile from './features/Athlete/AthleteProfile/AthleteProfile';
import EditProfile from './features/Athlete/AthleteProfile/EditProfile';
import { AnimalProfile } from './features/Animal/AnimalProfile/AnimalProfile';
import EditUserProfile from './pages/EditUserProfile';
import { GamesFeedPage } from './features/Games/GamesFeed/GamesFeedPage';
import Live from './pages/Live/Live';
import EventDisciplines from './pages/Event/EventDisciplines';
import LoadingPage from './pages/Empty/Loading';
import ConnectionLost from './pages/Empty/ConnectionLost';
import SomethingWrong from './pages/Empty/SomethingWrong';
import { SignUpContainer } from "./features/Auth/SignUpFlow/SignUpContainer";
import { NotAuthorizedRoute } from './features/Auth/NotAuthorizedRoute';
import { AthletesOrAnimalsPage } from "./features/Athlete/AthletesList/AthletesOrAnimalsPage";
import { routes } from "./routes";
import { NotFoundPage } from "./components/Errors/NotFoundPage";
import { StandingsListPage } from "./features/Standings/StandingsList/StandingsListPage";
import { StandingViewPage } from "./features/Standings/StandingView/StandingViewPage";
import { ENABLE_STANDINGS } from "./config/constants";
import {
  AuthorizedButNotRegisteredAthleteRoute
} from "./features/Auth/RouteGuards/AuthorizedButNotRegisteredAthleteRoute";
import AthleteSetupPage from './features/Auth/SignUpFlow/AthleteSetup/AthleteSetupPage';
import CompetitionBackgroundSetupPage from './features/Auth/SignUpFlow/AthleteSetup/CompetitionBackgroundSetupPage';
import HobbiesSetupPage from './features/Auth/SignUpFlow/AthleteSetup/HobbiesSetupPage';
import { WelcomePage } from './features/Auth/WelcomePage/WelcomePage';
import GameDetail from './features/Games/GameDetail/GamePage';
import GamingVideoView from './pages/VideoView/gaming-index';
import { PlayGamePage } from './features/Games/PlayGame/PlayGamePage';

const router = ({ appError }: { appError: any }) => (
  <Main>
    {appError ? (
      <ErrorPage status={(appError as any).status || 500} message={appError.message} showRetry />
    ) : (
      <Routes>
        <Route path='/' element={<NotAuthorizedRoute />} />
        <Route path='/welcome' element={<WelcomePage />} />
        <Route path={routes.athleteAuth.welcome} element={<NotAuthorizedRoute isAthleteRoute />} />
        <Route path={routes.athleteAuth.phoneVerification} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={
              <VerifyNumber currentUser={currentUser} />}
            />}
          />
        } />
        <Route path={routes.athleteAuth.disciplines} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={<ChooseDisciplines />} />}
          />
        } />
        <Route path={routes.athleteAuth.profileSetup} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ user, currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={
              <AthleteSetupPage user={user} currentUser={currentUser} />}
            />}
          />
        } />
        <Route path={routes.athleteAuth.stepTwoSignup} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={<CompetitionBackgroundSetupPage currentUser={currentUser} />} />}
          />
        } />
        <Route path={routes.athleteAuth.stepThreeSignup} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={<HobbiesSetupPage currentUser={currentUser} />} />}
          />
        } />
        <Route path={routes.athleteAuth.interests} element={
          <AuthorizedButNotRegisteredAthleteRoute element={({ user, currentUser }) =>
            <SignUpContainer currentUser={currentUser} element={
              <ChooseInterest user={user} currentUser={currentUser} />}
            />}
          />
        } />
        {/* Using default Auth0 login screen instead: */}
        {/*<Route path='/signin' element={<SignIn />} />*/}
        {/*<Route path='/signup' element={<SignUp />} />*/}
        <Route path='/profile-setup' element={
          <AuthorizedButNotRegisteredRoute element={({ user }) =>
            <SignUpContainer element={<ProfileSetupPage user={user} />} />
          } />
        } />
        <Route path='/verify-number' element={
          <AuthorizedButNotRegisteredRoute element={() =>
            <SignUpContainer element={<VerifyNumber />} />
          } />}
        />
        <Route path='/interest' element={
          <AuthorizedButNotRegisteredRoute element={({ user }) =>
            <SignUpContainer element={<ChooseInterest user={user} />} />
          } />}
        />
        <Route path='/dashboard' element={<PrivateRoute element={Dashboard} />} />
        <Route path='/dashboard/fanbase' element={<PrivateRoute element={JoinFanbase} />} />
        <Route path='/settings' element={<PrivateRoute element={Settings} />} />
        <Route path='/notifications' element={<PrivateRoute element={Notifications} />} />
        <Route path='/notification-settings' element={<PrivateRoute element={NotificationSettings} />} />
        <Route path='/payment-methods' element={<PrivateRoute element={PaymentSettings} />} />
        <Route path='/feed' element={<FeedPage />} />
        <Route path='/articles' element={<PrivateRoute element={Articles} />} />
        <Route path='/article/:id' element={<ArticleView />} />
        <Route path='/gaming/:id' element={<GamingView />} />
        <Route path='/collections/:id' element={<PrivateRoute element={Collections} />} />
        <Route path='/watch' element={<PrivateRoute element={Watch} />} />
        <Route path='/video/:id' element={<VideoView />} />
        <Route path='/gaming-video/:id' element={<GamingVideoView />} />
        <Route path='/gaming-video/:articleId/:muxId' element={<GamingVideoView />} />
        <Route path='/video/:articleId/:muxId' element={<VideoView />} />
        <Route path='/shop' element={<PrivateRoute element={Shop} />} />
        <Route path='/shop/all' element={<PrivateRoute element={AllShop} />} />
        <Route path='/shop/product/:id' element={<PrivateRoute element={ProductDetails} />} />
        <Route path='/cart' element={<PrivateRoute element={Cart} />} />
        <Route path='/checkout' element={<PrivateRoute element={Checkout} />} />
        <Route path='/profile' element={<PrivateRoute element={Profile} />} />
        <Route path='/edit-profile' element={<PrivateRoute element={EditUserProfile} />} />
        <Route path='/events' element={<PrivateRoute element={Events} />} />
        <Route path='/events/day-shift' element={<PrivateRoute element={EventDayShift} />} />
        <Route path='/event-team-adding' element={<PrivateRoute element={TeamAdding} />} />
        <Route path='/event-team-roping' element={<PrivateRoute element={EventTeamSelect} />} />
        <Route path='/event-team-roping/partner-selection' element={<PrivateRoute element={TeamPartnerSelection} />} />
        <Route path='/event/:id' element={<PrivateRoute element={Event} />} />
        <Route path='/event-regsistration' element={<PrivateRoute element={EventRegistration} />} />
        <Route path='/event-regsistration-compete' element={<PrivateRoute element={EventRegistrationCompete} />} />
        <Route path='/event-regsistration-horses' element={<PrivateRoute element={EventRegistrationHorses} />} />
        <Route path='/event-disciplines' element={<PrivateRoute element={EventDisciplines} />} />
        <Route path='/event-documents' element={<PrivateRoute element={EventDocuments} />} />
        <Route path='/event-docusign' element={<PrivateRoute element={EventDocusign} />} />
        <Route path='/event-checkout' element={<PrivateRoute element={EventCheckout} />} />
        <Route path='/event-thankyou' element={<PrivateRoute element={EventThankyou} />} />
        <Route path={routes.athletes} element={<PrivateRoute element={AthletesOrAnimalsPage} />} />
        <Route path={routes.athleteView()} element={<PrivateRoute element={AthleteProfile} />} />
        <Route path={routes.athleteEdit()} element={<PrivateRoute element={EditProfile} />} />
        <Route path={routes.animals} element={<PrivateRoute element={AthletesOrAnimalsPage} />} />
        <Route path={routes.animalView()} element={<PrivateRoute element={AnimalProfile} />} />

        {ENABLE_STANDINGS
          ? (
            <>
              <Route path={routes.standings} element={<PrivateRoute element={StandingsListPage} />} />
              <Route path={routes.standingView()} element={<PrivateRoute element={StandingViewPage} />} />
              <Route path={routes.standingCategoryView()} element={<PrivateRoute element={StandingViewPage} />} />
            </>
          )
          : (
            <>
              <Route path='/standings' element={<PrivateRoute element={Standings} />} />
              <Route path='/standing/:id' element={<PrivateRoute element={StandingView} />} />
            </>
          )
        }
        <Route path='/watch-live' element={<PrivateRoute element={Live} />} />
        <Route path='/watch-live/:id' element={<PrivateRoute element={Live} />} />
        <Route path={routes.games.index} element={<PrivateRoute element={GamesFeedPage} />} />
        <Route path={routes.games.gameDetails()} element={<PrivateRoute element={GameDetail} />} />
        <Route path={routes.games.play()} element={<PrivateRoute element={PlayGamePage} />} />
        <Route path='/callback' element={<PrivateRoute element={LoadingPage} />} />
        <Route path='/connection-lost' element={<PrivateRoute element={ConnectionLost} />} />
        <Route path='/something-wrong' element={<PrivateRoute element={SomethingWrong} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    )}
  </Main>
);

export default router;
