import React, { useState, useEffect } from 'react';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { Navigate, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import { getIsAthleteLogin, useAuthorize } from "./auth.service";
import { isDuplicatedAuth0Error, isUserNotRegisteredError } from "../../utils/api.utils";
import { WelcomeAthletesPage } from "./WelcomeAthletesPage/WelcomeAthletesPage";
import { routes } from "../../routes";
import { checkIsAthlete, isAthleteProfileSetUp, isProfileSetUp } from "../User/user.utils";
import { DuplicatedAuth0Screen } from "./DuplicatedAuth0Screen";

export const NotAuthorizedRoute = ({ isAthleteRoute }: { isAthleteRoute?: boolean }) => {
  const [params] = useSearchParams()
  const { isAuthenticated, isLoading } = useAuth0()
  const [manualRedirect, setManualRedirect] = useState(false)

  useEffect(() => {
    if (params.get('code') && !isAuthenticated) {
      const timeout = setTimeout(() => {
        setManualRedirect(true)
      }, 2000)
      return () => clearTimeout(timeout)
    }
  }, [params.get('code'), isAuthenticated])

  // show loading screen while auth0 is validating
  if (isLoading) {
    return <LoadingScreen />
  }
  if (isAuthenticated) {
    return <CheckIfRegisteredAndRedirect isAthleteRoute={isAthleteRoute} />
  }

  // if someone hit the TR+ with a code query param, we run into loop loading screen, so with this we redirect to welcomePage after 2 seconds if we stuck in this loop
  if (params.get('code') && !manualRedirect) {
    return <LoadingScreen />
  }

  return isAthleteRoute ? <WelcomeAthletesPage /> : <Navigate to='/feed' />
}

const CheckIfRegisteredAndRedirect = ({ isAthleteRoute }: { isAthleteRoute?: boolean }) => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const location = useLocation();
  const { error } = useAuthorize({
    onSuccess(user) {
      const loginAthlete = isAthleteRoute || getIsAthleteLogin()
      if (
        (loginAthlete && isAthleteProfileSetUp(user)) ||
        (!loginAthlete && isProfileSetUp(user))
      ) {
        navigate(routes.feed)
      } else if (checkIsAthlete(user)) {
        navigate(routes.athleteAuth.phoneVerification)
      } else {
        // skipping phone verification for non-athlete users because they already verified phone
        navigate(routes.athleteAuth.disciplines)
      }
    },
    onError(error) {
      if (isUserNotRegisteredError(error)) {
        navigate('/profile-setup', {replace: true})
      } else if (isDuplicatedAuth0Error(error)) {
        return;
      } else if (error instanceof OAuthError && error.error === 'login_required') {
        navigate('/welcome', { state: { prevRoute: location.pathname } });
      } else {
        logout()
      }
    }
  })

  if (isDuplicatedAuth0Error(error)) {
    return <DuplicatedAuth0Screen isAthlete={error.isAthlete} />
  }

  return <LoadingScreen />
}
