import ICON_RIDGE_RIDERS from '../img/interest/interest-8.png';
import { buildApiMutationHook, buildApiQueryHook } from '../utils/api.utils';
import request from '../utils/request';

const ICON_BULL_RIDING =
  'https://cdn.tetonridge.io/ICON_BULL_RIDING_ca7676c090.png?updated_at=2022-12-16T19:57:49.864Z';
const ICON_BREAKAWAY = 'https://cdn.tetonridge.io/ICON_BREAKAWAY_d3952fd87d.png?updated_at=2022-11-23T23:49:53.739Z';
const ICON_CUTTING = 'https://cdn.tetonridge.io/ICON_CUTTING_997c975e36.png?updated_at=2022-11-23T23:49:53.802Z';
const ICON_TEAM_ROPING =
  'https://cdn.tetonridge.io/ICON_TEAM_ROPING_61440c44c4.png?updated_at=2022-11-23T23:49:53.822Z';
const ICON_BARREL_RACE =
  'https://cdn.tetonridge.io/ICON_BARREL_RACE_15ee3d3258.png?updated_at=2022-11-23T23:49:53.271Z';
const ICON_BARBACK_RIDING =
  'https://cdn.tetonridge.io/ICON_BARBACK_RIDING_906b87ec3c.png?updated_at=2022-11-23T23:49:53.417Z';
const ICON_SADDLE_BRONC =
  'https://cdn.tetonridge.io/ICON_SADDLE_BRONC_40c13098a4.png?updated_at=2022-11-23T23:49:53.714Z';
const ICON_REINED_COW_HORSE =
  'https://cdn.tetonridge.io/ICON_REINED_COW_HORSE_b36427823a.png?updated_at=2022-11-23T23:49:53.841Z';
const ICON_REINING = 'https://cdn.tetonridge.io/ICON_REINING_93c9046469.png?updated_at=2022-11-23T23:49:53.961Z';
const ICON_TIE_DOWN = 'https://cdn.tetonridge.io/ICON_TIE_DOWN_8d02b4d948.png?updated_at=2022-11-23T23:49:53.997Z';
const ICON_STEER_WRESTLING =
  'https://cdn.tetonridge.io/ICON_STEER_WRESTLING_f8b99e92ea.png?updated_at=2022-11-23T23:49:54.138Z';

export const interestImages = {
  ICON_BULL_RIDING,
  ICON_BARREL_RACE,
  ICON_BREAKAWAY,
  ICON_REINING,
  ICON_REINED_COW_HORSE,
  ICON_CUTTING,
  ICON_TIE_DOWN,
  ICON_TEAM_ROPING,
  ICON_STEER_WRESTLING,
  ICON_BARBACK_RIDING,
  ICON_SADDLE_BRONC,
  ICON_RIDGE_RIDERS,
};

export type Interest = {
  uuid: string;
  name: string;
  iconUrl?: string;
};

type UserInterestsQueryResult = {
  InterestUUID: string;
};

export const hardcodedInterests: Interest[] = [
  {
    name: 'Bull Riding',
    uuid: '001',
    iconUrl: ICON_BULL_RIDING,
  },
  {
    name: 'Barrel Racing',
    uuid: '002',
    iconUrl: ICON_BARREL_RACE,
  },
  {
    name: 'Breakaway',
    uuid: '003',
    iconUrl: ICON_BREAKAWAY,
  },
  {
    name: 'Reining',
    uuid: '004',
    iconUrl: ICON_REINING,
  },
  {
    name: 'REINED COW HORSE',
    uuid: '005',
    iconUrl: ICON_REINED_COW_HORSE,
  },
  {
    name: 'CUTTING',
    uuid: '006',
    iconUrl: ICON_CUTTING,
  },
  {
    name: 'TIE DOWN',
    uuid: '007',
    iconUrl: ICON_TIE_DOWN,
  },
  {
    name: 'TEAM ROPING',
    uuid: '008',
    iconUrl: ICON_TEAM_ROPING,
  },
  {
    name: 'STEER WRESTLING',
    uuid: '009',
    iconUrl: ICON_STEER_WRESTLING,
  },
  {
    name: 'BAREBACK',
    uuid: '010',
    iconUrl: ICON_BARBACK_RIDING,
  },
  {
    name: 'SADDLE BRONC',
    uuid: '011',
    iconUrl: ICON_SADDLE_BRONC,
  },
  {
    name: 'RIDGE RIDERS',
    uuid: '012',
    iconUrl: ICON_RIDGE_RIDERS,
  },
];

export const useInterestsQuery = buildApiQueryHook<Interest[]>('interest', async () => request.get('/interest'));

export const useUserInterestsQuery = buildApiQueryHook<UserInterestsQueryResult[]>('user-interests', async () =>
  request.get('/interest/user-interests'),
);

export const useUpdateUserInterests = buildApiMutationHook<{ uuids: string[] }, void>(
  ({ uuids }) => request.put('/interest', { interests: uuids }),
  (options) => ({
    ...options,
    onSuccess(...args) {
      options?.onSuccess?.(...args);

      // send GA event
      window.dataLayer.push({
        event: 'edit-profile',
      });
    },
  }),
);
