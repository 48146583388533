import { useParams, useSearchParams } from 'react-router-dom';
import { styled, Box } from '@mui/system';
import { Typography, Button } from '@mui/material';
import { FacebookShareButton, TwitterShareButton } from 'react-share'
// import SavedList from '../../components/SavedList';
import TweetsSlider from '../Feeds/TweetsSlider';
// import SectionTitle from '../../components/SectionTitle';
import RealTimeScoreSlider from '../Feeds/RealTimeScoreSlider';
// import CommentBox from '../ArticleView/CommentBox';
// import RelatedContents from '../ArticleView/RelatedContents';
import PageHeader from '../../components/Header/PageHeader';
import { Page } from '../../components/Page/Page';
// import Post from '../../components/Post/Post';
import { TwitterLineIcon, FacebookLineIcon, ClockIcon } from '../../components/Icons';
import VideoCard from '../../components/VideoCard';
import CardMedia from '@mui/material/CardMedia';
// import VideoImage from '../../img/video-post-1.png';
import { useEffect, useState } from 'react';
import { _get } from '../../net/net';
import { Helmet } from 'react-helmet';
import { formatDateFromNow } from '../../utils/date.utils';
import MuxVideo from '@mux/mux-video-react';
import { CurrentUser } from '../../api/auth.api';
import { subscribeUser } from '../../subscription';

// const Tags = (props: { children: React.ReactNode }) => (
//   <Typography variant="body1" sx={{ pr: 1 }}>
//     {props.children}
//   </Typography>
// );

const TimeIcon = styled('span')(({ theme }) => ({
  paddingRight: '5px',
  '& > svg': {
    height: '10px',
    width: '10px',
    fill: theme.palette.lightPallet.main,
  },
}));

// const videoPostTest = {
//   title: 'Video Title Goes Here and Probably Takes 2 Lines.',
//   date: '8 mins',
//   type: 'video',
//   media: VideoImage,
// };
interface Video {
  attributes: VideoAttributes;
  id: number;
}

interface Article {
  attributes: ArticleAttributes;
  id: number;
}

interface ArticleAttributes {
  mux_video_uploader_mux_assets: MuxVideo;
  author: Author;
}

interface MuxVideo {
  data: MuxData[];
}

interface MuxData {
  id: number;
  attributes: MuxAttributes;
}

interface MuxAttributes {
  title: string;
  asset_id: string;
  playback_id: string;
  updatedAt: string;
  mux_description: string;
}
interface VideoAttributes {
  Title: string;
  Video_Link: string;
  Description: string;
  Featured: boolean;
  Live: boolean;
  MC_Shopify: string[];
  Cover_Image: CoverImage;
  author: Author;
  publishedAt: string;
}

interface CoverImage {
  data: CoverImageData;
}

interface CoverImageData {
  attributes: CoverImageAttributes;
  id: number;
}

interface CoverImageAttributes {
  name: string;
  alternativeText: string;
  url: string;
}

interface Author {
  data: AuthorData;
}

interface AuthorData {
  id: number;
  attributes: AuthorAttributes;
}

interface AuthorAttributes {
  First_Name: string;
  Last_Name: string;
}
interface ButtonProps {
  filled?: string;
  stroke?: string;
}

const IconButton = styled(Button)<ButtonProps>(({ filled, stroke, theme }) => ({
  marginRight: '5px',
  padding: 0,
  minWidth: '30px',
  '& svg': {
    fill: filled ? theme.palette.grey['500'] : 'none',
    stroke: stroke ? theme.palette.grey['500'] : 'none',
    height: '25px',
    width: '25px',
  },
}));

const ShareMedias = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row-reverse', pb: '2rem' }}>
      <Typography variant="caption" fontSize="16px" sx={{ pl: 2 }}>
        Share this article
      </Typography>
      <TwitterShareButton url={window.location.href}>
        <IconButton stroke="true" variant="text" size="small">
          <TwitterLineIcon />
        </IconButton>
      </TwitterShareButton>
      <FacebookShareButton url={window.location.href}>
        <IconButton stroke="true" variant="text" size="small">
          <FacebookLineIcon />
        </IconButton>
      </FacebookShareButton>
    </Box>
  );
};

const GamingVideoView = ({ user }: { user?: CurrentUser }) => {
  const [searchParams] = useSearchParams();
  const isLive = searchParams.get('live');
  const { id, articleId, muxId } = useParams();

  const [videoPost, setVideoPost] = useState<Video | null>(null);
  const [articlePost, setArticlePost] = useState<Article | null>(null);
  const [muxVideo, setMuxVideo] = useState<MuxAttributes | null>(null);
  const [iOS, setIOS] = useState(false);

  const { First_Name, Last_Name } = articlePost?.attributes.author.data.attributes || videoPost?.attributes.author.data.attributes || { First_Name: '', Last_Name: '' };
  useEffect(() => {
    if (id) {
      _get(`${process.env.REACT_APP_CMS_BASE_URL}api/gaming-video-posts/${id}?populate=*`).then((response) => {
        setVideoPost(response.data);
      });
    } else {
      _get(`${process.env.REACT_APP_CMS_BASE_URL}api/gaming-posts/${articleId}?populate=*`).then((response) => {
        const article: Article = response.data;
        setArticlePost(article)
        if (muxId) {
          const muxVideo = article.attributes.mux_video_uploader_mux_assets.data.find((muxVideo: MuxData) => {
            return muxVideo.id.toString() === muxId;
          })
          if (muxVideo) {
            setMuxVideo(muxVideo.attributes);
            if (navigator.userAgent.includes('iPhone')) setIOS(true);
          }
        }
      });
    }
  }, [id, articleId, muxId]);

  useEffect(() => {
    if (user) subscribeUser(user.uuid);
  }, [])

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={muxVideo ? muxVideo.title : videoPost ? videoPost?.attributes.Title : 'TetonRidge+'} />
        <meta name="twitter:description" content={muxVideo ? muxVideo.mux_description : videoPost ? videoPost?.attributes.Description : 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta name="twitter:image:src" content={videoPost?.attributes?.Cover_Image?.data?.attributes?.url ? videoPost?.attributes?.Cover_Image?.data?.attributes?.url : 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content={muxVideo ? muxVideo.title : videoPost ? videoPost?.attributes.Title : 'TetonRidge+'} />
        <meta property="og:description" content={muxVideo ? muxVideo?.mux_description : videoPost ? videoPost?.attributes?.Description : 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={videoPost ? videoPost?.attributes?.Cover_Image?.data?.attributes?.url : 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <meta property="og:video" content={videoPost ? videoPost?.attributes?.Video_Link : ''} />
        <title>Video | {muxVideo ? `${muxVideo?.title}` : `${videoPost?.attributes?.Title}`}</title>
      </Helmet>
      <Page>
        <PageHeader />
        <Box sx={{ pt: 2 }}>
          <TimeIcon>
            <ClockIcon />
          </TimeIcon>
          <Typography variant="caption" textTransform={'uppercase'}>
            {' '}
            | {muxVideo ? formatDateFromNow(new Date(muxVideo?.updatedAt)) : videoPost ? formatDateFromNow(new Date(videoPost.attributes.publishedAt)) : ''}
          </Typography>
        </Box>
        <Box sx={{ pt: 4, pb: 3 }}>
          <Typography variant="h3" fontWeight="700" color="primary">
            {muxVideo ? muxVideo.title : videoPost ? videoPost?.attributes.Title : ''}
          </Typography>
        </Box>
        <Box sx={{ mt: 1, mb: 2 }}>
          <Typography variant="overline" textTransform={'uppercase'}>
            BY {First_Name + ' ' + Last_Name}
          </Typography>
        </Box>
        <Box sx={{ mr: '-16px', ml: '-16px' }}>
          {muxVideo ? (
            iOS ? (
              <Box sx={{ position: 'relative' }}>
                <CardMedia sx={{ position: 'relative' }} component='img' image={`https://image.mux.com/${muxVideo.playback_id}/thumbnail.jpg`} />
                <MuxVideo
                  playbackId={muxVideo.playback_id}
                  controls
                  style={{ height: '100%', width: '100%', position: 'absolute', top: '0', left: '0' }}
                  onPlay={() => {
                    window.dataLayer.push({
                      event: 'play-video',
                    });
                  }}
                />
              </Box>
            ) : (
              <MuxVideo
                playbackId={muxVideo.playback_id}
                controls
                style={{ height: '100%', width: '100%' }}
                onPlay={() => {
                  window.dataLayer.push({
                    event: 'play-video',
                  });
                }}
              />
            )
          ) : (
            <VideoCard media={videoPost ? videoPost?.attributes.Video_Link : ''} />
          )}
        </Box>
        <Box sx={{ pt: 4, pb: 3 }}>
          <Typography>{muxVideo ? muxVideo.mux_description : videoPost ? videoPost?.attributes.Description : ''}</Typography>
        </Box>
        {!isLive && (
          <>
            <ShareMedias />
            <Box sx={{ mt: 2 }} />
            {/* <CommentBox /> */}
            <Box sx={{ mt: 6 }} />
          </>
        )}
        {isLive && (
          <>
            <RealTimeScoreSlider />
            <TweetsSlider />
          </>
        )}

        {/* <SectionTitle title="Related Contents" />
          <Post.Video {...videoPostTest} />
          <Post.Video {...videoPostTest} />
          <Box sx={{ mt: 6 }} />
          <RelatedContents />
          <SavedList /> */}
      </Page>
    </>
  );
};

export default GamingVideoView;
