import {Box, Typography} from "@mui/material";
import {RightChevronIcon} from "../../components/Icons";
import {styled} from "@mui/system";
import { Link } from "react-router-dom";

export type SettingLinkItem = {
  title: string
  Icon: () => JSX.Element,
  link?: string
  onClick?(): void
}

const IconDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > svg': {
    height: '15px',
    width: '15px',
    fill: theme.palette.lightPallet.lighter,
  },
}));

export const SettingLink = ({ title, Icon, link, onClick }: SettingLinkItem) => {
  return (
    <Box
      component={link ? Link : 'a'}
      to={link}
      onClick={onClick}
      sx={{ display: 'flex', cursor: 'pointer', pb: 2, pt: 2 }}
    >
      <IconDiv>
        <Icon />
      </IconDiv>
      <Typography sx={{ flex: '1', pl: 1, pr: 1 }} variant="subtitle1">
        {title}
      </Typography>
      <IconDiv>
        <RightChevronIcon />
      </IconDiv>
    </Box>
  );
}