import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { styled, Box } from '@mui/system';
import Header from '../../../components/Header';
import { Page } from '../../../components/Page/Page';
import { ClockIcon } from '../../../components/Icons';
import { _get } from '../../../net/net';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { formatDateFromNow } from '../../../utils/date.utils';

const TimeIcon = styled('span')(({ theme }) => ({
  paddingLeft: '10px',
  paddingRight: '5px',
  '& > svg': {
    height: '10px',
    width: '10px',
    fill: theme.palette.lightPallet.main,
  },
}));

interface Standing {
  data: StandingData;
}

interface StandingData {
  attributes: StandingAttributes;
  id: number;
}

interface StandingAttributes {
  Standing_Title: string;
  content: string;
  Featured: boolean;
  'MC & Shopify': string[];
  author: Author;
  category: string;
  publishedAt: string;
  Standing_Image: CoverMedia;
}

interface CoverMedia {
  data: CoverMediaData;
}

interface CoverMediaData {
  id: number;
  attributes: CoverMediaAttributes;
}

interface CoverMediaAttributes {
  name: string;
  alternativeText: string;
  url: string;
}

interface Author {
  data: AuthorData;
}

interface AuthorData {
  id: number;
  attributes: AuthorAttributes;
}

interface AuthorAttributes {
  First_Name: string;
  Last_Name: string;
}

const ImgWrapper = styled(Box)(() => ({
  position: 'relative',
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const StandingView = () => {
  const [standing, setStanding] = useState<Standing | null>(null);
  const { id } = useParams();
  useEffect(() => {
    _get(`${process.env.REACT_APP_CMS_BASE_URL}api/standings/${id}?populate=*`).then((response) => {
      setStanding(response);
    });
  }, [id]);

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Standings | Teton Ridge+`} />
        <meta name="twitter:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Standing | Teton Ridge+" />
        <meta property="og:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Standing | {id}</title>
      </Helmet>
      <Page>
        <Header backIcon />
        <Box sx={{ pt: 2, pb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{ flex: 1, display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}
            >
              <Box>
                <TimeIcon>
                  <ClockIcon />
                </TimeIcon>
                <Typography variant="caption" textTransform={'uppercase'}>
                  | {standing ? formatDateFromNow(new Date(standing.data.attributes.publishedAt)) : ''}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pt: 4, pb: 3 }}>
            <Typography variant="h2" fontWeight="700" fontSize="40px" color="primary">
              {standing?.data.attributes.Standing_Title}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }} />
          <ImgWrapper>
            <img
              src={standing?.data?.attributes?.Standing_Image.data?.attributes.url}
              alt={standing?.data?.attributes?.Standing_Image.data?.attributes.alternativeText} />
          </ImgWrapper>
        </Box>
      </Page>
    </>
  );
};

export default StandingView;
