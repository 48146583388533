import { Box, Typography } from '@mui/material';

const BigBonusGame = ({ item }: { item: any }) => {
  return (
    <Box>
      {item?.BigBonus?.length > 0 &&
        <div>
          <Typography
            variant='h3'
            color='primary.main'
            align='center'
            sx={{
              pt: 3,
              mt: 3
            }}>
            BIG GAMES BONUS
          </Typography>
          <div style={{
            display: 'flex',
            width: '100%',
            height: 250,
            marginTop: 15,
            flexDirection: 'row',
          }}>
            <div style={{
              overflowX: 'auto',
              scrollbarWidth: 'none',
            }}>
              <div style={{
                width: 'max-content',
                display: 'flex',
                gap: 10
              }}>
                {item?.BigBonus?.map((game: any, index: number) => (
                  <div key={index} style={{
                    display: 'flex',
                    padding: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative',
                    height: 170
                  }}>
                    <div style={index === 0 ? {
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      marginRight: 30
                    } : {
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                      <img
                        width={'75px'}
                        src={game?.BonusImage?.data?.attributes?.url}
                        alt={game?.BonusImage?.data?.attributes?.alternativeText}
                      />
                      {index === 0 &&
                        <span style={{
                          display: 'flex',
                          margin: 'auto',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'absolute',
                          right: -50,
                          top: 0,
                          bottom: 0,
                          fontSize: 50,
                          fontWeight: 700,
                          fontFamily: 'BourbonW00-Regular'
                        }}>=</span>}
                    </div>
                    <span style={{ position: 'absolute', bottom: -25, textAlign: 'center', width: 120, fontWeight: 700 }}>{game?.BonusText}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
    </Box >
  );
};

export default BigBonusGame;
