// import { Button } from "@mui/material";
import { Athlete } from "../../../api/users.api";
import { CardWithImage } from "../../../components/Card/CardWithImage";
import { routes } from "../../../routes";
import { CurrentUser } from "../../../api/auth.api";
import { ATHLETE_DEFAULT_LOGO } from "../../../utils/images";

type Props = {
  item: Athlete;
  onLoad?(): void;
  user: CurrentUser;
  row: number;
};

export const AthleteCard = ({ item: user, onLoad, row }: Props) => {
  // const { mutate, isLoading } = useToggleAthleteFollowMutation();

  // const toggleFollow = user.isFollowed
  //   ? () => mutate({ athleteUUID: user.uuid, follow: false })
  //   : () => mutate({ athleteUUID: user.uuid, follow: true });

  return (
    <CardWithImage
      image={user.avatar || ATHLETE_DEFAULT_LOGO}
      defaultLogo={ATHLETE_DEFAULT_LOGO}
      link={`${routes.athleteView(user.uuid)}?row=${row}`}
      onImageLoad={onLoad}
      title={<>{user.name}</>}
      subTitle={<>{user.city}{user.city && user.state && ', '}{user.state}</>}
    // button={
    //   user.id === currentUser.id
    //     ? (
    //       <Button
    //         variant='outlined'
    //         fullWidth
    //         disabled
    //       >
    //         Your Profile
    //       </Button>
    //     ) : null
    // : (
    //   <Button
    //     variant={user.isFollowed ? 'contained' : 'outlined'}
    //     fullWidth
    //     onClick={toggleFollow}
    //     disabled={isLoading}
    //   >
    //     {user.isFollowed ? 'Following' : 'Follow'}
    //   </Button>
    // )
    // }
    />
  );
};
