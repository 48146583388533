import { InputProps } from "@mui/material";
import { FieldValues, UseFormReturn, get } from "react-hook-form";
import { Input, Button } from "@mui/material";
import { FieldPath, PathValue, Path } from "react-hook-form/dist/types/path";
import cls from "./ImagePicker.module.css";

type Props<T extends FieldValues> = Omit<InputProps, 'name'> & {
  form: UseFormReturn<T>
  name: FieldPath<T>
  label: string;
  classname?: string;
}

export const ImagePicker = <T extends FieldValues>({ form, name, label, classname, ...props }: Props<T>) => {
  const error = get(form.formState.errors, name);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const files = input.files;
    const file = files?.[0];

    if (file) {
      if (file.size > 3 * 1024 * 1024)
        return form.setError(name, { message: 'File too large must be under 3MB' })

      const reader = new FileReader()
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        if (base64) {
          form.setValue(name, base64 as PathValue<T, Path<T>>)
          form.clearErrors(name)
        }
      };

    }
  }

  return <>
    {Boolean(error) && <div className={cls["error"]} >{error.message}</div>}
    <Button variant="contained" component="label" className={classname ? cls[classname] : ''}>
      {label}
      <Input
        {...props}
        {...form.register(name)}
        inputProps={{ accept: 'image/png, image/jpg' }}
        style={{ display: "none" }}
        name={name}
        onChange={onFileChange}
        error={Boolean(error)}
        type="file"
        className={classname ? cls[classname] : ''}
      />
    </Button>

  </>
}