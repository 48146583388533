import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import Product, { ProductsGrid } from '../../components/Products';
import { BookmarkButton } from '../../components/Buttons';
import { Page } from '../../components/Page/Page';
import Header from '../../components/Header';
import ProductImageSlider from './ProductImageSlider';
import TShirt from '../../img/t-shirt.png';
import ProductImg from '../../img/product-img.png';
import { Helmet } from 'react-helmet';

const images = [TShirt, TShirt, TShirt];
const sizes = ['xs', 's', 'm', 'l', 'xl', 'xxl'];
const colors = ['black', 'gray', 'white'];

const ButtonRound = styled(Button)(({ theme }) => ({
  height: '40px',
  width: '40px',
  border: `2px solid transparent`,
  borderRadius: '100%',
  minWidth: 'auto',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
  textTransform: 'uppercase',
  color: theme.palette.lightPallet.lighter,
  '&.selected': {
    borderColor: theme.palette.primary.main,
  },
}));

const ColorRound = styled(Box)(({ theme }) => ({
  height: '40px',
  width: '40px',
  border: `2px solid transparent`,
  borderRadius: '100%',
  minWidth: 'auto',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '5px',
  textTransform: 'uppercase',
  '&.selected': {
    borderColor: theme.palette.primary.main,
  },
}));

const ProductList = [
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    oldPrice: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '01',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$129.95',
    media: ProductImg,
    category: 'Unisex',
    id: '02',
  },
];

const ProductDetails = () => {
  const size = 'l';
  const color = 'black';
  const saved = false;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onPage = (name: string) => () => {
    navigate(name);
  };

  const open = Boolean(anchorEl);

  const rect = anchorEl ? anchorEl.getBoundingClientRect() : { top: 450 };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Product Detail</title>
      </Helmet>
      <Page>
        <Header backIcon={true} disableSearch cartIcon={true} />
        <Box sx={{ mb: 2 }} />
        <ProductImageSlider images={images} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ flex: 1, pt: 1 }}>
            <Typography variant='h3' fontSize='28px' color='primary'>
              BUCK’D Tee
            </Typography>
            <Typography variant='subtitle1' fontWeight='700'>
              $29.95
            </Typography>
          </Box>
          <Box>
            {saved ? <BookmarkButton sx={{ borderColor: 'transparent' }} variant='outlined' /> : <BookmarkButton />}
          </Box>
        </Box>

        <Typography variant='subtitle1' fontWeight='700' sx={{ mt: 5, pb: 2 }}>
          Select size
        </Typography>
        <Box sx={{ display: 'flex', aligItems: 'center', flexWrap: 'wrap' }}>
          {sizes.map((item) => (
            <ButtonRound variant='outlined' sx={{ fontSize: '14px' }} className={`${size === item ? 'selected' : ''}`}>
              {item}
            </ButtonRound>
          ))}
        </Box>
        <Typography variant='subtitle1' fontWeight='700' sx={{ mt: 5, pb: 2 }}>
          Select Color
        </Typography>
        <Box sx={{ display: 'flex', aligItems: 'center', flexWrap: 'wrap' }}>
          {colors.map((item) => (
            <Box sx={{ pr: 2 }}>
              <ColorRound sx={{ backgroundColor: item }} className={`${color === item ? 'selected' : ''}`}></ColorRound>
              <Typography variant='body2' sx={{ pt: 1 }} textTransform='capitalize'>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2, mb: 10 }}>
          <Typography variant='subtitle1' fontWeight='700'>
            $29.95
          </Typography>
          <Box>
            <Button variant='contained' sx={{ textTransform: 'uppercase' }} onClick={handleClick}>
              Add to cart
            </Button>
          </Box>
        </Box>

        <Product.Header title='Similar products' moreText='See All' />
        <ProductsGrid list={ProductList} />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorPosition={{ top: rect.top - 10, left: 0 }}
          PaperProps={{
            style: { width: '100%' },
          }}
        >
          <Box sx={{ width: '100%', display: 'flex', p: 1, cursor: 'pointer', alignItems: 'center' }}>
            <Box sx={{ '& img': { width: '30px', mr: 1 } }}>
              <img src={TShirt} alt='Product' />
            </Box>
            <Typography sx={{ flex: 1 }} color='primary.main'>
              ITEM NAME saved...
            </Typography>
            <Button variant='text' size='small' onClick={onPage('/cart')}>
              View
            </Button>
          </Box>
        </Popover>
      </Page>
    </>
  );
};

export default ProductDetails;
