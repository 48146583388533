import { Page } from '../../components/Page/Page';
import PageHeader from '../../components/Header/PageHeader';
import SectionTitle from '../../components/SectionTitle';
import { useEffect, useState } from 'react';
import { _get } from '../../net/net';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Post } from "../../components/Post/Post";
import { VideoPost } from "../../components/Post/VideoPost/VideoPost";
import { Collection } from '../../components/Collection/Collection';

interface Collection {
  attributes: CollectionAttributes;
  id: number;
}

interface CollectionAttributes {
  Collection_Image: Image;
  Collection_Title: string;
  article_posts: ArticlePost;
  video_posts: VideoPost;
}

interface Image {
  data: ImageData;
  id: number;
}

interface ImageData {
  attributes: ImageAttributes;
  id: number;
}

interface ImageAttributes {
  name: string;
  alternativeText: string;
  url: string;
}

interface ArticlePost {
  data: ArticlePostData[];
}

interface ArticlePostData {
  attributes: ArticlePostAttributes;
  id: number;
}

interface ArticlePostAttributes {
  Featured: boolean;
  content: string;
  title: string;
  'MC & Shopify': string[];
  publishedAt: string;
  cover_media: Image;
}

interface VideoPost {
  data: VideoPostData[];
}

interface VideoPostData {
  attributes: VideoPostAttributes;
  id: number;
}

interface VideoPostAttributes {
  Description: string;
  Featured: boolean;
  Live: boolean;
  'MC & Shopify': string[];
  Title: string;
  Video_Link: string;
  Cover_Image: Image;
  publishedAt: string;
}

const Collections = () => {
  const { id } = useParams();
  const [collection, setCollection] = useState<Collection | null>(null);

  useEffect(() => {
    _get(
      `${process.env.REACT_APP_CMS_BASE_URL}api/custom-collections/${id}?populate[article_posts][filters][publishedAt][$notNull]=true&populate[article_posts][populate]=*&populate[video_posts][filters][publishedAt][$notNull]=true&populate[video_posts][populate]=*`,
    ).then((response) => {
      setCollection(response.data);
    });
  }, [id]);

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={collection?.attributes?.Collection_Title} />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content={collection?.attributes?.Collection_Image?.data?.attributes?.url} />

        <meta property="og:title" content={collection?.attributes?.Collection_Title} />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={collection?.attributes?.Collection_Image?.data?.attributes?.url} />
        <title>Collection | {id}</title>
      </Helmet>
      <Page>
        <PageHeader />
        <SectionTitle title={collection ? collection?.attributes.Collection_Title : ''} hasLine />
        {collection?.attributes.article_posts.data.map((article) => (
          <Post
            //Change these props when all "Posts" have same attributes to type.
            key={article.id}
            title={article.attributes.title}
            media={article.attributes.cover_media.data?.attributes?.url}
            date={article.attributes.publishedAt}
            id={article.id} />
        ))}
        {collection?.attributes.video_posts.data.map((video) => (
          <VideoPost
            id={video.id}
            key={video.id}
            title={video.attributes.Title}
            media={video.attributes.Video_Link}
            date={video.attributes.publishedAt}
          />
        ))}
      </Page>
    </>
  );
};

export default Collections;
