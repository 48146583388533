import { Button } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../components/Header';
import { Page } from '../../components/Page/Page';
import Fixedbox from '../../components/Fixedbox';
import PartnerSelectionFirstStep from './PartnerSelectionFirstStep';
import PartnerSelectionSecondStep from './PartnerSelectionSecondStep';
import { Helmet } from 'react-helmet';

const TeamPartnerSelection = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);
  const step = searchParams.get('step');

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Team Partner Selection | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Team Partner Selection</title>
      </Helmet>
      <Page>
        <Header backIcon disableSearch />
        {step === '2' ? <PartnerSelectionSecondStep /> : <PartnerSelectionFirstStep />}

        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onPage(step === '2' ? '/event-checkout' : '/event-team-roping/partner-selection?step=2')}
            >
              Next
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default TeamPartnerSelection;
