import interestImg8 from '../img/interest/interest-8.png';

const ICON_BULL_RIDING =
  'https://cdn.tetonridge.io/ICON_BULL_RIDING_ca7676c090.png?updated_at=2022-12-16T19:57:49.864Z';
const ICON_BREAKAWAY = 'https://cdn.tetonridge.io/ICON_BREAKAWAY_d3952fd87d.png?updated_at=2022-11-23T23:49:53.739Z';
const ICON_CUTTING = 'https://cdn.tetonridge.io/ICON_CUTTING_997c975e36.png?updated_at=2022-11-23T23:49:53.802Z';
const ICON_TEAM_ROPING =
  'https://cdn.tetonridge.io/ICON_TEAM_ROPING_61440c44c4.png?updated_at=2022-11-23T23:49:53.822Z';
const ICON_BARREL_RACE =
  'https://cdn.tetonridge.io/ICON_BARREL_RACE_15ee3d3258.png?updated_at=2022-11-23T23:49:53.271Z';
const ICON_BARBACK_RIDING =
  'https://cdn.tetonridge.io/ICON_BARBACK_RIDING_906b87ec3c.png?updated_at=2022-11-23T23:49:53.417Z';
const ICON_SADDLE_BRONC =
  'https://cdn.tetonridge.io/ICON_SADDLE_BRONC_40c13098a4.png?updated_at=2022-11-23T23:49:53.714Z';
const ICON_REINED_COW_HORSE =
  'https://cdn.tetonridge.io/ICON_REINED_COW_HORSE_b36427823a.png?updated_at=2022-11-23T23:49:53.841Z';
const ICON_REINING = 'https://cdn.tetonridge.io/ICON_REINING_93c9046469.png?updated_at=2022-11-23T23:49:53.961Z';
const ICON_TIE_DOWN = 'https://cdn.tetonridge.io/ICON_TIE_DOWN_8d02b4d948.png?updated_at=2022-11-23T23:49:53.997Z';
const ICON_STEER_WRESTLING =
  'https://cdn.tetonridge.io/ICON_STEER_WRESTLING_f8b99e92ea.png?updated_at=2022-11-23T23:49:54.138Z';

const setIcon = (iconName: string) => {
  switch (iconName.toLowerCase()) {
    case 'bull riding':
      return ICON_BULL_RIDING;
    case 'barrel racing':
      return ICON_BARREL_RACE;
    case 'breakaway':
      return ICON_BREAKAWAY;
    case 'reining':
      return ICON_REINING;
    case 'reined cow horse':
      return ICON_REINED_COW_HORSE;
    case 'cutting':
      return ICON_CUTTING;
    case 'tie down':
      return ICON_TIE_DOWN;
    case 'team roping':
      return ICON_TEAM_ROPING;
    case 'steer wrestling':
      return ICON_STEER_WRESTLING;
    case 'bareback':
      return ICON_BARBACK_RIDING;
    case 'saddle bronc':
      return ICON_SADDLE_BRONC;
    case 'ridge riders':
      return interestImg8;
  }
};

export default setIcon;
