import React from 'react';
import { StandingsListItem } from "../../../api/standings.api";
import { styled } from "@mui/system";
import { Link } from 'react-router-dom'
import { routes } from "../../../routes";
import { ClockIcon } from "../../../components/Icons";

const Block = styled('div')(() => ({
  padding: '0 18px',
  marginBottom: 32,
}))

const Title = styled('div')(() => ({
  height: 52,
  display: 'flex',
  alignItems: 'center',
  color: 'white',
  fontSize: 20,
  textTransform: 'uppercase',
  fontFamily: 'BourbonW00-Regular',
  fontWeight: 'normal',
}))

const Standing = styled('div')<{ available?: boolean }>(({ theme, available }) => ({
  marginBottom: '4px',
  borderRadius: '4px',
  height: 52,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.primary.dark,
  fontSize: 20,
  fontFamily: 'BourbonW00-Regular',
  fontWeight: 'normal',
  letterSpacing: 0.2,
  color: 'black',
  opacity: !available ? .5 : undefined,
}))

export const StandingsSeason = ({ item }: { item: StandingsListItem }) => {
  return (
    <Block>
      <Title>{item.name} Season</Title>
      {item.standings.map((standing) =>
        standing.available
          ? (
            <Link key={standing.id} to={routes.standingView(standing.id)}>
              <Standing available>
                {standing.name}
              </Standing>
            </Link>
          )
          : (
            <Standing>
              <div style={{ width: '24px', marginRight: '12px' }}>
                <ClockIcon />
              </div>
              {standing.name}
            </Standing>
          )
      )}
    </Block>
  )
}