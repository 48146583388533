import React, { useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { Auth0User, useAuthorize, useLogOut } from "../auth.service";
import { CurrentUser } from "../../../api/auth.api";
import { useNavigate } from "react-router-dom";
import { isAthleteProfileSetUp } from "../../User/user.utils";

type Props = {
  element: (props: { user: Auth0User, currentUser: CurrentUser }) => React.ReactElement
}

// let in users who registered in auth0 but not completed athlete registration
export const AuthorizedButNotRegisteredAthleteRoute = withAuthenticationRequired(({ element }: Props) => {
  const navigate = useNavigate()
  const { user: auth0User } = useAuth0<Auth0User>()
  const { logout } = useLogOut()

  const { isLoading, user } = useAuthorize({
    onSuccess(user) {
      if (isAthleteProfileSetUp(user)) {
        navigate('/feed')
      }
    },
    onError() {
      logout()
    }
  })

  useEffect(() => {
    if (!auth0User) logout()
  }, [auth0User])

  if (isLoading || !user || isAthleteProfileSetUp(user)) {
    return <LoadingScreen />
  }

  return auth0User ? element({ user: auth0User, currentUser: user }) : null
}, { onRedirecting: LoadingScreen });
