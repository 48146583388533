import { AnimalInList } from "../../api/animals.api";
import cowPlaceholder from '../../img/cowboy-placeholder.png'
import calfPlaceholder from '../../img/calf-placeholder.png'
import bullPlaceholder from '../../img/bull-placeholder.png'
import horsePlaceholder from '../../img/horse-placeholder.png'
import { CSSProperties } from "react";

export const AnimalImagePlaceholder = ({ animal, style = { width: '50%' } }: { animal: AnimalInList, style?: CSSProperties }) => {
  const lower = animal.animalType.toLowerCase()
  return lower.includes('horse')
    ? <img src={horsePlaceholder} alt="horse" style={style} />
    : lower.includes('bull')
      ? <img src={bullPlaceholder} alt="bull" style={style} />
      : lower.includes('calf')
        ? <img src={calfPlaceholder} alt="calf" style={style} />
        : lower.includes('steer')
          ? <img src={cowPlaceholder} alt="steer" style={style} />
          : null
}