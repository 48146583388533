import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import logoImg from '../../img/title-logo.png';
import { LeftChevronIcon } from '../Icons';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.darkPallet.darker,
    height: '20px',
    width: '20px',
  },
}));

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  position: 'absolute',
  marginLeft: '47%',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const PageHeader = ({ logo = true }: { logo?: boolean }) => {
  const navigate = useNavigate();

  const onBack = () => navigate(-1);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        height: '50px',
        alignItems: 'center',
        backgroundImage: (theme) => theme.palette.background.gradient,
        ml: '-1rem',
        mr: '-1rem',
      }}
    >
      <CustomIconButton aria-label="back" onClick={onBack}>
        <LeftChevronIcon />
      </CustomIconButton>
      {logo && (
        <Logo>
          <img src={logoImg} alt="Logo" />
        </Logo>
      )}
    </Box>
  );
};

export default PageHeader;
