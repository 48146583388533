import { Typography, Select, MenuItem, TextField, Box, InputLabel } from '@mui/material';

const PartnerSelectionSecondStep = () => {
  return (
    <>
      <Typography variant="h3" color="primary.main" sx={{ pt: 2, pb: 2 }}>
        Add your partner information
      </Typography>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Name</InputLabel>
        <TextField fullWidth placeholder="Charles Macmillan" />
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Email</InputLabel>
        <TextField fullWidth placeholder="macmillancharles@gmail.com" />
      </Box>
      <Box sx={{ pt: 2 }}>
        <InputLabel>Position</InputLabel>
        <Select fullWidth value="header" displayEmpty inputProps={{ 'aria-label': 'SIRE' }}>
          <MenuItem value="header">Header</MenuItem>
          <MenuItem value="footer">Footer</MenuItem>
        </Select>
      </Box>
      <Typography sx={{ pt: 2 }} variant="overline" display="block">
        An email will be send to: macmillancharles@gmail.com providing instructions and links for registering the event.
      </Typography>
    </>
  );
};

export default PartnerSelectionSecondStep;
