
import { CSSProperties, ReactElement, useRef } from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useResetRowHeightOnResize } from "../../../components/InfiniteList/hooks";

type InfiniteListElement<T> = (props: {
  item: T,
  onLoad?(): void,
  row: number,
  firstPost?: boolean,
  itemRef?: any,
}) => ReactElement;

export function InfiniteVariableSizeGameList<T>({
  columnsCount = 1,
  isFetchingNextPage,
  fetchNextPage,
  items,
  perPage,
  total,
  element,
  virtualLoaderRef,
  spaceY = 0,
}: {
  columnsCount?: 1 | 2;
  isFetchingNextPage: boolean,
  fetchNextPage(): void;
  items: T[];
  perPage: number;
  total: number;
  element: InfiniteListElement<T>;
  virtualLoaderRef?: any;
  spaceY: number;
}) {
  const totalRows = Math.ceil(total / columnsCount);
  const rowsPerPage = Math.ceil(perPage / columnsCount);
  const loaded = items.length;

  const listRef = useRef<VariableSizeList | null>(null);
  const rowHeightsRef = useRef<number[]>([])
  useResetRowHeightOnResize(() => listRef.current?.resetAfterIndex(0))

  const isItemLoaded = (index: number) => {
    return index * columnsCount <= loaded;
  };

  const loadMoreItems = () => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  };
  const getRowHeight = (index: number): number => {
    return (rowHeightsRef.current[index] ?? 350) + spaceY;
  }

  const addNewRowHeight = (index: number, size: number) => {
    if (rowHeightsRef.current[index] === size) {
      return
    }
    else {
      rowHeightsRef.current[index] = size
      listRef.current?.resetAfterIndex(0)
    }
  }
  return (
    <AutoSizer
      children={({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={totalRows}
          loadMoreItems={loadMoreItems}
          minimumBatchSize={rowsPerPage}
          threshold={5}
          ref={virtualLoaderRef}
          children={({ onItemsRendered, ref }) => (
            <VariableSizeList
              onItemsRendered={onItemsRendered}
              itemSize={getRowHeight}
              height={height}
              itemCount={total}
              width={width}
              ref={list => {
                ref(list);
                listRef.current = list;
              }}
              children={({ style, index }) => (
                // Here is where each post is created
                <InfiniteListRow
                  columnsCount={columnsCount}
                  items={items}
                  element={element}
                  style={style}
                  index={index}
                  addNewRowHeight={addNewRowHeight}
                />
              )}
            />
          )}
        />
      )}
    />
  );
}

function InfiniteListRow<T>({
  items,
  element: Element,
  style,
  index,
  columnsCount,
  addNewRowHeight,
}: {
  items: T[],
  element: InfiniteListElement<T>;
  style?: CSSProperties,
  index: number;
  columnsCount: 1 | 2;
  addNewRowHeight?(index: number, size: number): void;
}) {
  const item = items[index * columnsCount];

  if (!item) return null;

  const rowRef = useRef<HTMLDivElement>(null);
  if (index === 0 && rowRef.current) {
    addNewRowHeight?.(index, rowRef.current?.children[0].clientHeight);
  }

  const onLoad = () => {
    if (rowRef.current) {
      addNewRowHeight?.(index, rowRef.current?.children[0].clientHeight);
    }
  }

  return (
    <div ref={rowRef} style={{ ...style }} >
      <Element
        onLoad={onLoad}
        item={item}
        row={index}
      />
    </ div>
  );
}

