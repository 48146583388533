import { Avatar, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import BullRidingImg from '../../img/bull-riding.png';

const items = [
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TX',
    avatar: BullRidingImg,
    pt: '82pt',
    serial: 1,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TX',
    avatar: BullRidingImg,
    pt: '82pt',
    serial: 1,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TX',
    avatar: BullRidingImg,
    pt: '82pt',
    serial: 1,
  },
  {
    title: 'Caleb Tierney',
    price: '$47.156,58',
    tx: 'TX',
    avatar: BullRidingImg,
    pt: '82pt',
    serial: 1,
  },
];

const SliderItem = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#423C37', width: '320px', m: 1, borderRadius: '6px', overflow: 'hidden' }}>
        {props.children}
      </Box>
    </Box>
  );
};

const SliderItemTitle = styled(Box)(({ theme }) => ({
  backgroundImage: theme.palette.background.gradient,
  display: 'flex',
  alignItems: 'center',
  height: '44px',
  paddingLeft: '8px',
}));

const RidingItem = (props: {
  title: string;
  price: string;
  avatar: string;
  pt: string;
  serial: number;
  tx: string;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'darkPallet.darker',
        borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 2,
        overflow: 'hidden',
        pl: 1,
        mb: 2,
      }}
    >
      <Typography variant="caption" fontSize="2em" sx={{ pr: 1, color: 'primary.main' }}>
        {props.serial}
      </Typography>
      <Avatar src={props.avatar} alt={props.title} />
      <Box sx={{ pl: 1, flex: 1 }}>
        <Typography variant="subtitle1" fontWeight="700">
          {props.title}
        </Typography>
        <Typography variant="caption" fontWeight="500" color="primary.main" sx={{ pr: 1 }}>
          {props.price}
        </Typography>
        <Typography variant="caption">. {props.tx}</Typography>
      </Box>
      <Typography
        variant="body1"
        fontWeight="700"
        sx={{
          backgroundImage: (theme) => theme.palette.background.gradient,
          height: '80px',
          width: '72px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'darkPallet.dark',
        }}
      >
        {props.pt}
      </Typography>
    </Box>
  );
};

const RealTimeScoreSlider = () => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };
  return (
    <Box sx={{ pb: 3, marginLeft: '-15px', marginRight: '-15px' }}>
      <Slider {...settings}>
        <SliderItem>
          <SliderItemTitle>
            <Typography
              variant="h6"
              color="darkPallet.dark"
              fontWeight="400"
              textTransform="uppercase"
              fontFamily="BourbonW00-Regular"
            >
              Bull Riding
            </Typography>
          </SliderItemTitle>
          <Box sx={{ p: 2 }}>
            {items.map((item, index) => {
              return <RidingItem key={index} {...item} />;
            })}
          </Box>
        </SliderItem>
        <SliderItem>
          <SliderItemTitle>
            <Typography
              variant="h6"
              color="darkPallet.dark"
              fontWeight="400"
              textTransform="uppercase"
              fontFamily="BourbonW00-Regular"
            >
              Bull Riding
            </Typography>
          </SliderItemTitle>
          <Box sx={{ pt: 2 }}>
            {items.map((item, index) => {
              return <RidingItem key={index} {...item} />;
            })}
          </Box>
        </SliderItem>
        <SliderItem>
          <SliderItemTitle>
            <Typography
              variant="h6"
              color="darkPallet.dark"
              fontWeight="400"
              textTransform="uppercase"
              fontFamily="BourbonW00-Regular"
            >
              Bull Riding
            </Typography>
          </SliderItemTitle>
          <Box sx={{ pt: 2 }}>
            {items.map((item, index) => {
              return <RidingItem key={index} {...item} />;
            })}
          </Box>
        </SliderItem>
      </Slider>
    </Box>
  );
};

export default RealTimeScoreSlider;
