import { useEffect, useRef, useState } from 'react';
import { Button, SelectChangeEvent, Typography } from '@mui/material';
import { v1 as uuid } from 'uuid';
import InputLabel from '@mui/material/InputLabel';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { z } from 'zod'
import { Box, styled } from '@mui/system';
import { useNavigate } from "react-router-dom";

import Fixedbox from '../../../../components/Fixedbox';
import { Page } from '../../../../components/Page/Page';
import cls from './AthleteSetup.module.css';
import { FormTextField } from "../../../../components/Form/FormTextField";
import { useSignUpContext } from "../SignUpContext";
import { useZodForm } from "../../../../utils/useZodForm";
import logoImg from '../../../../img/title-logo.png';
import { FormSelectField } from '../../../../components/Form/FormSelector';
import { getYearsRange } from '../../../../utils';
import plusSvg from '../../../../img/svgs/add.svg'
import trashSvg from '../../../../img/svgs/trash.svg'
import { usePhoneContext } from '../PhoneContext';
import { routes } from '../../../../routes';
import { CurrentUser } from '../../../../api/auth.api';
import { useUpdateAthlete } from '../../auth.service';
import { useDisciplineContext } from '../DisicplineContext';

export type Horse = {
  name?: string; age?: any; refId: string
}

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const YEARS = getYearsRange(1970);
const PLACEHOLDER = 'Enter answer here'

const schema = z.object({
  nfrQualificationsYear: z.optional(z.array(z.string().max(100))),
  worldTitlesYear: z.optional(z.array(z.string().max(100))),
  biggestRodeoWin: z.optional(z.string().max(250)),
  highestScoreYear: z.optional(z.string().max(100)),
  highestScoreLoc: z.optional(z.string().max(100)),
  sponsors: z.optional(z.string().max(100)),
  favoriteHorseDraw: z.optional(z.string().max(100)),
  rankestHorse: z.optional(z.string().max(100)),
  scariestHorse: z.optional(z.string().max(100)),
  travellingPartners: z.optional(z.string().max(100)),
  rodeoRig: z.optional(z.string().max(100)),
  qstEventRodeo: z.optional(z.string().max(250)),
  qstSuccessfulEvent: z.optional(z.string().max(250)),
});

const CompetitionBackgroundSetupPage = ({ currentUser }: { currentUser: CurrentUser }) => {
  const myRef = useRef<null | HTMLDivElement>(null)
  const navigate = useNavigate()
  const [horses, setHorses] = useState<Horse[]>([{
    name: '',
    age: 0,
    refId: uuid(),
  }])

  useEffect(() => {
    if (currentUser?.athleteProfile?.horses && currentUser?.athleteProfile?.horses?.length > 0) {
      setHorses(currentUser?.athleteProfile?.horses)
    }
  }, [window, currentUser])

  const form = useZodForm(schema, {
    defaultValues: {
      nfrQualificationsYear: currentUser?.athleteProfile?.nfrQualificationsYear || undefined,
      worldTitlesYear: currentUser?.athleteProfile?.worldTitlesYear || undefined,
      biggestRodeoWin: currentUser?.athleteProfile?.biggestRodeoWin || undefined,
      highestScoreYear: currentUser?.athleteProfile?.highestScoreYear || undefined,
      sponsors: currentUser?.athleteProfile?.sponsors || undefined,
      favoriteHorseDraw: currentUser?.athleteProfile?.favoriteHorseDraw || undefined,
      rankestHorse: currentUser?.athleteProfile?.rankestHorse || undefined,
      scariestHorse: currentUser?.athleteProfile?.scariestHorse || undefined,
      travellingPartners: currentUser?.athleteProfile?.travellingPartners || undefined,
      rodeoRig: currentUser?.athleteProfile?.rodeoRig || undefined,
      qstEventRodeo: currentUser?.athleteProfile?.qstEventRodeo || undefined,
      qstSuccessfulEvent: currentUser?.athleteProfile?.qstSuccessfulEvent || undefined,
    }
  });
  const [profile, setContext] = useSignUpContext()
  const [phone] = usePhoneContext();
  const [disciplines] = useDisciplineContext();
  if (!phone?.phone) navigate(routes.athleteAuth.phoneVerification)
  if (!disciplines?.disciplineIds.length) navigate(routes.athleteAuth.disciplines)
  if (!profile) navigate(routes.athleteAuth.profileSetup)
  const { mutate: registerAthlete } = useUpdateAthlete({ lastStep: false });

  useEffect(() => { myRef?.current?.scrollIntoView() }, [])

  const submit = () => {
    if (currentUser) {
      registerAthlete({
        ...{ ...profile, ...form.getValues(), horses: horses.filter(horse => !!horse.name).map(horse => { horse.age = horse.age || 0; return horse }) },
        uuid: currentUser.uuid,
        isProfileSetUp: true,
        disciplines: disciplines?.disciplineIds,
        interests: [],
        phone: phone?.phone,
      })
    }

    window.dataLayer.push({
      event: 'signup-athlete-step-2',
      value: {
        nfrQualificationsYear: form.getValues().nfrQualificationsYear,
        worldTitlesYear: form.getValues().worldTitlesYear,
        biggestRodeoWin: form.getValues().biggestRodeoWin,
        highestScoreYear: form.getValues().highestScoreYear,
        sponsors: form.getValues().sponsors,
        favoriteHorseDraw: form.getValues().favoriteHorseDraw,
        rankestHorse: form.getValues().rankestHorse,
        scariestHorse: form.getValues().scariestHorse,
        travellingPartners: form.getValues().travellingPartners,
        rodeoRig: form.getValues().rodeoRig,
        qstEventRodeo: form.getValues().qstEventRodeo,
        qstSuccessfulEvent: form.getValues().qstSuccessfulEvent,
      }
    });
    if (profile) { setContext({ ...profile, ...form.getValues(), horses: horses.filter(horse => !!horse.name).map(horse => { horse.age = horse.age || 0; return horse }) }); navigate(routes.athleteAuth.stepThreeSignup) }
    else navigate(routes.athleteAuth.profileSetup)
  }

  const newHorse = () => ({
    name: '',
    age: 0,
    refId: uuid(),
  });

  const handleChange = (value: string | number, refId: string | undefined, key: string, ind: number) => {
    const element = horses.find((h) => h.refId === refId);
    if (element) {
      element[key as keyof Horse] = value;
      setHorses((prev: Horse[]) => {
        const ret = prev;
        ret[ind] = element;
        return [...ret];
      });
    }
  };

  const handleChangeMultiSelect = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string[]>) => {
    const { target: { value } } = e;
    form.setValue(key as any, typeof value === 'string' ? value.split(',') : value);
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Profile Setup | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Athlete Setup Step 2</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{ pt: 5 }} ref={myRef}>
        <div className={cls['container']}>
          <Logo>
            <img src={logoImg} alt="Logo" />
          </Logo>
        </div>
        <Typography variant="h3" className={cls['middle-title']} align="left" color="primary.dark">Your Competition Background</Typography>
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>NFR qualifications</InputLabel>
              <FormSelectField fullWidth id="user-qualifications" multiple form={form} name='nfrQualificationsYear' values={YEARS} customOnChange={e => handleChangeMultiSelect('nfrQualificationsYear', e)} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>World titles</InputLabel>
              <FormSelectField fullWidth id="user-world-titles" multiple form={form} name='worldTitlesYear' values={YEARS} customOnChange={e => handleChangeMultiSelect('worldTitlesYear', e)} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Biggest Rodeo Win</InputLabel>
              <FormTextField id="user-rodeo_win" fullWidth form={form} name='biggestRodeoWin' />
            </Grid>
          </Grid>

          <Typography className={cls['vitals']} align="left">Your fastest time or highest score</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>When</InputLabel>
              <FormSelectField fullWidth id="user-hight_score_year" form={form} name='highestScoreYear' values={YEARS} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Where</InputLabel>
              <FormTextField fullWidth id="user-hight_score_loc" form={form} name='highestScoreLoc' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Your sponsors</InputLabel>
              <FormTextField id="user-sponsors" placeholder='Name 1, name 2, name 3' fullWidth form={form} name='sponsors' />
            </Grid>
          </Grid>

          <div className={cls['line']} />
          <Typography className={cls['vitals']} align="left">Your Horses</Typography>
          <Grid container spacing={2}>
            {horses.length && horses.map(({ name, age, refId }, index) =>
              <>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontWeight: '700' }}>Name</InputLabel>
                  <FormTextField fullWidth id="user-horse-name" placeholder="Name" value={name} customOnChange={(e) => handleChange(e.target.value, refId, 'name', index)} />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel sx={{ fontWeight: '700' }}>Age</InputLabel>
                  <FormTextField type='number' fullWidth id="user-horse-name" placeholder="1" value={age} customOnChange={(e) => handleChange(Number(e.target.value) || '', refId, 'age', index)} />
                </Grid>
                {index !== 0 && <div className={cls['trash-container']} onClick={() => { setHorses(prev => prev.filter(h => h.refId !== refId)) }}> <img src={trashSvg} /></div>}
                <div className={cls['line-less']} />
              </>
            )}
            <div className={cls['add-container']} onClick={() => { setHorses(prev => [...prev, newHorse()]) }}>
              <img src={plusSvg} alt='add' />
              <InputLabel sx={{ fontWeight: '700', margin: '8px 5px 0px 10px', width: 150 }}>Add Horse</InputLabel>
            </div>
          </Grid>

          <div className={cls['line']} />
          <Typography className={cls['vitals']} align="left">Horses and Bulls</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Favorite to draw</InputLabel>
              <FormTextField fullWidth id="user-horse_draw" placeholder="Enter answer here" form={form} name='favoriteHorseDraw' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Rankest</InputLabel>
              <FormTextField fullWidth id="user-horse-rankest" placeholder="Enter answer here" form={form} name='rankestHorse' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Scariest</InputLabel>
              <FormTextField fullWidth id="user-horse-scariest" placeholder="Enter answer here" form={form} name='scariestHorse' />
            </Grid>
          </Grid>

          <div className={cls['line']} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Current traveling partners</InputLabel>
              <FormTextField fullWidth id="user-traveling-partners" placeholder="Name 1, name 2, name 3, name 4" form={form} name='travellingPartners' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Rodeo rig</InputLabel>
              <FormTextField fullWidth id="user-rodeo-rig" placeholder="Enter answer here" form={form} name='rodeoRig' />
            </Grid>
          </Grid>

          <div className={cls['line']} />
          <Grid item xs={12}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Why you picked your chosen event in rodeo?</InputLabel>
              <FormTextField
                fullWidth
                sx={{
                  borderRadius: '6px',
                  overflow: 'scroll',
                  justifyContent: 'flex-start'
                }}
                multiline
                form={form}
                name='qstSuccessfulEvent'
                id='qstSuccessfulEvent'
                placeholder={PLACEHOLDER}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ pt: 2, pb: 12 }}>
              <InputLabel>What other event in Rodeo do you think you could be successful at if you haven’t tried?</InputLabel>
              <FormTextField
                fullWidth
                sx={{
                  borderRadius: '6px',
                  overflow: 'scroll',
                  justifyContent: 'flex-start'
                }}
                multiline
                form={form}
                name='qstEventRodeo'
                id='qstEventRodeo'
                placeholder={PLACEHOLDER}
              />
            </Box>
          </Grid>
        </Box>
        <Fixedbox>
          <Button type='submit' variant="contained" color="primary" fullWidth>
            Next
          </Button>
        </Fixedbox>
      </Page>
    </>
  );
};

export default CompetitionBackgroundSetupPage;
