import { Typography, Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { LinkIcon } from '../../components/Icons';

interface Props {
  children: React.ReactNode;
}

const SliderItem = (props: Props) => {
  return <Box>{props.children}</Box>;
};

interface LinkItemProps {
  name: string;
  link: string;
}

const LinkItem = (props: LinkItemProps) => {
  return (
    <Box sx={{ width: '350px', mr: 2 }}>
      <Link
        component={RLink}
        to={props.link}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'darkPallet.main',
          fontSize: '18px',
          height: '80px',
          textDecoration: 'none',
          color: 'lightPallet.lighter',
          ' & svg': {
            height: '20px',
            width: '20px',
            marginRight: '5px',
            display: 'inline-block',
            stroke: (theme) => theme.palette.lightPallet.lighter,
          },
        }}
      >
        <LinkIcon />
        <Typography variant="caption" fontSize="18px" textTransform="uppercase">
          {props.name}
        </Typography>
      </Link>
    </Box>
  );
};

const links = [
  {
    name: 'BBR Rules and Guidelines',
    link: '/',
  },
  {
    name: 'BBR Rules and Guidelines',
    link: '/',
  },
  {
    name: 'BBR Rules and Guidelines',
    link: '/',
  },
  {
    name: 'BBR Rules and Guidelines',
    link: '/',
  },
];

const LinksSlider = () => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };

  return (
    <Box sx={{ marginRight: '-2px' }}>
      <Slider {...settings}>
        {links.map((item: LinkItemProps, index: number) => {
          return (
            <SliderItem key={index}>
              <LinkItem {...item} />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};
export default LinksSlider;
