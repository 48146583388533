import { Box } from '@mui/material';
import { Page } from '../../components/Page/Page';
import PageHeader from '../../components/Header/PageHeader';
import pageBg from '../../img/home-page-bg.png';
import { Helmet } from 'react-helmet';

import {
  CreditCardIcon,
  LogoutIcon,
} from '../../components/Icons';
import { useLogOut } from "../../features/Auth/auth.service";
import { SettingLink } from "./SettingLink";

const settingLinks = [
  {
    title: 'Payment methods',
    Icon: CreditCardIcon,
    link: '/payment-methods',
  },
  // {
  //   title: 'Privacy',
  //   Icon: LockIcon,
  //   link: '/privacy',
  // },
  // {
  //   title: 'Security',
  //   Icon: SheildIcon,
  //   link: '/security',
  // },
  // {
  //   title: 'Help',
  //   Icon: LifeRing,
  //   link: '/help',
  // },
  // {
  //   title: 'Notifiations',
  //   Icon: NotificationIcon,
  //   link: '/notification-settings',
  // },
  // {
  //   title: 'About',
  //   Icon: InfoIcon,
  //   link: '/about-us',
  // },
];

const Settings = () => {
  const { logout } = useLogOut();

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Settings | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Settings</title>
      </Helmet>
      <Page
        sx={{
          pt: 0,
          backgroundImage: `url(${pageBg})`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <PageHeader />
        <Box
          sx={{
            pt: 2,
            zIndex: 2,
            position: 'relative',
          }}
        >
          {settingLinks.map((item, index) => <SettingLink {...item} key={index} />
          )}
          <SettingLink title='Logout' Icon={LogoutIcon} onClick={logout} />
        </Box>
      </Page>
    </>
  );
};

export default Settings;
