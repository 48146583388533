import { buildApiInfiniteQueryHook, buildApiQueryHook } from '../utils/api.utils';
import qs from 'qs';
import cmsRequest from '../utils/cmsRequest';
import { CmsImage, CmsPaginated } from './cms.api';
import { CurrentUser } from './auth.api';
import { FeedTypeEnum } from '../pages/Feeds/feedTypes';

export type ArticleFromApi = {
  id: number;
  attributes: {
    title: string;
    subtitle: string;
    content: string;
    Featured: boolean;
    cover_media: {
      data?: CmsImage<['large', 'medium', 'small', 'thumbnail']>;
    };
    facebook_share_image: {
      data?: CmsImage<['large', 'medium', 'small', 'thumbnail']>;
    };
    publishedAt: string;
    MC_Shopify: string[];
    author: {
      data?: AuthorFromApi;
    };
    video_posts: {
      data: VideoPost[];
    };
    mux_video_uploader_mux_assets: {
      data: Mux[];
    };
    Social_Link: {
      id?: number;
      IG_Link?: string| null;
      YT_Link?: string | null;
      Tweet_Link?: string | null;
      FBook_Link?: string | null;
      TikTok_Link?: string | null;
      [key: string]: any;
    };
    ads_url?: string;
  };
};

export type Article = { id: number } & Omit<ArticleFromApi['attributes'],'cover_media' | 'author' | 'video_posts' | 'mux_video_uploader_mux_assets'> & {
    cover: ArticleFromApi['attributes']['cover_media']['data'];
    author?: Author;
    videos: ArticleFromApi['attributes']['video_posts']['data'];
    muxVideos: ArticleFromApi['attributes']['mux_video_uploader_mux_assets']['data'];
    Featured?: ArticleFromApi['attributes']['Featured'];
    Social_Link?: ArticleFromApi['attributes']['Social_Link'];
  } & { feedType: FeedTypeEnum.ARTICLE };

const mapArticle = (item: ArticleFromApi): Article => ({
  id: item.id,
  ...item.attributes,
  cover: item.attributes.cover_media.data,
  author: item.attributes.author.data && mapAuthor(item.attributes.author.data),
  videos: item.attributes.video_posts.data,
  muxVideos: item.attributes.mux_video_uploader_mux_assets.data,
  feedType: FeedTypeEnum.ARTICLE,
  Featured: item.attributes.Featured,
  Social_Link: item.attributes.Social_Link,
});

export type VideoPost = {
  id: number;
  attributes: ArticleFromApi['attributes'] & {
    Title?: string;
    Cover_Image?: CmsImage<never>;
    MC_Shopify: string[];
    publishedAt: string;
    Video_Link?: string;
  };
};

export type Mux = {
  id: number;
  attributes: {
    asset_id: string;
    playback_id: string;
    title: string;
  };
};

type AuthorFromApi = {
  id: number;
  attributes: {
    First_Name: string;
    Last_Name: string;
  };
};

export type Author = { id: number } & AuthorFromApi['attributes'];

const mapAuthor = (item: AuthorFromApi): Author => ({
  id: item.id,
  ...item.attributes,
});

type PinPostArticleFromApi = {
  id: number;
  attributes: {
    title: string;
    subtitle: string;
    content: string;
    publishedAt: string;
    updatedAt: string;
    MC_Shopify: string[];
    Featured: boolean;
  };
};

export type ArticlePinPost = { id: number } & Omit<PinPostArticleFromApi['attributes'],'cover_media' | 'author' | 'video_posts' | 'mux_video_uploader_mux_assets'>;

export const mapPinPostArticle = (item: PinPostArticleFromApi): ArticlePinPost => ({
  id: item.id,
  ...item.attributes,
});

export const usePinPostInfiniteQuery = buildApiInfiniteQueryHook<
  {
    filterParams: [];
  },
  CmsPaginated<ArticleFromApi>,
  Article[]
>(
  'articles',
  () =>
    cmsRequest.get('/articles', {
      params: {
        populate: '*',
        sort: 'publishedAt:DESC',
        filters: {
          Featured: { $eq: true },
          MC_Shopify: {
            $notContainsi: 'Not-App',
          },
        },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }),
  {
    keepPreviousData: true,
    select: (data) => data.data.map(mapArticle),
  },
);

export const useArticlesInfiniteQuery = buildApiInfiniteQueryHook<
  {
    limit: number;
    filterParams: { selected: boolean; name: string }[];
  },
  CmsPaginated<ArticleFromApi>,
  Article[]
>(
  'articles',
  ({ limit, pageParam, filterParams }) =>
    cmsRequest.get('/articles', {
      params: {
        pagination: {
          limit,
          start: (pageParam - 1) * limit,
        },
        populate: '*',
        sort: 'publishedAt:DESC',
        filters: filterParams.some((interest) => interest.selected)
          ? {
              $or: filterParams
                .filter((interest) => interest.selected)
                .map((interest) => ({ MC_Shopify: { $containsi: interest.name } })),
              MC_Shopify: {
                $notContainsi: 'Not-App',
              },
            }
          : {
              $or: filterParams.map((interest) => ({ MC_Shopify: { $containsi: interest.name } })),
              MC_Shopify: {
                $notContainsi: 'Not-App',
              },
            },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }),
  {
    keepPreviousData: true,
    select: (data) => data.data.map(mapArticle),
  },
);

export const useArticleQuery = buildApiQueryHook<{ id: number }, { data: ArticleFromApi }, Article>(
  'article',
  ({ id }) => cmsRequest.get(`/articles/${id}?populate=*`),
  {
    select: ({ data }) => mapArticle(data),
  },
);

export const useGetPinPostQuery = async (user?: CurrentUser) => {
  const strapiUser = await cmsRequest.get(`/pin-posts?filters[UserUID][$eq]=${user?.uuid}&populate=*`);
  return strapiUser?.data;
};

export const useGetLivePostQuery = async () => {
  const strapiPosts = await cmsRequest.get(`/live-video?populate=deep`);
  return strapiPosts?.data;
};

export const usePostPinPostQuery = async (id: any[], user?: CurrentUser, strapiUserId?: string) => {
  const userToUpdate = {
    data: {
      Articles: id,
    },
  };
  const userToCreate = {
    data: {
      UserUID: user?.uuid,
      Articles: id,
    },
  };

  if (strapiUserId) {
    await cmsRequest.put(`/pin-posts/${strapiUserId}`, userToUpdate);
  } else {
    await cmsRequest.post('/pin-posts', userToCreate);
  }
};
