import Grid from '@mui/material/Grid';
import Product from './Product';

const ProductsGrid = ({ list, space = 2, xs = 6 }: { list: any; space?: number; xs?: number }) => {
  return (
    <Grid container spacing={space}>
      {list.map(
        (
          item: { title: string; price: string; oldPrice?: string; media?: string; category?: string; id?: string },
          index: number,
        ) => {
          return (
            <Grid item xs={xs} key={index}>
              <Product {...item} />
            </Grid>
          );
        },
      )}
    </Grid>
  );
};
export default ProductsGrid;
