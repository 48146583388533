import { Box, styled } from '@mui/system';
import { Divider, Typography, Chip, IconButton } from '@mui/material';
import { PlusIcon } from '../../components/Icons';
import eventImg from '../../img/card-bg-img.png';
import logo from '../../img/radio-logo.png';

const ViewsChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#ffffff',
  fontSize: '12px',
  borderColor: theme.palette.primary.main,
  fontWeight: '700',
  '& .MuiChip-label': {
    paddingLeft: '3px',
  },
  '& .MuiChip-icon': { height: '20px', width: '20px', marginRight: '4px' },
}));

const desiplineList = [
  {
    title: 'Barrell Racing',
    availableSlots: 5,
    limit: '3 of 5',
  },
  {
    title: 'Team Roping',
    availableSlots: 5,
    limit: '5 of 5',
  },
  {
    title: 'Steer Wrestling',
    availableSlots: 5,
    limit: '5 of 5',
  },
  {
    title: 'Saddle Bronc',
    availableSlots: 5,
    limit: '5 of 5',
  },
];

interface DesiplineType {
  title: string;
  availableSlots: number;
  limit: string;
}

const Desipline = ({ title, limit, availableSlots }: DesiplineType) => {
  return (
    <Box sx={{ display: 'flex', pb: 4, alignItems: 'center', justifyContent: 'space-between' }}>
      <Box>
        <Typography variant="h3" color="primary.main">
          {title}
        </Typography>
        <Box sx={{ mt: 1, display: 'flex' }}>
          <Typography variant="overline" sx={{ pr: 2 }}>
            <strong>Open Slots: </strong> {availableSlots}
          </Typography>
          <Typography variant="overline">
            Entry Limit: <strong> {limit}</strong>
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton
          sx={{
            backgroundImage: (theme) => theme.palette.background.gradient,
            '& svg': { width: '20px', height: '20px', fill: (theme) => theme.palette.darkPallet.dark },
          }}
        >
          <PlusIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const SecondStep = () => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          ml: '-1rem',
          mr: '-1rem',
          '& img': { position: 'static', height: '100%', width: '100%', objectFit: 'cover', opacity: 0.4 },
        }}
      >
        <img src={eventImg} alt="Event img" />
        <Box sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, pl: '1rem', pr: '1rem', pb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ViewsChip icon={<img src={logo} alt="" />} label="QUALIFIER" variant="outlined" />
          </Box>
          <Typography variant="h3" textTransform={'uppercase'} color="primary.main">
            American Rodeo Classic
          </Typography>
          <Typography variant="caption" fontWeight="700">
            July 12 - July 15 | AT Stadium 1 AT&T Way, Arlington, TX 7601, Dallas Texas
          </Typography>
        </Box>
      </Box>
      <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a consectetur phasellus ut id. Lorem nibh purus
        sollicitudin gravida mattis.
      </Typography>

      <Divider sx={{ ml: '-1rem', mr: '-1rem', mt: 2 }} />
      <Typography variant="subtitle1" sx={{ mt: 2, pb: 2 }} fontWeight="700" textTransform="uppercase">
        Select Disciplines
      </Typography>
      <Typography>Select the disciplines that you want to enter for this event.</Typography>
      <Box sx={{ ml: '-1rem', mr: '-1rem', mt: 2, backgroundColor: 'darkPallet.main', p: 2, pb: 0 }}>
        {desiplineList.map((item, index) => {
          return <Desipline key={index} {...item} />;
        })}
      </Box>
    </>
  );
};

export default SecondStep;
