import { StandingResults } from "../../../api/standings.api";
import { Fragment } from "react";
import { Cell, FirstColumn, HeaderCell, Position, SectionTitle, Table } from "./TableElements";
import { styled } from "@mui/system";

export const Wrapper = styled('div')(() => ({
  width: '100%',
  overflow: 'auto',
  background: '#423C37',
  flexGrow: 1,
}))

export const RegionsResultsTable = ({ item: { sections } }: { item: StandingResults.Region }) => {
  return (
    <>
      {sections.map((section, i) => (
        <Fragment key={i}>
          {section.name && (
            <SectionTitle>{section.name}</SectionTitle>
          )}
          <Wrapper>
            <Table>
              <thead>
                <tr>
                  <FirstColumn><b>Athlete</b></FirstColumn>
                  {section.titles.map((point, i) => (
                    <HeaderCell key={i}>{point}</HeaderCell>
                  ))}
                  <HeaderCell sx={{ width: '100%' }} />
                </tr>
              </thead>
              <tbody>
                {section.items.map((row, i) => (
                  <tr key={i}>
                    <FirstColumn>
                      <Position>{row.position}.</Position> {row.firstName} {row.lastName}
                    </FirstColumn>
                    {row.points.map((point, i) => (
                      <Cell key={i}>{point}</Cell>
                    ))}
                    <Cell sx={{ width: '100%' }} />
                  </tr>
                ))}
              </tbody>
            </Table>
          </Wrapper>
        </Fragment>
      ))}
    </>
  )
}
