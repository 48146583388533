import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { TimesIcon, SearchIcon } from '../../components/Icons';
import { styled } from '@mui/system';

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CancelIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    height: '30px',
    width: '30px',
    fill: theme.palette.lightPallet.main,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HistorySearchIcon = styled(Box)(({ theme }) => ({
  '& svg': {
    height: '15px',
    width: '15px',
    fill: theme.palette.lightPallet.main,
  },
}));
const historyList = ['Bull ridder', 'Bull Ridding', 'Professional Bull Riding'];

const SearchHistory = ({ onSelect, value }: { value: string; onSelect: (value: string) => () => void }) => {
  const result = historyList.filter((word) => word.toLowerCase().indexOf(value) > -1);

  if (result.length < 1) return null;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h3" color="primary.main" textTransform="uppercase">
        Recent
      </Typography>
      <Box sx={{ pt: 2 }}>
        {result.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 1, pb: 1 }}
              onClick={onSelect(item)}
            >
              <Box>
                <HistorySearchIcon>
                  <SearchIcon />
                </HistorySearchIcon>
              </Box>
              <Typography sx={{ flex: 1, pl: 2, pr: 1 }}>{item}</Typography>
              <Box>
                <HistorySearchIcon>
                  <TimesIcon />
                </HistorySearchIcon>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const SearchModal = ({ open = false, handleClose }: Props) => {
  const [value, setValue] = useState('');
  const [showHistory, setHistory] = useState(false);
  const onCloseHandler = () => {
    handleClose();
  };

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const onSelectFromHistory = (searchIalue: string) => () => {
    setValue(searchIalue);
    setHistory(false);
  };

  const onBeforeInputAction = () => {
    setHistory(true);
  };

  if (!open) return null;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onCloseHandler}
        hideBackdrop
        transitionDuration={{ enter: 1 }}
        TransitionComponent={Transition}
        sx={{ '& .MuiDialog-paper': { backgroundColor: 'darkPallet.darker', backgroundImage: 'none' } }}
      >
        <Box>
          <Box sx={{ display: 'flex', p: 1, alignItems: 'center', justifyContent: 'center' }}>
            <Box>
              <CancelIconButton onClick={onCloseHandler}>
                <TimesIcon />
              </CancelIconButton>
            </Box>
            <Box sx={{ flex: 1 }}>
              <TextField
                fullWidth
                multiline
                onChange={onChangeValue}
                value={value}
                onFocus={onBeforeInputAction}
                placeholder={'...Search'}
                sx={{ borderRadius: 2, pr: 4 }}
              />
            </Box>
          </Box>
          {showHistory && <SearchHistory value={value} onSelect={onSelectFromHistory} />}
        </Box>
      </Dialog>
    </div>
  );
};
export default SearchModal;
