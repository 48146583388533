import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { GameDescriptionData, GamingArticle, useGameQuery, useGamingPostsInfiniteQuery } from '../../../api/gaming.api';
import { InfiniteVariableSizeGameList } from './InfiniteVariableSizeGameList';
import { Layout } from "../../../components/Layout/Layout";
import { LoadingScreen } from '../../../components/Loading/LoadingScreen';
import useWindowDimentions from '../../../hooks/useWindowDimentions';
import { FeedItem } from '../GamesFeed/GameItem';
import { FeedTypeEnum } from '../gameTypes';
import { BigBonusGame, GameSponsors } from './GameDetailEvent';
import { NotFoundPage } from "../../../components/Errors/NotFoundPage";

export default function GameDetail() {
  const { gameName } = useParams<{ gameName: string }>();
  if (!gameName) return <NotFoundPage />

  return <LoadGame gameName={gameName} />
}

const LoadGame = ({ gameName }: { gameName: string }) => {
  const { data: game, isLoading } = useGameQuery({
    game: gameName
  })

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!game) {
    return <NotFoundPage />
  }

  return <GamePage gameName={gameName} game={game} />
}

const perPage = 8;

const GamePage = ({ gameName, game }: { gameName: string, game: GameDescriptionData }) => {
  const { width } = useWindowDimentions();

  const { data: gamePostsData, isFetchingNextPage, fetchNextPage } = useGamingPostsInfiniteQuery({
    limit: perPage,
    filterParams: [{ selected: true, name: gameName }]
  })

  const gamePosts = useMemo(() => {
    return gamePostsData?.pages.flat() || []
  }, [gamePostsData])

  const gameAndPosts = useMemo(() => {
    const items: (GamingArticle | GameDescriptionData | GameSponsors | BigBonusGame)[] = [...gamePosts]

    if (game) {
      items.unshift(game)

      if (game.BonusGame?.length) {
        items.push({
          BigBonus: game.BonusGame,
          feedType: FeedTypeEnum.BIGBONUSGAME
        })
      }

      if (game.Sponsors?.length) {
        items.push({
          GameSponsors: game.Sponsors,
          feedType: FeedTypeEnum.GAMESPONSORS
        })
      }
    }

    return items
  }, [gamePosts, game])

  if (!gamePostsData) {
    return <LoadingScreen />
  }

  const hasNextPage = gamePostsData.pages.length ? gamePostsData.pages[gamePostsData.pages.length - 1].length === perPage : true
  const total = gameAndPosts.length + (hasNextPage ? perPage : 0)

  const includeWhatsNewTitle = gamePosts.length > 0

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={game?.Name + " | Teton Ridge+"} />
        <meta name="twitter:description" content={game?.Description} />
        <meta name="twitter:image:src" content={game?.Image?.data?.attributes?.url} />

        <meta property="og:title" content={game?.Name + " | Teton Ridge+"} />
        <meta property="og:description" content={game?.Description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={game?.Image?.data?.attributes?.url} />
        <title>{gameName}</title>
      </Helmet>
      <Layout
        contentSx={{
          pl: 0,
          overflow: 'hidden',
          overflowX: 'hidden',
          maxWidth: '100%',
          position: 'relative',
        }}
        backTo='/more'
      >
        <Box sx={{
          display: 'flex',
          height: '99%',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'hidden'
        }}>
          <InfiniteVariableSizeGameList
            columnsCount={1}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            items={gameAndPosts}
            perPage={perPage}
            total={total}
            spaceY={width > 600 ? 80 : 15}
            element={(props) => (
              <FeedItem
                {...props}
                includeWhatsNewTitle={includeWhatsNewTitle}
              />
            )}
          />
        </Box>
      </Layout>
    </>
  );
}
