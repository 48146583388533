import { routes } from "../../../routes";
import { Layout } from "../../../components/Layout/Layout";
import { useMatch } from "react-router-dom";
import { AthletesListPage } from "./AthletesListPage";
// import { AnimalListPage } from "../../Animal/AnimalList/AnimalListPage";
import { CurrentUser } from "../../../api/auth.api";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const AthletesOrAnimalsPage = ({ user }: { user: CurrentUser }) => {
  const isAthletesPage = useMatch(routes.athletes)
  useEffect(() => { if (navigator.cookieEnabled) localStorage.removeItem("filterTags") }, [])
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${isAthletesPage ? 'Athletes' : 'Stock'} | Teton Ridge+`} />
        <meta name="twitter:description" content="Find your favorite rodeo athletes from all disciplines, and learn their stories!" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content={`${isAthletesPage ? 'Athletes' : 'Stock'} | Teton Ridge+`} />
        <meta property="og:description" content="Find your favorite rodeo athletes from all disciplines, and learn their stories!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>{isAthletesPage ? 'Athletes' : 'Stock'}</title>
      </Helmet>
      <Layout
      // contentSx={{ pl: 0 }}
      // tabs={{
      //   value: isAthletesPage ? 0 : 1,
      //   options: ['RIDERS', 'STOCK'],
      //   onChange(x) {
      //     navigate(x === 0 ? routes.athletes : routes.animals)
      //   }
      // }}
      >
        {/* {isAthletesPage ? <AthletesListPage user={user} /> : <ComingSoon />} */}
        <AthletesListPage user={user} />
      </Layout>
    </>
  )
}