const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export function register(config?: any) {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin)
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;

    window.addEventListener('load', () => {
      // pass needed env variables as URL params because env vars are not available inside service worker
      const params = new URLSearchParams({
        apiBaseUrl: process.env.REACT_APP_API_BASE_URL as string,
      }).toString()

      const swUrl = `${process.env.PUBLIC_URL}/custom-sw.js?${params}`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready;
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config: any) {
  navigator.serviceWorker
    .register(swUrl, { type: 'module' })
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) return;
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              if (config && config.onUpdate) config.onUpdate(registration);
            } else if (config && config.onSuccess) config.onSuccess(registration);
          }
        };
      };
    })
    .catch((error) => {
      console.error('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl: string, config: any) {
  fetch(swUrl)
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1))
        navigator.serviceWorker.ready.then((registration) =>
          registration.unregister().then(() => window.location.reload()),
        );
      else registerValidSW(swUrl, config);
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) navigator.serviceWorker.ready.then((registration) => registration.unregister());
}
