import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet';
import EventList from '../../../components/EventList';
import { CustomIconButton } from '../../../components/Header';
import { AlertIcon, LeftChevronIcon } from '../../../components/Icons';
import { Page } from '../../../components/Page/Page';
import EventPost from '../../../components/Post/EventPost';
import SectionTitle from '../../../components/SectionTitle';
import AthleteProfileImg from '../../../img/athlete-profile.png';
import EventImg from '../../../img/events-img.png';
import interestImg8 from "../../../img/interest/interest-8.png";
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetAnimalByUUID } from "../../../api/animals.api";
import { NotFoundPage } from "../../../components/Errors/NotFoundPage";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import AnimalSliders from '../../../components/RiderSliders';
import { AnimalImagePlaceholder } from "../AnimalmagePlaceholder";
import { VideoPost } from "../../../components/Post/VideoPost/VideoPost";

const ICON_BULL_RIDING = 'https://cdn.tetonridge.io/ICON_BULL_RIDING_ca7676c090.png?updated_at=2022-12-16T19:57:49.864Z';
const ICON_BREAKAWAY = 'https://cdn.tetonridge.io/ICON_BREAKAWAY_d3952fd87d.png?updated_at=2022-11-23T23:49:53.739Z';
const ICON_CUTTING = 'https://cdn.tetonridge.io/ICON_CUTTING_997c975e36.png?updated_at=2022-11-23T23:49:53.802Z';
const ICON_TEAM_ROPING =
  'https://cdn.tetonridge.io/ICON_TEAM_ROPING_61440c44c4.png?updated_at=2022-11-23T23:49:53.822Z';
const ICON_BARREL_RACE =
  'https://cdn.tetonridge.io/ICON_BARREL_RACE_15ee3d3258.png?updated_at=2022-11-23T23:49:53.271Z';
const ICON_BARBACK_RIDING =
  'https://cdn.tetonridge.io/ICON_BARBACK_RIDING_906b87ec3c.png?updated_at=2022-11-23T23:49:53.417Z';
const ICON_SADDLE_BRONC =
  'https://cdn.tetonridge.io/ICON_SADDLE_BRONC_40c13098a4.png?updated_at=2022-11-23T23:49:53.714Z';
const ICON_REINED_COW_HORSE =
  'https://cdn.tetonridge.io/ICON_REINED_COW_HORSE_b36427823a.png?updated_at=2022-11-23T23:49:53.841Z';
const ICON_REINING = 'https://cdn.tetonridge.io/ICON_REINING_93c9046469.png?updated_at=2022-11-23T23:49:53.961Z';
const ICON_TIE_DOWN = 'https://cdn.tetonridge.io/ICON_TIE_DOWN_8d02b4d948.png?updated_at=2022-11-23T23:49:53.997Z';
const ICON_STEER_WRESTLING =
  'https://cdn.tetonridge.io/ICON_STEER_WRESTLING_f8b99e92ea.png?updated_at=2022-11-23T23:49:54.138Z';

// asked to remove the button (https://github.com/apollo21io/tetonridge-roadmap/issues/550)
// const ProfileButton = styled(IconButton)(({ theme }) => ({
//   background: theme.palette.background.gradient,
//   border: `2px solid ${theme.palette.primary.main}`,
//   width: '45px',
//   height: '45px',
//   '& svg': {
//     width: '20px',
//     height: '20px',
//     stroke: theme.palette.primary.main,
//     strokeWidth: 0,
//   },
// }));

const ImagePlaceholder = styled('div')(({ theme }) => ({
  background: theme.palette.darkPallet.light,
  width: '100%',
  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },
}));

const ImagePlaceholderInner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    opacity: 0.1,
  },
}));

interface Rider {
  id: string,
  name: string,
}

const initialPastEventsState = {
  bullRiding: {
    title: 'Bull Riding',
    logo: ICON_BULL_RIDING,
    list: []
  },
  barrelRacing: {
    title: 'Barrel Racing',
    logo: ICON_BARREL_RACE,
    list: []
  },
  breakAway: {
    title: 'Break Away',
    logo: ICON_BREAKAWAY,
    list: []
  },
  reining: {
    title: 'Reining',
    logo: ICON_REINING,
    list: []
  },
  reinedCowHorse: {
    title: 'Reined Cow Horse',
    logo: ICON_REINED_COW_HORSE,
    list: []
  },
  cutting: {
    title: 'Cutting',
    logo: ICON_CUTTING,
    list: []
  },
  tieDown: {
    title: 'Tie Down',
    logo: ICON_TIE_DOWN,
    list: []
  },
  teamRoping: {
    title: 'Team Roping',
    logo: ICON_TEAM_ROPING,
    list: []
  },
  steerWrestling: {
    title: 'Steer Wrestling',
    logo: ICON_STEER_WRESTLING,
    list: []
  },
  bareback: {
    title: 'Bareback',
    logo: ICON_BARBACK_RIDING,
    list: []
  },
  saddleBronc: {
    title: 'Saddle Bronc',
    logo: ICON_SADDLE_BRONC,
    list: []
  },
  ridgeRiders: {
    title: 'Ridge Riders',
    logo: interestImg8,
    list: []
  },
};

interface pastEventsListObject {
  logo: string;
  title: string;
  list: any;
}

interface PastEventList {
  [id: string]: pastEventsListObject,
  bullRiding: pastEventsListObject;
  barrelRacing: pastEventsListObject;
  breakAway: pastEventsListObject;
  reining: pastEventsListObject,
  reinedCowHorse: pastEventsListObject,
  cutting: pastEventsListObject;
  tieDown: pastEventsListObject;
  teamRoping: pastEventsListObject,
  steerWrestling: pastEventsListObject,
  bareback: pastEventsListObject,
  saddleBronc: pastEventsListObject,
  ridgeRiders: pastEventsListObject,
}

const postItem: { title: string, logo: string, media: string, date: string; id: number }[] = [
  // {
  //   title: 'Video Title Goes Here and Probably Takes 2 Lines.',
  //   logo: videoLogo,
  //   media: VideoImage,
  //   date: '8 mins',
  // },
  // {
  //   title: 'Video Title Goes Here and Probably Takes 2 Lines.',
  //   logo: videoLogo,
  //   media: VideoImage1,
  //   date: '8 mins',
  // },
];

export const AnimalProfile = () => {
  const { uuid } = useParams();

  if (!uuid) {
    return <NotFoundPage />;
  }

  return <AnimalProfileWithParams uuid={uuid} />;
};

const AnimalProfileWithParams = ({ uuid }: { uuid: string; }) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [pastRow, setPastRow] = useState("");

  useEffect(() => {
    setPastRow(searchParams.get('row') || "");
    navigate("");
  }, []);

  const { data: animal, isLoading: isLoadingAnimal } = useGetAnimalByUUID({ uuid });

  const pastEventsList = useMemo<PastEventList>(() => {
    const pastEvents: PastEventList = initialPastEventsState;

    if (!animal) return pastEvents;

    animal.pastPerformance.forEach((evt) => {
      const event = evt as any;
      const eventStart = new Date(event.start_at);
      const eventEnd = new Date(event.end_at);

      const formatStartDate = eventStart.toDateString().split(' ').slice(1, 3).join(' ');
      const formatEndDate = eventEnd.toDateString().split(' ').slice(1, 4).join(' ');
      const formatDate = `${formatStartDate} - ${formatEndDate}`;

      const eventData = {
        name: event.name,
        place: '11th',
        date: formatDate,
      };

      switch (event.disciplines[0].type) {
        case 'Bull Riding':
          pastEvents.bullRiding.list.push(eventData);
          break;
        case 'Barrel Racing':
          pastEvents.barrelRacing.list.push(eventData);
          break;
        case 'Breakaway':
          pastEvents.breakAway.list.push(eventData);
          break;
        case 'Reining':
          pastEvents.reining.list.push(eventData);
          break;
        case 'Reined Cow Horse':
          pastEvents.reinedCowHorse.list.push(eventData);
          break;
        case 'Cutting':
          pastEvents.cutting.list.push(eventData);
          break;
        case 'Tie Down':
          pastEvents.tieDown.list.push(eventData);
          break;
        case 'Team Roping':
          pastEvents.teamRoping.list.push(eventData);
          break;
        case 'Steer Wrestling':
          pastEvents.steerWrestling.list.push(eventData);
          break;
        case 'Bareback':
          pastEvents.bareback.list.push(eventData);
          break;
        case 'Saddle Bronc':
          pastEvents.saddleBronc.list.push(eventData);
          break;
        case 'Ridge Riders':
          pastEvents.saddleBronc.list.push(eventData);
          break;
      }
    });

    return pastEvents;
  }, [animal]);

  const upcomingEventsList = useMemo(() => {
    if (!animal) return [];

    return animal.pastPerformance.map((evt) => {
      const event = evt as any;
      const eventStart = new Date(event.start_at);
      const eventEnd = new Date(event.end_at);

      const payouts = event.disciplines[0].prize.payouts[0];
      const fees = event.fees[0];

      const formatStartDate = eventStart.toDateString().split(' ').slice(1, 3).join(' ');
      const formatEndDate = eventEnd.toDateString().split(' ').slice(1, 4).join(' ');
      const formatDate = `${formatStartDate} - ${formatEndDate}`;

      return {
        title: event.name,
        location: `AT ${event.venue.address}, ${event.venue.city}, ${event.venue.zip}, ${event.venue.city} ${event.venue.state}`,
        date: formatDate,
        media: EventImg,
        price: payouts?.amount_type && payouts?.amount ? `${payouts.amount_type}${payouts.amount}` : `$0`,
        startPrice: fees?.amount_type && fees?.amount ? `${fees.amount_type}${fees.amount}` : `$0`,
        slots: 20,
        registrationAvailable: '6/10, 6:00 am to 18/10, 6:00 pm',
        link: `/event/${event.id}`,
      };
    });
  }, [animal]);

  if (isLoadingAnimal) {
    return <LoadingScreen />;
  }

  if (!animal) {
    return <NotFoundPage />;
  }

  const racerRiding = animal.riders.map((item) => {
    const rider = item as Rider;

    return {
      id: rider.id,
      tagName: 'Discipline',
      title: rider.name,
      link: '/',
      media: AthleteProfileImg,
    };
  });

  const goBack = () => {
    if (pastRow) {
      navigate("/animals", { state: { row: pastRow } });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Animal Profile | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Animal Profile | {uuid}</title>
      </Helmet>
      <Page>
        <Box
          sx={{
            position: 'relative',
            ml: '-1rem',
            mr: '-1rem',
            '& img': {
              position: 'static',
              height: '100%',
              width: '100%',
              objectFit: 'cover'
            },
          }}
        >
          <Box sx={{
            position: 'absolute',
            zIndex: 5,
            width: '100%',
            top: 0,
            px: 1,
            pt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <CustomIconButton aria-label='Back' onClick={() => goBack()}>
              <LeftChevronIcon style={{ width: '24px', height: '24px' }} />
            </CustomIconButton>
            <CustomIconButton stroke={true} fill={false} onClick={() => navigate('/')}>
              <AlertIcon />
            </CustomIconButton>
          </Box>
          <ImagePlaceholder>
            <ImagePlaceholderInner>
              <AnimalImagePlaceholder animal={animal} style={{ width: '50%', height: 'auto', marginTop: '-20%' }} />
            </ImagePlaceholderInner>
          </ImagePlaceholder>
          <Box
            sx={{
              backgroundImage: 'linear-gradient(0.15deg, #000000 11.96%, rgba(23, 23, 23, 0.15) 69.32%)',
              position: 'absolute',
              bottom: '0',
              top: '0',
              left: 0,
              right: 0,
              display: 'flex',
              alignItems: 'end',
              p: 2,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="h2" color="primary.main" textTransform="uppercase">
                  {animal.registeredName}
                </Typography>
                <Typography variant="overline">{animal.homeLocation}</Typography>
              </Box>
              <Box>
                {/*<ProfileButton onClick={() => navigate(routes.athleteEdit(1))}>*/}
                {/*  <UserWithPlusIcon />*/}
                {/*</ProfileButton>*/}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          {animal.bio}
        </Box>

        {Object.keys(pastEventsList).some((key) => pastEventsList[key].list.length > 0) && (
          <>
            <SectionTitle title="Past Performances" />
            {Object.keys(pastEventsList).map((key, i) =>
              <React.Fragment key={i}>
                {pastEventsList[key].list.length > 0 && (
                  <>
                    <EventList {...pastEventsList[key]} />
                    <Box sx={{ pb: 3 }} />
                  </>
                )}
              </React.Fragment>
            )}
          </>
        )}

        {upcomingEventsList.length > 0 && (
          <>
            <Box sx={{ pt: 3 }} />
            <SectionTitle title="Upcoming Events" hasLine />
            <Box>
              {upcomingEventsList.map((event: any, index) => (
                <EventPost.Upcoming
                  key={index}
                  title={event.title}
                  price={event.price}
                  slots={event.slots}
                  date={event.date}
                  media={event.media}
                  {...event}
                />
              ))}
            </Box>
          </>
        )}


        {racerRiding.length > 0 && (
          <>
            <SectionTitle title="Riders Ridden" hasLine />
            <AnimalSliders list={racerRiding} />
          </>
        )}

        {postItem.length > 0 && (
          <>
            <SectionTitle title="RELATED NEWS" hasLine />
            {postItem.map((item, index) => (
              <VideoPost key={index} {...item} />
            ))}
          </>
        )}
      </Page>
    </>
  );
};
