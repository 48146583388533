import { Alert, Button, Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Fixedbox from '../../../../components/Fixedbox';
import { Page } from '../../../../components/Page/Page';
import { z } from 'zod'
import { FormTextField } from "../../../../components/Form/FormTextField";
import { useNavigate } from "react-router-dom";
import { usePhoneContext } from "../PhoneContext";
import { useZodForm } from "../../../../utils/useZodForm";
import { postData } from '../../../../utils/api.twilio';
import { TWILIO_APP_API_BASE_URL } from '../../../../config/constants';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { CurrentUser } from '../../../../api/auth.api';
import { routes } from '../../../../routes';


const schema = z.object({
  phone: z.string({
    required_error: 'Phone is required!',
  }).nonempty({ message: "Can't be empty" }),
  code: z.string({
    required_error: 'Code is required!',
    // invalid_type_error: "It must be a 6 digits code!"
  }).length(6, { message: "The code must be exactly 6 digits long" }).nonempty({ message: "Can't be empty" })
});

const VerifyNumber = ({ currentUser: athleteUser }: { currentUser?: CurrentUser }) => {
  const [validatedPhone, setValidatedPhone] = useState('');

  const form = useZodForm(schema)

  const postRes = async (to: string, code: string) => {
    const res = await postData(TWILIO_APP_API_BASE_URL + 'check-verify', { to, code });
    return res.data?.success
  };

  const postRes2 = async (to: string) => {
    const res = await postData(TWILIO_APP_API_BASE_URL + 'start-verify', { to });
    return res.data?.success
  };


  const [showCodeError, setShowCodeError] = useState(false)
  const [showCodeSuccess, setShowCodeSuccess] = useState(false)
  const [showCodeError2, setShowCodeError2] = useState(false)

  const [, setPhoneContext] = usePhoneContext()
  const navigate = useNavigate()

  const sendCode = async () => {
    const phone = form.getValues().phone;
    // send datalayer event
    window.dataLayer.push({
      event: 'phone-send-code',
      value: phone
    });
    let success;
    try {
      success = await postRes2(phone);
    }
    catch (e) {
      success = false;
    }
    if (phone.length > 4 && success) {
      setValidatedPhone(phone);
      setShowCodeError(false);
      setShowCodeSuccess(true);
      setShowCodeError2(false)
    }
    else {
      setShowCodeError(true)
      form.setError("phone", { message: '' })
      setShowCodeSuccess(false)
      setShowCodeError2(false)
    }
  }

  const submit = async () => {
    const code = form.getValues().code;
    let success;
    try {
      // allow skipping check in development with code 123456
      if (process.env.NODE_ENV === 'development' && code === '123456') {
        success = true
      } else {
        success = await postRes(validatedPhone, code);
      }
    }
    catch (e) {
      success = false;
    }
    if (success) {
      // send datalayer event
      window.dataLayer.push({
        event: 'phone-verification-complete',
        value: validatedPhone
      })
      setPhoneContext({ phone: validatedPhone })
      navigate(athleteUser ? routes.athleteAuth.disciplines : '/interest')
    }
    else {
      setShowCodeSuccess(false)
      setShowCodeError2(true)
    }
  }

  const set1ToPhoneNumberIfEmpty = (value: string) => {
    if (!value.length) {
      form.setValue('phone', '1')
    }
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Verify Number | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Verify Number</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{ pt: 5 }}>
        <Typography variant="h3" align="center" color="primary.main">
          VERIFY YOUR PHONE NUMBER
        </Typography>
        <Typography
          variant="subtitle2"
          align="center"
          textTransform="uppercase"
          fontWeight="500"
          sx={{ pr: 5, pl: 5, pt: 2, pb: 3 }}
        >
          VERIFY YOUR PHONE NUMBER
        </Typography>
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <InputLabel sx={{ fontWeight: '700' }}>PHONE NUMBER</InputLabel>
              <InputLabel sx={{ fontWeight: '400', fontSize: 10 }}>Please include country code as well. US numbers will start with 1.</InputLabel>
              <FormTextField
                fullWidth
                placeholder="1 (000) 000-0000"
                form={form}
                name='phone'
                smsAuto={true}
                sx={{ borderRadius: "6px" }}
                onFocus={(e) => set1ToPhoneNumberIfEmpty(e.target.value)}
              />
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" color="primary" fullWidth sx={{ mt: "3rem", lineHeight: "23px", borderRadius: "6px" }} onClick={sendCode}>
                SEND CODE
              </Button>
            </Grid>
            {showCodeError && (
              <Container sx={{ maxWidth: '100%', paddingRight: 0, mt: "2rem" }} maxWidth={false}>
                <Box mb={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {!navigator.onLine ?
                    <Alert severity="error">Please check your internet connection</Alert>
                    :
                    <Alert severity="error">Please add a valid phone number</Alert>
                  }
                </Box>
              </Container>
            )}

            {showCodeSuccess && (
              <Container sx={{ maxWidth: '100%', mt: "2rem" }} maxWidth={false}>
                <Box mb={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Alert severity="success">Please verify the code sent to your phone</Alert>
                </Box>
              </Container>
            )}
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>VERIFICATION CODE</InputLabel>
              <FormTextField
                type="code"
                fullWidth
                placeholder="000000"
                form={form}
                name='code'
                sx={{ borderRadius: "6px" }}
                autoComplete="sms-otp"
                smsAuto={true}
              />
            </Grid>
          </Grid>
        </Box>

        {showCodeError2 && (
          <Container sx={{ maxWidth: '100%', paddingRight: 0, mt: "2rem" }} maxWidth={false}>
            <Box mb={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '100%' }}>
              <Alert severity="error">Please make sure the code is correct</Alert>
            </Box>
          </Container>
        )}
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              type='submit'
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                borderRadius: "6px",
                color: validatedPhone ? 'black' : undefined,
                opacity: validatedPhone ? 1 : 0.5,
              }}
              disabled={!validatedPhone}
            >
              VERIFY
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default VerifyNumber;
