import { datadogRum } from '@datadog/browser-rum';

const applicationId = process.env.REACT_APP_DD_APP_ID
const clientToken = process.env.REACT_APP_DD_CLIENT_TOKEN
const env = process.env.REACT_APP_DD_ENV

if (applicationId && clientToken && env) {
  datadogRum.init({
    applicationId,
    clientToken,
    env,
    site: 'datadoghq.com',
    service: 'tr-plus-client',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 0,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask-user-input',
    traceSampleRate: 0,
    allowedTracingUrls: [
      "https://api.tetonridgeplus.com",
      "https://dev-webapp-be.tetonridge.io",
      "https://tr-plus-api.staging.tetonridge.io"
    ]
  });

  datadogRum.startSessionReplayRecording();
}

export const setUserToDatalogRum = (user?: { sub?: string, email?: string }) => {
  if (user) {
    datadogRum.setUser({
      id: user.sub,
      email: user.email,
    })
  } else {
    datadogRum.removeUser()
  }
}
