import { Button, Chip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { CurrentUser, updateCurrentUserCache } from "../../api/auth.api";
import { DeleteIcon } from '../../components/Icons';
import { Page } from '../../components/Page/Page';
import { TabPanel } from '../../components/Tabs';
import { useZodForm } from '../../utils/useZodForm';
import cls from '../Dashboard/Dashboard.module.css';
import DashboardHeader from '../Dashboard/DashboardHeader';
import DashboardTab from '../Dashboard/DashboardTab';
import SavedContentTab from '../Dashboard/SavedContentTab';
import { subscribeUser } from '../../subscription';
import { checkIsAthlete } from "../../features/User/user.utils";
import { useUpdateAthleteMutation } from "../../api/athletes.api";
import { useUpdateUserMutation } from '../../api/users.api';

const Profile = ({ user }: { user: CurrentUser }) => {
  const [value] = useState(0);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const schema = z.object({
    profileImage: z.string(),
  });
  const form = useZodForm(schema, {
    defaultValues: {
      profileImage: user.profileImage
    }
  });

  const { mutate: updateAthlete } = useUpdateAthleteMutation()
  const { mutate: updateUser } = useUpdateUserMutation()

  useEffect(() => {
    subscribeUser(user.uuid);
  }, [])

  useEffect(() => {
    if (!isFirstTime) {
      checkIsAthlete(user) ? updateAthlete({ uuid: user.uuid, profileImage: form.watch('profileImage') }) : updateUser({ uuid: user.uuid, profileImage: form.watch('profileImage') })
    }
    updateCurrentUserCache({ profileImage: form.watch('profileImage') })
    setIsFirstTime(false);
  }, [form.watch('profileImage')]);

  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Profile | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Profile | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Profile</title>
      </Helmet>
      <Page className={cls['dashboard']}>
        <DashboardHeader />

        <Typography variant="h2" align="center" textTransform="uppercase">
          {user.firstName} {user.lastName}
        </Typography>
        <div className={cls['profile-media-section']}>
          <div className={cls['profile-media-section']}>
            <div className={cls['profile-picture-container']}>
              {form.formState.defaultValues?.profileImage !== form.watch('profileImage') && <span className={cls['trash-icon']} onClick={() => form.setValue('profileImage', form.formState.defaultValues?.profileImage || '')}>
                <DeleteIcon />
              </span>}
              <div className={cls['media']}>
                {form.watch('profileImage') && <img src={form.watch('profileImage')} alt="Profile" />}
              </div>
              <div className={cls['prfile-btn-holder']}>
                {
                  checkIsAthlete(user) ? (
                    <Chip
                      label="Athlete"
                      color="primary"
                      variant="filled"
                      size="small"
                      sx={{ fontSize: '10px', fontWeight: '700', pl: 2, pr: 2 }} />
                  )
                    : null

                }
              </div>
            </div>
          </div>
          <Button variant="contained" component="label" onClick={() => navigate('/edit-profile')} style={{ cursor: 'pointer', padding: '5px 10px', fontSize: '12px' }}>
            {/* <ImagePicker label="Edit My Profile" form={form} name="profileImage" classname='container' /> */}
            {/* <Typography sx={{ pt: 3, pb: 3 }} variant="subtitle1" textTransform="uppercase">
              Edit My Profile
            </Typography> */}
            Edit My Profile
          </Button>

        </div>
        {/* <Box
          sx={{
            ml: '-15px',
            mr: '-15px',
            mb: 3,
            borderTop: (theme) => `2px solid ${theme.palette.primary.dark}`,
            borderBottom: (theme) => `2px solid ${theme.palette.primary.dark}`,
          }}
        >
          <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="Dashboard tab">
            <Tab type="primary" label="DASHBOARD" />
            <Tab type="primary" label="MY SAVED CONTENT" />
          </Tabs>
        </Box> */}
        <TabPanel index={0} value={value}>
          <DashboardTab />
        </TabPanel>
        <TabPanel index={1} value={value}>
          <SavedContentTab />
        </TabPanel>
      </Page>
    </>
  );
};

export default Profile;
