import { buildApiQueryHook } from "../utils/api.utils";
import request from "../utils/request";
import { Interest, interestImages } from "./interests.api";

export type StandingsListItem = {
  name: string;
  standings: StandingsListItem.StandingType[];
};

export namespace StandingsListItem {
  export type StandingType = {
    id: string;
    name: string;
    available: boolean;
  };
}

export namespace StandingResults {
  export type Result = Region | Values;

  export type Values = {
    titles: string[];
    data: string[][];
  };

  export type Region = {
    name: string;
    sections: Section[];
  };

  export type Section = {
    name?: string;
    titles: string[];
    items: Item[];
  };

  export type Item = {
    position: string;
    firstName: string;
    lastName?: string;
    points: string[];
  };
}

export const useListStandingsQuery = buildApiQueryHook<StandingsListItem[]>(
  'listStandings',
  () => request.get('/standings'),
  {
    select(data) {
      // order by year: from newer to older
      return data.sort((a, b) => {
        const aYear = a.name.match(/\d+/)
        const bYear = b.name.match(/\d+/)
        if (aYear && bYear) {
          return parseInt(aYear[0]) > parseInt(bYear[0]) ? -1 : 1
        }

        return a.name > b.name ? -1 : 1;
      }).map((season) => ({
        ...season,
        // unavailable goes down
        standings: season.standings.sort((a, b) => {
          if (a.available === b.available) {
            return a.name > b.name ? -1 : 1
          }

          return !a.available ? 1 : -1
        })
      }))
    }
  }
)

type StandingsCategoriesFromAPI = {
  name: string
  categories: string[]
}

type StandingsCategories = {
  name: string
  categories: Interest[]
}

const mapCategory = (name: string): Interest => {
  const lower = name.toLowerCase()

  let iconUrl: string | undefined
  if (lower.startsWith('bull')) {
    iconUrl = interestImages.ICON_BULL_RIDING
  } else if (lower.startsWith('breakaway')) {
    iconUrl = interestImages.ICON_BREAKAWAY
  } else if (lower.startsWith('cutting')) {
    iconUrl = interestImages.ICON_CUTTING
  } else if (lower.startsWith('team')) {
    iconUrl = interestImages.ICON_TEAM_ROPING
  } else if (lower.startsWith('barrel')) {
    iconUrl = interestImages.ICON_BARREL_RACE
  } else if (lower.startsWith('bareback')) {
    iconUrl = interestImages.ICON_BARBACK_RIDING
  } else if (lower.startsWith('saddle')) {
    iconUrl = interestImages.ICON_SADDLE_BRONC
  } else if (lower.startsWith('cow') || lower.startsWith('reined')) {
    iconUrl = interestImages.ICON_REINED_COW_HORSE
  } else if (lower.startsWith('reining')) {
    iconUrl = interestImages.ICON_REINING
  } else if (lower.startsWith('tie')) {
    iconUrl = interestImages.ICON_TIE_DOWN
  } else if (lower.startsWith('steer')) {
    iconUrl = interestImages.ICON_STEER_WRESTLING
  }

  return {
    uuid: name,
    name,
    iconUrl,
  }
}

export const useStandingCategoriesQuery = buildApiQueryHook<{ folderId: string }, StandingsCategoriesFromAPI, StandingsCategories>(
  'standingCategories',
  ({ folderId }) => request.get(`/standings/${folderId}/categories`),
  {
    select(data) {
      return {
        ...data,
        categories: data.categories.map(mapCategory)
      }
    }
  }
)

export const useStandingResultsQuery = buildApiQueryHook<{ folderId: string, category: string }, StandingResults.Result[]>(
  'standingSheets',
  ({ folderId, category }) => request.get(`/standings/${folderId}/${category}`),
  {
    select(data) {
      return data.sort((a, b) => {
        if (!('name' in a) || !('name' in b)) return 0;

        const an = a.name.match(/\d+/)?.[0]
        const bn = b.name.match(/\d+/)?.[0]

        // if both a and b are "round X" then sort by X number
        if (an && bn) {
          return parseInt(an) > parseInt(bn) ? 1 : -1
        }

        // otherwise, return unordered
        return 0
      })
    }
  }
)
