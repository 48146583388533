import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page/Page';
import { Button, Typography } from '@mui/material';
import Fixedbox from '../../components/Fixedbox';
import { Helmet } from 'react-helmet';


const Checkout = () => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Checkout | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Checkout</title>
      </Helmet>
      <Page style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h1" align="center" color="primary.main">
          Shopify Checkout
        </Typography>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage('/shop/all')}>
              BACK TO SHOP
            </Button>
          </Fixedbox>
        </div>
      </Page></>
  );
};

export default Checkout;
