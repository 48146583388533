import { createTheme, styled } from '@mui/material/styles';
import Palette, { themeColors } from './palette';
import Typography from './typography';
import Components from './components';

const theme = createTheme({
  palette: Palette as any,
  typography: Typography as any,
  components: Components as any,
  spacing: 8 as number,
});

export const Root = styled('div')(() => ({
  backgroundColor: themeColors.dark.charcoal,
}));

export default theme;
