import { useEffect, useState } from 'react';
import _ from 'lodash';

const Marker = (props: google.maps.MarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(_.omit(props, ['mapCapabilities', 'renderingType']));
    }
  }, [marker, props]);

  return null;
};

export default Marker;
