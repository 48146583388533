import './config/datadogRum'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { Auth0ProviderWithHistory } from './features/Auth/Auth0ProviderWithHistory';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { APOLLO_CLIENT_BASE_URI } from './config/constants';
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./config/queryClient";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";

// needed for .fromNow() method
dayjs.extend(relativeTime)
// needed for .calendar() method
dayjs.extend(calendar)

const client = new ApolloClient({
  uri: APOLLO_CLIENT_BASE_URI,
  cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ApolloProvider client={client}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </ApolloProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
    <ToastContainer />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
