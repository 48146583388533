import { Typography, Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import { PlusIcon } from '../../components/Icons';
import { Page } from '../../components/Page/Page';
import EventHeader from './EventHeader';
import SeatMap from './siteMap';
import { Helmet } from 'react-helmet';

const desiplineList = [
  {
    title: 'Steer Wresling Round 1',
    availableDate: '6/10, 6:00 am to 18/10, 6:00 pm',
    type: 'Breakaway',
    startDate: '10 - 12 From 3 pm to 9 pm',
    slots: 15,
    availableSlots: 5,
    priceType: 'Jackpot',
  },
  {
    title: 'Steer Wresling Round 1',
    availableDate: '6/10, 6:00 am to 18/10, 6:00 pm',
    type: 'Breakaway',
    startDate: '10 - 12 From 3 pm to 9 pm',
    slots: 15,
    availableSlots: 5,
    priceType: 'Jackpot',
  },
  {
    title: 'Steer Wresling Round 1',
    availableDate: '6/10, 6:00 am to 18/10, 6:00 pm',
    type: 'Breakaway',
    startDate: '10 - 12 From 3 pm to 9 pm',
    slots: 15,
    availableSlots: 5,
    priceType: 'Jackpot',
  },
];

interface DesiplineType {
  title: string;
  startDate: string;
  availableDate: string;
  type: string;
  slots: number;
  availableSlots: number;
  priceType: string;
}

const Desipline = ({ title, availableDate, type, startDate, slots, availableSlots, priceType }: DesiplineType) => {
  return (
    <Box sx={{ display: 'flex', pb: 4, alignItems: 'center' }}>
      <Box>
        <Typography variant="h3" color="primary.main" sx={{ pb: 1 }}>
          {title}
        </Typography>
        <Typography variant="caption" display="block">
          Available registration from {availableDate}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography>
            <strong>Type: </strong> {type}
          </Typography>
          <Typography>
            <strong>Start Date: </strong> {startDate}
          </Typography>
          <Typography>
            Open Slots: <strong>{slots} </strong> Your Available Entries: <strong>{availableSlots}</strong>
          </Typography>
          <Typography>
            <strong>Price type: </strong> {priceType}
          </Typography>
        </Box>
      </Box>
      <Box>
        <IconButton
          sx={{
            backgroundColor: 'primary.main',
            '& svg': { width: '20px', height: '20px', fill: (theme) => theme.palette.darkPallet.dark },
          }}
        >
          <PlusIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const sectionlist = [
  { name: 'Section 1', background: '#C0ADB1' },
  { name: 'Section 2', background: '#8A8666' },
  { name: 'Section 3', background: '#B58128' },
];

const Section = ({ name, background }: { name: string; background: string }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
      <Box
        sx={{
          display: 'inline-block',
          height: '15px',
          width: '15px',
          backgroundColor: background,
          borderRadius: '100%',
          mr: 1,
        }}
      />
      <Typography variant="caption" fontSize="14px">
        {name}
      </Typography>
    </Box>
  );
};

const EventDisciplines = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event Disciplines | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Disciplines</title>
      </Helmet>
      <Page>
        <EventHeader />
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a consectetur phasellus ut id. Lorem nibh purus
          sollicitudin gravida mattis.
        </Typography>

        <Divider sx={{ ml: '-1rem', mr: '-1rem', mt: 2 }} />
        <Typography variant="subtitle1" sx={{ mt: 2, pb: 2 }} fontWeight="700" textTransform="uppercase">
          Select Disciplines
        </Typography>
        <Typography>Select the disciplines that you want to enter for this event.</Typography>
        <Box sx={{ ml: '-1rem', mr: '-1rem', mt: 2, backgroundColor: 'darkPallet.main', p: 2, pb: 0 }}>
          {desiplineList.map((item, index) => {
            return <Desipline key={index} {...item} />;
          })}
        </Box>
        <Typography variant="h3" textTransform="uppercase" sx={{ pb: 2, pt: 2 }}>
          Seat Map
        </Typography>
        <Box>
          <SeatMap />
        </Box>
        <Typography variant="h3" textTransform="uppercase" sx={{ pb: 2, pt: 2 }}>
          Sections
        </Typography>
        <Box sx={{ display: 'flex', pb: 5 }}>
          {sectionlist.map((section, index) => (
            <Section {...section} key={index} />
          ))}
        </Box>
      </Page>
    </>
  );
};

export default EventDisciplines;
