import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Page } from '../../components/Page/Page';
import { Helmet } from 'react-helmet';

const TeamAdding = () => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);

  // const [searchParams] = useSearchParams();
  // const header = searchParams.get('header');

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Team Adding | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Team Adding</title>
      </Helmet>
      <Page
        style={{
          alignItems: 'start',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h4"
          align="center"
          color="primary.main"
          fontWeight="700"
          sx={{ width: '100%' }}
          onClick={onPage('/event-team-roping')}
        >
          Add/Invite <br /> Roping Partner
        </Typography>
      </Page>
    </>
  );
};

export default TeamAdding;
