import { useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { z } from 'zod'
import { zodResolver } from "@hookform/resolvers/zod";

export function useZodForm<T extends z.ZodObject<z.ZodRawShape>>(
  schema: T,
  props?: UseFormProps<z.infer<T>>
): UseFormReturn<z.infer<T>> {
  return useForm({
    ...props,
    resolver: zodResolver(schema),
  })
}
