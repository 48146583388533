import { Avatar, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/system';
import PageHeader from '../../components/Header/PageHeader';
import { Page } from '../../components/Page/Page';
import { SignalStreamIcon } from '../../components/Icons';
import NotificationIcon1 from '../../img/notification-source-1.png';
import NotificationIcon2 from '../../img/notification-source-2.png';
import { Helmet } from 'react-helmet';

const notificationList = [
  {
    title: 'American Rodeo posted a new video',
    date: 'Jun 23, 2022 at 09:15 AM',
    Icon: NotificationIcon1,
    isRead: false,
  },
  {
    title: 'Teton Ridge posted a new article',
    date: 'Jun 23, 2022 at 09:15 AM',
    Icon: NotificationIcon2,
    isRead: false,
  },
  {
    title: 'Watch the live rodeo video',
    date: 'Jun 23, 2022 at 09:15 AM',
    Icon: NotificationIcon2,
    isRead: true,
    isLive: true,
  },
  {
    title: 'American Rodeo posted a new video',
    date: '9hs ago',
    Icon: NotificationIcon1,
    isRead: true,
    isLive: false,
  },
];

const Item = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.darkPallet.light}`,
  display: 'flex',
  alignItems: 'center',
  padding: '15px 0',
}));

const CustomButton = styled(Button)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.lightPallet.light,
    height: '20px',
    width: '20px',
  },
}));

interface ItemProps {
  title: string;
  date: string;
  Icon: string;
  isRead?: boolean;
  isLive?: boolean;
}

const NotificationItem = (props: ItemProps) => {
  return (
    <Box sx={{ pl: 2, pr: 2, backgroundColor: !props?.isRead ? 'darkPallet.main' : 'darkPallet.darker' }}>
      <Item>
        <Avatar alt={props.title} src={props.Icon} />
        <Box sx={{ pl: 1 }}>
          <Typography>{props.title} </Typography>
          <Typography variant="caption">{props.date} </Typography>
        </Box>
        {props?.isLive && (
          <CustomButton
            sx={{ ml: 2, fontSize: '8px' }}
            size="small"
            variant="contained"
            color="error"
            startIcon={<SignalStreamIcon />}
          >
            Live Now
          </CustomButton>
        )}
      </Item>
    </Box>
  );
};

const Notifications = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />

      </Helmet>
      <Page sx={{ pt: 0 }}>
        <PageHeader />
        <Box sx={{ ml: '-1rem', mr: '-1rem', mt: 2 }}>
          {notificationList.map((item, index) => {
            return <NotificationItem key={index} {...item} />;
          })}
        </Box>
      </Page>
    </>
  );
};

export default Notifications;
