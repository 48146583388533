import Interest from '../../components/Interest';
import CollectionSlider from '../Feeds/CollectionSlider';
import { VideoPost } from "../../components/Post/VideoPost/VideoPost";

interface Video {
  id: number;
  attributes: VideoAttributes;
}
interface VideoAttributes {
  Title?: string;
  Cover_Image?: CoverImage;
  MC_Shopify: string[];
  publishedAt: string;
  Video_Link: string;
}
interface CoverImage {
  data: CoverImageData;
}
interface CoverImageData {
  id: number;
  attributes: CoverImageAttributes;
}
interface CoverImageAttributes {
  alternativeText: string;
  url: string;
}
interface Interest {
  name: string;
  uuid: string;
  selected: boolean;
}
interface TabProps{
  videos: Video[];
  tags: Interest[];
  onToggle(id: string): void;
}

const IntertainmentTab = (props: TabProps) => {
  const { videos, tags, onToggle } = props;
  return (
    <>
      <Interest.Slider list={tags} onToggle={onToggle} />
      {videos.map((video:Video) =>
        <VideoPost
          key={video.id}
          id={video.id}
          media={video.attributes.Video_Link}
          date={video.attributes.publishedAt}
          title={video.attributes.Title}
        />
      )}
      <CollectionSlider />
    </>
  );
};

export default IntertainmentTab;
