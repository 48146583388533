import { Box, styled } from '@mui/material';

export default styled(Box)(({ theme }) => ({
  backgroundImage: theme.palette.background.gradient,
  color: theme.palette.darkPallet.main,
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
