import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { UseFormReturn } from 'react-hook-form';

import { FormTextField } from '../../../components/Form/FormTextField';
import cls from '../../../features/Auth/SignUpFlow/AthleteSetup/AthleteSetup.module.css';

type HobbiesEditScreenProps = {
  form: UseFormReturn<any, any>
}

const PLACEHOLDER = 'Enter answer here.'


const HobbiesEditScreen = ({ form }: HobbiesEditScreenProps) => {

  return (
    <div className={cls['athlete-container']}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>if you weren’t rodeoing what occupation <br /> would you want to do?</InputLabel>
            <FormTextField
              fullWidth
              sx={{
                borderRadius: '6px',
                overflow: 'scroll',
                justifyContent: 'flex-start'
              }}
              multiline
              id='qst_Occupation'
              name='qstOccupation'
              form={form}
              placeholder={PLACEHOLDER}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite food</InputLabel>
          <FormTextField id="user-food" placeholder='Enter answer here' fullWidth form={form} name='favoriteFood' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite beverage</InputLabel>
          <FormTextField id="user-beverage" placeholder='Enter answer here' fullWidth form={form} name='favoriteBeverage' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite movie</InputLabel>
          <FormTextField id="user-movie" placeholder='Enter answer here' fullWidth form={form} name='favoriteMovie' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite series</InputLabel>
          <FormTextField id="user-series" placeholder='Enter answer here' fullWidth form={form} name='favoriteSeries' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite song</InputLabel>
          <FormTextField id="user-song" placeholder='Enter answer here' fullWidth form={form} name='favoriteSong' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite sports team</InputLabel>
          <FormTextField id="user-sport_team" placeholder='Enter answer here' fullWidth form={form} name='favoriteSportTeam' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite athlete</InputLabel>
          <FormTextField id="user-athlete" placeholder='Enter answer here' fullWidth form={form} name='favoriteAthlete' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Favorite quote</InputLabel>
          <FormTextField id="user-quote" placeholder='Enter answer here' fullWidth form={form} name='favoriteQuote' />
        </Grid>
      </Grid>
    </div >
  );
};

export default HobbiesEditScreen;