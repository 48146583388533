import { AxiosError, AxiosResponse } from "axios";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions, UseInfiniteQueryResult,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { setRequestAuthToken } from "./request";

export type ApiErrorCodes = 'userNotRegistered' | 'duplicatedAuth0'

type Fetch<Params = unknown, Result = unknown> = (params: Params) => Promise<AxiosResponse<Result>>

type ApiErrorParams = {
  message: string,
  code?: ApiErrorCodes,
  data?: unknown
}

export class ApiError extends Error {
  public code?: ApiErrorCodes
  public data?: unknown

  constructor({ message, code, data }: ApiErrorParams) {
    super(message);
    this.code = code
    this.data = data
  }
}

export class UserNotRegisteredError extends ApiError {}

export class DuplicatedAuth0Error extends ApiError {
  public isAthlete: boolean

  constructor(params: ApiErrorParams) {
    super(params);
    const { data } = params
    this.isAthlete = !!data && (data as { isAthlete: boolean }).isAthlete
  }
}

export const isUserNotRegisteredError = (error: unknown): error is UserNotRegisteredError => {
  return error instanceof UserNotRegisteredError
}

export const isDuplicatedAuth0Error = (error: unknown): error is DuplicatedAuth0Error => {
  return error instanceof DuplicatedAuth0Error
}

const performFetch = async <Params, Result>(
  fetch: Fetch<Params, Result>,
  params: Params,
  getAccessTokenSilently: ReturnType<typeof useAuth0>['getAccessTokenSilently'],
  select?: (data: unknown) => Result
) => {
  try {
    let { data } = await fetch(params)
    if (select) {
      data = select(data)
    }
    return data
  } catch (error) {
    const err = error as AxiosError

    if (!checkInvalidToken(err)) {
      throw getApiErrorFromAxiosError(err)
    }

    const token = await getAccessTokenSilently()
    setRequestAuthToken(token)

    try {
      const result = await fetch(params)
      return result.data
    } catch (error) {
      throw getApiErrorFromAxiosError(error as AxiosError)
    }
  }
}

const checkInvalidToken = (err: AxiosError) => {
  return Boolean(
    err && typeof err === 'object' && (err as { error?: { code?: 'invalidToken' } }).error?.code === 'invalidToken'
  )
}

const getApiErrorFromAxiosError = (error: AxiosError) => {
  const err = error.response?.data as { error: { message: string, code?: ApiErrorCodes, data?: unknown } } | undefined
  if (err && err.error) {
    if (err.error.code === 'userNotRegistered') {
      return new UserNotRegisteredError(err.error)
    } else if (err.error.code === 'duplicatedAuth0') {
      return new DuplicatedAuth0Error(err.error)
    }
  }
  return err
}

const onErrorDefault = (error: unknown) => {
  toast.error(error instanceof Error ? error.message : 'Something went wrong');
}

type ApiQueryHook<Params, Result, Select = Result> = <SelectResult = Select>(
  params: Params,
  options?: UseQueryOptions<Result, ApiError, SelectResult>,
) => UseQueryResult<SelectResult, ApiError>;

type ApiSimpleQueryHook<Result, Select = Result> = <SelectResult = Select>(
  options?: UseQueryOptions<Result, ApiError, SelectResult>,
) => UseQueryResult<SelectResult, ApiError>;

export function buildApiQueryHook<Params, Result, Select = Result>(
  cacheKey: string,
  fetch: (params: Params) => Promise<AxiosResponse<Result>>,
  options?: UseQueryOptions<Result, ApiError, Select>
): ApiQueryHook<Params, Result, Select>
export function buildApiQueryHook<Result, Select = Result>(
  cacheKey: string,
  fetch: () => Promise<AxiosResponse<Result>>,
  options?: UseQueryOptions<Result, ApiError, Select>
): ApiSimpleQueryHook<Result, Select>
export function buildApiQueryHook(
  cacheKey: string,
  fetch: (params?: unknown) => Promise<AxiosResponse>,
  options?: UseQueryOptions<any, any>,
): any {
  return (first: unknown, second: unknown) => {
    const params = fetch.length === 0 ? null : first
    const localOptions = (fetch.length === 0 ? first : second) as UseQueryOptions
    const combinedOptions = {
      ...options,
      ...localOptions
    }

    const { getAccessTokenSilently } = useAuth0()

    return useQuery(
      options?.queryKey || [cacheKey, params],
      () => performFetch(fetch, params, getAccessTokenSilently),
      {
        ...combinedOptions,
        onError: combinedOptions?.onError || onErrorDefault,
      },
    );
  }
}

type InfiniteQueryOptions<Result, Select = Result> = Omit<UseInfiniteQueryOptions<Result, ApiError, Select>, 'select'> & {
  select?(data: Result): Select
}

type ApiInfiniteQueryHook<Params, Result, Select = Result> = <SelectResult = Select>(
  params: Params,
  options?: InfiniteQueryOptions<Result, SelectResult>,
) => UseInfiniteQueryResult<SelectResult, ApiError>;

type ApiSimpleInfiniteQueryHook<Result, Select = Result> = <SelectResult = Select>(
  options?: InfiniteQueryOptions<Result, SelectResult>,
) => UseInfiniteQueryResult<SelectResult, ApiError>;

export function buildApiInfiniteQueryHook<Params, Result, Select = Result>(
  cacheKey: string,
  fetch: (params: Params & { pageParam: number }) => Promise<AxiosResponse<Result>>,
  options?: InfiniteQueryOptions<Result, Select>
): ApiInfiniteQueryHook<Params, Result, Select>
export function buildApiInfiniteQueryHook<Result, Select = Result>(
  cacheKey: string,
  fetch: (params: { pageParam: number }) => Promise<AxiosResponse<Result>>,
  options?: InfiniteQueryOptions<Result, Select>
): ApiSimpleInfiniteQueryHook<Result, Select>
export function buildApiInfiniteQueryHook(
  cacheKey: string,
  fetch: (params: { pageParam: number }) => Promise<AxiosResponse>,
  options?: InfiniteQueryOptions<any, any>,
): any {
  return (params: object, localOptions: typeof options) => {
    const { select, ...withoutSelect } = { ...options, ...localOptions };

    const { getAccessTokenSilently } = useAuth0()

    return useInfiniteQuery({
      queryKey: options?.queryKey || [cacheKey, params],
      queryFn: ({ pageParam = 1 }) => {
        return performFetch(
          fetch,
          { ...params, pageParam },
          getAccessTokenSilently,
          select,
        )
      },
      onError: options?.onError || onErrorDefault,
      getNextPageParam: (_, pages) => pages.length + 1,
      ...withoutSelect,
    })
  }
}

export const buildApiMutationHook = <Params, Result = void>(
  fetch: (params: Params) => Promise<AxiosResponse<Result>>,
  mapOptions: (
    options?: UseMutationOptions<Result, ApiError, Params>,
  ) => undefined | UseMutationOptions<Result, ApiError, Params> = (x) => x,
): ((
  options?: UseMutationOptions<Result, ApiError, Params>,
) => UseMutationResult<Result, ApiError, Params>) => {
  return (options?: UseMutationOptions<Result, ApiError, Params>) => {
    options = mapOptions(options);

    const { getAccessTokenSilently } = useAuth0()

    return useMutation<Result, ApiError, Params>(
      (params) => performFetch(fetch, params, getAccessTokenSilently),
      {
        ...options,
        onError: options?.onError || onErrorDefault,
      },
    );
  };
};
