import { Box } from '@mui/material';
import {
  Button,
  Divider,
  TextField,
  InputLabel,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Page } from '../../components/Page/Page';
import Header from '../../components/Header';
import Fixedbox from '../../components/Fixedbox';
import { AthleteFormValidation } from '../../utils/validator';
import { isEmpty } from '../../utils';
import { Helmet } from 'react-helmet';
import { CurrentUser } from "../../api/auth.api";
import { useUpdateAthleteMutation } from "../../api/athletes.api";

const EventRegistrationCompete = ({ user }: { user: CurrentUser }) => {
  // const navigate = useNavigate();
  const [errors, setError] = useState<any>({});
  // formData is of any type because form fields don't match actual user fields
  const [formData, setForm] = useState<any>({
    first_name: '',
    last_name: '',
    preferred_name: '',
    gender: 'male',
    email: '',
    dateOfBirth: null,
    phone: '',
    phone2: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    address_mail: '',
    city_mail: '',
    state_mail: '',
    zipcode_mail: '',
    emergency_first_name: '',
    emergency_last_name: '',
    emergency_email: '',
    emergency_phone: '',
    emergency_phone2: '',
  });
  const contactField = ['address', 'city', 'state', 'zipcode'];
  const [mailingAddress, setMailingAddress] = useState<boolean>(true);

  useEffect(() => {
    if (user) {
      const values: any = { ...formData };
      Object.keys(formData).forEach((key) => {
        if (user[key as keyof CurrentUser]) {
          values[key as keyof CurrentUser] = user[key as keyof CurrentUser];
        }
      });
      setForm({ ...values });
    }
  }, [user]);

  const { mutate: updateUser } = useUpdateAthleteMutation()

  const onPage = () => {
    // const link = '/event-documents';
    const _errors = AthleteFormValidation(formData);
    if (isEmpty(_errors)) {
      updateUser({
        uuid: user.id,
        ...formData,
        is_athlete: true
      });
      // navigate(link);
    } else {
      setError(_errors);
    }
  };

  const deleteError = (key: string) => {
    if (errors[key]) {
      const newErrors = { ...errors };
      delete newErrors[key];
      setError(newErrors);
    }
  };

  const onChangeMailingAddressStatus = () => {
    setMailingAddress(!mailingAddress);
    if (mailingAddress) {
      setForm({ ...formData, city_mail: '', state_mail: '', address_mail: '', zipcode_mail: '' });
    } else {
      setForm(updateMailingAddress(formData));
    }
  };

  const updateMailingAddress = (data: any) => {
    return {
      ...data,
      city_mail: data.city,
      state_mail: data.state,
      address_mail: data.address,
      zipcode_mail: data.zipcode,
    };
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    deleteError(name);
    let nextFormData = { ...formData, [name]: value };
    if (contactField.includes(name) && mailingAddress) {
      nextFormData = updateMailingAddress(nextFormData);
    }
    setForm(nextFormData);
  };

  const onSelect = (event: SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    deleteError(name);
    let nextFormData = { ...formData, [name]: value };
    if (contactField.includes(name) && mailingAddress) {
      nextFormData = updateMailingAddress(nextFormData);
    }
    setForm(nextFormData);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Register to Compete  | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Register to Compete | Teton Ridge+</title>
      </Helmet>
      <Page>
        <Header.Light />
        <Box sx={{ pt: 5, pb: 5 }}>
          <Typography variant='h2' color='primary.main' align='center' textTransform={'uppercase'} sx={{
            pb: 2,
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            Register to Compete
          </Typography>
          <Typography color='lightPallet.lighter' align='center'>
            Before you can enter to compete in this event, we need some additional details. None of the information
            collected today will be used
          </Typography>
        </Box>
        <Divider sx={{ mb: 5 }} />
        <Typography variant='h3' color='primary.main' textTransform={'uppercase'}>
          Personal Details
        </Typography>
        <Typography>Only enter accurate and valid information as this will be used for official records.</Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Legal First Name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Jonathan'
                  name='first_name'
                  value={formData.first_name}
                  error={errors?.first_name}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Legal Last Name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Bates'
                  name='last_name'
                  value={formData.last_name}
                  error={errors?.last_name}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Preferred Name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Orchard Ranch'
                  name='preferred_name'
                  value={formData.preferred_name}
                  error={errors?.preferred_name}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <InputLabel>Date of Birth</InputLabel>
                  <MobileDatePicker
                    value={formData.dateOfBirth}
                    maxDate={dayjs()}
                    onChange={(newValue) => {
                      setForm({ ...formData, dateOfBirth: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} error={errors?.dateOfBirth} placeholder='MM/DD/YYYY' />} />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Gender</InputLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    value={formData.gender}
                    name='gender'
                    displayEmpty
                    inputProps={{ 'aria-label': 'sex' }}
                    onChange={onSelect}
                  >
                    <MenuItem value=''>Select</MenuItem>
                    <MenuItem value='male'>Male</MenuItem>
                    <MenuItem value='female'>Female</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 5, mt: 5 }} />
        <Typography variant='h3' color='primary.main' textTransform={'uppercase'}>
          Contact Details
        </Typography>
        <Typography>We will use this information to send you details about the events that you register for.</Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Mobile Phone</InputLabel>
                <TextField
                  fullWidth
                  placeholder=''
                  name='phone'
                  value={formData.phone}
                  onChange={onChange}
                  error={errors?.phone} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Home Phone</InputLabel>
                <TextField fullWidth placeholder='' name='phone2' value={formData.phone2} onChange={onChange} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Email Address</InputLabel>
            <TextField
              fullWidth
              placeholder='john@emailaddress.com'
              name='email'
              disabled
              value={formData.email}
              onChange={onChange} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Home Address</InputLabel>
            <TextField
              fullWidth
              placeholder='1234 First St.'
              name='address'
              error={errors?.address}
              value={formData.address}
              onChange={onChange} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <InputLabel>City</InputLabel>
            <TextField
              fullWidth
              placeholder='1234 First St.'
              name='city'
              value={formData.city}
              onChange={onChange}
              error={errors?.city} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>State</InputLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    value={formData.state || ''}
                    name='state'
                    displayEmpty
                    error={errors?.state}
                    inputProps={{ 'aria-label': 'sex' }}
                    onChange={onSelect}
                  >
                    <MenuItem value=''>Select</MenuItem>
                    <MenuItem value='ca'>CA</MenuItem>
                    <MenuItem value='ny'>NY</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Zip Code</InputLabel>
                <TextField fullWidth placeholder='' name='zipcode' value={formData.zipcode} onChange={onChange} />
              </Box>
            </Grid>
          </Grid>
          <FormGroup sx={{ mt: 3, mb: 3 }}>
            <FormControlLabel
              control={<Checkbox checked={mailingAddress} onChange={onChangeMailingAddressStatus} />}
              label='Home address different from mailing address' />
          </FormGroup>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Mailing Address</InputLabel>
            <TextField
              fullWidth
              placeholder='1234 First St.'
              name='address_mail'
              value={formData.address_mail}
              error={errors?.address_mail}
              onChange={onChange} />
          </Box>
          <Box sx={{ pt: 2 }}>
            <InputLabel>City</InputLabel>
            <TextField
              fullWidth
              placeholder='Atlanta'
              name='city_mail'
              value={formData.city_mail}
              error={errors?.city_mail}
              onChange={onChange} />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>State</InputLabel>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    value={formData.state_mail || ''}
                    name='state_mail'
                    displayEmpty
                    inputProps={{ 'aria-label': 'sex' }}
                    error={errors?.state_mail}
                    onChange={onSelect}
                  >
                    <MenuItem value=''>Select</MenuItem>
                    <MenuItem value='ca'>CA</MenuItem>
                    <MenuItem value='ny'>NY</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Zip Code</InputLabel>
                <TextField
                  fullWidth
                  placeholder=''
                  name='zipcode_mail'
                  value={formData.zipcode_mail}
                  onChange={onChange} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ mb: 5, mt: 5 }} />
        <Typography variant='h3' color='primary.main' textTransform={'uppercase'}>
          Emergency Contact
        </Typography>
        <Typography>In the event of an emergency, we’ll reach out to this contact to notify.</Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>First Name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Jonathan'
                  name='emergency_first_name'
                  value={formData.emergency_first_name}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Bates'
                  name='emergency_last_name'
                  value={formData.emergency_last_name}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Mobile Phone</InputLabel>
                <TextField
                  fullWidth
                  placeholder=''
                  name='emergency_phone'
                  value={formData.emergency_phone}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Home Phone</InputLabel>
                <TextField
                  fullWidth
                  placeholder='Bates'
                  name='emergency_phone2'
                  value={formData.emergency_phone2}
                  onChange={onChange} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Email Address</InputLabel>
                <TextField
                  fullWidth
                  placeholder='john@emailaddress.com'
                  name='emergency_email'
                  value={formData.emergency_email}
                  onChange={onChange} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant='contained' color='primary' fullWidth onClick={onPage}>
              Continue
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventRegistrationCompete;
