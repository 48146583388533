import { styled, Typography, Link } from '@mui/material';
import { Box } from '@mui/system';
import { Link as RLink } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { RightArrowIcon } from '../Icons';

interface Props {
  title: string;
  tagName: string;
  link: string;
  media: string;
}

const Mask = styled(Box)(() => ({
  backgroundImage: 'linear-gradient(0.15deg, rgba(48, 48, 48, 0.376) 0.06%, rgba(28, 28, 28, 0.192) 69.32%)',
  position: 'absolute',
  left: '0',
  right: '0',
  top: '0',
  bottom: '0',
}));

const Post = (props: Props) => {
  return (
    <Box
      sx={{
        width: '210px',
        m: 1.5,
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '6px',
        '& img': { position: 'static', objectFit: 'cover', height: '100%', width: '100%' },
      }}
    >
      <Mask />
      <img src={props.media} alt={props.title} />
      <Box sx={{ position: 'absolute', bottom: '0', left: '0', right: '0', p: 2 }}>
        <Typography variant="overline" fontWeight="700">
          {props.tagName}
        </Typography>
        <Typography variant="h3" color="primary.main" sx={{ pb: 1, pt: 1 }}>
          {props.title}
        </Typography>
        <Link
          component={RLink}
          to={props.link}
          sx={{
            textDecoration: 'none',
            color: 'lightPallet.lighter',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              marginLeft: '5px',
              height: '12px',
              width: '12px',
              fill: (theme) => theme.palette.lightPallet.lighter,
            },
          }}
        >
          View Profile <RightArrowIcon />
        </Link>
      </Box>
    </Box>
  );
};

const SliderItem = (props: { children: React.ReactNode }) => {
  return <Box>{props.children}</Box>;
};

const AnimalSliders = ({ list }: { list: any }) => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };
  return (
    <Box sx={{ pb: 3, marginLeft: '-15px', marginRight: '-15px' }}>
      <Slider {...settings}>
        {list.map((item: { title: string; tagName: string; link: string; media: string }, index: number) => {
          return (
            <SliderItem key={index}>
              <Post {...item} />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};

export default AnimalSliders;
