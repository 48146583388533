
import { Controller } from "react-hook-form";
import { useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';


type Item = { value: string | number; name: string | number }
type Props = {
  form: any,
  name: string,
  requiredChar?: boolean,
  smsAuto?: boolean,
  items?: (Item)[],
  values?: (string | number)[],
  fullWidth?: boolean;
  id?: string;
  multiple?: boolean;
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string[]>) => void
}

const autoFillStyle = {
  "& input:-webkit-autofill": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:focus": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:active": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:hover": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  }
}

export const FormSelectField = ({
  form,
  name,
  requiredChar = false,
  smsAuto = false,
  items = [],
  values = [],
  multiple = false,
  customOnChange,
  ...props
}: Props) => {
  const [charCount, setCharCount] = useState(0)

  const onChange = (e: SelectChangeEvent<string | string[]>) => {
    setCharCount(e.target.value.length)
  }

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => {

        let sx
        if (smsAuto) {
          sx = {
            "& .MuiFormHelperText-root": {
              marginBottom: '3px',
              color: charCount >= 6 ? 'green' : '#f44336',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#DDAF63',
              },
              '&.Mui-error fieldset': {
                borderColor: charCount >= 6 ? 'green' : '#f44336',
              },
              '&.Mui-focused fieldset': {
                borderColor: charCount >= 6
                  ? 'green'
                  : '#DDAF63',
              },
            },
            ...autoFillStyle
          }
        } else {
          sx = requiredChar ? {
            "& .MuiFormHelperText-root": {
              marginBottom: '3px',
              color: charCount >= 3 ? 'green' : '#f44336',
              borderColor: charCount >= 3 ? 'green' : '#f44336',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#DDAF63',
              },
              '&.Mui-error fieldset': {
                borderColor: charCount >= 3 ? 'green' : '#f44336',
              },
              '&.Mui-focused fieldset': {
                borderColor: charCount >= 3
                  ? 'green'
                  : '#DDAF63',
              },
            },
            ...autoFillStyle
          } : {}
        }

        return (
          <Select
            multiple={multiple}
            sx={sx}
            {...field}
            {...props}
            value={multiple ? (form.getValues()[name] || []) : form.getValues()[name]}
            onChange={(e) => { field.onChange(e); onChange(e); customOnChange?.(e) }}
            renderValue={(selected) => multiple ? selected.join(', ') : items.length ? items.find(i => i.value === selected)?.name : selected}
          >
            {items.map(item => <MenuItem value={item.value}>{item.name}</MenuItem>)}
            {values.map((item) => (
              multiple ? <MenuItem key={item} value={item}>
                <Checkbox checked={form.getValues()[name]?.indexOf(String(item)) > -1} />
                <ListItemText primary={item} />
              </MenuItem>
                : <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        )
      }}
    />

  )
}
