import { styled } from '@mui/system';
import { Button, Typography, Chip } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { ShopIcon } from '../../components/Icons';
import { Page } from '../../components/Page/Page';
import Fixedbox from '../../components/Fixedbox';
import { MapIcon, EventIcon } from '../../components/Icons';
import Header, { CustomIconButton } from '../../components/Header';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { Helmet } from 'react-helmet';

const ViewsChip = styled(Chip)(({ theme }) => {
  return {
    backgroundColor: theme.palette.darkPallet.darker,
    color: theme.palette.lightPallet.lighter,
    fontSize: '12px',
    borderColor: 'transparent',
    borderRadius: '100%',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiChip-label': {
      paddingLeft: '0',
      paddingRight: '0',
      fontWeight: '700',
    },
  };
});

const EventRegistration = () => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get('step');

  const navigate = useNavigate();

  const onPage = (link: string) => () => {
    navigate(link);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Registration</title>
      </Helmet>
      <Page>
        <Header
          RightComponent={<>
            <CustomIconButton>
              <MapIcon />
            </CustomIconButton>
            <CustomIconButton>
              <EventIcon />
            </CustomIconButton>
          </>} />
        {step === '2' ? <SecondStep /> : <FirstStep />}

        <div style={{ height: '100px' }}>
          <Fixedbox>
            {step === '2' ? (
              <Button
                onClick={onPage('/event-documents?header=true')}
                variant="contained"
                color="primary"
                fullWidth
                size="medium"
                startIcon={<ShopIcon />}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="caption" fontSize="16px" fontWeight="700">
                  Cart
                </Typography>
                <ViewsChip label="1" variant="filled" size="small" />
              </Button>
            ) : (
              <Button variant="contained" color="primary" fullWidth onClick={onPage('/event-regsistration?step=2')}>
                Add to Cart
              </Button>
            )}
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventRegistration;
