import { useFanFavoriteGameGetTokenQuery } from "../../../api/gaming.api";
import { useEffect } from "react";
import { CurrentUser } from "../../../api/auth.api";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { NotFoundPage } from "../../../components/Errors/NotFoundPage";
import { Layout } from "../../../components/Layout/Layout";
import { routes } from "../../../routes";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";

export const PlayGamePage = ({ user }: { user: CurrentUser }) => {
  const { gameName } = useParams<{ gameName: string }>()
  if (gameName !== 'Fan Favorite') return <NotFoundPage />

  return <PlayGame user={user} gameName={gameName} />
}

const PlayGame = ({ user, gameName }: { user: CurrentUser, gameName: string }) => {
  const { mutate: getToken, data, isLoading } = useFanFavoriteGameGetTokenQuery()

  useEffect(() => {
    getToken({ email: user.email })
  }, [])

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (isLoading) return;
      if (e.data === 'game-token-invalid') {
        getToken({ email: user.email })
      }
    }
    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [isLoading])

  const gamePage = routes.games.gameDetails(gameName)

  if (!data) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Games | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Games | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>{gameName}</title>
      </Helmet>
      <Layout
        backTo={gamePage}
      >
        <iframe
          src={`${process.env.REACT_APP_FAN_FAVORITE_GAME_IFRAME_URL}${data.token}`}
          style={{
            border: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </Layout>
    </>
  )
}