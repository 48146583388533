import { CurrentAthleteUser, CurrentUser } from '../../api/auth.api';

export const checkIsAthlete = (user: CurrentUser): user is CurrentAthleteUser => {
  return user.athleteProfile?.active === 'active' && user?.interests.length !== 0;
};

// true for fans and complete athletes
export const isProfileSetUp = (user: CurrentUser) => {
  return checkIsAthlete(user) ? user.athleteProfile.isProfileSetUp : true;
};

// false for fans, true for complete athletes
export const isAthleteProfileSetUp = (user: CurrentUser) => {
  return checkIsAthlete(user) ? user.athleteProfile.isProfileSetUp : false;
};
