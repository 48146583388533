import { FeedItem } from "./FeedItem";

export const PinnedPosts = ({
  pinnedPosts,
}: {
  pinnedPosts: any[];
}) => {
  return (
    <div style={{
      width: '100%',
      height: '490px',
      overflowY: 'hidden',
      overflowX: 'auto',
      scrollbarWidth: 'none',
    }}>
      <div style={{
        minWidth: '110vw',
        width: 'max-content',
        display: 'flex',
        flexDirection: 'row'
      }}>
        {
          pinnedPosts.map((item, i) => (
            <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
              <FeedItem item={item} pinned pinnedList />
            </div>
          ))
        }
      </div>
    </div>
  )
}