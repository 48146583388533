import { TextFieldProps } from "@mui/material";
import { ControllerFieldState, ControllerRenderProps, FieldValues, UseFormReturn } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { FieldPath, Path } from "react-hook-form/dist/types/path";
import { Controller, get } from "react-hook-form";
import { useEffect, useState } from "react";

type Props<T extends FieldValues> = Omit<TextFieldProps, 'name'> & {
  form?: UseFormReturn<T>
  name?: FieldPath<T>,
  requiredChar?: boolean,
  smsAuto?: boolean,
  value?: string,
  customOnChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const autoFillStyle = {
  "& input:-webkit-autofill": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:focus": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:active": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  },
  "& input:-webkit-autofill:hover": {
    backgroundColor: "#266798 !important",
    backgroundClip: "content-box !important",
    WebkitBoxShadow: "0 0 0 100px #266798 inset !important"
  }
}

export const FormTextField = <T extends FieldValues>({
  form,
  name,
  requiredChar = false,
  smsAuto = false,
  customOnChange,
  ...props
}: Props<T>) => {
  const [error, setError] = useState(false)
  const [charCount, setCharCount] = useState(0)

  const initError = get(form?.formState.errors, name || '')

  useEffect(() => {
    if (initError) {
      setError(true)
      setCharCount(0)
    }
  }, [initError])

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCharCount(e.target.value.length)
  }

  const renderField = (field?: ControllerRenderProps<T, Path<T>>, fieldState?: ControllerFieldState) => {

    let sx
    if (smsAuto) {
      sx = {
        "& .MuiFormHelperText-root": {
          marginBottom: '3px',
          color: charCount >= 6 ? 'green' : '#f44336',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#DDAF63',
          },
          '&.Mui-error fieldset': {
            borderColor: charCount >= 6 ? 'green' : '#f44336',
          },
          '&.Mui-focused fieldset': {
            borderColor: error && charCount >= 6
              ? 'green'
              : error
                ? '#f44336'
                : '#DDAF63',
          },
        },
        ...autoFillStyle
      }
    } else {
      sx = requiredChar ? {
        "& .MuiFormHelperText-root": {
          marginBottom: '3px',
          color: charCount >= 3 ? 'green' : '#f44336',
          borderColor: charCount >= 3 ? 'green' : '#f44336',
        },
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: '#DDAF63',
          },
          '&.Mui-error fieldset': {
            borderColor: charCount >= 3 ? 'green' : '#f44336',
          },
          '&.Mui-focused fieldset': {
            borderColor: error && charCount >= 3
              ? 'green'
              : error
                ? '#f44336'
                : '#DDAF63',
          },
        },
        ...autoFillStyle
      } : {}
    }

    return (
      <TextField
        {...props}
        {...field}
        sx={sx}
        onChange={(e) => { field?.onChange(e); onChange(e); customOnChange?.(e) }}
        error={Boolean(fieldState?.error)}
        helperText={fieldState?.error?.message}
      />
    )
  }

  return (
    name && form ? <Controller
      name={name}
      control={form.control}
      render={({ field, fieldState }) => renderField(field, fieldState)}
    /> : renderField()

  )
}
