import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Button, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { z } from 'zod'
import { Box, styled } from '@mui/system';

import Fixedbox from '../../../../components/Fixedbox';
import { Page } from '../../../../components/Page/Page';
import cls from './AthleteSetup.module.css';
import { FormTextField } from "../../../../components/Form/FormTextField";
import { useSignUpContext } from "../SignUpContext";
import { useZodForm } from "../../../../utils/useZodForm";
import logoImg from '../../../../img/title-logo.png';
import { usePhoneContext } from '../PhoneContext';
import { routes } from '../../../../routes';
import { CurrentUser } from '../../../../api/auth.api';
import { useUpdateAthlete } from '../../auth.service';
import { useDisciplineContext } from '../DisicplineContext';

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const PLACEHOLDER = 'Enter answer here.'

const schema = z.object({
  qstOccupation: z.optional(z.string().max(250)),
  favoriteFood: z.optional(z.string().max(100)),
  favoriteBeverage: z.optional(z.string().max(100)),
  favoriteMovie: z.optional(z.string().max(100)),
  favoriteSeries: z.optional(z.string().max(100)),
  favoriteSong: z.optional(z.string().max(100)),
  favoriteSportTeam: z.optional(z.string().max(100)),
  favoriteAthlete: z.optional(z.string().max(100)),
  favoriteQuote: z.optional(z.string().max(250)),
});

const HobbiesSetupPage = ({ currentUser }: { currentUser: CurrentUser }) => {
  const myRef = useRef<null | HTMLDivElement>(null)
  const form = useZodForm(schema, {
    defaultValues: {
      qstOccupation: currentUser?.athleteProfile?.qstEventRodeo || undefined,
      favoriteFood: currentUser?.athleteProfile?.favoriteFood || undefined,
      favoriteBeverage: currentUser?.athleteProfile?.favoriteBeverage || undefined,
      favoriteMovie: currentUser?.athleteProfile?.favoriteMovie || undefined,
      favoriteSeries: currentUser?.athleteProfile?.favoriteSeries || undefined,
      favoriteSong: currentUser?.athleteProfile?.favoriteSong || undefined,
      favoriteSportTeam: currentUser?.athleteProfile?.favoriteSportTeam || undefined,
      favoriteQuote: currentUser?.athleteProfile?.favoriteQuote || undefined,
      favoriteAthlete: currentUser?.athleteProfile?.favoriteAthlete || undefined,
    }
  });
  const [profile, setContext] = useSignUpContext()
  const navigate = useNavigate()
  const [phone] = usePhoneContext();
  const [disciplines] = useDisciplineContext();
  if (!phone?.phone) navigate(routes.athleteAuth.phoneVerification)
  if (!profile) navigate(routes.athleteAuth.profileSetup)
  if (!disciplines?.disciplineIds.length) navigate(routes.athleteAuth.disciplines)
  const { mutate: registerAthlete } = useUpdateAthlete({ lastStep: false });

  const submit = () => {
    if (currentUser) {
      registerAthlete({
        ...{ ...profile, ...form.getValues() },
        uuid: currentUser.uuid,
        isProfileSetUp: true,
        disciplines: disciplines?.disciplineIds,
        interests: [],
        phone: phone?.phone,
      })
    }

    window.dataLayer.push({
      event: 'signup-athlete-step-3',
      value: {
        qstOccupation: form.getValues().qstOccupation,
        favoriteFood: form.getValues().favoriteFood,
        favoriteBeverage: form.getValues().favoriteBeverage,
        favoriteMovie: form.getValues().favoriteMovie,
        favoriteSeries: form.getValues().favoriteSeries,
        favoriteSong: form.getValues().favoriteSong,
        favoriteSportTeam: form.getValues().favoriteSportTeam,
        favoriteQuote: form.getValues().favoriteQuote,
        favoriteAthlete: form.getValues().favoriteAthlete,
      }
    });
    if (profile) { setContext({ ...profile }); window.scrollTo(0, 0); navigate(routes.athleteAuth.interests) }
    else navigate(routes.athleteAuth.profileSetup)
  }

  useEffect(() => { myRef?.current?.scrollIntoView() }, [])

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Profile Setup | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Athlete Setup Step 3</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{ pt: 5 }} ref={myRef}>
        <div className={cls['container']}>
          <Logo>
            <img src={logoImg} alt="Logo" />
          </Logo>
        </div>
        <Typography variant="h3" className={cls['middle-title']} align="left" color="primary.dark">hobbies & interests</Typography>

        <Grid container spacing={2} sx={{ pb: 12 }}>
          <Grid item xs={12}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>If you weren’t rodeoing what occupation <br /> would you want to do?</InputLabel>
              <FormTextField
                fullWidth
                sx={{
                  borderRadius: '6px',
                  overflow: 'scroll',
                  justifyContent: 'flex-start'
                }}
                multiline
                id='qst_Occupation'
                name='qstOccupation'
                form={form}
                placeholder={PLACEHOLDER}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite food</InputLabel>
            <FormTextField id="user-food" placeholder='Enter answer here' fullWidth form={form} name='favoriteFood' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite beverage</InputLabel>
            <FormTextField id="user-beverage" placeholder='Enter answer here' fullWidth form={form} name='favoriteBeverage' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite movie</InputLabel>
            <FormTextField id="user-movie" placeholder='Enter answer here' fullWidth form={form} name='favoriteMovie' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite series</InputLabel>
            <FormTextField id="user-series" placeholder='Enter answer here' fullWidth form={form} name='favoriteSeries' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite song</InputLabel>
            <FormTextField id="user-song" placeholder='Enter answer here' fullWidth form={form} name='favoriteSong' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite sport team</InputLabel>
            <FormTextField id="user-sport_team" placeholder='Enter answer here' fullWidth form={form} name='favoriteSportTeam' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite athlete</InputLabel>
            <FormTextField id="user-athlete" placeholder='Enter answer here' fullWidth form={form} name='favoriteAthlete' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite quote</InputLabel>
            <FormTextField id="user-quote" placeholder='Enter answer here' fullWidth form={form} name='favoriteQuote' />
          </Grid>
        </Grid>
        <Fixedbox>
          <Button type='submit' variant="contained" color="primary" fullWidth>
            Done
          </Button>
        </Fixedbox>
      </Page>
    </>
  );
};

export default HobbiesSetupPage;
