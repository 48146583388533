import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { removeRequestAuthToken, setRequestAuthToken } from '../../utils/request';
import { useCallback } from 'react';
import { queryClient } from '../../config/queryClient';
import { useNavigate } from 'react-router-dom';
import { CurrentUser, useAuthUserQuery, useRegisterUserMutation } from '../../api/auth.api';
import { useUpdateAthleteMutation } from '../../api/athletes.api';

// Actual Auth0 user type
export type Auth0User = {
  email: string;
  email_verified: true;
  name: string;
  phone: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
};

export const useAuthTokenQuery = (options?: UseQueryOptions<string>) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery<string>(
    ['getAuth0Token'],
    async () => {
      const token = await getAccessTokenSilently();
      // set token inside a data loader function
      // intentionally not in onSuccess, because the callback fires AFTER rendering of fetching data
      // which was causing attempts to call /auth before setting the token
      setRequestAuthToken(token);
      return token;
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
      ...options,
    },
  );

  return { data, isLoading, error };
};

export const useAuthorize = ({
  onSuccess,
  onError,
}: {
  onSuccess?(data: CurrentUser): void;
  onError(err: unknown): void;
}): { isLoading: boolean; user?: CurrentUser; error: unknown } => {
  const { isLoading: tokenIsLoading, error: tokenError } = useAuthTokenQuery({
    onSuccess: setRequestAuthToken,
    onError,
  });

  const isAthlete = getIsAthleteLogin();

  const {
    data: user,
    isLoading: userIsLoading,
    error: userError,
  } = useAuthUserQuery(
    {
      isAthlete,
    },
    {
      enabled: !tokenIsLoading && !tokenError,
      retry: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess(data) {
        onSuccess?.(data);
      },
      onError,
    },
  );

  const actualCache: CurrentUser | undefined = queryClient.getQueryData(['auth']);
  if (actualCache?.profileImage && user) {
    user.profileImage = actualCache.profileImage;
  }

  const error = tokenError || userError;
  const isLoading = !error && (tokenIsLoading || userIsLoading);

  return { isLoading, user, error };
};

export const useLogOut = (): { logout(): void } => {
  const { logout } = useAuth0();

  return {
    logout: useCallback(() => {
      removeRequestAuthToken();
      queryClient.clear();
      logout({
        returnTo: window.location.origin + '/welcome',
      });
    }, []),
  };
};

export const useRegisterUser = () => {
  const navigate = useNavigate();

  return useRegisterUserMutation({
    onSuccess() {
      navigate('/feed');
    },
  });
};

export const useUpdateAthlete = ({ lastStep }: { lastStep: boolean }) => {
  const navigate = useNavigate();

  return useUpdateAthleteMutation({
    onSuccess() {
      if (lastStep) navigate('/feed');
    },
  });
};

export const useAuth0Login = ({ isAthlete }: { isAthlete: boolean }) => {
  const { loginWithRedirect } = useAuth0();

  return (hint: 'signup' | 'login', returnTo?: string) => {
    if (isAthlete) {
      localStorage.setItem('loginAthlete', 'true');
    } else {
      localStorage.removeItem('loginAthlete');
    }

    loginWithRedirect({ screen_hint: hint, prompt: 'login', appState: { returnTo } });
  };
};

export const getIsAthleteLogin = () => localStorage.getItem('loginAthlete') === 'true';
