import { ReactNode, RefObject, useEffect, useRef } from "react";

export const FloatingHeaderWrapper = ({
  children,
  floating,
  height,
  scrollThresholdPx = height,
}: {
  children: ReactNode,
  floating?: RefObject<HTMLDivElement>
  height: number,
  scrollThresholdPx?: number,
}) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const scrollPos = useRef(0)

  useEffect(() => {
    const el = floating?.current
    if (!el) return

    const scrollListener = () => {
      const header = headerRef.current
      if (!header) return

      const diff = el.scrollTop - scrollPos.current
      if (diff > scrollThresholdPx) {
        scrollPos.current = el.scrollTop
        header.style.transform = `translateY(-${height}px)`
      } else if (diff < -scrollThresholdPx) {
        scrollPos.current = el.scrollTop
        header.style.transform = `translateY(0)`
      }
    }

    el.addEventListener('scroll', scrollListener)
    return () => el.removeEventListener('scroll', scrollListener)
  }, [])

  if (!floating) return <>{children}</>

  return (
    <div
      style={{
        marginBottom: `${height}px`,
      }}
    >
      <div
        ref={headerRef}
        style={{
          position: 'fixed',
          transition: '.2s',
          transform: `translateY(0)`,
          width: '100%',
          zIndex: 100,
        }}
      >
        {children}
      </div>
    </div>
  )
}