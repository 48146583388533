import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useLogOut } from '../../features/Auth/auth.service';
import DashboardMenuItem from './DashboardMenuItem';
import { CurrentUser } from "../../api/auth.api";
import { checkIsAthlete } from "../../features/User/user.utils";

const DashboardMenu = ({ user }: { user: CurrentUser }) => {
  const { logout } = useLogOut();
  useEffect(() => {
    if (navigator.cookieEnabled) {
      localStorage.removeItem("athleteFilters")
      localStorage.removeItem("filterTags")
    }
  }
    , [])

  return (
    <Box sx={{ ml: '-15px', mr: '-1rem', pt: 5, pb: 5 }}>
      <DashboardMenuItem item={{ title: 'View My Profile', link: '/profile' }} />
      {checkIsAthlete(user) && (
        <>
          <DashboardMenuItem
            item={{ title: 'Share my run', link: 'https://clips.tetonridgeplus.com' }}
            external
          />
          <DashboardMenuItem
            item={{ title: 'Event Forms', link: 'https://forms.tetonridgeplus.com/' }}
            external
          />
        </>
      )}
      <DashboardMenuItem
        item={{
          title: 'Support',
          link: 'mailto:support@tetonridgeplus.com?subject=Teton Ridge+ Support',
          onClick() {
            // send GA event
            window.dataLayer.push({
              event: 'support-click',
            });
          }
        }}
        mailTo
      />
      <DashboardMenuItem
            item={{ title: 'Terms of Service', link: 'https://www.tetonridge.com/legal/terms/' }}
            external
      />
      <DashboardMenuItem
            item={{ title: 'Privacy Policy', link: 'https://www.tetonridge.com/legal/privacy/' }}
            external
      />
      <DashboardMenuItem item={{ title: 'Logout', onClick: logout }} />
    </Box>
  );
};

export default DashboardMenu;
