import cmsRequest from '../utils/cmsRequest';
import { buildApiQueryHook } from '../utils/api.utils';
import { CmsImage, CmsPaginated } from './cms.api';
import { FeedTypeEnum } from '../pages/Feeds/feedTypes';

type CustomFeedFromApi = {
  id: number;
  attributes: {
    CustomFeed_Image: {
      data: CmsImage<['thumbnail']>;
    };
    CustomFeed_Title: string;
    CustomFeed_URL: string;
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
  };
};

export type CustomFeed =
  & { id: number }
  & Omit<CustomFeedFromApi['attributes'], 'CustomFeed_Image' | 'CustomFeed_Title' | 'CustomFeed_URL'>
  & {
    image: CustomFeedFromApi['attributes']['CustomFeed_Image']['data'],
    title: string
    url: string
  }
  & { feedType: FeedTypeEnum.CUSTOMFEED }



const mapCustomFeed = (item: CustomFeedFromApi): CustomFeed => ({
  id: item.id,
  ...item.attributes,
  image: item.attributes.CustomFeed_Image.data,
  title: item.attributes.CustomFeed_Title,
  url: item.attributes.CustomFeed_URL,
  feedType: FeedTypeEnum.CUSTOMFEED,
})

export const useCustomFeedQuery = buildApiQueryHook<void, CmsPaginated<CustomFeedFromApi>, CustomFeed[]>(
  '/custom-feeds',
  () => cmsRequest.get('/custom-feeds?sort=createdAt:ASC&populate=*'),
  {
    select: (data) => data.data.map(mapCustomFeed)
  },
);
