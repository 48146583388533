import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type DisciplineContextValue = {
  disciplineIds: string[];
} | undefined

const DisciplineContext = createContext<[DisciplineContextValue, Dispatch<SetStateAction<DisciplineContextValue>>] | null>(null);

export const DisciplineContextProvider = DisciplineContext.Provider

export const useDisciplineContext = () => {
  const value = useContext(DisciplineContext)
  if (!value) throw new Error(`DisciplineContext value is not set`)
  return value
}