import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import useWindowDimentions from '../../hooks/useWindowDimentions';
import dayjs from "dayjs";
interface PostInterface {
	title?: string;
	logo?: string;
	date?: string;
	index?: number;
	media?: string;
	category?: string;
	type?: string;
	comments?: number;
	id?: number;
}

window.addEventListener('resize', resize);

function resize() {

	if (window.innerWidth > 700) {
		return '650px';
	}
	else {
		return '100%'
	}
}

const Post = (props: PostInterface) => {
	const { width } = useWindowDimentions();
	let time = undefined

	if (props.date) {
		time = dayjs(props.date).fromNow()
	}

	return (
		<Card
			sx={{
				mb: 2,
				position: 'relative',
				mt: 0,
				backgroundColor: '#423C37',
				borderRadius: 3,
				my: props.index === 0 ? '10px' : '50px',
				mx: 'auto',
				width: (width > 700) ? '650px' : '100%'
			}}
		>
			<CardContent sx={{ p: 0 }}>
				<Box sx={{ p: 2, pb: 0 }}>
					{time && <Typography variant="caption">{time}</Typography>}
					{props?.category && <Typography>{props.category}</Typography>}
					<Box sx={{ display: 'flex' }}>
						<Box
							sx={{
								flex: 2,
								position: 'relative',
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'column',
							}}
						>
							<Link
								component={RLink}
								variant="subtitle1"
								sx={{ textDecoration: 'none' }}
								fontSize="28px"
								lineHeight="31px"
								color="primary.main"
								fontFamily="BourbonW00-Regular"
								to={`/standing/${props?.id}`}
								onClick={() => {
									// send GA event
									window.dataLayer.push({
										event: 'standings-article-click',
										value: `/standing/${props?.id}`
									});
								}}
							>
								{props.title}
							</Link>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 1 }}>
								{props.comments && (
									<Typography variant="caption" color="grey.400">
										{props.comments} comments
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</CardContent>
		</Card>
	);
};

export default Post;
