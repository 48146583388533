import { Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Box, styled } from '@mui/system';
import { CheckedIn } from '../Icons';

export type InterestItem = {
  uuid: string;
  name: string;
  iconUrl?: string;
  selected?: boolean;
  title?: string;
};

type InterestItemProps = {
  item: InterestItem;
  onToggle?(id: string): void;
};

const InterestCircle = styled(Box)(({ theme }) => ({
  height: '62px',
  width: '62px',
  backgroundImage: theme.palette.background.gradient,
  margin: '0 auto',
  border: `2px solid ${alpha(theme.palette.primary.main, 1)}`,
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border 0.3s ease-in 0s',
  position: 'relative',
  textAlign: 'center',
  marginBottom: '5px',
  '& svg': {
    height: '20px',
    fill: theme.palette.lightPallet.lighter,
  },
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
  '&.selected-item': {
    backgroundColor: theme.palette.darkPallet.main,
    backgroundImage: 'none',
    borderColor: theme.palette.primary.main,
  },
}));

export const InterestItem = ({ item, onToggle }: InterestItemProps) => {
  return (
    <Box onClick={() => onToggle?.(item.uuid)} sx={{ position: 'relative', textAlign: 'center', pt: 2, pb: 1 }}>
      <InterestCircle className={`${item?.selected ? 'selected-item' : ''}`}>
        {item.selected && <CheckedIn />}
        {!item.selected && item.iconUrl && <img src={item.iconUrl} alt={item.title} />}
      </InterestCircle>
      <Typography variant="overline" textTransform="uppercase" lineHeight="140%">
        {item.title || item.name}
      </Typography>
    </Box>
  );
};
