import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useAuthorize } from "../auth.service";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { CurrentUser } from "../../../api/auth.api";
import { isDuplicatedAuth0Error, isUserNotRegisteredError } from "../../../utils/api.utils";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';
import { WelcomePage } from '../WelcomePage/WelcomePage';
import { DuplicatedAuth0Screen } from "../DuplicatedAuth0Screen";

type Props = {
  element: (props: { user: CurrentUser }) => React.ReactElement
}

export const PrivateRoute = ({ element: Component }: Props) => {
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const location = useLocation();
  if (navigator.cookieEnabled) {
    const { user, isLoading, error } = useAuthorize({
      onError(error) {
        if (isUserNotRegisteredError(error)) {
          navigate('/profile-setup', {replace: true})
        } else if (isDuplicatedAuth0Error(error)) {
          return;
        } else if (error instanceof OAuthError && error.error === 'login_required') {
          navigate('/welcome', { state: { prevRoute: location.pathname } })
        } else {
          logout()
        }
      }
    })

    if (isDuplicatedAuth0Error(error)) {
      return <DuplicatedAuth0Screen isAthlete={error.isAthlete} />
    }

    if (isLoading) {
      return <LoadingScreen />
    } else if (user) {
      return <Component user={user} />
    } else {
      return null
    }
  }
  else {
    return <WelcomePage />
  }
}
