import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Avatar, Typography, Card, CardMedia } from '@mui/material';
import Header from '../../components/Header';
import { Page } from '../../components/Page/Page';
import Tabs, { Tab, TabPanel } from '../../components/Tabs';
import Navbar from '../../components/Navbar';
import Product, { ProductSlider, ProductsGrid } from '../../components/Products';
import Interest from '../../components/Interest';
import tab1Image from '../../img/shop-tab-1.png';
import tab2Image from '../../img/shop-tab-2.png';
import tab3Image from '../../img/shop-tab-3.png';
import ProductImg from '../../img/product-img.png';
import productMaskImg from '../../img/shop-product-mask.png';

const interestList = [
  {
    name: 'All',
    uuid: '0001',
    selected: true,
  },
  {
    name: 'Film',
    uuid: '001',
    selected: true,
  },
  {
    name: 'TV',
    uuid: '002',
  },
  {
    name: 'Documentaries',
    uuid: '003',
  },
  {
    name: 'Podcast',
    uuid: '004',
    selected: true,
  },
  {
    name: 'Action Movie',
    uuid: '005',
  },
  {
    name: 'Dram Series',
    uuid: '006',
    selected: true,
  },
];

const ProductList = [
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$129.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
];

const productGrid = [
  ...ProductList,
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
];

const SalesCard = () => {
  return (
    <Box sx={{ mb: 3 }}>
      <Card>
        <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography variant="h2" sx={{ position: 'absolute' }} color="primary">
            Sales
          </Typography>
          <CardMedia component={'img'} src={productMaskImg} alt="Sales" />
        </Box>
      </Card>
    </Box>
  );
};

const TabContents = () => {
  return (
    <>
      <Interest.Slider list={interestList} />
      <ProductSlider list={ProductList} />
      <SalesCard />
      <Product.Header title="Most Popular" hasMore={false} />
      <ProductSlider list={ProductList} />
      <Product.Header title="New T-shirts" moreText="View All" link="/shop/all" />
      <Box sx={{ mb: 2 }} />
      <ProductsGrid list={productGrid} />
    </>
  );
};

const Shop = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Page>
      <Header />
      <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="Shop tab" sx={{ pt: 2 }}>
        <Tab type="image" icon={<Avatar alt="Shop tab" src={tab1Image} sx={{ width: '80px' }} />} />
        <Tab type="image" icon={<Avatar alt="Shop tab" src={tab2Image} sx={{ width: '80px' }} />} />
        <Tab type="image" icon={<Avatar alt="Shop tab" src={tab3Image} sx={{ width: '80px' }} />} />
      </Tabs>
      <TabPanel index={0} value={value}>
        <TabContents />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <TabContents />
      </TabPanel>
      <TabPanel index={2} value={value}>
        Entertainment
      </TabPanel>
      <Navbar />
    </Page>
  );
};

export default Shop;
