import { isEmpty } from '.';

// basic validation for athlete registration form
export const AthleteFormValidation = (
  data: any,
  requiredFields = [
    'first_name',
    'last_name',
    'dateOfBirth',
    'state',
    'city',
    'address',
    'address_mail',
    'city_mail',
    'state_mail',
    'zipcode_mail',
    'emergency_first_name',
    'emergency_last_name',
    'emergency_email',
    'emergency_phone',
  ],
) => {
  const error: any = {};

  requiredFields.forEach((key) => {
    if (isEmpty(data[key])) {
      error[key] = true;
    } else {
      delete error[key];
    }
  });
  return error;
};
