import cls from './Page.module.css';
import React from 'react';
import { Box, styled } from '@mui/system';

export const Page = styled(Box)(() => ({
  padding: '1rem',
  paddingTop: 0,
  flex: 1,
}));

export const PageTitle = ({ className = '', children }: { className?: string; children?: React.ReactNode }) => {
  return <h1 className={`${cls['page-title']} ${className || ''}`}>{children}</h1>;
};
