import { Divider, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import Header from '../../components/Header'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { ClockIcon } from '../../components/Icons';
import { formatDateFromNow } from '../../utils/date.utils';
import cls from './Article.module.css';
import { useGamingQuery } from "../../api/gaming.api";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import { ShareMedias } from "./ShareMedias";
import { useRef } from "react";
import { CurrentUser } from '../../api/auth.api';
import { subscribeUser } from '../../subscription';

const TimeIcon = styled('span')(({ theme }) => ({
  paddingRight: '5px',
  '& > svg': {
    height: '10px',
    width: '10px',
    fill: theme.palette.lightPallet.main,
  },
}));

const ImgWrapper = styled(Box)(() => ({
  position: 'relative',
  padding: '15px 0',
  marginLeft: '-1rem',
  marginRight: '-1rem',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const PageWrapper = styled('div')(() => ({
  flex: 1,
  height: '100%',
  overflow: 'auto',
  overflowX: 'hidden',
}))

const ContentWrapper = styled('div')(() => ({
  '& img': {
    width: '100%',
    position: 'relative',
  },
}))

const GamingView = ({ user }: { user?: CurrentUser }) => {
  const { id } = useParams();
  const pageRef = useRef<HTMLDivElement>(null)
  if (user) subscribeUser(user.uuid);

  const { data: article } = useGamingQuery({ id: parseInt(id as string) })

  if (!article) {
    return <LoadingScreen />
  }

  const { author, title, content, cover } = article
  const cleanContent = content?.replace(/<[^>]+>/g, '')
  const fullName = author && `${author.First_Name} ${author.Last_Name}`

  const saved = true;
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title ? title : "TetonRidge+"} />
        <meta name="twitter:description" content={cleanContent ? cleanContent: 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta name="twitter:image:src" content={cover ? cover.attributes.url : 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta name="twitter:title" content={title ? title : "TetonRidge+"} />
        <meta name="twitter:description" content={cleanContent ? cleanContent: 'Your Western Sports Destination | Live results, updated standings, insider info and more!'} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={cover ? cover?.attributes?.url : 'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Article | {title}</title>
      </Helmet>
      <PageWrapper ref={pageRef}>
        <Header backIcon floating={pageRef} />
        <Box sx={{ pt: 2, pb: 2, px: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{ flex: 1, display: 'flex', alignItems: 'start', justifyContent: 'center', flexDirection: 'column' }}
            >
              <Box>
                <TimeIcon>
                  <ClockIcon />
                </TimeIcon>
                <Typography variant="caption" textTransform="uppercase">
                  | {formatDateFromNow(new Date(article.publishedAt))}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ pt: 4, pb: 3 }}>
            <Typography variant="h2" fontWeight="700" fontSize="40px" color="primary">
              {title}
            </Typography>
          </Box>
          <Typography variant="overline" textTransform={'uppercase'}>
            {fullName && `By ${fullName}`}
          </Typography>
          <Box sx={{ mt: 1 }} />
          <ImgWrapper>
            <img
              src={cover?.attributes.url}
              alt={cover?.attributes.alternativeText} />
          </ImgWrapper>
          <Typography variant='subtitle2' fontSize={12} color='#B1B3B3'>{cover?.attributes.caption}</Typography>
          {saved && <ShareMedias />}

          <Divider sx={{ mt: 6, mb: 6 }} />
          <Box>
            <Typography color="primary">
              <ContentWrapper
                className={cls['container']}
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              />
            </Typography>
          </Box>
        </Box>
      </PageWrapper>
    </>
  );
};

export default GamingView;
