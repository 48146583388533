import { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import { Helmet } from 'react-helmet';
// import { ButtonProps } from '@mui/material/Button';

import MenuList from '@mui/material/MenuList';
import { Box, styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import PageHeader from '../../components/Header/PageHeader';
import { Page } from '../../components/Page/Page';
import VisaCard from '../../img/visa-logo.png';
import MasterCard from '../../img/master-logo.png';
import { MoreVerticalIcon } from '../../components/Icons';
import pageBg from '../../img/home-page-bg.png';

const methodList = [
  {
    name: 'Jhon smith',
    cardNumber: '4762 **** **** 1974',
    logo: VisaCard,
  },
  {
    name: 'Jhon smith',
    cardNumber: '4762 **** **** 1974',
    logo: MasterCard,
  },
];

const ImageDiv = styled('div')(() => {
  return {
    width: '60px',
    position: 'relative',
    '& img': {
      width: '100%',
    },
  };
});

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.lightPallet.light,
    height: '20px',
    width: '20px',
  },
}));

// const TextButton = styled(Button)<ButtonProps>(({ theme }) => ({
//   color: theme.palette.lightPallet.light,
//   backgroundColor: 'transparent',
//   border: 'none',
//   '&:hover': {
//     backgroundColor: 'transparent',
//   },
//   '&:active': {
//     boxShadow: 'none',
//     backgroundColor: 'transparent',
//   },
//   '&:focus': {
//     boxShadow: 'none',
//   },
// }));

const PaymentSettings = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />

      </Helmet>
      <Page
        sx={{ backgroundImage: `url(${pageBg})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}
      >
        <PageHeader />
        <Box sx={{ pt: 2, pb: 2 }}>
          {methodList.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  p: 2,
                  mb: 2,
                  backgroundColor: 'darkPallet.dark',
                  borderRadius: '5px',
                  alignItems: 'center',
                }}
              >
                <ImageDiv>
                  <img src={item.logo} alt="Visa Card" />
                </ImageDiv>
                <Box sx={{ pl: 2, flex: '1' }}>
                  <Typography variant="h6">{item.name}</Typography>
                  <Typography>{item.cardNumber}</Typography>
                </Box>
                <CustomIconButton onClick={onContextMenu}>
                  <MoreVerticalIcon />
                </CustomIconButton>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="text">+ Add Card</Button>
        </Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Paper>
            <MenuList>
              <MenuItem>Edit</MenuItem>
              <MenuItem>Delete</MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      </Page>
    </>
  );
};

export default PaymentSettings;
