import { Alert, Box, Container, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { interestImages, useInterestsQuery } from "../../../../api/interests.api";
import Fixedbox from '../../../../components/Fixedbox';
import Interest from '../../../../components/Interest';
import { Page } from '../../../../components/Page/Page';
import { usePhoneContext } from '../PhoneContext';
import { routes } from '../../../../routes';
import { useDisciplineContext } from '../DisicplineContext';

const MIN_DISCIPLINES_TO_SELECT = 1;

const ChooseDecipline = () => {
	const { data: disicplines } = useInterestsQuery();
	const [selectedIds, setSelectedIds] = useState<Record<string, true>>({});
	const [showMinError, setShowMinError] = useState(false);
	const navigate = useNavigate()
	// needs verification - what context to use instead of useSignUpContext
	const [phone] = usePhoneContext();
	const [, setDisciplineContext] = useDisciplineContext();

	if (!phone?.phone) {
		return <Navigate to={routes.athleteAuth.phoneVerification} />;
	}

	const disicplinesWithSelect = disicplines?.map((item) => {
		const imageUrl = interestImages[item.iconUrl as keyof typeof interestImages];
		return { ...item, selected: selectedIds[item.uuid], imageUrl };
	});

	const toggleDiscipline = (id: string) => {
		setShowMinError(false);
		setSelectedIds(({ ...ids }) => {
			if (ids[id]) {
				delete ids[id];
			} else {
				ids[id] = true;
			}
			return ids;
		});
	};

	const submit = () => {
		const ids = Object.keys(selectedIds);
		if (ids.length < MIN_DISCIPLINES_TO_SELECT) {
			setShowMinError(true);
			return;
		}

		window.dataLayer.push({
			event: 'athlete-sign-in-flow-disicplines',
		});
		// Store information on the context here.
		setDisciplineContext({ disciplineIds: ids })
		navigate(routes.athleteAuth.profileSetup)
	};

	return (
		<>
			<Helmet>
				<meta property="og:title" content="Choose disicplines | Teton Ridge+" />
				<meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
				<meta property="og:type" content="website" />
				<title>Choose Discipline</title>
			</Helmet>
			<Page sx={{ pt: 6 }}>
				<Typography variant="h3" align="center" textTransform="uppercase" color="primary.main" marginBottom={10}>
					DISCIPLINES YOU COMPETE IN
				</Typography>

				{showMinError && (
					<Container maxWidth='xs'>
						<Box mb={4}>
							<Alert severity="error">Please select at least {MIN_DISCIPLINES_TO_SELECT}</Alert>
						</Box>
					</Container>
				)}
				<Box sx={{ pb: 10 }}>
					<Interest list={disicplinesWithSelect || []} onToggle={toggleDiscipline} />
				</Box>
				<Fixedbox>
					<Button variant="contained" color="primary" fullWidth onClick={submit}>
						NEXT
					</Button>
				</Fixedbox>
			</Page>
		</>
	);
};

export default ChooseDecipline;
