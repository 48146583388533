import { Typography, Box } from '@mui/material';

interface Props {
  hasLine?: boolean;
  title: string;
  color?: string;
}

const titleStyle = {
  backgroundColor: 'darkPallet.darker',
  display: 'inline-block',
  pr: 2,
  position: 'relative',
  zIndex: 2,
};

const SectionTitle = (props: Props) => {
  return (
    <Box sx={{ pt: 2, pb: 2, position: 'relative', display: 'flex', alignItems: 'center' }}>
      <Typography
        variant="h3"
        sx={titleStyle}
        color={props.color ? props.color : 'lightPallet.lighter'}
        fontSize={20}
        textTransform="uppercase"
      >
        {props.title}
      </Typography>
      {props.hasLine && (
        <hr
          style={{
            position: 'absolute',
            width: '100%',
            height: '1px',
            backgroundColor: 'var(--gray_400)',
            border: 'none',
          }}
        />
      )}
    </Box>
  );
};

export default SectionTitle;
