import { StringDecoder } from 'string_decoder';

import { Horse } from './../features/Auth/SignUpFlow/AthleteSetup/CompetitionBackgroundSetupPage';
import { queryClient } from '../config/queryClient';
import { buildApiMutationHook, buildApiQueryHook } from '../utils/api.utils';
import request from '../utils/request';

export type CurrentUser = {
  id: number;
  uuid: string;
  athleteProfile?: AthleteProfile;
  interests: string[];

  // following fields are nullable in DB but are required during registration:
  idAuth0: string;
  email: string;
  firstName: string;
  lastName: string;
  profileImage: string;
  avatar: string;

  gender?: string;
  phone?: string;
  phone2?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  addressMail?: string;
  cityMail?: string;
  stateMail?: string;
  zipcodeMail?: string;
  createdAt: string;
  updatedAt: string;
  dateOfBirth?: string;
};

export type CurrentAthleteUser = CurrentUser & {
  athleteProfile: AthleteProfile;
};

export type AthleteProfile = {
  userId: string;
  active: 'active' | 'disabled';
  isProfileSetUp: boolean;
  bio?: string;
  preferredName?: string;
  emergencyFirstName?: string;
  emergencyLastName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  emergencyPhone2?: string;
  heightFeet?: number;
  heightInches?: number;
  weight?: number;
  isoCountry?: string;
  city?: string;
  state?: string;
  location?: string;
  status?: string;
  rodeoWin?: string;
  degree?: string;
  college?: string;
  children?: number;
  fatherName?: string;
  motherName?: string;
  partnerName?: string;
  nfrQualificationsYear?: string[];
  worldTitlesYear?: string[];
  biggestRodeoWin?: string;
  highestScoreYear?: string;
  highestScoreLoc?: string;
  sponsors?: string;
  favoriteHorseDraw?: string;
  scariestHorse?: string;
  rankestHorse?: string;
  travellingPartners?: string;
  rodeoRig?: string;
  qstEventRodeo?: string;
  qstSuccessfulEvent?: string;
  favoriteFood?: string;
  favoriteBeverage?: string;
  favoriteMovie?: string;
  qstOccupation: string;
  favoriteSeries?: string;
  favoriteQuote?: string;
  favoriteAthlete?: string;
  favoriteSportTeam?: string;
  favoriteSong?: string;
  horses?: Horse[];
  disciplines?: string[];
};

export const useAuthUserQuery = buildApiQueryHook<{ isAthlete: boolean }, CurrentUser>('currentUser', ({ isAthlete }) =>
  request.get('/auth', {
    params: {
      isAthlete: isAthlete ? 'true' : undefined,
    },
  }),
);

export const updateCurrentUserCache = (user: Partial<CurrentUser>) => {
  queryClient.setQueryData(['currentUser'], (data) => {
    if (!data) return data;
    return { ...data, user };
  });
};

export type ApiRegisterUserParams = {
  idAuth0: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  interests: string[];
  profileImage?: string | StringDecoder;
};

export const useRegisterUserMutation = buildApiMutationHook<ApiRegisterUserParams, CurrentUser>(
  (params) => request.post('/users', params),
  (options) => ({
    onSuccess(data, ...args) {
      options?.onSuccess?.(data, ...args);
      queryClient.setQueryData(['currentUser'], data);
    },
  }),
);
