import { styled } from '@mui/material/styles';
import MTabs from '@mui/material/Tabs';
import MTab, { TabProps } from '@mui/material/Tab';

interface StyledTabProps extends TabProps {
  label?: string;
  type?: string;
}

const Tabs = styled(MTabs)(() => {
  return {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  };
});

export const Tab = styled((props: StyledTabProps) => <MTab disableRipple {...props} />)(({ theme, type }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0,
  },
  marginRight: type === 'primary' ? '0' : theme.spacing(0.5),
  backgroundColor: type === 'primary' || type === 'image' ? theme.palette.darkPallet.main : 'none',
  backgroundImage: type === 'primary' || type === 'image' ? 'none' : theme.palette.background.darkGradient,
  color: theme.palette.lightPallet.lighter,
  paddingBottom: '20px',
  paddingTop: '20px',
  paddingLeft: '5px',
  paddingRight: '5px',
  borderRadius: type === 'primary' ? '0' : '3px',
  fontWeight: '700',
  border: type === 'primary' || type === 'image' ? 'none' : `2px solid ${theme.palette.primary.dark}`,
  '&:hover': {
    opacity: 1,
  },
  '&.Mui-selected': {
    backgroundColor: type === 'primary' ? theme.palette.primary.main : 'none',
    backgroundImage: type === 'primary' || type === 'image' ? 'none' : theme.palette.background.gradient,
    color: theme.palette.darkPallet.dark,
    border: type === 'image' ? `2px solid ${theme.palette.primary.dark}` : 'none',
  },
  '&:last-child': {
    marginRight: 0,
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
};

export default Tabs;
