import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const SliderItem = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box sx={{ position: 'relative', p: 3 }}>{props.children}</Box>
    </Box>
  );
};

const ProductImageSlider = (props: { images: any }) => {
  const settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    appendDots: (dots: any) => (
      <ul style={{ margin: '0px', position: 'absolute', zIndex: 99, width: '100%', height: '1px', bottom: '0' }}>
        {dots}
      </ul>
    ),
    customPaging: () => <div className="dot-indicator">&nbsp;</div>,
  };
  return (
    <Box sx={{ pb: 3, borderRadius: '5px', backgroundColor: 'lightPallet.lighter' }}>
      <Slider {...settings} className="slider-dots dots-center theme-light">
        {props.images.map((item: string, index: number) => {
          return (
            <SliderItem key={index}>
              <img src={item} style={{ width: '100%' }} alt="" />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};

export default ProductImageSlider;
