import Box from '@mui/material/Box';
import cls from "./WelcomePage.module.css"
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Logo2 } from '../../../components/Logo/Logo';
import { Page } from '../../../components/Page/Page';
import pageBg from '../../../img/welcome-page/welcome-page-bg.png';
import pageBgMd from '../../../img/welcome-page/welcome-page-bg-md.png';
import pageBgXl from '../../../img/welcome-page/welcome-page-bg-xl.png';
import { styled } from "@mui/system";
import useWindowDimensions from '../../../hooks/useWindowDimentions'
import { Helmet } from 'react-helmet';
import { useAuth0Login } from "../auth.service";
import { useLocation } from 'react-router';
import CookieBanner from '../../../components/CookieBanner';
import { useState } from 'react';

const bgShadow = 'linear-gradient(0, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7))'

const Content = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    backgroundImage: `${bgShadow}, url(${pageBgXl})`,
  },
  [theme.breakpoints.down('md')]: {
    backgroundImage: `${bgShadow}, url(${pageBgMd})`,
  },
  backgroundImage: `${bgShadow}, url(${pageBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  flexGrow: 1,
}))

// const Footer = styled('div')(() => ({
//   background: 'black',
//   color: 'white',
//   padding: '20px 30px',
//   fontSize: 12,
//   fontFamily: 'Barlow Condensed Regular',
//   fontWeight: 700,
//   textTransform: 'uppercase',
//   textAlign: 'center'
// }));

export const WelcomePage = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const login = useAuth0Login({ isAthlete: false })
  const isCookiesDisabled = !navigator.cookieEnabled;
  const [isCookieBanner, setCookieBanner] = useState(true)
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Welcome | Teton Ridge+</title>
      </Helmet>
      <Page
        style={{
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          height: (width > 500) ? '128%' : '109%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Content>
          <Box sx={{ position: 'relative', height: '100px' }}>
            <Logo2 />
          </Box>
          <Box>
            <Typography
              variant='subtitle1'
              fontFamily={'Barlow Condensed Bold'}
              align='center'
              fontWeight='700'
              color='white'
              sx={{ pb: 2, pt: 4, px: '50px', fontSize: '2.5vh' }}
              textTransform='uppercase'
              lineHeight={1.2}
            >
              Teton Ridge+ is your digital destination for all things The American rodeo.
            </Typography>
            <div className={cls.videoWrapper}>
              <div className={cls.video}>
                <iframe src="https://player.vimeo.com/video/778226826"
                  frameBorder="0"
                  allow="autoplay fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
            <Typography
              variant='subtitle1'
              align='center'
              fontFamily={'Barlow Condensed Bold'}
              color='white'
              fontSize={24}
              fontWeight='700'
              sx={{
                pb: 0, px: '50px', fontSize: '2.5vh', lineHeight: '120%',
                mt: (width > 500) ? '1rem' : '2rem'
              }}
              textTransform='uppercase'
            >
              <span style={{ color: 'rgb(255 207 121)' }}>SIGN UP FOR FREE</span><br />TO GET HIGHLIGHTS, EXPERT ANALYSIS, ATHLETE PROFILES, INSIDER INFO, BREAKING NEWS AND MORE.
            </Typography>
          </Box>
          <Box sx={{
            mt: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => { isCookiesDisabled ? setCookieBanner(true) : login('signup', location?.state?.prevRoute) }}
              style={{ width: '112px' }}
              sx={{ mb: 1 }}
            >
              Sign Up
            </Button>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => { isCookiesDisabled ? setCookieBanner(true) : login('login', location?.state?.prevRoute) }}
              style={{ width: '112px' }}
              sx={{ mb: 1 }}
            >
              Log In
            </Button>
          </Box>
          {isCookieBanner && isCookiesDisabled && <CookieBanner setCookieBanner={setCookieBanner} />}
        </Content>
      </Page>
    </>
  );
};
