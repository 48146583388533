import request from './utils/request';

const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUBLIC_VAPID_KEY);

function urlBase64ToUint8Array(base64String) {
  if (!base64String) return;
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function sendSubscription(subscription, userId) {
  return request.post(
    `${process.env.REACT_APP_API_BASE_URL}/notifications/subscribe`,
    JSON.stringify({ subscription, userId }),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
}

export function subscribeUser(userId) {
  if ('serviceWorker' in navigator) {
    document.cookie = `userId=${userId}; path=/`;
    navigator.serviceWorker.ready
      .then(function (registration) {
        if (!registration.pushManager) return;

        registration.pushManager.getSubscription().then(function (existedSubscription) {
          if (existedSubscription === null)
            registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true,
              })
              .then(function (newSubscription) {
                sendSubscription(newSubscription, userId);
              })
              .catch(function (e) {
                if (Notification.permission !== 'granted') console.log('Permission was not granted.');
                else console.error('An error ocurred during the subscription process.', e);
              });
        });
      })
      .catch(function (e) {
        console.error('An error ocurred during Service Worker registration.', e);
      });
  }
}
