import { useEffect, useRef, useState } from 'react';
import { Button, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { z } from 'zod'
import { Box, styled } from '@mui/system';
import { useNavigate } from "react-router-dom";
import { State, City, Country } from "country-state-city";

import Fixedbox from '../../../../components/Fixedbox';
import { Page } from '../../../../components/Page/Page';
import { DeleteIcon } from '../../../../components/Icons';
import cls from './AthleteSetup.module.css';
import { FormTextField } from "../../../../components/Form/FormTextField";
import { useSignUpContext } from "../SignUpContext";
import { useZodForm } from "../../../../utils/useZodForm";
import { Auth0User, useUpdateAthlete } from "../../auth.service";
import { ImagePicker } from '../../../../components/ImagePicker/ImagePicker';
import { ATHLETE_DEFAULT_LOGO } from '../../../../utils/images';
import logoImg from '../../../../img/title-logo.png';
import eyeImg from '../../../../img/eye.svg';
import { FormSelectField } from '../../../../components/Form/FormSelector';
import { FormNumberField } from '../../../../components/Form/FormNumberField';
import { CurrentUser } from '../../../../api/auth.api';
import { usePhoneContext } from '../PhoneContext';
import { routes } from '../../../../routes';
import { useDisciplineContext } from '../DisicplineContext';

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const OPTION_NUMBERS = [{ value: 0, name: 0 }, { value: 1, name: 1 }, { value: 2, name: 2 }, { value: 3, name: 3 }, { value: 4, name: 4 }, { value: 5, name: 5 }, { value: 6, name: 6 }, { value: 7, name: 7 }, { value: 8, name: 8 }, { value: 9, name: 9 }, { value: 10, name: 10 }];

const schema = z.object({
  firstName: z.string({ required_error: 'First name is required' }).min(3, { message: "First name must contain at least 3 character(s)" }).max(100),
  lastName: z.string({ required_error: 'Last name is required' }).min(3, { message: "First name must contain at least 3 character(s)" }).max(100),
  email: z.string({ required_error: 'Email is required' }).email(),
  profileImage: z.string().optional(),
  preferredName: z.string({ required_error: 'Preferred Name is required' }).min(2).max(100),
  bio: z.optional(z.string().max(1500)),
  heightFeet: z.optional(z.number()),
  heightInches: z.optional(z.number()),
  weight: z.optional(z.number()),
  isoCountry: z.optional(z.string().max(100)),
  city: z.optional(z.string().max(100)),
  state: z.optional(z.string()),
  location: z.optional(z.string().max(100)),
  status: z.enum(['single', 'married']),
  partnerName: z.optional(z.string().max(100)),
  children: z.optional(z.number()),
  motherName: z.optional(z.string().max(100)),
  fatherName: z.optional(z.string().max(100)),
  college: z.optional(z.string().max(100)),
  degree: z.optional(z.string().max(100)),
});

const AthleteSetupPage = ({ user, currentUser }: { user: Auth0User, currentUser: CurrentUser }) => {
  const navigate = useNavigate()
  const myRef = useRef<null | HTMLDivElement>(null)
  const [countries, setCountries] = useState<{ value: string; isoCode: string; }[]>([]);
  const [states, setStates] = useState<{ value: string; id: string; }[]>([]);
  const [cities, setCities] = useState<{ value: string; }[]>([]);
  const form = useZodForm(schema, {
    defaultValues: {
      email: currentUser.email || user.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      preferredName: currentUser?.athleteProfile?.preferredName,
      bio: currentUser?.athleteProfile?.bio || undefined,
      profileImage: currentUser.profileImage || ATHLETE_DEFAULT_LOGO,
      heightFeet: currentUser?.athleteProfile?.heightFeet || 0,
      heightInches: currentUser?.athleteProfile?.heightInches || 0,
      weight: currentUser?.athleteProfile?.weight || undefined,
      state: currentUser?.athleteProfile?.state || undefined,
      status: currentUser?.athleteProfile?.partnerName ? 'married' : 'single',
      partnerName: currentUser?.athleteProfile?.partnerName || undefined,
      city: currentUser?.athleteProfile?.city || undefined,
      location: currentUser?.athleteProfile?.location || undefined,
      children: currentUser?.athleteProfile?.children || 0,
      motherName: currentUser?.athleteProfile?.motherName || undefined,
      fatherName: currentUser?.athleteProfile?.fatherName || undefined,
      college: currentUser?.athleteProfile?.college || undefined,
      degree: currentUser?.athleteProfile?.degree || undefined,
      isoCountry: currentUser?.athleteProfile?.isoCountry || 'US'
    }
  });

  useEffect(() => {
    setCountries(Country.getAllCountries().map(({ name, isoCode }) => ({
      value: name,
      isoCode
    })));
  }, [])

  useEffect(() => {
    setStates(State.getStatesOfCountry(form.getValues().isoCountry || '').map(({ name, isoCode }) => {
      return ({
        value: name,
        id: isoCode
      })
    }),
    );
  }, [form.watch('isoCountry')])

  useEffect(() => {
    const [completeState] = State.getStatesOfCountry("US").filter(({ name }) => name === form.getValues().state);

    setCities(City.getCitiesOfState("US", completeState?.isoCode || '').map(({ name }) => ({
      value: name,
    })),
    );
  }, [form.watch('state')])

  useEffect(() => { myRef?.current?.scrollIntoView() }, [])

  const [, setContext] = useSignUpContext()
  const [phone] = usePhoneContext();
  if (!phone?.phone) navigate(routes.athleteAuth.phoneVerification)
  const [disciplines] = useDisciplineContext();
  if (!disciplines?.disciplineIds.length) navigate(routes.athleteAuth.disciplines)
  const { mutate: registerAthlete } = useUpdateAthlete({ lastStep: false });


  const submit = () => {
    const values = form.getValues();

    if (currentUser) {
      registerAthlete({
        ...values,
        uuid: currentUser.uuid,
        isProfileSetUp: true,
        disciplines: disciplines?.disciplineIds,
        interests: [],
        phone: phone?.phone,
      })
    }

    window.dataLayer.push({
      event: 'signup-athlete-step-1',
      value: {
        firstName: values.firstName,
        lastName: values.lastName,
        preferredName: values.preferredName,
        bio: values.bio,
        email: values.email,
        heightFeet: values.heightFeet,
        heightInches: values.heightInches,
        weight: values.weight,
        city: values.city,
        isoCountry: values.isoCountry,
        state: values.state,
        location: values.location,
        status: values.status,
        partnerName: values.partnerName,
        children: values.children,
        motherName: values.motherName,
        fatherName: values.fatherName,
        college: values.college,
        degree: values.degree,
      }
    });

    // if fan already has the image and did not change it, don't send it to server
    if (values.profileImage === currentUser.profileImage) {
      delete values.profileImage
    }
    setContext(values)
    navigate(routes.athleteAuth.stepTwoSignup)
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Profile Setup | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Profile Setup</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{ pt: 5 }}>
        <div className={cls['container']}>
          <Logo>
            <img src={logoImg} alt="Logo" />
          </Logo>
        </div>
        <Typography variant="h3" align="center" color="primary.dark">
          Profile Setup
        </Typography>
        <div className={cls['eye-container']}>
          <Logo>
            <img src={eyeImg} alt="Logo" />
          </Logo>
        </div>
        <div className={cls['subtitle']}>
          Public visibility
        </div>
        <Typography
          variant="subtitle2"
          align="center"
          textTransform="uppercase"
          fontWeight="500"
          sx={{ pr: 1, pl: 1, pt: 1, pb: 3 }}
        >
          This data will be publicly visible.
        </Typography>
        <div className={cls['profile-media-section']}>
          <div className={cls['profile-picture-container']}>
            {form.watch('profileImage') && form.formState.defaultValues?.profileImage !== form.watch('profileImage') && <span className={cls['trash-icon']} onClick={() => form.setValue('profileImage', `${form.formState.defaultValues?.profileImage}`)}>
              <DeleteIcon />
            </span>}
            <div className={cls['media']}>
              {form.watch('profileImage') && <img src={form.watch('profileImage')} alt="Profile" />}
            </div>
          </div>
          <ImagePicker label="Upload Photo" form={form} name="profileImage" />
          <Typography color="grey.400" className={cls['image-size']}>
            Max: 3MB (.png,.jpg)
            <br/>
            Use a portrait style photo for the best look.
          </Typography>
        </div>
        <Box sx={{ mt: 8, pb: 13 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Legal First Name</InputLabel>
              <FormTextField requiredChar={true} fullWidth id="user-first-name" placeholder="Teton" form={form} name='firstName' />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Legal Last Name</InputLabel>
              <FormTextField requiredChar={true} fullWidth id="user-last-name" placeholder="Ridge" form={form} name='lastName' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Preferred Name</InputLabel>
              <FormTextField requiredChar={true} id="user-preferred-name" fullWidth placeholder="TR" form={form} name='preferredName' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Email Address</InputLabel>
              <FormTextField type="email" id="user-email-id" fullWidth placeholder="howdy@emailaddress.com" form={form} name='email' />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Bio</InputLabel>
                <FormTextField
                  fullWidth
                  sx={{
                    borderRadius: '6px',
                    overflow: 'scroll',
                    justifyContent: 'flex-start'
                  }}
                  multiline
                  id='bio'
                  form={form}
                  name='bio'
                  placeholder='Enter answer here'
                />
              </Box>
            </Grid>
          </Grid>
          <Typography variant="h3" className={cls['middle-title']} align="left" color="primary.dark">HELP YOUR FANS LEARN MORE ABOUT YOU</Typography>
          <Typography className={cls['vitals']} align="left">Your Vitals:</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Feet</InputLabel>
              <FormNumberField type='number' fullWidth id="user-feet" placeholder="5" form={form} name='heightFeet' />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Inches</InputLabel>
              <FormNumberField type='number' fullWidth id="user-inches" placeholder="2" form={form} name='heightInches' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Weight</InputLabel>
              <FormTextField type='number' fullWidth id="user-weight" placeholder="70lbs" form={form} name='weight' customOnChange={(e) => form.setValue('weight', e.target.value ? Number(e.target.value) : undefined)} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Country</InputLabel>
              <FormSelectField fullWidth id="user-iso-country" form={form} name='isoCountry' items={countries.map(c => ({ value: c.isoCode, name: c.value }))} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>State</InputLabel>
              <FormSelectField fullWidth id="user-state" form={form} name='state' values={states.map(state => state.value)} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Hometown(city)</InputLabel>
              {form.watch('state') && form.watch('isoCountry') && cities.length || !form.watch('state') ?
                <FormSelectField fullWidth id="user-city" form={form} name='city' values={cities.map(city => city.value)} /> :
                <FormTextField fullWidth id="user-city" form={form} name='city' />}
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Current Location</InputLabel>
              <FormTextField fullWidth id="user-location" placeholder="Location" form={form} name='location' />
            </Grid>
          </Grid>
          <div className={cls['line']} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Status</InputLabel>
              <FormSelectField fullWidth id="user-status" form={form} name='status' items={[{ value: 'married', name: 'Married' }, { value: 'single', name: 'Single' }]} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Partner Name</InputLabel>
              <FormTextField fullWidth id="user-partner-name" placeholder="Patner Name" form={form} name='partnerName' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Children</InputLabel>
              <FormSelectField fullWidth id="user-children" form={form} name='children' items={OPTION_NUMBERS} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Mother</InputLabel>
              <FormTextField fullWidth id="user-mother-name" placeholder="Mother Name" form={form} name='motherName' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Father</InputLabel>
              <FormTextField fullWidth id="user-father-name" placeholder="Father Name" form={form} name='fatherName' />
            </Grid>
          </Grid>
          <div className={cls['line']} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>College attended</InputLabel>
              <FormTextField fullWidth id="user-college" placeholder="Enter answer here" form={form} name='college' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Degree</InputLabel>
              <FormTextField fullWidth id="user-egree" placeholder="Enter answer here" form={form} name='degree' />
            </Grid>
          </Grid>
        </Box>
        <Fixedbox>
          <Button type='submit' variant="contained" color="primary" fullWidth>
            Next
          </Button>
        </Fixedbox>
      </Page>
    </>
  );
};

export default AthleteSetupPage;
