import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import SectionHeader from '../../components/Header/SectionHeader';
import { UpArrowIcon, DownArrowIcon } from '../../components/Icons';
import EventPost from '../../components/Post/EventPost';
import EventImg from '../../img/events-img.png';

const EventList = [
  {
    title: 'Houston Livestock and Rodeo',
    location: 'NRG Stadium - Houston',
    date: 'July 8 - july 11',
    media: EventImg,
    link: '/event/1',
  },
  {
    title: 'Houston Livestock and Rodeo',
    location: 'NRG Stadium - Houston',
    date: 'July 8 - july 11',
    media: EventImg,
    link: '/event/1',
  },
];

const UpcomingEventList = [
  {
    title: 'Houston Livestock and Rodeo',
    location: 'AT Stadium 1 a&t way, arlington, tx 7601, dallas texas',
    date: 'July 8 - july 11',
    media: EventImg,
    price: '$2.000',
    startPrice: '$30',
    slots: 20,
    registrationAvailable: '6/10, 6:00 am to 18/10, 6:00 pm',
    link: '/event/1',
  },
  {
    title: 'Houston Livestock and Rodeo',
    location: 'AT Stadium 1 a&t way, arlington, tx 7601, dallas texas',
    date: 'July 12 - july 16',
    media: EventImg,
    price: '$2.000',
    startPrice: '$30',
    slots: 20,
    registrationAvailable: '6/10, 6:00 am to 18/10, 6:00 pm',
    link: '/event/1',
  },
];

const EventView = () => {
  return (
    <>
      {EventList.map((event, index) => {
        return <EventPost key={index} {...event} />;
      })}
      <Box sx={{ mb: 2 }}>
        <Button
          size="small"
          variant="text"
          fullWidth
          endIcon={<UpArrowIcon />}
          sx={{
            color: (theme) => theme.palette.lightPallet.main,
            textTransform: 'capitalize',
            '& svg': { width: '15px', height: '15px', fill: (theme) => theme.palette.lightPallet.main },
          }}
        >
          Past Events
        </Button>
        <SectionHeader sx={{ ml: '-1rem', mr: '-1rem', mt: 1, mb: 1 }}>
          <Typography variant="body2" textTransform="uppercase" align="center" fontWeight="700">
            Today is July 10
          </Typography>
        </SectionHeader>
        <Button
          size="small"
          variant="text"
          fullWidth
          endIcon={<DownArrowIcon />}
          sx={{
            color: (theme) => theme.palette.lightPallet.main,
            textTransform: 'capitalize',
            '& svg': { width: '15px', height: '15px', fill: (theme) => theme.palette.lightPallet.main },
          }}
        >
          Upcoming Events
        </Button>
      </Box>
      <Box>
        {UpcomingEventList.map((event, index) => (
          <EventPost.Upcoming key={index} {...event} />
        ))}
      </Box>
    </>
  );
};

export default EventView;
