import { createContext, Dispatch, SetStateAction, useContext } from "react";

export type PhoneContextValue = {
  phone: string
} | undefined

const PhoneContext = createContext<[PhoneContextValue, Dispatch<SetStateAction<PhoneContextValue>>] | null>(null);

export const PhoneContextProvider = PhoneContext.Provider

export const usePhoneContext = () => {
  const value = useContext(PhoneContext)
  if (!value) throw new Error(`PhoneContext value is not set`)
  return value
}