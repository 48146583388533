import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Page } from '../../components/Page/Page';
import Fixedbox from '../../components/Fixedbox';
import pageBg from '../../img/home-page-bg.png';
import { Logo } from '../../components/Logo/Logo';
import { Helmet } from 'react-helmet';

const EventThankyou = () => {
  const navigate = useNavigate();
  const onPage = (link: string) => () => navigate(link);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event Thank You | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Thank You</title>
      </Helmet>
      <Page style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            backgroundImage: `url(${pageBg})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }} />
        <Box sx={{ height: '80px', top: '100px', width: '200px', position: 'absolute' }}>
          <Logo />
        </Box>
        <Box>
          <Typography variant="h1" align="center" color="primary.main">
            Thank you.
          </Typography>
          <Typography fontWeight="700" align="center" sx={{ pl: 6, pr: 6 }}>
            We received your registration for [EVENT NAME HERE].
          </Typography>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage('/events')}>
              BACK TO EVENTS
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventThankyou;
