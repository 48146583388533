import { StandingResults, useStandingResultsQuery } from "../../../api/standings.api";
import { styled } from "@mui/system";
import { useState } from "react";
import { RegionsResultsTable } from "./RegionsResultsTable";
import { ResultsTable } from './ResultsTable'
import { Box } from "@mui/material";

const TabsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'auto',
  background: 'var(--charcoal)',
}))

const Tabs = styled('div')<{ fullWidth: boolean }>(({ fullWidth }) => ({
  display: 'flex',
  alignItems: 'center',
  width: fullWidth ? '100%' : undefined,
}))

const Tab = styled('button')<{ active: boolean, isThree: boolean }>(({ active, isThree, theme }) => ({
  background: 'none',
  border: 0,
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '46px',
  color: active ? 'white' : 'rgba(255, 255, 255, 0.47)',
  fontFamily: 'Gotham',
  fontSize: 16,
  fontWeight: 'bold',
  width: isThree ? '33.3%' : 'auto',
  padding: '0 20px',
  borderBottom: `2px solid ${active ? theme.palette.primary.dark : 'transparent'}`,
}))

export const StandingCategory = ({ folderId, category }: { folderId: string, category: string }) => {
  const { data } = useStandingResultsQuery({ folderId, category })
  const [tab, setTab] = useState<string>()

  if (!data || !data.length) return null

  const isRegionsResults = data.every((item) => 'name' in item)
  if (isRegionsResults) {
    const results = data as StandingResults.Region[]
    const isThree = results.length === 3
    const activeTab = tab || results[0].name

    const content = results.find((item) => item.name === activeTab)

    return (
      <Box sx={{ flexGrow: 1, pb: 4, background: '#423C37' }}>
        <TabsWrapper>
          <Tabs fullWidth={isThree}>
            {results.map((item) =>
              <Tab
                key={item.name}
                active={item.name === activeTab}
                isThree={isThree}
                onClick={() => setTab(item.name)}
              >
                {item.name}
              </Tab>
            )}
          </Tabs>
        </TabsWrapper>
        {content && <RegionsResultsTable item={content}/>}
      </Box>
    )
  }

  const results = data as StandingResults.Values[]
  return (
    <>
      {results.map((result, i) =>
        <ResultsTable key={i} item={result} />
      )}
    </>
  )
}