import { TextFieldProps } from "@mui/material";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { FieldPath } from "react-hook-form/dist/types/path";
import { Controller } from "react-hook-form";

import cls from './FormStyle.module.css';
import minusSvg from '../../img/svgs/minus.svg'
import plusSvg from '../../img/svgs/plus.svg'

type Props<T extends FieldValues> = Omit<TextFieldProps, 'name'> & {
  form: UseFormReturn<T>
  name: FieldPath<T>,
  requiredChar?: boolean,
  smsAuto?: boolean
}

export const FormNumberField = <T extends FieldValues>({
  form,
  name
}: Props<T>) => {

  return (
    <Controller
      name={name}
      control={form.control}
      render={({ field }) => {
        return (
          <div className={cls['input-container']}>
            <div onClick={() => field.onChange(field.value - 1)}><img src={minusSvg} className={cls['img']} /></div>
            <div
              className={cls['input']} >{field.value}'
            </div>
            <div onClick={() => field.onChange(field.value + 1)}><img src={plusSvg} className={cls['img']} /></div>
          </div>)

      }} />)
}
