import { Card, CardContent, CardMedia, Link, Box, Typography } from "@mui/material";
import useWindowDimentions from "../../hooks/useWindowDimentions";
import { useEffect } from 'react';
import { CustomFeed } from "../../api/customFeed.api";

type Props = {
  item: CustomFeed
  onLoad?(): void;
}

const styles = {
  media: {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    height: '162px'
  }
}

export const CustomFeedModule = ({ item, onLoad }: Props) => {
  const { width } = useWindowDimentions();

  useEffect(() => {
    if (!item.image) onLoad?.();
  }, [])

  return (
    <Card
      sx={{
        mb: 2, position: 'relative', mt: 3, overflow: 'inherit', backgroundColor: '#423C37 !important', borderRadius: 3, mx: 'auto', width: (width > 700) ? '650px' : '100%'
      }}
    >
      <Link
        component='a'
        href={item.url}
        underline='none'
      >
        <CardContent sx={{ p: 0 }}>
          {item.image && (
            <CardMedia component='img' image={item.image.attributes.url} alt={item.image.attributes.alternativeText} style={styles.media} onLoad={onLoad} />
          )}
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1" fontFamily='BourbonW00-Regular' fontSize='28px' fontWeight='400' sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}>
              {item.title}
            </Typography>
          </Box>
        </CardContent>
      </Link>
    </Card>
  )
}
