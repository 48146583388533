import { Box, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DashboardHeader from '../Dashboard/DashboardHeader';
import { Page } from '../../components/Page/Page';
import { Logo } from '../../components/Logo/Logo';
import SectionTitle from '../../components/SectionTitle';
import ExploreLogos from '../../components/ExploreLogos';
import pageBg from '../../img/home-page-bg.png';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const JoinFanbase = () => {
  const navigate = useNavigate();
  const [age, setAge] = useState<string>('');
  const onPage = (name: string) => {
    navigate(`/${name}`);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Join Fanbase | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Join Fanbase</title>
      </Helmet>
      <Page>
        <DashboardHeader notification={false} settings={false} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              left: '0',
              right: '0',
              top: '0',
              bottom: '0',
              backgroundImage: `url(${pageBg})`,
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
            }} />
          <Box sx={{ height: '80px', mb: 13, mt: 5 }}>
            <Logo />
          </Box>
          <Typography variant='h1' align='center' color='primary'>
            Join the Fanbase
          </Typography>
          <Typography
            align='center'
            color={'lightPallet.lighteer'}
            fontSize='14px'
            fontWeight={'600'}
            lineHeight='20px'
            sx={{ pl: 3, pr: 3, mt: 3 }}
          >
            Your Teton Ridge account allows you to login to any of our properties. Create your account to follow your
            favorite disciplines, athletes, and events. You’ll also get early access to purchase tickets for events like
            the American Rodeo and earn perks like Cowboy Points.
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', pt: 13, pb: 5, flexDirection: 'column' }}>
            <Button style={{ maxWidth: 400 }} variant='contained' fullWidth onClick={() => onPage('signup')} sx={{ mb: 2 }}>
              SIGN UP
            </Button>
            <Button variant='outlined' style={{ maxWidth: 400 }} fullWidth color='primary' onClick={() => onPage('signin')}>
              LOGIN
            </Button>
          </Box>
        </Box>
        <SectionTitle title='EXPLORE TETON RIDGE' hasLine />
        <ExploreLogos />

        <SectionTitle title='Sign up for our Newsletter' hasLine />
        <Box component='form' noValidate autoComplete='off'>
          <Box sx={{ pb: 2 }}>
            <InputLabel>NAME</InputLabel>
            <TextField fullWidth id='outlined-error' placeholder='Teton' />
          </Box>
          <Box sx={{ pb: 2 }}>
            <InputLabel>EMAIL</InputLabel>
            <TextField type='email' id='outlined-error-helper-text' fullWidth placeholder='howdy@emailaddress.com' />
          </Box>
          <Box sx={{ pb: 2 }}>
            <Select fullWidth value={age} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Interest' }}>
              <MenuItem value=''>
                <Typography variant='caption'>What interests you about Western Sports?</Typography>
              </MenuItem>
              <MenuItem value={1}>Vollyball</MenuItem>
              <MenuItem value={2}>Cricket</MenuItem>
              <MenuItem value={3}>Football</MenuItem>
            </Select>
          </Box>
          <Button variant='contained' fullWidth sx={{ mt: 2, mb: 3 }} style={{
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            SIGN UP
          </Button>
        </Box>
      </Page>
    </>
  );
};

export default JoinFanbase;
