import {
  Typography,
  Box,
  Divider,
  TextField,
  InputLabel,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import { useState } from 'react';
import { Page } from '../../../components/Page/Page';
import ProfileImage from '../../../components/Image/ProfileImage';
import { DeleteIcon } from '../../../components/Icons';
import { styled } from '@mui/system';
import SectionTitle from '../../../components/SectionTitle';
import Fixedbox from '../../../components/Fixedbox';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NotFoundPage } from "../../../components/Errors/NotFoundPage";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { CurrentUser } from "../../../api/auth.api";
import dayjs from "dayjs";
import { useUpdateAthleteMutation, useAthleteQuery, Athlete } from "../../../api/athletes.api";

const DeleteIconBtn = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '30px',
  width: '30px',
  backgroundColor: theme.palette.lightPallet.lighter,
  display: 'flex',
  alignItems: 'center',
  boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.5), 0px 4px 6px rgba(0, 0, 0, 0.25)',
  justifyContent: 'center',
  zIndex: 1,
  right: '15px',
  top: '10px',
  borderRadius: '50%',
  cursor: 'pointer',
  transition: 'all 0.3s linear 0s',
  '& svg': {
    height: '14px',
    width: '14px',
    fill: theme.palette.error.main,
  },
}));

export default function EditProfile({ user }: { user: CurrentUser }) {
  const params = useParams()
  if (!params.id) {
    return <NotFoundPage />
  }

  return <EditProfileWithParams uuid={params.id} user={user} />
}

const EditProfileWithParams = ({ uuid, user }: { uuid: string, user: CurrentUser }) => {
  const { data: athlete, isLoading } = useAthleteQuery({ uuid })

  if (isLoading) {
    return <LoadingScreen />
  } else if (!athlete) {
    return <NotFoundPage />
  }

  return <EditProfileWithAthlete athleteId={uuid} athlete={athlete} currentUser={user} />
}

const EditProfileWithAthlete = ({
  athleteId,
  athlete,
  currentUser,
}: {
  athleteId: string,
  athlete: Athlete,
  currentUser: CurrentUser,
}) => {
  const navigate = useNavigate();
  const [athleteData, setAthleteData] = useState({ ...athlete, ...currentUser });

  const { mutate } = useUpdateAthleteMutation()

  // const placeholder = {
  //   address: "1234 First St.",
  //   addressMail: "1234 First St.",
  //   bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a consectetur phasellus ut id. Lorem nibh purus sollicitudin gravida mattis.",
  // }

  const submitChanges = async () => {
    // Add Nickname and Bio - Date of birth not allowed - Handle Email Error (500)
    const data = Object.fromEntries(Object.entries(athleteData).filter(([key, v]) => v != null && key != 'id'));
    mutate({ uuid: athleteId, firstName: data.firstName as string })
    navigate(`/athletes/${athleteId}`)
  }

  const handleText = (event: any) => {
    const { id, value } = event.target;

    // format date and fields here

    setAthleteData((prevState: any) => {
      return {
        ...prevState,
        [id]: value
      }
    })
  }

  const handleSelect = (event: any, id: string) => {
    const value = event.target.value;
    setAthleteData((prevState: any) => {
      return {
        ...prevState,
        [id]: value
      }
    })
  }

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Edit Athlete | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Edit Athlete | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Edit Athlete | {athleteId}</title>
      </Helmet>
      <Page sx={{ pt: 5 }}>
        <ProfileImage
          src={athleteData.avatar}
          center
          TrashIcon={
            <DeleteIconBtn>
              <DeleteIcon />
            </DeleteIconBtn>
          }
        />
        <Box sx={{ pt: 5 }}>
          {/* TODO: uncomment if users are going to have bio  */}
          {/*<Grid item xs={6}>*/}
          {/*  <Box sx={{ pt: 2 }}>*/}
          {/*    <InputLabel>Bio</InputLabel>*/}
          {/*    <TextField*/}
          {/*      fullWidth*/}
          {/*      sx={{*/}
          {/*        borderRadius: '6px',*/}
          {/*        overflow: 'scroll',*/}
          {/*        justifyContent: 'flex-start'*/}
          {/*      }}*/}
          {/*      multiline*/}
          {/*      onChange={handleText}*/}
          {/*      value={athleteData.bio}*/}
          {/*      id='bio'*/}
          {/*      placeholder={placeholder.bio}*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Legal First Name</InputLabel>
                <TextField
                  onChange={handleText}
                  id='firstName'
                  fullWidth
                  placeholder="Jonathan"
                  value={athleteData.firstName}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Legal Last Name</InputLabel>
                <TextField
                  onChange={handleText}
                  id='lastName'
                  fullWidth
                  placeholder="Bates"
                  value={athleteData.lastName}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Date of Birth</InputLabel>
                <TextField
                  onChange={handleText}
                  id='dateOfBirth'
                  fullWidth
                  placeholder="06/10/1990"
                  value={dayjs(athleteData.dateOfBirth).subtract(10, 'days').calendar()}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Gender</InputLabel>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => handleSelect(e, 'gender')}
                    fullWidth
                    displayEmpty
                    inputProps={{ 'aria-label': 'sex' }}
                    value={athleteData.gender}
                  >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value='male'>Male</MenuItem>
                    <MenuItem value='female'>Female</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ ml: '-1rem', mr: '-1rem', mt: 5, mb: 3 }} />
        <SectionTitle color="primary.main" title="Contact Details" />
        <Typography>We will use this information to send you details about the events that you register for.</Typography>
        <Box>
          {/* <Grid container spacing={2}> */}
          {/* <Grid item xs={6}> */}
          <Box sx={{ pt: 2 }}>
            <InputLabel>Mobile Phone</InputLabel>
            <TextField
              onChange={handleText}
              id='phone'
              fullWidth
              placeholder="(555) 666-999-666"
              value={athleteData.phone}
            />
          </Box>
          {/* </Grid> */}
          {/* <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Home Phone</InputLabel>
              <TextField
                onChange={handleText}
                id='phone2'
                fullWidth
                placeholder="(555) 666-999-666"
                value={athleteData.phone2}
              />
            </Box>
          </Grid> */}
          {/* </Grid> */}
          <Box sx={{ pt: 2 }}>
            <InputLabel>Email Address</InputLabel>
            <TextField
              onChange={handleText}
              value={athleteData.email}
              id='email'
              fullWidth
              placeholder="john@emailaddress.com"
            />
          </Box>
          {/* <Box sx={{ pt: 2 }}>
          <InputLabel>Home Address</InputLabel>
          <TextField
            onChange={handleText}
            id='address'
            fullWidth
            placeholder={placeholder.address}
            value={athleteData.address}
          />
        </Box> */}
          <Box sx={{ pt: 2 }}>
            <InputLabel>City</InputLabel>
            <TextField
              onChange={handleText}
              id='city'
              fullWidth
              placeholder="Dallas"
              value={athleteData.city}
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>State</InputLabel>
                <FormControl fullWidth>
                  <Select onChange={(e) => handleSelect(e, 'state')} fullWidth value={athleteData.state} displayEmpty inputProps={{ 'aria-label': 'sex' }}>
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value="CA">CA</MenuItem>
                    <MenuItem value="NY">NY</MenuItem>
                    <MenuItem value="TX">TX</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Zip Code</InputLabel>
                <TextField
                  onChange={handleText}
                  id='zipcode'
                  fullWidth
                  placeholder=""
                  value={athleteData.zipcode}
                />
              </Box>
            </Grid>
          </Grid>
          {/* <FormGroup sx={{ mt: 3, mb: 3 }}>
          <FormControlLabel control={<Checkbox defaultChecked />} label="Home address different from mailing address" />
        </FormGroup> */}
          {/* <Box sx={{ pt: 2 }}>
          <InputLabel>Mailing Address</InputLabel>
          <TextField
            onChange={handleText}
            id='addressMail'
            fullWidth
            placeholder={placeholder.addressMail}
            value={athleteData.addressMail}
          />
        </Box> */}
          {/* <Box sx={{ pt: 2 }}>
          <InputLabel>City</InputLabel>
          <TextField
            onChange={handleText}
            id='cityMail'
            fullWidth
            placeholder="Atlanta"
            value={athleteData.cityMail}
          />
        </Box> */}
          {/* <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>State</InputLabel>
              <FormControl fullWidth>
                <Select fullWidth value={athleteData.stateMail} displayEmpty inputProps={{ 'aria-label': 'sex' }} onChange={(e) => handleSelect(e, 'stateMail')}>
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="CA">CA</MenuItem>
                  <MenuItem value="NY">NY</MenuItem>
                  <MenuItem value="TX">TX</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Zip Code</InputLabel>
              <TextField
                onChange={handleText}
                id='zipcodeMail'
                fullWidth
                placeholder=""
                value={athleteData.zipcodeMail}
              />
            </Box>
          </Grid>
        </Grid> */}
        </Box>

        <Divider sx={{ ml: '-1rem', mr: '-1rem', mt: 5, mb: 3 }} />
        <SectionTitle color="primary.main" title="Emergency Contact" />
        <Typography>In the event of an emergency, we’ll reach out to this contact to notify.</Typography>
        <Box>
          <Grid container spacing={2}>
            {/*<Grid item xs={6}>*/}
            {/*  <Box sx={{ pt: 2 }}>*/}
            {/*    <InputLabel>First Name</InputLabel>*/}
            {/*    <TextField*/}
            {/*      onChange={handleText}*/}
            {/*      id='emergencyFirstName'*/}
            {/*      fullWidth*/}
            {/*      placeholder="Jonathan"*/}
            {/*      value={athleteData.emergencyFirstName}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6}>*/}
            {/*  <Box sx={{ pt: 2 }}>*/}
            {/*    <InputLabel>Last Name</InputLabel>*/}
            {/*    <TextField*/}
            {/*      onChange={handleText}*/}
            {/*      id='emergencyLastName'*/}
            {/*      fullWidth*/}
            {/*      placeholder="Bates"*/}
            {/*      value={athleteData.emergencyLastName}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6}>*/}
            {/*  <Box sx={{ pt: 2 }}>*/}
            {/*    <InputLabel>Mobile Phone</InputLabel>*/}
            {/*    <TextField*/}
            {/*      onChange={handleText}*/}
            {/*      id='emergencyPhone'*/}
            {/*      fullWidth*/}
            {/*      placeholder="(555) 666-999-666"*/}
            {/*      value={athleteData.emergencyPhone}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
            {/* <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Home Phone</InputLabel>
              <TextField
                onChange={handleText}
                id='emergencyPhone2'
                fullWidth
                placeholder="(555) 666-999-666"
                value={athleteData.emergencyPhone2}
              />
            </Box>
          </Grid> */}
            {/* <Grid item xs={12}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Email Address</InputLabel>
              <TextField
                onChange={handleText}
                id='emergencyEmail'
                fullWidth
                placeholder="john@emailaddress.com"
                value={athleteData.emergencyEmail}
              />
            </Box>
          </Grid> */}
          </Grid>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={submitChanges}
            >
              Save
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};
