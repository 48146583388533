import Fixedbox from "../../components/Fixedbox";
import { Helmet } from 'react-helmet';
import { Page } from "../../components/Page/Page";
import { Button, Typography } from '@mui/material';
import { useAuth0Login } from "./auth.service";

// This screen is displayed when user signed up via oauth and then via email using the same email address
// In such case, we delete account from auth0 and tell user to register again, but to choose to merge accounts this time
export const DuplicatedAuth0Screen = ({ isAthlete }: { isAthlete: boolean }) => {
  const login = useAuth0Login({ isAthlete })

  return (
    <>
      <Helmet>
        <meta property="og:title" content="User is alredy registered | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>User is already registered with different method</title>
      </Helmet>
      <Page sx={{ pt: 5 }}>
        <Typography variant="h3" align="center" color="primary.main">
          Account was set up incorrectly
        </Typography>
        <br />
        <br />
        <Typography align="center">
          An account with this email was already registered using a different method. Since the merge was not successful, we recommend you try logging in with that email. If you forgot the password, please use the forgot password flow.
          <br /><br />
          If you are running into problems, please reach out to <a href='mailto:support@tetonridgeplus.com'>support@tetonridgeplus.com</a>.
        </Typography>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => login('signup')}
            >
              Register
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  )
}