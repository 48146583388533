import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { State, City, Country } from "country-state-city";

import { FormSelectField } from '../../../components/Form/FormSelector';
import { FormTextField } from '../../../components/Form/FormTextField';
import cls from '../../../features/Auth/SignUpFlow/AthleteSetup/AthleteSetup.module.css';
import { FormNumberField } from '../../../components/Form/FormNumberField';
import { UseFormReturn } from 'react-hook-form';

const OPTION_NUMBERS = [{ value: 0, name: 0 }, { value: 1, name: 1 }, { value: 2, name: 2 }, { value: 3, name: 3 }, { value: 4, name: 4 }, { value: 5, name: 5 }, { value: 6, name: 6 }, { value: 7, name: 7 }, { value: 8, name: 8 }, { value: 9, name: 9 }, { value: 10, name: 10 }];

type AthleteScreenProps = {
  form: UseFormReturn<any, any>
}

const VitalsEditScreen = ({ form }: AthleteScreenProps) => {
  const [countries, setCountries] = useState<{ value: string; isoCode: string; }[]>([]);
  const [states, setStates] = useState<{ value: string; id: string; }[]>([]);
  const [cities, setCities] = useState<{ value: string; }[]>([]);

  useEffect(() => {
    setCountries(Country.getAllCountries().map(({ name, isoCode }) => ({
      value: name,
      isoCode
    })));
  }, [])

  useEffect(() => {
    setStates(State.getStatesOfCountry(form.getValues().isoCountry || '').map(({ name, isoCode }) => {
      return ({
        value: name,
        id: isoCode
      })
    }),
    );
  }, [form.watch('isoCountry')])

  useEffect(() => {
    const [completeState] = State.getStatesOfCountry("US").filter(({ name }) => name === form.getValues().state);

    setCities(City.getCitiesOfState("US", completeState?.isoCode || '').map(({ name }) => ({
      value: name,
    })),
    );
  }, [form.watch('state')])


  return (
    <div className={cls['athlete-container']}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: '700' }}>Feet</InputLabel>
          <FormNumberField type='number' fullWidth id="user-feet" placeholder="5" form={form} name='heightFeet' />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: '700' }}>Inches</InputLabel>
          <FormNumberField type='number' fullWidth id="user-inches" placeholder="2" form={form} name='heightInches' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Weight</InputLabel>
          <FormTextField type='number' fullWidth id="user-weight" placeholder="70lbs" form={form} name='weight' customOnChange={(e) => form.setValue('weight', e.target.value ? Number(e.target.value) : undefined)} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Country</InputLabel>
          <FormSelectField fullWidth id="user-iso-country" form={form} name='isoCountry' items={countries.map(c => ({ value: c.isoCode, name: c.value }))} />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: '700' }}>State</InputLabel>
          <FormSelectField fullWidth id="user-state" form={form} name='state' values={states.map(state => state.value)} />
        </Grid>
        <Grid item xs={6}>
          <InputLabel sx={{ fontWeight: '700' }}>Hometown(city)</InputLabel>
          {form.watch('state') && form.watch('isoCountry') && cities.length || !form.watch('state') ?
            <FormSelectField fullWidth id="user-city" form={form} name='city' values={cities.map(city => city.value)} /> :
            <FormTextField fullWidth id="user-city" form={form} name='city' />}
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Current Location</InputLabel>
          <FormTextField fullWidth id="user-location" placeholder="Location" form={form} name='location' />
        </Grid>
      </Grid>
      <div className={cls['line']} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Status</InputLabel>
          <FormSelectField fullWidth id="user-status" form={form} name='status' items={[{ value: 'married', name: 'Married' }, { value: 'single', name: 'Single' }]} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Partner Name</InputLabel>
          <FormTextField fullWidth id="user-partner-name" placeholder="Patner Name" form={form} name='partnerName' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Children</InputLabel>
          <FormSelectField fullWidth id="user-children" form={form} name='children' items={OPTION_NUMBERS} />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Mother</InputLabel>
          <FormTextField fullWidth id="user-mother-name" placeholder="Mother Name" form={form} name='motherName' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Father</InputLabel>
          <FormTextField fullWidth id="user-father-name" placeholder="Father Name" form={form} name='fatherName' />
        </Grid>
      </Grid>
      <div className={cls['line']} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>College attended</InputLabel>
          <FormTextField fullWidth id="user-college" placeholder="Enter answer here" form={form} name='college' />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ fontWeight: '700' }}>Degree</InputLabel>
          <FormTextField fullWidth id="user-egree" placeholder="Enter answer here" form={form} name='degree' />
        </Grid>
      </Grid>
    </div >
  );
};

export default VitalsEditScreen;