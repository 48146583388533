import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { useState } from 'react';

const CookieBanner = ({ setCookieBanner }: { setCookieBanner(item: boolean): void }) => {
  const [isExpanded, setExpand] = useState(false)
  return (
    <Box sx={{
      position: 'absolute',
      backgroundColor: 'white',
      width: '350px',
      bottom: '3%',
      left: '0%',
      right: '0%',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: '#423C37',
      fontSize: '12px',
      borderRadius: '9px'
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
      }}>
        <p
          style={{
            marginBottom: 0.2
          }}>
          Cookies are
          <strong>
            {' '} required to access {' '}
          </strong>
          tetonridgeplus.com <br />
          <span
            style={{
              color: 'blue',
              fontSize: '10px'
            }}
            onClick={() => setExpand(!isExpanded)}>
            {!isExpanded &&
              'Expand'
            }
          </span>
        </p>
        {isExpanded && <p style={{
          fontSize: '10px',
          textAlign: 'left',
          paddingLeft: '15px'
        }}>
          We use cookies to improve your experience, by storing your session information when you login. This allows our platform to recognize that you have logged in, and provides a better experience as you navigate tetonridgeplus.com.
        </p>}
        <Button
          fullWidth
          variant='contained'
          color='primary'
          onClick={() => setCookieBanner(false)}
          style={{
            width: '85px',
            height: '3px'
          }}
          sx={{
            mt: 1,
            mb: 1
          }}
        >
          GOT IT
        </Button>
      </Box>
    </Box >
  );
};

export default CookieBanner;
