import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { RightArrowIcon } from '../../components/Icons';
import ProductPost from '../../components/Post/ProductPost';
import Tabs, { Tab, TabPanel } from '../../components/Tabs';
import EventImg from '../../img/events-img.png';
import ProductImg from '../../img/product-img.png';
import ArticleImg from '../../img/content-post.png';
import VideoPostImg from '../../img/video-post.png';
import ContentLogo from '../../img/content-logo.png';
import starLogo from '../../img/video-star-icon.png';
import postLogo from '../../img/post-icon.png';
import { Post } from "../../components/Post/Post";
import { VideoPost } from "../../components/Post/VideoPost/VideoPost";

const Bookmark = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '30px',
  height: '30px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > svg': {
    height: '15px',
    width: '15px',
    fill: theme.palette.lightPallet.lighter,
  },
}));

interface EventProps {
  title: string;
  place: string;
  logo: string;
  date: string;
  media: string;
  price: string;
  startPrice: string;
  slots: number;
  open: boolean;
}

const EventsCard = (props: EventProps) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography sx={{ pb: 2 }} textTransform="uppercase" fontWeight="700">
          {props.date}
        </Typography>
        <CardMedia component="img" image={props.media} alt="Content media" />
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              flex: 1,
              pt: 1,
            }}
          >
            <Typography variant="h3" color="primary" textTransform="uppercase" sx={{ pb: 1 }}>
              Houston Livestock and Rodeo
            </Typography>
            <Typography variant="caption" textTransform="uppercase">
              AT Stadium 1 a&t way, arlington, tx 7601, dallas texas
            </Typography>
          </Box>
          <Box>
            <Bookmark>
              <RightArrowIcon />
            </Bookmark>
          </Box>
        </Box>
      </CardContent>

      <CardContent>
        <Typography fontWeight="700">Payout: $2.000 • 20 Slots Open</Typography>
        <Typography variant="caption" textTransform="uppercase">
          Available registration from 6/10, 6:00 am to 18/10, 6:00 pm
        </Typography>
        <Button variant="outlined" fullWidth sx={{ mt: 2, backgroundColor: 'transparent', textTransform: 'uppercase' }}>
          Register to Complete
        </Button>
      </CardContent>

      <CardContent>
        <Typography variant="body2" sx={{ pb: 2 }}>
          Tickets start at $35
        </Typography>
        <Button variant="contained" fullWidth sx={{
          textTransform: 'uppercase',
          maxWidth: 400,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          Register to Compete
        </Button>
      </CardContent>
    </Card>
  );
};

const ContentList = [
  {
    id: 1,
    title: 'Video Title Goes Here and Probably Takes 2 Lines AT MAXIMUM',
    logo: starLogo,
    date: '8 mins',
    media: VideoPostImg,
    type: 'video',
  },
  {
    id: 2,
    title: 'Richest Single-Day Rodeo in Western Sports Awards $3 Million across EIGHT DISCIPLINES',
    logo: postLogo,
    media: ArticleImg,
    comments: 3,
    user: 'John Deer',
    category: 'Post Category',
  },
  {
    id: 3,
    title: 'Video Title Goes Here and Probably Takes 2 Lines AT MAXIMUM',
    logo: starLogo,
    date: '8 mins',
    media: VideoPostImg,
    type: 'video',
  },
  {
    id: 4,
    title: 'Richest Single-Day Rodeo in Western Sports Awards $3 Million across EIGHT DISCIPLINES',
    logo: postLogo,
    media: ArticleImg,
    user: 'John Deer',
    category: 'Post Category',
  },
];

const EventList = [
  {
    title: 'Houston Livestock and Rodeo',
    place: 'NRG Stadium - Houston',
    logo: ContentLogo,
    date: 'July 12 - july 16',
    media: EventImg,
    price: '$2.000',
    startPrice: '$30',
    slots: 20,
    open: true,
  },
  {
    title: 'Houston Livestock and Rodeo',
    place: 'NRG Stadium - Houston',
    logo: ContentLogo,
    date: 'July 12 - july 16',
    media: EventImg,
    price: '$2.000',
    startPrice: '$30',
    slots: 20,
    open: true,
  },
];

const ProductList = [
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$129.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
];

const SavedContentTab = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ pt: 4, pb: 4 }}>
      <Tabs value={value} variant="fullWidth" onChange={handleChange} aria-label="Saved Contents">
        <Tab label="CONTENT" />
        <Tab label="EVENTS" />
        {/* <Tab label="PRODUCTS" /> */}
      </Tabs>
      <TabPanel index={0} value={value}>
        <Box sx={{ pt: 2 }}>
          {ContentList.map((item) => (item.type === 'video' ? <VideoPost {...item} /> : <Post {...item} />))}
        </Box>
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Box sx={{ pt: 2 }}>
          {EventList.map((item) => (
            <EventsCard {...item} />
          ))}
        </Box>
      </TabPanel>
      <TabPanel index={2} value={value}>
        <Box sx={{ pt: 2 }}>
          <Grid container spacing={2}>
            {ProductList.map((item) => {
              return (
                <Grid item xs={6}>
                  <ProductPost {...item} light={true} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </TabPanel>
    </Box>
  );
};

export default SavedContentTab;
