export const themeColors = {
  primary: {
    sand_700: '#E0B975',
    sand_500: '#BD9B60',
    sand_400: '#8A7144',
  },
  secondary: {
    amber_700: '#FFB330',
    amber_500: '#D99B30',
    amber_400: '#B58128',
  },
  dark: {
    charcoal_800: '#484848',
    charcoal_700: '#423C37',
    charcoal_500: '#2D2926',
    charcoal_400: '#151312',
    charcoal: '#000000',
  },
  light: {
    gray_900: '#FFFFFF',
    gray_700: '#E0E3E3',
    gray_500: '#B1B3B3',
    gray_400: '#6B6B6B',
  },
};

declare module '@mui/material/styles' {
  interface Palette {
    dark: Palette['primary'];
    light: Palette['primary'];
    darkPallet: Palette['primary'];
    lightPallet: Palette['primary'];
  }
  // allow configuration using `createTheme`
  interface PaletteOptions {
    darkPallet: PaletteOptions['primary'];
    lightPallet: PaletteOptions['primary'];
  }
  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }
  interface TypeBackground {
    gradient: string;
    darkGradient: string;
  }
}

const Palette = {
  mode: 'dark',
  primary: {
    light: themeColors.primary.sand_400,
    main: themeColors.primary.sand_500,
    dark: themeColors.primary.sand_700,
    contrastText: themeColors.dark.charcoal_700,
  },
  secondary: {
    light: themeColors.secondary.amber_400,
    main: themeColors.secondary.amber_500,
    dark: themeColors.secondary.amber_700,
  },
  darkPallet: {
    lighter: themeColors.dark.charcoal_800,
    light: themeColors.dark.charcoal_700,
    main: themeColors.dark.charcoal_500,
    dark: themeColors.dark.charcoal_400,
    darker: themeColors.dark.charcoal,
  },
  lightPallet: {
    lighter: themeColors.light.gray_900,
    light: themeColors.light.gray_700,
    main: themeColors.light.gray_500,
    dark: themeColors.light.gray_400,
  },
  background: {
    paper: themeColors.dark.charcoal,
    default: themeColors.dark.charcoal,
    gradient: 'linear-gradient(180deg, #BD9B60 0%, #E0B975 48.96%, #8A7144 96.87%)',
    darkGradient: 'linear-gradient(180deg, #423C37 0%, #000000 100%)',
  },
};

export default Palette;
