import Box from '@mui/material/Box';
import Interest from '../../components/Interest';
import { hardcodedInterests } from "../../api/interests.api";

const EventInterest = () => {
  return (
    <Box sx={{ mt: 5, mb: 5 }}>
      <Interest.Slider list={hardcodedInterests} />
    </Box>
  );
};
export default EventInterest;
