import { Helmet } from "react-helmet";
import { Layout } from "../../../components/Layout/Layout";
import { Box } from "@mui/material";
import { useListStandingsQuery } from "../../../api/standings.api";
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { StandingsSeason } from "./StandingsSeason";
import { styled } from "@mui/system";
import { useEffect } from "react";

const Title = styled('h1')(({ theme }) => ({
  marginTop: 40,
  marginBottom: 24,
  textAlign: 'center',
  color: theme.palette.primary.dark,
  fontSize: 28,
  fontFamily: 'BourbonW00-Regular',
  fontWeight: 'normal',
}))

export const StandingsListPage = () => {
  const { data } = useListStandingsQuery()
  useEffect(() => {
    if (navigator.cookieEnabled) {
      localStorage.removeItem("filterTags")
      localStorage.removeItem("athleteFilters")
    }
  }, [])
  if (!data) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Standings | Teton Ridge+" />
        <meta name="twitter:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Standings | Teton Ridge+" />
        <meta property="og:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta property="og:type" content="website" />
        <meta name="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Results</title>
      </Helmet>
      <Layout
        contentSx={{ pl: 0 }}
      >
        <Box sx={{ height: '100%', overflow: 'auto', pb: 4 }}>
          <Title>
            Select an event or series
          </Title>
          {data.map((item) => (
            <StandingsSeason key={item.name} item={item} />
          ))}
        </Box>
      </Layout>
    </>
  )
}