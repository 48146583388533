import React from 'react';
import cls from './Main.module.css';

export type Props = {
  children: React.ReactNode;
};

export const Main = ({ children }: Props) => {
  return (
    <div className={cls['main']}>
      <div className={cls['content']}>{children}</div>
    </div>
  );
};
