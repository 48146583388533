import { useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { Link as RLink, useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { Square } from "../../Shapes/Square/Square";

import CardMedia from '@mui/material/CardMedia';
import { FeedTypeEnum } from "../../../pages/Feeds/feedTypes";

/* eslint-disable */
type Collection2 = {
  articleQuantity?: number;
  feedType?: FeedTypeEnum;
  id: number;
  image?: CollectionImage;
  publishedAt?: string;
  title: string;
  url: string;
  videoQuantity?: number;
  onLoad?(): void;
  onOpen?(item: { id: number }): void,
  onPin?(): void,
}

interface Collection {
  attributes: CollectionAttributes;
  id?: number;
  onLoad?(): void;
}

interface CollectionAttributes {
  Collection_Image: CollectionImage;
  Collection_Title: string;
  article_posts: ArticlePost;
  video_posts: VideoPost;
}

interface CollectionImage {
  data: CollectionImageData;
}
interface CollectionImageData {
  id: number;
  attributes: CollectionImageAttributes;
}
interface CollectionImageAttributes {
  name: string;
  url: string;
}
interface ArticlePost {
  data: ArticlePostData[];
}
interface ArticlePostData { }
interface VideoPost {
  data: VideoPostData[];
}
interface VideoPostData { }

export const CollectionPost =
  ({
    id,
    title,
    url,
    image,
    onLoad,
    onOpen,
    onPin,
  }: Collection2) => {
    useEffect(() => onLoad?.(), [])
    const navigate = useNavigate();
    return (
      <Card
        sx={{
          mx: 'auto',
          position: 'relative',
          overflow: 'inherit',
          backgroundColor: '#423C37 !important',
          borderRadius: 3,
          height: '450px',
          width: '350px'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 20,
            left: 30,
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: 'scale(2.2)'
          }}
          onClick={() => navigate(`/collections/${id}`)}
        >
        </div>
        <Link
          component={RLink}
          variant="subtitle1"
          sx={{ textDecoration: 'none' }}
          fontSize="28px"
          lineHeight="31px"
          color="#BD9B60"
          fontFamily="BourbonW00-Regular"
          className="ga-article"
          to={`/collections/${id}`}
          onClick={() => navigate(`/collections/${id}`)}
        >
          <CardContent sx={{ p: 0, paddingBottom: '0 !important' }}>
            <Square>
              <Box sx={{
                position: 'relative',
                height: '450px',
                width: '350px',
              }}>
                <div style={{
                  height: '450px',
                  width: '350px',
                  backgroundColor: 'rgba(0,0,0,0.7)',
                  position: 'absolute',
                  top: 0
                }} />
                <CardMedia
                  component="img"
                  src={url}
                  alt="Content media"
                  sx={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderBottomRightRadius: '12px',
                    width: '100%',
                    height: '100%',
                  }}
                // onLoad={onLoad}
                />
                <Box sx={{
                  position: 'absolute',
                  top: '50%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {title}
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      pt: 1,
                      mb: 3,
                    }}>
                    </Box>
                  </Box>

                  {/* <Box sx={{
                    border: '2px solid',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    width: '200px',
                  }}>
                    VIEW COLLECTION
                  </Box> */}
                </Box>
                <Box sx={{
                  position: 'absolute',
                  top: '80%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pr: '30px',
                  pl: '30px',
                  textAlign: 'center'
                }}>
                  <Box sx={{
                    position: 'absolute',
                    top: '65.5%',
                    border: '2px solid',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    width: '200px',
                  }}>
                    VIEW COLLECTION
                  </Box>
                </Box>
              </Box>
            </Square>
          </CardContent>
        </Link>
      </Card >
    )
  };
