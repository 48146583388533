type User = {
  username: string;
  email: string;
  blocked: boolean;
  confirmed: boolean;
  id: number;
  jwt?: string;
};

// type ApiItem = {
//     id: number,
//     attributes: any
// }

const api = {
  _user: false,
  _api_url: process.env.REACT_APP_API_URL || 'http://localhost:3443',
  _cms_url: process.env.REACT_APP_CMS_BASE_URL || 'https://dev-cms.tetonridge.io/',
  _strapi_token:
    process.env.REACT_APP_API_KEY ||
    'bd3f730ad471d1efb3fae2b665377d2e300744bfe488a73dde1c75f05ce978c61fbac751ad3e43815493975328537c7fc74dd28662ef7d1412466057d08e053d528202e9a5d9c05acb9dbe563f9925b7d5bb9d7e61dc2b1f3667a33c0098de64090cedcc5a58e49cf10e24dd645f8186ef09066b5896930ac887195cf2dddd7e',
  _fetch: async (url: string) => {
    return await fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
      },
    });
  },
  _redirect: async (url: string) => {
    if (typeof window !== 'undefined') {
      window.location.href = url;
    }
  },
  setAccess: (access: string) => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('access_token', access);
    }
  },
  getAccess: () => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem('access_token');
    }
  },
  login: () => {
    api._redirect(`${api._api_url}/api/connect/auth0?callback=${window.location.origin}${window.location.pathname}`);
  },
  logout: () => {
    if (typeof window !== 'undefined') {
      window.localStorage.clear();
    }
    api._user = false;
    window.location.reload();
  },
  _getuser: async () => {
    return await fetch(`${api._api_url}/api/auth/auth0/callback?access_token=${api.getAccess()}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
      },
    }).then((r) => {
      return new Promise((resolve) => {
        if (r.status === 200) {
          resolve(r.json());
        } else {
          resolve({ error: 'unauthorized' });
        }
      });
    });
  },
  getUser: (): User | undefined => {
    if (api._user && (api._user as any).user.username) {
      return {
        username: (api._user as any).user.username,
        email: (api._user as any).user.email,
        blocked: (api._user as any).user.blocked,
        confirmed: (api._user as any).user.confirmed,
        id: (api._user as any).user.id,
        jwt: (api._user as any).jwt,
      } as User;
    } else {
      return undefined;
    }
  },
  //the inital login sequence, determines if the user is logged in, or handles the auth0 tokens
  load: async () => {
    if (api.getAccess() && api.getAccess() !== 'false') {
      await api
        ._getuser()
        .then((data) => {
          if ((data as any).user) {
            api._user = data as any;
          } else {
            api._user = false;
          }
        })
        .catch(() => {
          console.warn('unauthorized');
        });
    } else {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        if (params && params.has('id_token')) {
          api.setAccess(params.get('access_token') as string);
          await api._getuser().then((data) => {
            api._user = data as any;
            window.history.replaceState({}, document.title, `${window.location.origin}${window.location.pathname}`);
          });
        }
      }
    }
  },
  articles: async () => {
    // this url will need to have filters added to restrict articles to the site in question
    // this supports graphql style filters
    // see: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
    return await fetch(
      `${api._api_url}/api/articles?populate=*&sort=updatedAt:DESC&filters[MC_Shopify][$notContainsi]=Not-App&pagination[pageSize]=200`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${api._strapi_token}`,
        },
      },
    ).then((r) => r.json());
  },
  article: async (id: string | number) => {
    //pulls a single article, with all relations populated
    //also pulls all products from shopify that match the tag selection
    return await fetch(`${api._api_url}/api/articles/${id}?populate=*`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
      },
    }).then((r) => r.json());
  },
  products: async () => {
    //not in use yet: this endpoint returns all of the products (unfiltered) from shopify
    //this endpoint does not currently implement the same graphql filters as a standard strapi endpoint
    return await fetch(`${api._api_url}/shopify-tags/products`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
      },
    }).then((r) => r.json());
  },
  comments: async (article_id: string | number) => {
    //pulls all comments associated to an article
    //will need to be modified if comments are needed on more than articles
    return await fetch(`${api._api_url}/api/comments/api::article.article:${article_id}`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
      },
    }).then((r) => r.json());
  },
  //https://www.npmjs.com/package/strapi-plugin-comments
  //posts a comment as the logged in user
  //see docs, above, this will support nested comments if the parent_id is included
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addComment: async (article_id: string | number, content: string, parent_id: string | number | null) => {
    const user = await api._getuser();
    if (user && (user as any).user) {
      //authenticate as the user, not api
      return await fetch(`${api._api_url}/api/comments/api::article.article:${article_id}`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${(user as any).jwt}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          author: {
            id: (user as any).user.id,
            name: (user as any).user.username,
            email: (user as any).user.email,
          },
          content: content,
        }),
      }).then((r) => r.json());
    }
  },
  liveVideos: async () => {
    //pulls all comments associated to an article
    //will need to be modified if comments are needed on more than articles
    return await fetch(`${api._cms_url}api/live-video?populate=deep`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Authorization: `Bearer ${api._strapi_token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then((r) => r.json());
  },
  games: async () => {
    //pulls all comments associated to an article
    //will need to be modified if comments are needed on more than articles
    return await fetch(`${api._cms_url}api/games?populate=deep`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      // headers: {
      //   Authorization: `Bearer ${api._strapi_token}`,
      // },
    }).then((r) => r.json());
  },
};

export default api;
