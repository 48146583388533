import { CustomFeedModule } from '../../components/CustomFeed';
import { Post } from '../../components/Post/Post';
import { VideoPost } from '../../components/Post/VideoPost/VideoPost';
import { LivePost } from '../../components/Post/LivePost/LivePost';
import { Box } from "@mui/material";
import { Article } from "../../api/articles.api";
import { CustomFeed } from '../../api/customFeed.api';
import { useEffect, useState } from 'react';
import { CollectionFeed } from '../../api/collections.api';
import { FeedTypeEnum } from './feedTypes';
import { Collection } from '../../components/Collection/Collection';
import { CmsImage } from '../../api/cms.api';
import { CollectionPost } from '../../components/Post/CollectionPost/CollectionPost';

type LivePost = {
  id: number;
  title: string;
  url: CoverImage;
  featured: null | boolean;
}

interface CoverImage {
  data: CmsImage<['thumbnail']>;
}

type Props = {
  item: Article | (CustomFeed & { isCustomFeed: true }) | CollectionFeed | any;
  onLoad?(): void;
  itemRef?(el: HTMLDivElement, item: { id: number }): void
  onOpen?(item: { id: number }): void
  onPin?(item: any): void,
  pinned?: boolean,
  pinnedList?: boolean,
};

export const FeedItem = (
  props: Props
) => {
  const { item, onLoad, itemRef, onOpen, onPin, pinned, pinnedList = false } = props
  const [isPin, setIsPin] = useState(pinned ? pinned : false);
  const refFabricated = itemRef ? (el: HTMLDivElement) => itemRef(el, item) : undefined

  useEffect(() => {
    setIsPin(pinned ? pinned : false)
  }, [pinned])

  const onClick = () => {
    if (onPin) {
      setIsPin(true)
      onPin(item)
    }
  }

  if ('isCustomFeed' in item) {
    return (
      <Box sx={{ px: 2 }} ref={refFabricated}>
        <CustomFeedModule
          item={item}
          onLoad={onLoad}
        />
      </Box>
    )
  }

  const renderElement = () => {
    let element: JSX.Element = <></>
    switch (item.feedType) {
      case FeedTypeEnum.ARTICLE: {
        const authorFullName = item.author && `${item.author.First_Name} ${item.author.Last_Name}`;
        const {
          videos: [video],
          muxVideos: [muxVideo],
        } = item;
        element = (
          <>
            {muxVideo
              ?
              <VideoPost
                isMux={true}
                media={muxVideo.attributes.playback_id}
                id={item.id}
                muxId={muxVideo.id}
                title={item.title}
                user={authorFullName}
                date={item.publishedAt}
                onLoad={onLoad}
                onOpen={onOpen}
                isPin={isPin}
                onPin={onClick}
                pinnedList={pinnedList}
              />
              : video
                ?
                <VideoPost
                  key={video.id}
                  media={video.attributes.Video_Link}
                  date={item.publishedAt}
                  id={video.id}
                  title={video.attributes.Title}
                  user={authorFullName}
                  onLoad={onLoad}
                  onOpen={onOpen}
                  isPin={isPin}
                  onPin={onClick}
                  pinnedList={pinnedList}
                />
                :
                <Post
                  key={item.id}
                  title={item.title}
                  media={item.cover?.attributes?.url}
                  id={item.id}
                  date={item.publishedAt}
                  user={authorFullName}
                  onLoad={onLoad}
                  onOpen={onOpen}
                  isPin={isPin}
                  onPin={onClick}
                  pinnedList={pinnedList}
                />
            }
          </>
        )
        break;
      }
      case FeedTypeEnum.COLLECTION: {
        const {
          id,
          title,
          url,
          image,
          onLoad,
          Featured,
          videoQuantity,
        } = item
        {
          element = Featured ?
            (<CollectionPost
              id={id}
              title={title}
              url={url}
              image={image}
            />)
            :
            (<Collection
              key={id}
              id={id}
              title={title}
              articleQuantity={videoQuantity}
              videoQuantity={videoQuantity}
              url={url}
              onLoad={onLoad}
            />)
        }
        break;
      }
      case FeedTypeEnum.LIVE: {
        const {
          id,
          Title,
          Image
        } = item
        element = (
          <LivePost
            id={id}
            title={Title}
            url={Image?.data?.attributes?.url}
            pinnedList={pinnedList}
            onLoad={onLoad}
            onOpen={onOpen}
          />
        )
        break;
      }
      default:
        element = <></>
        break;
    }
    return element
  }

  return (
    <Box sx={{ px: 2 }}>
      {renderElement()}
    </Box>

  )
}