import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { InterestItem } from './InterestItem';

interface InterestProps {
  list: InterestItem[];
  onToggle?(id: string): void;
}

const Interest = ({ list, onToggle }: InterestProps) => {
  return (
    <Box sx={{ m: '0 auto', pb: 3 }}>
      <Grid container spacing={1}>
        {list.filter(item => !!item).map((item, index) => {
          return (
            <Grid item xs={4} key={index}>
              <InterestItem item={item} onToggle={onToggle} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const SliderItem = (props: { children: React.ReactNode; }) => {
  return (
    <Box>
      <Box sx={{ width: '115px', borderRadius: 2 }}>{props.children}</Box>
    </Box>
  );
};

Interest.Slider = ({ list, onToggle }: InterestProps) => {
  const settings = {
    dots: false,
    arrows: false,
    centerPadding: '200px',
    variableWidth: true,
    slidesToScroll: 1,
    infinite: false,
    draggable: true,
    touchThreshold: 100,
    swipeToSlide: true,
  };
  return (
    <Box sx={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
      <Slider {...settings}>
        {list.map((item, index) => {
          return (
            <SliderItem key={index}>
              <InterestItem item={item} onToggle={onToggle} />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};

export default Interest;
