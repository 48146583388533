import { PostProps } from "../Post";
import { useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import { Link as RLink } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import LiveIcon from "../../../img/liveIcon.svg";
import { Square } from "../../Shapes/Square/Square";

import CardMedia from '@mui/material/CardMedia';

type VideoPostProps = PostProps & {
	id: number,
	isLive?: boolean;
	feature?: boolean;
	isPin?: boolean;
	pinnedList?: boolean,
	url?: string,
	onLoad?(): void;
	onOpen?(item: { id: number }): void,
	onPin?(): void,
}

export const LivePost = ({
	id,
	title,
	url,
	onLoad,
	onOpen,
	onPin,
}: VideoPostProps) => {
	useEffect(() => onLoad?.(), [])

	return (
		<Card
			sx={{
				mx: 'auto',
				position: 'relative',
				overflow: 'inherit',
				backgroundColor: '#423C37 !important',
				borderRadius: 3,
				height: '450px',
				width: '350px'
			}}
		>
			{/* <div
				style={{
					position: 'absolute',
					top: 10,
					right: 10,
					zIndex: 100,
					padding: '10px',
					backgroundColor: "rgba(0, 0, 0, 0.45)",
					borderRadius: "50%",
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer'
				}}
			>
				<img style={{
					height: '1.5rem',
					width: '1.5rem',
				}} src={PinnedIcon} />
			</div> */}
			<div
				style={{
					position: 'absolute',
					top: 20,
					left: 30,
					zIndex: 100,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					transform: 'scale(2.2)'
				}}
				onClick={onPin}
			>
				<img style={{
					height: '1.5rem',
					width: '1.5rem',
				}} src={LiveIcon} />
			</div>
			<Link
				component={RLink}
				variant="subtitle1"
				sx={{ textDecoration: 'none' }}
				fontSize="28px"
				lineHeight="31px"
				color="#fff"
				fontFamily="BourbonW00-Regular"
				className="ga-article"
				to={`/watch-live/${id}`}
				onClick={() => onOpen?.({ id })}
			>
				<CardContent sx={{ p: 0, paddingBottom: '0 !important' }}>
					<Square>
						<Box sx={{
							position: 'relative',
							height: '450px',
							width: '350px',
						}}>
							<div style={{
								height: '450px',
								width: '350px',
								backgroundColor: 'rgba(0,0,0,0.7)',
								position: 'absolute',
								top: 0
							}} />
							<CardMedia
								component="img"
								src={url}
								alt="Content media"
								sx={{
									borderTopLeftRadius: '12px',
									borderTopRightRadius: '12px',
									borderBottomLeftRadius: '12px',
									borderBottomRightRadius: '12px',
									width: '100%',
									height: '100%',
								}}
								onLoad={onLoad}
							/>
							<Box sx={{
								position: 'absolute',
								top: '50%',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								pr: '30px',
								pl: '30px',
								textAlign: 'center'
							}}>
								<Box
									sx={{
										position: 'relative',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									{title}
									<Box sx={{
										display: 'flex',
										justifyContent: 'space-around',
										alignItems: 'center',
										pt: 1,
										mb: 3,
									}}>
									</Box>
								</Box>
							</Box>
							<Box sx={{
								position: 'absolute',
								top: '80%',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
								pr: '30px',
								pl: '30px',
								textAlign: 'center'
							}}>
								<Box sx={{
									border: '2px solid',
									borderRadius: '5px',
									backgroundColor: 'black',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									p: 1,
									width: '200px',
								}}>
									WATCH NOW
								</Box>
							</Box>
						</Box>
					</Square>
				</CardContent>
			</Link>
		</Card >
	)
};
