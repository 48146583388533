import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { Page } from "../../components/Page/Page";
import Api from "../../net/api";



export const Articles = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const getArticles = async () => {
      const res = await Api.articles();
      setArticles(res.data);
    };

    getArticles();
  }, [])
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Feed | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Articles | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Articles</title>
      </Helmet>
      <Page>
        <h1>Articles</h1>
        <ul>{articles.map(article => {
          return (
            <li>
              <Link to={`/article/${(article as any).id}`}>
                <div>{(article as any).attributes.title}</div>
                <div dangerouslySetInnerHTML={{ __html: (article as any).attributes.subtitle }}></div>
              </Link>
            </li>
          );
        })}</ul>
      </Page></>
  )
}