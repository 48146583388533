export enum FeedTypeEnum {
  COLLECTION = 'COLLECTION',
  ARTICLE = 'ARTICLE',
  CUSTOMFEED = 'CUSTOMFEED',
  LIVE = 'LIVE',
  GAMEDESCRIPTION = 'GAMEDESCRIPTION',
  GAMESPONSORS = 'GAMESPONSORS',
  BIGBONUSGAME = 'BIGBONUSGAME',
}

