import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { routes } from "../../../routes";
import { GameDescriptionData } from "../../../api/gaming.api";

const GameDescription = ({
  item,
  includeWhatsNewTitle,
}: {
  item: GameDescriptionData,
  includeWhatsNewTitle?: boolean
}) => {
  const button = (
    <Button
      fullWidth
      variant='contained'
      color='primary'
      sx={{
        width: 350
      }}
    >
      PLAY {item.Name}
    </Button>
  )

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%',
            margin: 'auto',
          }}>
          <Box
            sx={{
              marginTop: 2,
              width: 120,
              height: 120,
              border: '1px solid #E0B975',
              borderWidth: '0.5px',
              borderRadius: 2,
              padding: 1,
              'img': {
                width: '100%',
                height: '100%',
                position: 'static',
                objectFit: 'cover'
              },
            }}>
            <img
              src={item.Image?.data?.attributes?.url}
              alt={item.Image?.data?.attributes?.alternativeText}
            />
          </Box>
          <Box
            sx={{
              pt: 7,
              pb: 4,
              width: 150,
              paddingLeft: 2
            }}>
            <span
              style={{
                color: 'white',
                textTransform: 'capitalize',
                fontFamily: 'Gotham',
                fontSize: 22,
                fontWeight: 900,
                textAlign: 'left',
                paddingBottom: 2,
              }}
            >
              {item.Name}
            </span>
          </Box>
        </Box>
        {item.Description
          ? (
            <Box sx={{
              margin: 'auto',
              width: '90%'
            }}>
              <p>
                {item.Description}
              </p>
            </Box>
          )
          : <Box sx={{ height: 20 }} />
        }
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {'Url' in item && item.Name != 'Fan Favorite'
            ? (
              <a
                href={item.Url}
                target='_blank'
                rel="noreferrer"
              >
                {button}
              </a>
            )
            : (
              <Link to={routes.games.play(item.Name)}>
                {button}
              </Link>
            )
          }
        </Box>
      </Box>
      {includeWhatsNewTitle && (
        <Typography
          variant='h3'
          color='primary.main'
          align='center'
          sx={{
            pt: 3,
            flexShrink: 0
          }}>
          WHAT'S NEW
        </Typography>
      )}
    </Box>
  );
};

export default GameDescription;
