// reset row height when resizing window,
// use 50ms timeout to debounce the reset
import { useEffect } from "react";

export const useResetRowHeightOnResize = (onResize: () => void) => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined

    const listener = () => {
      if (timeout) clearTimeout(timeout)

      timeout = setTimeout(() => {
        onResize()
      }, 50)
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [])
}
