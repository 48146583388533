import logo from "../../img/logo-final.png";
import logo2 from "../../img/teton-ridge-plus-logo-2.png";
import formLogo from "../../img/form-logo.png";
import comingSoonText from "../../img/coming-soon-text.png";

import cls from "./Logo.module.css";

export const Logo2 = () => {
  return <div className={cls["logo"]} style={{ backgroundImage: `url(${logo2})`, transform: "scale(0.8)" }} />;
};

export const Logo = () => {
  return <div className={cls["logo"]} style={{ backgroundImage: `url(${logo})` }} />;
};

export const FormLog = () => {
  return <div className={cls["form-logo"]} style={{ backgroundImage: `url(${formLogo})` }} />;
};

export const ComingSoonText = () => {
  return <div className={cls["logo"]} style={{ backgroundImage: `url(${comingSoonText})` }} />;
};
