import Axios from 'axios';
import { configure } from 'axios-hooks';
import { API_BASE_URL } from '../config/constants';

const JSON_HEADER = 'application/json';

const REQ_TIMEOUT = 10 * 60 * 1000; // 10 minutes

const instance = Axios.create({
  baseURL: API_BASE_URL,
  timeout: REQ_TIMEOUT,
});

let authToken: string | undefined;

export const setRequestAuthToken = (token: string) => {
  authToken = `Bearer ${token}`;
}

export const removeRequestAuthToken = () => {
  authToken = undefined;
}

instance.defaults.headers.common['Accept'] = JSON_HEADER;
instance.defaults.headers.common['Content-Type'] = JSON_HEADER;

instance.interceptors.request.use(
  (config) => {
    if (authToken) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = authToken;
    }

    return config;
  },
  (error) => {
    console.log('****** API Request Error ******', error);
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error instanceof Axios.Cancel && !error.message) {
      error.message = 'Request cancelled';
    }
    return Promise.reject(error);
  },
);

configure({ axios: instance });

export default instance;
