import IconButton from '@mui/material/IconButton';
import { Box, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { TimesIcon } from '../../components/Icons';
import cls from './Dashboard.module.css';
import logoImg from '../../img/title-logo.png';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.primary.main,
    height: '20px',
    width: '20px',
  },
}));

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

interface Props {
  notification?: boolean;
  settings?: boolean;
  logo?: boolean;
}

const DashboardHeader = ({ logo = true }: Props) => {
  const navigate = useNavigate();

  // const onPage = (name: string) => () => {
  //   navigate(`/${name}`);
  // };

  const onBack = () => {
    if (window?.history?.state?.usr?.previousPath === "/edit-profile") {
      navigate("/feed");
    } else {
      // when opening dashboard directly by the link, history length is 2
      // in this case we redirect to feed to prevent leaving the site
      // When opening dashboard not logged in, after logged and trying to close the dashboard, usr state is null
      if (window.history.length <= 2 || window?.history?.state?.usr === null) {
        navigate('/feed')
      } else {
        navigate(-1);
      }
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '50px',
      }}
    >
      <CustomIconButton aria-label="remove" onClick={onBack} className={cls['important']}>
        <TimesIcon />
      </CustomIconButton>
      {logo && (<div className={cls['container']}>
        <Logo>
          <img src={logoImg} alt="Logo" />
        </Logo></div>
      )
      }
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end' }}>
        {/* {notification && (
          <CustomIconButton aria-label="notification" onClick={onPage('notifications')}>
            <NotificationIcon />
          </CustomIconButton>
        )}
        {settings && (
          <CustomIconButton aria-label="settings" onClick={onPage('settings')}>
            <SettingsIcon />
          </CustomIconButton>
        )} */}
      </Box>
    </Box >
  );
};

export default DashboardHeader;
