import { InputLabel, SelectChangeEvent, Typography } from '@mui/material';
import { v1 as uuid } from 'uuid';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';

import { Horse } from '../../../features/Auth/SignUpFlow/AthleteSetup/CompetitionBackgroundSetupPage';
import { UseFormReturn } from 'react-hook-form';
import { FormSelectField } from '../../../components/Form/FormSelector';
import { FormTextField } from '../../../components/Form/FormTextField';
import cls from '../../../features/Auth/SignUpFlow/AthleteSetup/AthleteSetup.module.css';
import { getYearsRange } from '../../../utils';
import plusSvg from '../../../img/svgs/add.svg'
import trashSvg from '../../../img/svgs/trash.svg'

type CompetitionScreenProps = {
  setHorses: React.Dispatch<React.SetStateAction<Horse[]>>;
  horses: Horse[];
  form: UseFormReturn<any, any>
}

const YEARS = getYearsRange(1970);
const PLACEHOLDER = 'Enter answer here'

const BackgroundEditScreen = ({ form, horses, setHorses }: CompetitionScreenProps) => {
  const newHorse = () => ({
    name: '',
    age: 0,
    refId: uuid(),
  });

  const handleChange = (value: string | number, refId: string | undefined, key: string, ind: number) => {
    const element = horses.find((h) => h.refId === refId);
    if (element) {
      element[key as keyof Horse] = value;
      setHorses((prev: Horse[]) => {
        const ret = prev;
        ret[ind] = element;
        return [...ret];
      });
    }
  };

  const handleChangeMultiSelect = (key: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string[]>) => {
    const { target: { value } } = e;
    form.setValue(key as any, typeof value === 'string' ? value.split(',') : value);
  }

  return (
    <>
      <div className={cls['athlete-container']}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>NFR qualifications</InputLabel>
            <FormSelectField fullWidth id="user-qualifications" multiple form={form} name='nfrQualificationsYear' values={YEARS} customOnChange={e => handleChangeMultiSelect('nfrQualificationsYear', e)} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>World titles</InputLabel>
            <FormSelectField fullWidth id="user-world-titles" multiple form={form} name='worldTitlesYear' values={YEARS} customOnChange={e => handleChangeMultiSelect('worldTitlesYear', e)} />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Biggest Rodeo Win</InputLabel>
            <FormTextField id="user-rodeo_win" fullWidth form={form} name='biggestRodeoWin' />
          </Grid>
        </Grid>

        <Typography className={cls['vitals']} align="left">Your fastest time or highest score</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel sx={{ fontWeight: '700' }}>When</InputLabel>
            <FormSelectField fullWidth id="user-hight_score_year" form={form} name='highestScoreYear' values={YEARS} />
          </Grid>
          <Grid item xs={6}>
            <InputLabel sx={{ fontWeight: '700' }}>Where</InputLabel>
            <FormTextField fullWidth id="user-hight_score_loc" form={form} name='highestScoreLoc' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Your sponsors</InputLabel>
            <FormTextField id="user-sponsors" placeholder='Name 1, name 2, name 3' fullWidth form={form} name='sponsors' />
          </Grid>
        </Grid>

        <div className={cls['line']} />
        <Typography className={cls['vitals']} align="left">Your Horses</Typography>
        <Grid container spacing={2}>
          {horses.length && horses.map(({ name, age, refId }, index) =>
            <>
              <Grid item xs={12}>
                <InputLabel sx={{ fontWeight: '700' }}>Name</InputLabel>
                <FormTextField fullWidth id="user-horse-name" placeholder="Name" value={name} customOnChange={(e) => handleChange(e.target.value, refId, 'name', index)} />
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={{ fontWeight: '700' }}>Age</InputLabel>
                <FormTextField type='number' fullWidth id="user-horse-name" placeholder="1" value={age} customOnChange={(e) => handleChange(Number(e.target.value) || '', refId, 'age', index)} />
              </Grid>
              {index !== 0 && <div className={cls['trash-container']} onClick={() => { setHorses(prev => prev.filter(h => h.refId !== refId)) }}> <img src={trashSvg} /></div>}
              <div className={cls['line-less']} />
            </>
          )}
          <div className={cls['add-container']} onClick={() => { setHorses(prev => [...prev, newHorse()]) }}>
            <img src={plusSvg} alt='add' />
            <InputLabel sx={{ fontWeight: '700', margin: '8px 5px 0px 10px', width: 150 }}>Add Horse</InputLabel>
          </div>
        </Grid>

        <div className={cls['line']} />
        <Typography className={cls['vitals']} align="left">Horses and Bulls</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Favorite to draw</InputLabel>
            <FormTextField fullWidth id="user-horse_draw" placeholder="Enter answer here" form={form} name='favoriteHorseDraw' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Rankest</InputLabel>
            <FormTextField fullWidth id="user-horse-rankest" placeholder="Enter answer here" form={form} name='rankestHorse' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Scariest</InputLabel>
            <FormTextField fullWidth id="user-horse-scariest" placeholder="Enter answer here" form={form} name='scariestHorse' />
          </Grid>
        </Grid>

        <div className={cls['line']} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Current traveling partners</InputLabel>
            <FormTextField fullWidth id="user-traveling-partners" placeholder="Name 1, name 2, name 3, name 4" form={form} name='travellingPartners' />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ fontWeight: '700' }}>Rodeo rig</InputLabel>
            <FormTextField fullWidth id="user-rodeo-rig" placeholder="Enter answer here" form={form} name='rodeoRig' />
          </Grid>
        </Grid>

        <div className={cls['line']} />
        <Grid item xs={12}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>Why you picked your chosen event in rodeo?</InputLabel>
            <FormTextField
              fullWidth
              sx={{
                borderRadius: '6px',
                overflow: 'scroll',
                justifyContent: 'flex-start'
              }}
              multiline
              form={form}
              name='qstSuccessfulEvent'
              id='qstSuccessfulEvent'
              placeholder={PLACEHOLDER}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ pt: 2 }}>
            <InputLabel>What other event in Rodeo do you think you could be successful at if you haven’t tried?</InputLabel>
            <FormTextField
              fullWidth
              sx={{
                borderRadius: '6px',
                overflow: 'scroll',
                justifyContent: 'flex-start'
              }}
              multiline
              form={form}
              name='qstEventRodeo'
              id='qstEventRodeo'
              placeholder={PLACEHOLDER}
            />
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default BackgroundEditScreen;