import { Typography, Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { RightArrowIcon } from '../../components/Icons';

interface Props {
  children: React.ReactNode;
}

const SliderItem = (props: Props) => {
  return <Box>{props.children}</Box>;
};

interface ItemProps {
  name: string;
  date: string;
  media: string;
  place: string;
  link: string;
}

const EventItem = (props: ItemProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'darkPallet.darker',
        width: '360px',
        borderRadius: '3px',
        p: 1,
        m: 1,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '100px',
          position: 'relative',
          mr: 1,
          '& img': { height: '100%', width: '100%', position: 'static', objectFit: 'cover' },
        }}
      >
        <img src={props.media} alt={props.name} />
      </Box>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography variant="h3" textTransform={'uppercase'} color="primary">
          {props.name}
        </Typography>
        <Typography>
          <Typography variant="overline" display={'block'}>
            {props.date}
          </Typography>
          <Typography variant="overline" display={'block'}>
            {props.place}
          </Typography>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Link
          component={RLink}
          to={props.link}
          sx={{
            '& svg': { height: '20px', width: '20px', fill: (theme) => theme.palette.primary.main },
          }}
        >
          <RightArrowIcon />
        </Link>
      </Box>
    </Box>
  );
};

const EventSlider = ({ events }: { events: any }) => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };

  return (
    <Box sx={{ marginRight: '-2px' }}>
      <Slider {...settings}>
        {events.map((item: ItemProps, index: number) => {
          return (
            <SliderItem key={index}>
              <EventItem {...item} />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};
export default EventSlider;
