import { Helmet } from "react-helmet";
import { ErrorPage } from "../../pages/ErrorPage/ErrorPage";

export const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Teton Ridge+</title>
      </Helmet>
      <ErrorPage status={404} message='Oops! This page no longer exists' showRetry={false} />
    </>
  )
}