import { Box, Typography } from '@mui/material';

const GameSponsors = ({ item }: { item: any }) => {
  return (
    <Box>
      {item?.GameSponsors?.length &&
        <div style={{
          marginTop: 35
        }}>
          <Typography
            variant='h3'
            color='primary.main'
            align='center'
          >
            SPONSORS
          </Typography>
          <div style={{
            display: 'flex',
            width: '100%',
            height: 230,
            marginTop: 30,
            flexDirection: 'row',
          }}>
            <div style={{
              overflowX: 'auto',
              scrollbarWidth: 'none',
            }}>
              <div style={{
                width: 'max-content',
                display: 'flex',
                gap: 10
              }}>
                {item?.GameSponsors?.map((game: any) => (
                  <div style={{
                    display: 'flex',
                    backgroundColor: '#423C37',
                    borderRadius: '8px',
                    padding: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }
                  }>
                    <img
                      onClick={() => window.open(game?.Url)}
                      width={'200px'}
                      src={game?.GameSponsor?.data?.attributes?.url}
                      alt={game?.GameSponsor?.data?.attributes?.alternativeText}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      }
    </Box >
  );
};

export default GameSponsors;
