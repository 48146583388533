import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BookmarkIcon } from '../Icons';
import { BookmarkButton } from '../Buttons';

const Bookmark = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '30px',
  height: '30px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > svg': {
    height: '15px',
    width: '15px',
    fill: theme.palette.lightPallet.lighter,
  },
}));

interface ProductProps {
  media?: string;
  price: string;
  oldPrice?: string;
  title: string;
  category?: string;
  light?: boolean;
}

const ProductPost = ({ media, title, oldPrice, price, category, light = false }: ProductProps) => {
  return (
    <Card sx={{ backgroundColor: 'darkPallet.dark' }}>
      <CardContent sx={{ p: 1, paddingBottom: '5px !important' }}>
        <CardMedia component="img" image={media} alt="Product media" />
        {light ? (
          <Typography variant="subtitle1" fontWeight="700" fontSize="12px" sx={{ pt: 1 }}>
            {title}
          </Typography>
        ) : (
          <Typography
            variant="subtitle1"
            fontWeight="700"
            fontSize="20px"
            color="primary.dark"
            sx={{ pt: 1, pb: 2 }}
            fontFamily="BourbonW00-Regular"
          >
            {title}
          </Typography>
        )}
        <Box sx={{ display: 'flex', position: 'relative', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <Typography variant="subtitle1" fontSize="12px" fontWeight="700">
              {price}{' '}
              {oldPrice && (
                <Typography
                  variant="caption"
                  fontSize="16px"
                  color="grey.600"
                  sx={{ textDecoration: 'line-through', pl: 1 }}
                >
                  {oldPrice}
                </Typography>
              )}
            </Typography>
            <Typography color="grey.400" fontSize="12px">
              {category}
            </Typography>
          </Box>
          <Box>
            {light ? (
              <Bookmark>
                <BookmarkIcon />
              </Bookmark>
            ) : (
              <BookmarkButton />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductPost;
