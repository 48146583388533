import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Link } from '@mui/material';
import { Link as RLink, } from 'react-router-dom';
import { useEffect } from 'react';
// import { Square } from "../Shapes/Square/Square";

export type CollectionProps = {
  id: number;
  title: string
  videoQuantity: number;
  articleQuantity: number;
  url?: string
  onOpen?: (item: { id: number }) => void
  onLoad?: () => void
}

window.addEventListener('resize', resize);

function resize() {
  if (window.innerWidth > 700) {
    return '650px';
  } else {
    return '100%'
  }
}

function height() {
  if (window.innerWidth > 600) {
    return '500px !important';
  }
  else {
    return '450px !important'
  }
}

export const Collection = ({ id, onOpen, onLoad, ...props }: CollectionProps) => {

  useEffect(() => {
    onLoad?.();
  }, [])

  return (
    <Card
      sx={{
        mx: 'auto',
        position: 'relative',
        overflow: 'inherit',
        backgroundColor: '#423C37 !important',
        borderRadius: 3,
        width: resize(),
        height: height()
      }}
    >
      <Link
        component={RLink}
        variant="subtitle1"
        fontSize="28px"
        lineHeight="31px"
        color="primary.main"
        fontFamily="BourbonW00-Regular"
        to={`/collections/${id}`}
        onClick={() => {
          onOpen?.({ id })
        }}
      >
        <CardContent sx={{ p: 0, paddingBottom: '0px !important', height: '100%' }}>
          {/* <Square> */}
          <Box
            sx={{
              height: '100%',
              borderRadius: '12px',
              padding: '1.75rem',
              display: 'flex',
              flexDirection: 'column',
              background: props.url ? `no-repeat url(${props.url})` : 'inherit',
              backgroundSize: 'cover'
            }}
          >
            <Box
              sx={{
                height: '100%',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white'
              }}
            >
              {props.title}
              <Typography variant="body2" color="white" fontWeight={'600'} fontSize={'12px'}>
                {props.articleQuantity ? <>{props.articleQuantity} {`${props.articleQuantity > 1 ? 'ARTICLES' : 'ARTICLE'}`}</> : ''}
                {props.articleQuantity && props.videoQuantity ? ' - ' : ''}
                {props.videoQuantity ? <>{props.videoQuantity} {`${props.articleQuantity > 1 ? 'VIDEOS' : 'VIDEO'}`}</> : ''}
              </Typography>
            </Box>
            <Button variant="outlined" fullWidth={true}>VIEW COLLECTION</Button>
          </Box>
          {/* </Square> */}
        </CardContent>
      </Link>
    </Card>
  );
};
