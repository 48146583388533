import { Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page/Page';
import Fixedbox from '../../components/Fixedbox';
import { OffWifiIcon } from '../../components/Icons';
import { styled } from '@mui/system';
import pageBg from '../../img/home-page-bg.png';
import { Helmet } from 'react-helmet';

const Icon = styled(Box)(({ theme }) => ({
  width: '45px',
  height: '45px',
  margin: '20px auto',
  '& svg': {
    stroke: theme.palette.primary.main,
    width: '50px',
    height: '50px',
  },
}));

const ConnectionLost = () => {
  const navigate = useNavigate();

  const onPage = () => () => {
    navigate(0);
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />

      </Helmet>
      <Page style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
            backgroundImage: `url(${pageBg})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }} />
        <Box>
          <Icon>
            <OffWifiIcon />
          </Icon>
          <Typography variant="h2" align="center" color="primary.main">
            Connection lost!
          </Typography>
          <Typography fontWeight="700" align="center" sx={{ pt: 2 }} textTransform="uppercase">
            Please try again.
          </Typography>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage}>
              Reload
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default ConnectionLost;
