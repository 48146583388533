import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { useLocation, useNavigate } from "react-router-dom";
import { v1 as uuid } from 'uuid';
import { z } from 'zod';

import { CurrentUser } from "../../api/auth.api";
import Fixedbox from '../../components/Fixedbox';
import { FormTextField } from "../../components/Form/FormTextField";
import { DeleteIcon } from '../../components/Icons';
import { ImagePicker } from '../../components/ImagePicker/ImagePicker';
import { Page } from '../../components/Page/Page';
import { DEFAULT_LOGO } from '../../utils/images';
import { useZodForm } from "../../utils/useZodForm";
import cls from './Profile.module.css';
import DashboardHeader from '../Dashboard/DashboardHeader';
import { Helmet } from 'react-helmet';
import { useUpdateAthleteMutation } from "../../api/athletes.api";
import { useUpdateUserMutation } from "../../api/users.api";
import { checkIsAthlete } from "../../features/User/user.utils";
import { useState } from 'react';
import { Horse } from '../../features/Auth/SignUpFlow/AthleteSetup/CompetitionBackgroundSetupPage';
import BackgroundEditScreen from './EditAthleteScreens/BackgroundEditScreen';
import VitalsEditScreen from './EditAthleteScreens/VitalsEditScreen';
import HobbiesEditScreen from './EditAthleteScreens/HobbiesEditScreen';

const VALID_USER_FIELDS = ['firstName', 'lastName', 'email', 'profileImage'];

const schema = z.object({
  firstName: z.string({ required_error: 'First name is required' }).min(3, { message: "First name must contain at least 3 character(s)" }),
  lastName: z.string({ required_error: 'Last name is required' }).min(3, { message: "Last name must contain at least 3 character(s)" }),
  email: z.string({ required_error: 'Email is required' }).email(),
  profileImage: z.string(),
  bio: z.optional(z.string().max(1500)),
  preferredName: z.string().max(100),
  heightFeet: z.optional(z.number()),
  heightInches: z.optional(z.number()),
  weight: z.optional(z.number()),
  isoCountry: z.optional(z.string().max(100)),
  city: z.optional(z.string().max(100)),
  state: z.optional(z.string()),
  location: z.optional(z.string().max(100)),
  status: z.enum(['single', 'married']),
  partnerName: z.optional(z.string().max(100)),
  children: z.optional(z.number()),
  motherName: z.optional(z.string().max(100)),
  fatherName: z.optional(z.string().max(100)),
  college: z.optional(z.string().max(100)),
  degree: z.optional(z.string().max(100)),
  nfrQualificationsYear: z.optional(z.array(z.string().max(100))),
  worldTitlesYear: z.optional(z.array(z.string().max(100))),
  biggestRodeoWin: z.optional(z.string().max(250)),
  highestScoreYear: z.optional(z.string().max(100)),
  highestScoreLoc: z.optional(z.string().max(100)),
  sponsors: z.optional(z.string().max(100)),
  favoriteHorseDraw: z.optional(z.string().max(100)),
  rankestHorse: z.optional(z.string().max(100)),
  scariestHorse: z.optional(z.string().max(100)),
  travellingPartners: z.optional(z.string().max(100)),
  rodeoRig: z.optional(z.string().max(100)),
  qstEventRodeo: z.optional(z.string().max(250)),
  qstSuccessfulEvent: z.optional(z.string().max(250)),
  qstOccupation: z.optional(z.string().max(250)),
  favoriteFood: z.optional(z.string().max(100)),
  favoriteBeverage: z.optional(z.string().max(100)),
  favoriteMovie: z.optional(z.string().max(100)),
  favoriteSeries: z.optional(z.string().max(100)),
  favoriteSong: z.optional(z.string().max(100)),
  favoriteSportTeam: z.optional(z.string().max(100)),
  favoriteAthlete: z.optional(z.string().max(100)),
  favoriteQuote: z.optional(z.string().max(250)),
});

type Props = {
  user: CurrentUser
}

const ATHLETE_SCREENS = {
  VITALS: 'vitals',
  BACKGROUND: 'background',
  HOBBIES: 'hobbies'
}

const EditUserProfile = ({ user }: Props) => {
  const form = useZodForm(schema, {
    defaultValues: {
      email: user.email,
      profileImage: user.profileImage,
      firstName: user.firstName,
      lastName: user.lastName,
      bio: user.athleteProfile?.bio || '',
      preferredName: user.athleteProfile?.preferredName || '',
      heightFeet: user.athleteProfile?.heightFeet || 0,
      heightInches: user.athleteProfile?.heightInches || 0,
      weight: user.athleteProfile?.weight || undefined,
      isoCountry: user.athleteProfile?.isoCountry || 'US',
      city: user.athleteProfile?.city || '',
      state: user.athleteProfile?.state || '',
      location: user.athleteProfile?.location || '',
      status: user.athleteProfile?.status as "single" | "married" || 'single',
      partnerName: user.athleteProfile?.partnerName || '',
      children: user.athleteProfile?.children || 0,
      motherName: user.athleteProfile?.motherName || '',
      fatherName: user.athleteProfile?.fatherName || '',
      college: user.athleteProfile?.college || '',
      degree: user.athleteProfile?.degree || '',
      nfrQualificationsYear: user.athleteProfile?.nfrQualificationsYear || [],
      worldTitlesYear: user.athleteProfile?.worldTitlesYear || [],
      biggestRodeoWin: user.athleteProfile?.biggestRodeoWin || '',
      highestScoreYear: user.athleteProfile?.highestScoreYear || '',
      highestScoreLoc: user.athleteProfile?.highestScoreLoc || '',
      sponsors: user.athleteProfile?.sponsors || '',
      favoriteHorseDraw: user.athleteProfile?.favoriteHorseDraw || '',
      rankestHorse: user.athleteProfile?.rankestHorse || '',
      scariestHorse: user.athleteProfile?.scariestHorse || '',
      travellingPartners: user.athleteProfile?.travellingPartners || '',
      rodeoRig: user.athleteProfile?.rodeoRig || '',
      qstEventRodeo: user.athleteProfile?.qstEventRodeo || '',
      qstSuccessfulEvent: user.athleteProfile?.qstSuccessfulEvent || '',
      qstOccupation: user.athleteProfile?.qstOccupation || '',
      favoriteFood: user.athleteProfile?.favoriteFood || '',
      favoriteBeverage: user.athleteProfile?.favoriteBeverage || '',
      favoriteMovie: user.athleteProfile?.favoriteMovie || '',
      favoriteSeries: user.athleteProfile?.favoriteSeries || '',
      favoriteSong: user.athleteProfile?.favoriteSong || '',
      favoriteSportTeam: user.athleteProfile?.favoriteSportTeam || '',
      favoriteAthlete: user.athleteProfile?.favoriteAthlete || '',
      favoriteQuote: user.athleteProfile?.favoriteQuote || '',
    }
  });


  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAthlete = checkIsAthlete(user);
  const [selectedAthleteScreen, setSelectedAthleteScreen] = useState('vitals');
  const [horses, setHorses] = useState<Horse[]>(user.athleteProfile?.horses?.length ? user.athleteProfile?.horses.map(h => ({ ...h, refId: uuid() })) : [{
    name: '',
    age: 0,
    refId: uuid(),
  }])

  const onSuccess = () => {
    navigate('/profile', { state: { previousPath: pathname } });
  }

  const { mutate: updateAthlete } = useUpdateAthleteMutation({
    onSuccess,
  })

  const { mutate: updateUser } = useUpdateUserMutation({
    onSuccess,
  })

  const submit = async () => {
    const userToEdit: any = form.getValues();
    const notAthleteFields = VALID_USER_FIELDS.reduce((acc: any, value) => { acc[value] = userToEdit[value]; return acc; }, {});
    isAthlete ? updateAthlete({ uuid: user.uuid, ...userToEdit, horses }) : updateUser({ uuid: user.uuid, ...notAthleteFields })
  };

  const hasErrors = () => Object.keys(form.formState.errors)?.length > 0

  const getStyles = (selected = false) => {
    const hasError = hasErrors();
    let ret = {};
    if (selected) {
      ret = { color: 'white', borderBottom: `2px solid ${hasError ? '#f44336' : '#E0B975'}` }
    } else if (hasError) ret = { borderBottom: `2px solid #f44336` };
    return ret;
  }

  const renderAthleteEditScreen = () => {
    switch (selectedAthleteScreen) {
      case ATHLETE_SCREENS.VITALS: {
        return <VitalsEditScreen form={form} />;
      }
      case ATHLETE_SCREENS.HOBBIES: {
        return <HobbiesEditScreen form={form} />
      }
      case ATHLETE_SCREENS.BACKGROUND: {
        return <BackgroundEditScreen horses={horses} setHorses={setHorses} form={form} />;
      }
      default:
        return <></>;
    }
  }

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Edit Profile | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Edit Profile | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Edit Profile</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{}}>
        <DashboardHeader />
        <Typography variant="h3" align="center" color="primary.main">
          EDIT YOUR PROFILE
        </Typography>
        <Typography
          variant="subtitle2"
          align="center"
          textTransform="uppercase"
          fontWeight="500"
          sx={{ pr: 5, pl: 5, pt: 2, pb: 3 }}
        >
          Start exploring the world of Teton Ridge
        </Typography>
        <div className={cls['profile-media-section']}>
          <div className={cls['profile-picture-container']}>
            {form.watch('profileImage') !== DEFAULT_LOGO && <span className={cls['trash-icon']} onClick={() => form.setValue('profileImage', DEFAULT_LOGO)}>
              <DeleteIcon />
            </span>}
            <div className={cls['media']}>
              {form.watch('profileImage') && <img src={form.watch('profileImage')} alt="Profile" />}
            </div>
          </div>
          <ImagePicker label="Update Profile Picture" form={form} name="profileImage" />
          <Typography color="grey.400" className={cls['image-size']}>
            Max: 3MB (.png,.jpg)
            <br/>
            Use a portrait style photo for the best look.
          </Typography>
        </div>
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>First Name</InputLabel>
              <FormTextField fullWidth id="user-first-name" placeholder="Teton" form={form} name='firstName' requiredChar={true} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Last Name</InputLabel>
              <FormTextField fullWidth id="user-last-name" placeholder="Ridge" form={form} name='lastName' requiredChar={true} />
            </Grid>
            {isAthlete && <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Preferred Name</InputLabel>
              <FormTextField requiredChar={true} id="user-preferred-name" fullWidth placeholder="TR" form={form} name='preferredName' />
            </Grid>}
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Email Address</InputLabel>
              <FormTextField disabled type="email" id="user-email-id" fullWidth placeholder="howdy@emailaddress.com" form={form} name='email' />
            </Grid>
            {isAthlete && <> <Grid item xs={12}>
              <Box sx={{ pt: 2 }}>
                <InputLabel>Bio</InputLabel>
                <FormTextField
                  fullWidth
                  sx={{
                    borderRadius: '6px',
                    overflow: 'scroll',
                    justifyContent: 'flex-start'
                  }}
                  multiline
                  id='bio'
                  form={form}
                  name='bio'
                  placeholder='Enter answer here'
                />
              </Box>
            </Grid>
              <div className={cls['athlete-label-container']}>
                <div className={cls['athlete-label']}
                  onClick={() => setSelectedAthleteScreen(ATHLETE_SCREENS.VITALS)}
                  style={getStyles(selectedAthleteScreen === ATHLETE_SCREENS.VITALS)}>Your vitals</div>
                <div className={cls['athlete-label']}
                  onClick={() => setSelectedAthleteScreen(ATHLETE_SCREENS.BACKGROUND)}
                  style={getStyles(selectedAthleteScreen === ATHLETE_SCREENS.BACKGROUND)}>Background</div>
                <div className={cls['athlete-label']}
                  onClick={() => setSelectedAthleteScreen(ATHLETE_SCREENS.HOBBIES)}
                  style={getStyles(selectedAthleteScreen === ATHLETE_SCREENS.HOBBIES)}>Hobbies</div>
              </div>
              {renderAthleteEditScreen()}
            </>}
          </Grid>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button type='submit' variant="contained" color="primary" fullWidth>
              Update
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EditUserProfile;
