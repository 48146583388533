import { Alert, Box, Button, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  interestImages,
  useInterestsQuery,
  useUpdateUserInterests,
  useUserInterestsQuery
} from '../../api/interests.api';
import Interest from '../../components/Interest';
import { InterestItem } from '../../components/Interest/InterestItem';
import SectionTitle from '../../components/SectionTitle';

const DashboardTab = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [showMinError, setShowMinError] = useState(false);
  const [interestsWithSelect, setInterestsWithSelect] = useState<InterestItem[]>([]);
  const { data: interests } = useInterestsQuery();
  const { data: userInterests } = useUserInterestsQuery();
  const toggleInterest = (uuid: string) => {
    setIsEditing(true);
    setShowMinError(false);
    setInterestsWithSelect(prevState => prevState.map(item => item.uuid === uuid ? ({ ...item, selected: !item.selected }) : item));
  };

  const { mutate: updateInterests } = useUpdateUserInterests()

  const submit = async () => {
    updateInterests({
      uuids: interestsWithSelect.filter(item => item.selected).map(item => item.uuid)
    })
    setIsEditing(false);
  };

  useEffect(() => {
    setInterestsWithSelect(interests && interests.length && interests.map((item) => {
      const userInterest = userInterests?.find((userInterest) => userInterest.InterestUUID === item.uuid);
      const imageUrl = interestImages[item.iconUrl as keyof typeof interestImages];

      return userInterest ? ({ ...item, selected: true, imageUrl }) : ({ ...item, imageUrl });
    }) || []);
  }, [userInterests, interests]);

  return (
    <Box sx={{ mb: 5 }}>
      {showMinError && (
        <Container maxWidth='xs'>
          <Box mb={4}>
            <Alert severity="error">Please select at least 3</Alert>
          </Box>
        </Container>
      )}
      <SectionTitle title="MY INTERESTS" hasLine />
      <Interest list={interestsWithSelect || []} onToggle={toggleInterest} />
      {
        isEditing
        && (
          <div style={{ height: '100px', marginTop: '20px' }}>
            <Button disabled={false} variant="contained" color="primary" fullWidth onClick={submit}>
              Update
            </Button>
          </div>
        )
      }
      {/* <SectionTitle title="YOU MAY LIKE" hasLine />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LatestArticle />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ pt: 1 }} />
          <LatestVideo />
        </Grid>
      </Box> */}
      {/* <SectionTitle title="EXPLORE TETON RIDGE" hasLine />
      <ExploreLogos /> */}
    </Box>
  );
};

export default DashboardTab;
