import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { _get } from '../../../net/net';
import { sortTags } from '../../../utils/sort';
import ContentStandings from './ContentStandings';
import { Layout } from "../../../components/Layout/Layout";

const ICON_BULL_RIDING = 'https://cdn.tetonridge.io/ICON_BULL_RIDING_ca7676c090.png?updated_at=2022-12-16T19:57:49.864Z';
const ICON_BREAKAWAY = 'https://cdn.tetonridge.io/ICON_BREAKAWAY_d3952fd87d.png?updated_at=2022-11-23T23:49:53.739Z';
const ICON_CUTTING = 'https://cdn.tetonridge.io/ICON_CUTTING_997c975e36.png?updated_at=2022-11-23T23:49:53.802Z';
const ICON_TEAM_ROPING =
  'https://cdn.tetonridge.io/ICON_TEAM_ROPING_61440c44c4.png?updated_at=2022-11-23T23:49:53.822Z';
const ICON_BARREL_RACE =
  'https://cdn.tetonridge.io/ICON_BARREL_RACE_15ee3d3258.png?updated_at=2022-11-23T23:49:53.271Z';
const ICON_BARBACK_RIDING =
  'https://cdn.tetonridge.io/ICON_BARBACK_RIDING_906b87ec3c.png?updated_at=2022-11-23T23:49:53.417Z';
const ICON_SADDLE_BRONC =
  'https://cdn.tetonridge.io/ICON_SADDLE_BRONC_40c13098a4.png?updated_at=2022-11-23T23:49:53.714Z';
const ICON_REINED_COW_HORSE =
  'https://cdn.tetonridge.io/ICON_REINED_COW_HORSE_b36427823a.png?updated_at=2022-11-23T23:49:53.841Z';
const ICON_REINING = 'https://cdn.tetonridge.io/ICON_REINING_93c9046469.png?updated_at=2022-11-23T23:49:53.961Z';
const ICON_TIE_DOWN = 'https://cdn.tetonridge.io/ICON_TIE_DOWN_8d02b4d948.png?updated_at=2022-11-23T23:49:53.997Z';
const ICON_STEER_WRESTLING =
  'https://cdn.tetonridge.io/ICON_STEER_WRESTLING_f8b99e92ea.png?updated_at=2022-11-23T23:49:54.138Z';

interface StandingData {
  data: Post[];
  meta: {
    pagination: {
      page: number;
      pageCount: number;
      pageSize: number;
      total: number;
    }
  };
}
interface Post {
  id: number;
  attributes: PostAttributes;
}
interface PostAttributes {
  publishedAt: string;
  MC_Shopify: string[];
  Standing_Title: string;
  Standing_Image: CoverImage;
  author?: {
    data?: {
      attributes: {
        First_Name: string;
      };
    };
  };
}

interface CoverImage {
  data: CoverImageData;
}

interface CoverImageData {
  id: number;
  attributes: CoverImageAttributes;
}

interface CoverImageAttributes {
  alternativeText: string;
  url: string;
}

interface Category {
  category: string;
  id: number;
  tags: Interest[];
}

export interface Interest {
  name: string;
  title: string;
  uuid: string;
  selected: boolean;
}

const categoriesList = [
  {
    category: 'Rodeo',
    id: 1,
    tags: [
      {
        name: 'Bull-Riding',
        title: 'Bull-Riding',
        uuid: '001',
        iconUrl: ICON_BULL_RIDING,
        selected: false,
      },
      {
        name: 'Bareback',
        title: 'Bareback',
        uuid: '002',
        iconUrl: ICON_BARBACK_RIDING,
        selected: false,
      },
      {
        name: 'Saddle-Bronc',
        title: 'Saddle-Bronc',
        uuid: '003',
        iconUrl: ICON_SADDLE_BRONC,
        selected: false,
      },
      {
        name: 'Barrel-Racing',
        title: 'Barrel-Racing',
        uuid: '004',
        iconUrl: ICON_BARREL_RACE,
        selected: false,
      },
      {
        name: 'Breakaway',
        title: 'Breakaway',
        uuid: '005',
        iconUrl: ICON_BREAKAWAY,
        selected: false,
      },
      {
        name: 'Tie-Down',
        title: 'Tie-Down',
        uuid: '006',
        iconUrl: ICON_TIE_DOWN,
        selected: false,
      },
      {
        name: 'Steer-Wrestling',
        title: 'Steer-Wrestling',
        uuid: '007',
        iconUrl: ICON_STEER_WRESTLING,
        selected: false,
      },
      {
        name: 'Team-Roping',
        title: 'Team-Roping',
        uuid: '008',
        iconUrl: ICON_TEAM_ROPING,
        selected: false,
      }
    ]

  },
  {
    category: 'Equine',
    id: 2,
    tags: [
      {
        name: 'Reining',
        title: 'Reining',
        uuid: '009',
        iconUrl: ICON_REINING,
        selected: false,
      },
      {
        name: 'Cutting',
        title: 'Cutting',
        uuid: '010',
        iconUrl: ICON_CUTTING,
        selected: false,
      },
      {
        name: 'Reined-Cow-Horse',
        title: 'Reined-Cow-Horse',
        uuid: '011',
        iconUrl: ICON_REINED_COW_HORSE,
        selected: false,
      },
      // {
      //   name: 'Quarter-Mile-Race',
      //   title: 'Quarter Mile Race',
      //   id: '012',
      //   selected: false,
      // },
    ]
  }

];

export const Standings = () => {
  const perPage = 25;
  const [value] = useState(0);
  const [standings, setStandings] = useState<Post[]>([]);
  const [filteredStandings, setFilteredStandings] = useState<Post[]>([]);
  const [filterTags, setFilterTags] = useState<Interest[]>([]);
  const [page, setPage] = useState(1);
  const [allLoaded, setAllLoaded] = useState(false);
  useEffect(() => {
    if (navigator.cookieEnabled) {
      localStorage.removeItem("filterTags")
      localStorage.removeItem("athleteFilters")
    }
  }, [])
  const fetchData = async () => {
    _get(`${process.env.REACT_APP_CMS_BASE_URL}api/standings?populate=*&sort=updatedAt:DESC&pagination[page]=${page}&pagination[pageSize]=${perPage}&pagination[withCount]=true`).then((response: StandingData) => {
      if (response.meta.pagination.pageCount === page) {
        setAllLoaded(true);
      }
      let newFilteredStadings: Post[] = [];
      if (filterTags.some((interest: Interest) => interest.selected)) {
        newFilteredStadings = filterByTags(response.data);
      } else {
        newFilteredStadings = response.data
      }
      setStandings([...standings, ...response.data]);
      setFilteredStandings([...filteredStandings, ...newFilteredStadings]);
      setPage(page + 1);
    });
  }

  useEffect(() => {
    setAllTags();
    fetchData();
  }, []);

  const onScroll = (el: HTMLDivElement) => {
    const { scrollTop, scrollHeight, clientHeight } = el;
    if (scrollTop + clientHeight === scrollHeight) {
      if (!allLoaded) {
        fetchData();
      }
    }
  }

  useEffect(() => {
    if (
      filterTags.some((tag: Interest) => {
        return tag.selected;
      })
    ) {
      setFilteredStandings(filterByTags(standings));
    } else {
      if (value === 0) {
        setFilteredStandings(standings);
      } else {
        filterByCategoryTags(filterTags, standings).then(newArticles => {
          setFilteredStandings(newArticles);
        });
      }
    }
  }, [filterTags]);

  const filterByTags = (posts: Post[]) => {
    let newPosts = posts;
    newPosts = newPosts.filter((post: Post) => {
      return filterTags.some((tag: Interest) => {
        return post.attributes.MC_Shopify ? post.attributes.MC_Shopify.includes(tag.name) && tag.selected : false;
      });
    });
    return newPosts;
  };

  const onToggle = (uuid: string): any => {
    let newFilterTags = filterTags;
    newFilterTags = newFilterTags.map((tag: Interest) => {
      if (tag.uuid === uuid) {
        return { ...tag, selected: !tag.selected };
      } else {
        return { ...tag };
      }
    });
    setFilterTags(newFilterTags);
    return null;
  };

  const setAllTags = () => {
    const allTags = categoriesList.map((category: Category) => {
      return category.tags;
    }).flat();
    setFilterTags(sortTags(allTags));
  };

  const filterByCategoryTags = async (tags: Interest[], posts: Post[]) => {
    let newPosts = posts;
    newPosts = newPosts.filter((post: Post) => {
      return tags.some((tag: Interest) => {
        return post.attributes.MC_Shopify ? post.attributes.MC_Shopify.includes(tag.name) : false;
      });
    });
    return newPosts;
  };

  return (
    <Layout>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Standings | Teton Ridge+`} />
        <meta name="twitter:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

        <meta property="og:title" content="Standings | Teton Ridge+" />
        <meta property="og:description" content="Check the latest standings for each event. See how your favorite athlete is stacking up to the competition" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
        <title>Results</title>
      </Helmet>

      <ContentStandings
        standings={filteredStandings}
        tags={filterTags}
        onToggle={onToggle}
        onScroll={onScroll}
      />
    </Layout>
  );
};
