import { Box } from '@mui/system';
import { EventMap } from '../../components/Map';
import eventImg from '../../img/map-event-img.png';
import EventSlider from './EventSlider';

const locations = [
  {
    latLng: { lat: 47.606209, lng: -122.332069 },
    name: 'Houston Livestock and Rodeo',
    date: 'Jan 8 - Jan 11',
    place: 'ATT Stadium • Arlington, tx',
    media: eventImg,
    link: '/event/1',
  },
  {
    latLng: { lat: 47.6089, lng: -122.3383 },
    name: 'Houston Livestock and Rodeo',
    date: 'Jan 8 - Jan 11',
    place: 'ATT Stadium • Arlington, tx',
    active: true,
    media: eventImg,
    link: '/event/1',
  },
  {
    latLng: { lat: 47.6094, lng: -122.3403 },
    name: 'Houston Livestock and Rodeo',
    date: 'Jan 8 - Jan 11',
    place: 'ATT Stadium • Arlington, tx',
    media: eventImg,
    link: '/event/1',
  },
  {
    latLng: { lat: 47.6098, lng: -122.3384 },
    name: 'Houston Livestock and Rodeo',
    date: 'Jan 8 - Jan 11',
    active: true,
    place: 'ATT Stadium • Arlington, tx',
    media: eventImg,
    link: '/event/1',
  },
];

const MapView = () => {
  return (
    <Box sx={{ ml: '-1rem', mr: '-1rem', position: 'relative' }}>
      <EventMap locations={locations} height="620px" />
      <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
        <EventSlider events={locations} />
      </Box>
    </Box>
  );
};
export default MapView;
