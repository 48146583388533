export enum FeedTypeEnum {
  COLLECTION = 'COLLECTION',
  ARTICLE = 'ARTICLE',
  CUSTOMFEED = 'CUSTOMFEED',
  LIVE = 'LIVE',
}

export interface FeedBase {
  feedType: FeedTypeEnum;
}
