import { createContext, Dispatch, type SetStateAction } from "react";
import { User } from "../definitions";

type UseState<T> = [T, Dispatch<SetStateAction<T>>]

const noop = () => {};

export const UserContext = createContext<User | null>(null);
UserContext.displayName = "UserContext";

export const AppErrorContext = createContext<UseState<Error | null>>([ null, noop ]);
AppErrorContext.displayName = "AppErrorContext";