import axios from 'axios';

// export const api = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
//   headers: {
//     Accept: "*/*",
//     "Content-Type": "application/json",
//   },
// });


export const postData = (endpoint: string, options: any) => axios.post(endpoint, options);

// export const post = async (endpoint: string, options = {}) => {
//   const { data } = await api.post(endpoint, options);
//   return data;
// };

// export default api;