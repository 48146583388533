import { Box } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import ProductPost from '../Post/ProductPost';

const SliderItem = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box sx={{ backgroundColor: '#423C37', width: '320px', m: 1, borderRadius: 2 }}>{props.children}</Box>
    </Box>
  );
};

const ProductSlider = ({ list }: { list: any }) => {
  const settings = { dots: false, arrows: false, centerPadding: '100px', variableWidth: true, slidesToShow: 1 };
  return (
    <Box sx={{ pb: 3, marginLeft: '-15px', marginRight: '-15px' }}>
      <Slider {...settings}>
        {list.map(
          (
            item: { title: string; price: string; oldPrice?: string; media?: string; category?: string },
            index: number,
          ) => {
            return (
              <SliderItem key={index}>
                <ProductPost {...item} />
              </SliderItem>
            );
          },
        )}
      </Slider>
    </Box>
  );
};

export default ProductSlider;
