import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Box, Typography, Button, Link } from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import { styled } from '@mui/system';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { RightArrowIcon } from '../Icons';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    fill: theme.palette.lightPallet.light,
    height: '16px',
    width: '16px',
  },
}));

interface EventProps {
  media: string;
  location?: string;
  title: string;
  date: string;
  link?: string;
}

const EventPost = ({
  media,
  location,
  title,
  date,
  link
}: EventProps) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ backgroundColor: '#423C37', p: 1 }}>
        <CardMedia component="img" image={media} alt="Event media" />

        <Typography variant="overline" display="block" fontSize="11px" sx={{ pt: 3 }}>
          {date} . {location}
        </Typography>
        <Box sx={{ display: 'flex', justifyContents: 'space-between', pb: 2 }}>
          <Typography variant="h3" sx={{ pb: 1, pt: 1, flex: 1 }} color="primary">
            {title}
          </Typography>
          <Link
            component={RLink}
            to={link || '/'}
            sx={{ '& svg': { height: '15px', width: '15px', fill: (theme) => theme.palette.lightPallet.lighter } }}
          >
            <RightArrowIcon />
          </Link>
        </Box>
        <Button variant="outlined" fullWidth style={{
          maxWidth: 400,
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}>
          View Results
        </Button>
      </CardContent>
    </Card>
  );
};

interface UpcomingEventProps {
  logo?: string;
  price: string;
  startPrice?: string;
  slots: number;
  registrationAvailable?: string;
  location?: string;
  title: string;
  date: string;
  link?: string;
  media: string;
}

EventPost.Upcoming = ({
  title,
  location,
  date,
  media,
  price,
  startPrice,
  slots,
  registrationAvailable,
  link,
}: UpcomingEventProps) => (
  <Card sx={{ mb: 2 }}>
    <CardContent>
      <Typography sx={{ pb: 2 }} textTransform="uppercase" fontWeight="700">
        {date}
      </Typography>
      <CardMedia component="img" image={media} alt="Content media" />
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            flex: 1,
            pt: 1,
          }}
        >
          <Typography variant="h3" color="primary" textTransform="uppercase" sx={{ pb: 1 }}>
            {title}
          </Typography>
          {location && (
            <Typography variant="caption" textTransform="uppercase">
              {location}
            </Typography>
          )}
        </Box>
        <Box>
          <Link
            component={RLink}
            to={link || '/'}
            sx={{ '& svg': { height: '15px', width: '15px', fill: (theme) => theme.palette.lightPallet.lighter } }}
          >
            <RightArrowIcon />
          </Link>
        </Box>
      </Box>
    </CardContent>

    <CardContent>
      <Typography fontWeight="700">
        Payout: {price} • {slots} Slots Open
      </Typography>
      {registrationAvailable && (
        <Typography variant="caption" textTransform="uppercase">
          Available registration from {registrationAvailable}
        </Typography>
      )}
      <Button variant="outlined" fullWidth sx={{
        mt: 2,
        backgroundColor: 'transparent',
        textTransform: 'uppercase',
        maxWidth: 400,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }} >
        Register to Compete
      </Button>
    </CardContent>

    <CardContent>
      <Typography variant="body2" sx={{ pb: 2 }}>
        Tickets start at {startPrice}
      </Typography>
      <Button variant="contained" fullWidth sx={{
        textTransform: 'uppercase',
        maxWidth: 400,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
        Buy Ticket To Attend
      </Button>
    </CardContent>
  </Card>
);

interface FutureEventProps extends EventProps {
  price: string;
  slots: string;
  ticketAvilable: boolean;
  registrationAvilable: boolean;
}

EventPost.FutureEvent = (props: FutureEventProps) => {
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent sx={{ backgroundColor: '#423C37', p: 1 }}>
        <CardMedia component="img" image={props.media} alt="Event media" />
        <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
          <Chip label="Ticket Available" color="success" variant="outlined" sx={{ borderRadius: '5px' }} />
          <Chip label="Registration Available" color="success" variant="outlined" sx={{ borderRadius: '5px' }} />
        </Stack>

        <Typography variant="overline" display="block" fontSize="11px" sx={{ pt: 3 }}>
          {props.date} . {props.location}
        </Typography>
        <Box sx={{ display: 'flex', justifyContents: 'space-between' }}>
          <Typography variant="h3" sx={{ pb: 1, pt: 1, flex: 1 }} color="primary">
            {props.title}
          </Typography>
          <CustomIconButton>
            <RightArrowIcon />
          </CustomIconButton>
        </Box>
        <Divider light sx={{ mt: 2, mb: 2 }} />
        <Typography variant="subtitle2" sx={{ pb: 2 }}>
          Payout: {props.price} . {props.slots} Slots Open
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button variant="outlined" sx={{ flex: 1, mr: 1 }}>
            Register
          </Button>
          <Button variant="contained" sx={{
            flex: 1,
            maxWidth: 400,
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}>
            Buy Ticket
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventPost;
