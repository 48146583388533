import { styled } from "@mui/system";

export const SectionTitle = styled('div')(({theme}) => ({
  color: theme.palette.primary.dark,
  fontFamily: 'BourbonW00-Regular',
  fontSize: 20,
  textAlign: 'center',
  padding: '16px 0 8px',
  background: 'var(--charcoal)',
}))

export const Table = styled('table')(() => ({
  whiteSpace: 'nowrap',
  background: '#423C37',
  color: 'white',
  minWidth: '100%',
  borderSpacing: 0,
}))

export const HeaderCell = styled('th')(() => ({
  height: '46px',
  padding: '0 12px',
  borderBottom: '1px solid #6B6B6B',
}))

export const FirstColumn = styled('th')(({theme}) => ({
  textAlign: 'left',
  borderRight: '3px solid #2D2926',
  padding: '0 24px',
  borderBottom: '1px solid #6B6B6B',
  fontWeight: 'normal',
  [theme.breakpoints.up('sm')]: {
    position: 'sticky',
    left: 0,
    background: '#423C37',
  },
}))

export const Cell = styled('td')(() => ({
  borderBottom: '1px solid #6B6B6B',
  height: 56,
  textAlign: 'center',
}))

export const Position = styled('span')(({theme}) => ({
  color: theme.palette.primary.dark,
}))
