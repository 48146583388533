import {
  Typography,
  ButtonGroup,
  Button,
  Divider,
  TextField,
  InputLabel,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, Box } from '@mui/system';
import { Page } from '../../components/Page/Page';
import Header, { CustomIconButton } from '../../components/Header';
import { MapIcon, EventIcon, MinusIcon, PlusIcon } from '../../components/Icons';
import Fixedbox from '../../components/Fixedbox';
import { Helmet } from 'react-helmet';

const CustomIcon = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.darkPallet.light,
  borderColor: theme.palette.darkPallet.light,
  color: theme.palette.lightPallet.lighter,
  '& svg': {
    fill: theme.palette.lightPallet.lighter,
    height: '20px',
    width: '20px',
  },
  '&:hover': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.light,
  },
  '&:active': {
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.light,
  },
  '&:focus': {
    boxShadow: 'none',
    borderColor: 'transparent',
    backgroundColor: theme.palette.darkPallet.light,
  },
}));

const cartItemList = [
  {
    name: 'Barrel Racing',
    time: 'Jan 8th, 2022 at 3pm',
    price: '$250.00',
  },
  {
    name: 'Barrel Racing',
    time: 'Jan 8th, 2022 at 3pm',
    price: '$250.00',
  },
];

interface CartItemProps {
  name: string;
  time: string;
  price: string;
}

const CartItem = (props: CartItemProps) => {
  return (
    <Box
      sx={{
        backgroundColor: 'darkPallet.main',
        borderRadius: '6px',
        mb: 2,
        p: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '138px',
          height: '136px',
          backgroundColor: '#434343',
          display: 'block',
          borderRadius: '3px',
          mr: 1,
        }}
      >
        {/* image */}
      </Box>
      <Box>
        <Typography variant="h3" color="primary.main" sx={{ pb: 1 }}>
          {props.name}
        </Typography>
        <Typography variant="caption" display="block">
          {props.time}
        </Typography>
        <Typography fontWeight="700">{props.price}</Typography>
        <Box sx={{ mt: 1 }}>
          <ButtonGroup sx={{ display: 'flex' }}>
            <CustomIcon fullWidth>
              <MinusIcon />
            </CustomIcon>
            <CustomIcon fullWidth>2</CustomIcon>
            <CustomIcon fullWidth>
              <PlusIcon />
            </CustomIcon>
          </ButtonGroup>
        </Box>
      </Box>
    </Box>
  );
};

const EventCheckout = () => {
  const navigate = useNavigate();

  const onPage = (link: string) => () => {
    navigate(link);
  };
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Event Checkout | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Event Checkout</title>
      </Helmet>
      <Page>
        <Header
          RightComponent={
            <>
              <CustomIconButton>
                <MapIcon />
              </CustomIconButton>
              <CustomIconButton>
                <EventIcon />
              </CustomIconButton>
            </>
          }
        />
        <Typography variant="h3" color="primary.main" textTransform="uppercase" sx={{ mt: 5 }}>
          American BreakAway Roping
        </Typography>
        <Typography textTransform="uppercase">Jan 8 - Jan 11 Austin, TX</Typography>
        <Box sx={{ mt: 5, mb: 5 }}>
          {cartItemList.map((item, index) => (
            <CartItem {...item} key={index} />
          ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight="700">Fees</Typography>
          <Typography fontWeight="700">$20</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography fontWeight="700">Total</Typography>
          <Typography fontWeight="700">$220</Typography>
        </Box>
        <Divider sx={{ mt: 5, mb: 5 }} />
        <Box>
          <InputLabel>Cardholder Name</InputLabel>
          <TextField fullWidth placeholder="Name" />
        </Box>
        <Box sx={{ pt: 2 }}>
          <InputLabel>Card Number</InputLabel>
          <TextField fullWidth placeholder="Card number" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>CVV</InputLabel>
              <TextField fullWidth placeholder="CCV" />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ pt: 2 }}>
              <InputLabel>Expiry</InputLabel>
              <TextField fullWidth placeholder="MM/DD" />
            </Box>
          </Grid>
        </Grid>
        <FormGroup sx={{ mt: 3, mb: 3 }}>
          <FormControlLabel control={<Checkbox />} label="Save card for express checkout" />
        </FormGroup>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button variant="contained" color="primary" fullWidth onClick={onPage('/event-thankyou')}>
              Pay
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default EventCheckout;
