import { Box, Typography, Link, Divider } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';
import { DownArrowIcon } from '../Icons';
import dayjs from "dayjs";

interface Props {
  logo: string;
  title: string;
  list: any;
}

const InterestCircle = styled(Box)(({ theme }) => ({
  height: '62px',
  width: '62px',
  backgroundColor: theme.palette.primary.main,
  border: '2px solid transparent',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'border 0.3s ease-in 0s',
  position: 'relative',
  textAlign: 'center',
  marginBottom: '5px',
  '& svg': {
    height: '20px',
    fill: theme.palette.lightPallet.lighter,
  },
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const EventList = (props: Props) => {
  const [showItems, setShowItems] = useState(5);

  const onShowMore = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowItems(showItems + 5);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '6px',
        minHeight: '100px',
        pt: 3,
        mt: 5,
        backgroundColor: (theme) => theme.palette.darkPallet.light,
      }}
    >
      <Box sx={{ zIndex: 99, pl: 2, mt: '-60px', position: 'relative' }}>
        <InterestCircle>
          <img src={props.logo} alt={props.title} />
        </InterestCircle>
        <Typography variant="h3" color="primary.main">
          {props.title}
        </Typography>
      </Box>
      <Box>
        {props.list.slice(0, showItems).map((item: { name: string; place: string; startAt: string }, index: number) => {
          return (
            <Box key={index}>
              <Box sx={{ p: 2, pt: 3 }}>
                <Typography variant="subtitle2" fontWeight="700" sx={{ pb: 1 }}>
                  {item.name}
                </Typography>
                <Typography variant="caption" display="block">
                  {dayjs(item.startAt).format('MMM Do')}
                </Typography>
              </Box>
              {showItems !== index + 1 && <Divider />}
            </Box>
          );
        })}
      </Box>
      {props.list.length > showItems && (
        <Box>
          <Link
            onClick={onShowMore}
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 3,
              justifyContent: 'center',
              textDecoration: 'none',
              '& svg': {
                width: '15px',
                height: '15px',
                marginLeft: '5px',
                fill: (theme) => theme.palette.primary.main,
              },
            }}
          >
            Show More <DownArrowIcon />
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default EventList;
