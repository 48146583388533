import Grid from '@mui/material/Grid';
import { Typography, Box, styled } from '@mui/material';

const PointWrapper = styled(Box)(({ theme }) => ({
  padding: '5px',
  height: 90,
  display: 'flex',
  backgroundColor: theme.palette.darkPallet.main,
  border: `2px solid ${theme.palette.primary.main}`,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '4px',
  backgroundImage: 'linear-gradient(180deg, #423C37 0%, #000000 100%)',
}));

const ProfilePoints = () => {
  return (
    <Grid container spacing={2} sx={{ mb: 3 }}>
      <Grid item xs={12}>
        <PointWrapper>
          <Typography
            variant="h3"
            align="center"
            color="primary.main"
            textTransform="uppercase"
            sx={{ pb: 1, display: 'flex', alignItems: 'center' }}
          >
            <Typography variant="caption" color="lightPallet.lighter" fontFamily="BourbonW00-Regular" fontSize="22px">
              Qualifing Events Limit:
            </Typography>
            5
          </Typography>
        </PointWrapper>
      </Grid>
      <Grid item xs={6}>
        <PointWrapper>
          <Typography variant="h3" align="center" color="primary.main" sx={{ pb: 1 }}>
            18+
          </Typography>
          <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="700">
            Horse Age
          </Typography>
        </PointWrapper>
      </Grid>
      <Grid item xs={6}>
        <PointWrapper>
          <Typography variant="h3" align="center" color="primary.main" sx={{ pb: 1 }}>
            5-10
          </Typography>
          <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="700">
            Horse Age
          </Typography>
        </PointWrapper>
      </Grid>
      <Grid item xs={6}>
        <PointWrapper>
          <Typography variant="h3" align="center" color="primary.main" sx={{ pb: 1 }}>
            BBR
          </Typography>
          <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="700">
            Membership
          </Typography>
        </PointWrapper>
      </Grid>
      <Grid item xs={6}>
        <PointWrapper>
          <Typography variant="h3" align="center" color="primary.main" sx={{ pb: 1 }}>
            Mens
          </Typography>
          <Typography variant="caption" align="center" textTransform="uppercase" fontWeight="700">
            Athlete Type
          </Typography>
        </PointWrapper>
      </Grid>
    </Grid>
  );
};

export default ProfilePoints;
