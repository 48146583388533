import { buildApiInfiniteQueryHook, buildApiMutationHook, buildApiQueryHook } from '../utils/api.utils';
import qs from 'qs';
import cmsRequest from '../utils/cmsRequest';
import { CmsImage, CmsPaginated } from './cms.api';
import { EventGameDetail } from "../features/Games/GameDetail/GameDetailEvent";
import { FeedTypeEnum } from "../features/Games/gameTypes";
import { ArticleFromApi } from "./articles.api";
import axios from "axios";

type GamingArticleFromApi = Omit<ArticleFromApi, 'attributes'> & {
  attributes: Omit<ArticleFromApi['attributes'], 'video_posts'> & {
    gaming_video_posts: ArticleFromApi['attributes']['video_posts']
  };
};

export type GamingArticle = { id: number } & Omit<
  GamingArticleFromApi['attributes'],
  'cover_media' | 'author' | 'video_posts' | 'mux_video_uploader_mux_assets'
> & {
    cover: GamingArticleFromApi['attributes']['cover_media']['data'];
    author?: Author;
    videos: GamingArticleFromApi['attributes']['gaming_video_posts']['data'];
    muxVideos: GamingArticleFromApi['attributes']['mux_video_uploader_mux_assets']['data'];
    Featured?: GamingArticleFromApi['attributes']['Featured'];
  } & { feedType: FeedTypeEnum.ARTICLE };

export type GameDescriptionData = {
  id: number;
  Description: EventGameDetail['attributes']['Description'];
  Name: EventGameDetail['attributes']['Name'];
  Url: EventGameDetail['attributes']['Url'];
  Image: EventGameDetail['attributes']['Image'];
  Sponsors: EventGameDetail['attributes']['GameSponsors'];
  BonusGame: EventGameDetail['attributes']['BigBonusGame'];
  feedType: FeedTypeEnum.GAMEDESCRIPTION;
};

const mapArticle = (item: GamingArticleFromApi): GamingArticle => ({
  id: item.id,
  ...item.attributes,
  cover: item.attributes.cover_media.data,
  author: item.attributes.author.data && mapAuthor(item.attributes.author.data),
  videos: item.attributes.gaming_video_posts.data,
  muxVideos: item.attributes.mux_video_uploader_mux_assets.data,
  feedType: FeedTypeEnum.ARTICLE,
  Featured: item.attributes.Featured,
});

const mapGameDescription = (item: EventGameDetail): GameDescriptionData => ({
  id: item.id,
  ...item.attributes,
  Image: item.attributes.Image,
  Sponsors: item.attributes.GameSponsors,
  BonusGame: item.attributes.BigBonusGame,
  feedType: FeedTypeEnum.GAMEDESCRIPTION,
});

export type VideoPost = {
  id: number;
  attributes: GamingArticleFromApi['attributes'] & {
    Title?: string;
    Cover_Image?: CmsImage<never>;
    MC_Shopify: string[];
    publishedAt: string;
    Video_Link?: string;
  };
};

export type Mux = {
  id: number;
  attributes: {
    asset_id: string;
    playback_id: string;
    title: string;
  };
};

type AuthorFromApi = {
  id: number;
  attributes: {
    First_Name: string;
    Last_Name: string;
  };
};

export type Author = { id: number } & AuthorFromApi['attributes'];

const mapAuthor = (item: AuthorFromApi): Author => ({
  id: item.id,
  ...item.attributes,
});

export const useGamingPostsInfiniteQuery = buildApiInfiniteQueryHook<
  {
    limit: number;
    filterParams: { selected: boolean; name: string }[];
  },
  CmsPaginated<GamingArticleFromApi>,
  GamingArticle[]
>(
  'gaming-posts',
  ({ limit, pageParam, filterParams }) =>
    cmsRequest.get('/gaming-posts', {
      params: {
        pagination: {
          limit,
          start: (pageParam - 1) * limit,
        },
        populate: '*',
        sort: 'publishedAt:DESC',
        filters: filterParams.some((interest) => interest.selected)
          ? {
              $or: filterParams
                .filter((interest) => interest.selected)
                .map((interest) => ({ MC_Shopify: { $containsi: interest.name } })),
              MC_Shopify: {
                $notContainsi: 'Not-App',
              },
            }
          : {
              $or: filterParams.map((interest) => ({ MC_Shopify: { $containsi: interest.name } })),
              MC_Shopify: {
                $notContainsi: 'Not-App',
              },
            },
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }),
  {
    keepPreviousData: true,
    select: (data) => data.data.map(mapArticle),
  },
);
export const useGameQuery = buildApiQueryHook<
  {
    game: string;
  },
  CmsPaginated<EventGameDetail>,
  GameDescriptionData
>(
  'games',
  (filterParams) =>
    cmsRequest.get('/games', {
      params: {
        filters: {
          Name: {
            $eq: filterParams.game,
          },
        },
        populate: 'deep',
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }),
  {
    keepPreviousData: true,
    select: (data) => {
      const first = data.data[0]
      return first && mapGameDescription(first)
    },
  },
);

export const useGamingQuery = buildApiQueryHook<{ id: number }, { data: GamingArticleFromApi }, GamingArticle>(
  'gaming-post',
  ({ id }) => cmsRequest.get(`/gaming-posts/${id}?populate=*`),
  {
    select: ({ data }) => mapArticle(data),
  },
);

export const useFanFavoriteGameGetTokenQuery = buildApiMutationHook<
  { email: string },
  { token: string }
>(
  ({ email }) => axios.post(process.env.REACT_APP_FAN_FAVORITE_GAME_API_URL as string, {
    email
  }).then(res => res.data)
)
