import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import useWindowDimensions from '../../hooks/useWindowDimentions';

interface VideoCardProps {
  media: string;
}

const VideoCard = (props: VideoCardProps) => {
  const { media } = props;
  const { width } = useWindowDimensions();
  return (
    <Card
      sx={{ mb: 2, position: 'relative', mt: 3, overflow: 'inherit', backgroundColor: '#423C37', borderRadius: 3, mx: 'auto', width: (width > 700) ? '600px' : '100%' }}
    >
      <CardContent sx={{ pb: 1 }}>
        <Box sx={{
          height: '0',
          overflow: 'hidden',
          paddingBottom: '56.25%',
          paddingTop: '30px',
          position: 'relative',
          width: '100%'
        }}>
          <CardMedia
            component="iframe"
            image={media}
            height='auto'
            width='100%'
            frameBorder='0'
            allowFullScreen
            sx={{
              height: '100%',
              left: '0',
              position: 'absolute',
              top: '0',
              width: '100%'
            }} />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '10px',
            width: '100%',
            pr: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
        </Box>
      </CardContent>
    </Card>
  );
};

export default VideoCard;
