import { Button } from '@mui/material';
import { styled } from '@mui/system';
import { BookmarkIcon } from '../Icons';

interface BookmarkProps {
  height?: string;
  width?: string;
  iconHeight?: string;
  iconWidth?: string;
  iconbgcolor?: string;
}

const Bookmark = styled(Button)<BookmarkProps>(
  ({ height = '40px', width = '100%', iconHeight = '15px', iconWidth = '15px', iconbgcolor, theme }) => ({
    width: width,
    height: height,
    minWidth: 'auto',
    padding: '0',
    borderRadius: '100%',
    maxWidth: 400,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      height: iconHeight,
      width: iconWidth,
      fill: theme.palette.darkPallet.darker || iconbgcolor,
    },
  }),
);

const BookmarkButton = (props: any) => (
  <Bookmark {...props} variant={props?.variant || 'contained'}>
    <BookmarkIcon />
  </Bookmark>
);

export { BookmarkButton };
