import {Box, styled} from "@mui/system";
import { useEffect, useState } from "react";
import { CurrentUser } from "../../../api/auth.api";
import { useInterestsQuery } from "../../../api/interests.api";
import { useAthletesInfiniteQuery } from "../../../api/users.api";
import InterestSlider from "../../../pages/Feeds/InterestSlider";
import { AthleteCard } from "./AthleteCard";
import { InfiniteListV2 } from "../../../components/InfiniteList/InfiniteListV2";
import useWindowDimensions from "../../../hooks/useWindowDimentions";

interface Interest {
  name: string;
  title: string;
  uuid: string;
  selected: boolean;
  iconUrl: string;
}

const CardOuter = styled('div')(() => ({
  position: 'relative',
  overflow: 'hidden',
  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '129%',
  },
}));

const CardInner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))

export const AthletesListPage = ({ user }: { user: CurrentUser; }) => {
  const perPage = 25;
  const [allLoaded, setAllLoaded] = useState(false);
  const { data: interests } = useInterestsQuery();
  const [filterTags, setFilterTags] = useState<Interest[]>([]);

  const { width } = useWindowDimensions()
  const columnsCount = width > 1500 ? 4 : width > 1100 ? 3 : 2

  useEffect(() => {
    const filters = navigator.cookieEnabled && localStorage?.getItem("athleteFilters");
    if (filters && JSON.parse(filters).length > 0) {
      setFilterTags(JSON.parse(filters))
    }
    else if (interests) {
      setFilterTags((interests as Interest[]).map((interest) => ({ ...interest, name: interest.name.replace(" ", "-"), title: interest.name.replace(" ", "-"), selected: false })));
    }
  }, [interests])

  const filters = filterTags.map((tag) => tag.selected ? { name: tag.name, uuid: tag.uuid } : null).filter((tag) => tag !== null);
  const { data, isFetchingNextPage, fetchNextPage } = useAthletesInfiniteQuery({
    limit: perPage,
    filters: filters.map(tag => tag?.uuid || '')
  }, {
    onSuccess(data) {
      const lastPage = data.pages[data.pages.length - 1];
      if (lastPage.length < perPage) {
        setAllLoaded(true);
      }
    }
  });

  if (!data || !data.pages) return <></>;

  const items = data.pages.flat();

  const toggleFilterTag = (uuid: string): any => {
    if (navigator.cookieEnabled) {
      const filters = filterTags.map((tag) => tag.uuid === uuid
        ? { ...tag, selected: !tag.selected }
        : tag
      )
      localStorage?.setItem("athleteFilters", JSON.stringify(filters))
    }
    setFilterTags((tags) =>
      tags.map((tag) =>
        tag.uuid === uuid
          ? { ...tag, selected: !tag.selected }
          : tag
      )
    )
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 1, height: '100%', flexDirection: 'column' }}>
        <Box sx={{ px: 2, flexShrink: 0 }}>
          <InterestSlider tags={filterTags} onToggle={toggleFilterTag} />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <InfiniteListV2
            ids={items.map((item) => item.id)}
            items={items.slice(0, Math.ceil(items.length / columnsCount))}
            render={({ index: row }) => {
              const index = row * columnsCount
              return (
                <Box display='flex' gap={1}>
                  {items.slice(index, index + columnsCount).map((item) => (
                    <CardOuter key={item.id} style={{ width: `${100 / columnsCount}%` }}>
                      <CardInner>
                        <AthleteCard item={item} user={user} row={row} />
                      </CardInner>
                    </CardOuter>
                  ))}
                </Box>
              )
            }}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={!allLoaded}
          />
        </Box>
      </Box>
    </>
  );
};
