import React from 'react'
import { styled } from "@mui/system"

const Outer = styled('div')(() => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'block',
    paddingBottom: '100%',
  },
}));

const Inner = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}))

export const Square = ({ children }: { children: React.ReactNode }) => {
  return (
    <Outer>
      <Inner>{children}</Inner>
    </Outer>
  )
}
