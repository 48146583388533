const string = (key: string, value: unknown) => {
  if (!value || typeof value !== 'string') {
    throw new Error(`${key} must be set in .env file`);
  }
  return value;
};

export const AUTH0_DOMAIN = string('REACT_APP_AUTH0_DOMAIN', process.env.REACT_APP_AUTH0_DOMAIN);
export const AUTH0_CLIENT_ID = string('REACT_APP_AUTH0_CLIENT_ID', process.env.REACT_APP_AUTH0_CLIENT_ID);
export const AUTH0_AUDIENCE = string('REACT_APP_AUTH0_AUDIENCE', process.env.REACT_APP_AUTH0_AUDIENCE);
export const AUTH0_REDIRECT_URL = window.location.origin;

export const GOOGLE_MAP_API_KEY = string('REACT_APP_GOOGLE_MAP_API_KEY', process.env.REACT_APP_GOOGLE_MAP_API_KEY);
export const APOLLO_CLIENT_BASE_URI = '';

export const TWILIO_APP_API_BASE_URL = string(
  'REACT_APP_TWILIO_APP_API_BASE_URL',
  process.env.REACT_APP_TWILIO_APP_API_BASE_URL,
);

export const API_BASE_URL = string('REACT_APP_API_BASE_URL', process.env.REACT_APP_API_BASE_URL);
export const CMS_BASE_URL = string('REACT_APP_CMS_BASE_URL', process.env.REACT_APP_CMS_BASE_URL);

export const ENABLE_STANDINGS = process.env.REACT_APP_ENABLE_STANDINGS === 'true'
