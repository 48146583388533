import { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Article,
  useArticlesInfiniteQuery,
  useGetLivePostQuery,
  usePinPostInfiniteQuery,
} from '../../api/articles.api';
import { CollectionFeed, useCollectionFeedQuery } from '../../api/collections.api';
import { Layout } from '../../components/Layout/Layout';
import { FeedItem } from './FeedItem';
// import InterestSlider from './InterestSlider';
import { Box } from "@mui/material";
import { CustomFeed, useCustomFeedQuery } from "../../api/customFeed.api";
import { LoadingScreen } from "../../components/Loading/LoadingScreen";
import { FeedTypeEnum } from "./feedTypes";
import { InfiniteListV2 } from "../../components/InfiniteList/InfiniteListV2";
import { PinnedPosts } from "./PinnedPosts";

const ICON_BULL_RIDING = 'https://cdn.tetonridge.io/ICON_BULL_RIDING_ca7676c090.png?updated_at=2022-12-16T19:57:49.864Z';
const ICON_BREAKAWAY = 'https://cdn.tetonridge.io/ICON_BREAKAWAY_d3952fd87d.png?updated_at=2022-11-23T23:49:53.739Z';
const ICON_CUTTING = 'https://cdn.tetonridge.io/ICON_CUTTING_997c975e36.png?updated_at=2022-11-23T23:49:53.802Z';
const ICON_TEAM_ROPING =
  'https://cdn.tetonridge.io/ICON_TEAM_ROPING_61440c44c4.png?updated_at=2022-11-23T23:49:53.822Z';
const ICON_BARREL_RACE =
  'https://cdn.tetonridge.io/ICON_BARREL_RACE_15ee3d3258.png?updated_at=2022-11-23T23:49:53.271Z';
const ICON_BARBACK_RIDING =
  'https://cdn.tetonridge.io/ICON_BARBACK_RIDING_906b87ec3c.png?updated_at=2022-11-23T23:49:53.417Z';
const ICON_SADDLE_BRONC =
  'https://cdn.tetonridge.io/ICON_SADDLE_BRONC_40c13098a4.png?updated_at=2022-11-23T23:49:53.714Z';
const ICON_REINED_COW_HORSE =
  'https://cdn.tetonridge.io/ICON_REINED_COW_HORSE_b36427823a.png?updated_at=2022-11-23T23:49:53.841Z';
const ICON_REINING = 'https://cdn.tetonridge.io/ICON_REINING_93c9046469.png?updated_at=2022-11-23T23:49:53.961Z';
const ICON_TIE_DOWN = 'https://cdn.tetonridge.io/ICON_TIE_DOWN_8d02b4d948.png?updated_at=2022-11-23T23:49:53.997Z';
const ICON_STEER_WRESTLING =
  'https://cdn.tetonridge.io/ICON_STEER_WRESTLING_f8b99e92ea.png?updated_at=2022-11-23T23:49:54.138Z';

interface Interest {
  name: string;
  title: string;
  uuid: string;
  selected: boolean;
  iconUrl: string;
}

const categoriesList = [
  {
    category: 'Rodeo',
    id: 1,
    tags: [
      {
        name: 'Bull-Riding',
        title: 'Bull-Riding',
        uuid: '001',
        iconUrl: ICON_BULL_RIDING,
        selected: false,
      },
      {
        name: 'Bareback',
        title: 'Bareback',
        uuid: '002',
        iconUrl: ICON_BARBACK_RIDING,
        selected: false,
      },
      {
        name: 'Saddle-Bronc',
        title: 'Saddle-Bronc',
        uuid: '003',
        iconUrl: ICON_SADDLE_BRONC,
        selected: false,
      },
      {
        name: 'Barrel-Racing',
        title: 'Barrel-Racing',
        uuid: '004',
        iconUrl: ICON_BARREL_RACE,
        selected: false,
      },
      {
        name: 'Breakaway',
        title: 'Breakaway',
        uuid: '005',
        iconUrl: ICON_BREAKAWAY,
        selected: false,
      },
      {
        name: 'Tie-Down',
        title: 'Tie-Down',
        uuid: '006',
        iconUrl: ICON_TIE_DOWN,
        selected: false,
      },
      {
        name: 'Steer-Wrestling',
        title: 'Steer-Wrestling',
        uuid: '007',
        iconUrl: ICON_STEER_WRESTLING,
        selected: false,
      },
      {
        name: 'Team-Roping',
        title: 'Team-Roping',
        uuid: '008',
        iconUrl: ICON_TEAM_ROPING,
        selected: false,
      }
    ]

  },
  {
    category: 'Equine',
    id: 2,
    tags: [
      {
        name: 'Reining',
        title: 'Reining',
        uuid: '009',
        iconUrl: ICON_REINING,
        selected: false,
      },
      {
        name: 'Cutting',
        title: 'Cutting',
        uuid: '010',
        iconUrl: ICON_CUTTING,
        selected: false,
      },
      {
        name: 'Reined-Cow-Horse',
        title: 'Reined-Cow-Horse',
        uuid: '011',
        iconUrl: ICON_REINED_COW_HORSE,
        selected: false,
      },
      // {
      //   name: 'Quarter-Mile-Race',
      //   title: 'Quarter Mile Race',
      //   id: '012',
      //   selected: false,
      // },
    ]
  }
];

const perPage = 3;
const makeTags = () => {
  if (navigator.cookieEnabled) {
    const tags = localStorage?.getItem("filterTags");
    if (tags && JSON.parse(tags)) {
      return JSON.parse(tags)
        .map((tag: any) => tag)
        .flat()
        .sort((a: { name: string; }, b: { name: string; }) => a.name > b.name ? 1 : -1)
    }
  }
  return categoriesList
    .map(({ tags }) => tags)
    .flat()
    .sort((a, b) => a.name > b.name ? 1 : -1)
}
export const FeedPage = () => {

  const [pinnedPosts, setPinnedPosts] = useState<any[]>([]);
  const [isLoadedPinned, setLoadedPinned] = useState(false);
  /* eslint-disable */
  const [filterTags, setFilterTags] = useState<Interest[]>(makeTags());

  const { data: articles, isFetchingNextPage, fetchNextPage } = useArticlesInfiniteQuery({
    limit: perPage,
    filterParams: filterTags
  })

  const { data: pinPosts } = usePinPostInfiniteQuery({
    filterParams: []
  })
  if (navigator.cookieEnabled) {
    localStorage.removeItem("athleteFilters")
  }
  useEffect(() => {
    localStorage?.setItem("filterTags", JSON.stringify(filterTags))
  }, [filterTags])

  const { data: customFeed } = useCustomFeedQuery()
  const { data: collectionFeed } = useCollectionFeedQuery()

  // it was an attempt to restore scroll position after clicking on post and going back,
  // but it doesn't work
  // const virtualLoaderRef = useCallback((node: any) => {
  //   if (node !== null && !!parseInt(window?.history?.state?.usr?.row)) {
  //     node._listRef.scrollToItem(window?.history?.state?.usr?.row, "smart");
  //   }
  // }, []);

  const filteredArticles = useMemo(() => {
    if (!articles) return []

    const items = articles.pages.flat()
    if (!filterTags.some((tag) => tag.selected)) {
      return items
    }

    return items.filter((article) => {
      const articleTags = article.MC_Shopify || []

      return filterTags.some((tag) =>
        articleTags.includes(tag.name)
      )
    })
  }, [articles, filterTags])

  const filteredPinPost = useMemo(() => {
    if (!pinPosts) return []

    const items = pinPosts.pages.flat()
    return items
  }, [pinPosts])

  const removeLastCollectionRecursive = (items: (Article | CollectionFeed)[]): (Article | CollectionFeed)[] => {
    if (items.length === 0) return []
    // if there is no next page for article doesn't remove last collection
    if (!isFetchingNextPage) return items
    const lastItem = items[items.length - 1]
    if (lastItem.feedType === FeedTypeEnum.COLLECTION) {
      return removeLastCollectionRecursive(items.slice(0, items.length - 1))
    }
    return items
  }
  const articlesWithCollections = useMemo(() => {
    if (!collectionFeed) return []
    // join and sort by published_at articles and collections
    const items: (Article | CollectionFeed)[] = [...filteredArticles, ...collectionFeed]
    items.sort((a, b) => {
      if (a.publishedAt > b.publishedAt) return -1
      if (a.publishedAt < b.publishedAt) return 1
      return 0
    })
    // if the las item is collection, remove it (use recursion)
    return removeLastCollectionRecursive(items)
  }, [filteredArticles, collectionFeed])


  /* eslint-disable */
  const pinnedWithCollections = useMemo(() => {
    if (!collectionFeed) return []
    // join and sort by published_at articles and collections
    const items: (Article | CollectionFeed)[] = [...filteredPinPost, ...collectionFeed]
    items.sort((a, b) => {
      if (a.publishedAt > b.publishedAt) return -1
      if (a.publishedAt < b.publishedAt) return 1
      return 0
    })
    // if the las item is collection, remove it (use recursion)
    return removeLastCollectionRecursive(items)
  }, [filteredArticles, collectionFeed])
  /* eslint-enable */
  const bundleWithAds = useMemo(() => {
    if (!customFeed) return []
    let customFeedIndex = 0
    return articlesWithCollections.reduce((arr: (Article | (CustomFeed & { isCustomFeed: true }) | CollectionFeed)[], articleOrCollection, i) => {
      arr.push(articleOrCollection)
      if (i !== 0 && i % 3 === 0) {
        const item = customFeed[customFeedIndex++]
        if (item) {
          arr.push({ ...item, isCustomFeed: true })
        }
      }
      return arr
    }, [])
  }, [articlesWithCollections, customFeed, pinnedPosts])

  useEffect(() => {
    const pinnedArticles = pinnedWithCollections?.filter(article => article.Featured === true);
    async function fetchUserPinPost() {
      try {
        const strapiLivePost = await useGetLivePostQuery()
        const strapiLivePostList = strapiLivePost?.data?.attributes?.LiveVideos.map((article: any) => {
          return {
            ...article,
            feedType: "LIVE"
          }
        }).filter((article: { Featured: boolean; }) => article.Featured)

        strapiLivePostList.push(...pinnedArticles)
        setPinnedPosts(strapiLivePostList)
        setLoadedPinned(true)
      } catch (error) {
        if (error instanceof Error) {
          if (error.message.slice(-3) === '404') {
            setPinnedPosts(pinnedArticles)
            setLoadedPinned(true)
          }
        }
      }
    }
    fetchUserPinPost()
  }, [articlesWithCollections]);

  if (!articles || !customFeed || !collectionFeed) {
    return <LoadingScreen />
  }

  for (let i = 0; i < pinnedPosts.length; i++) {
    if (pinnedPosts[i].feedType === "COLLECTION") {
      const id = pinnedPosts[i].id
      const objWithIdIndex = bundleWithAds.findIndex((obj) => obj.id === id);
      if (objWithIdIndex > -1) {
        bundleWithAds.splice(objWithIdIndex, 1);
      }
    }
  }

  const hasNextPage = articles?.pages.length ? articles.pages[articles.pages.length - 1].length === perPage : true
  // const toggleFilterTag = (uuid: string): any => {
  //   setFilterTags((tags) =>
  //     tags.map((tag) =>
  //       tag.uuid === uuid
  //         ? { ...tag, selected: !tag.selected }
  //         : tag
  //     )
  //   )
  // };

  const items = pinnedPosts.length ? [pinnedPosts, ...bundleWithAds] : bundleWithAds

  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Feed | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Feed | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Feed</title>
      </Helmet>
      <Layout
        contentSx={{ pl: 0, overflow: 'hidden', overflowX: 'hidden', maxWidth: '100%' }}
      >

        <Box sx={{ display: 'flex', flexGrow: 1, height: '100%', flexDirection: 'column' }}>
          {/* <Box sx={{ px: 2, flexShrink: 0 }}>
            <InterestSlider tags={filterTags} onToggle={toggleFilterTag} />
          </Box> */}
          {/* <Box sx={{ flexGrow: 0 }}>
            <CollectionSlider />
          </Box> */}
          <br/>
          <Box sx={{ flexGrow: 1 }}>
            {/*
               disabling virtualizing of the feed
               because the order of items is unstable:
               collection item is injected in the end of items on the first load
               it's expected to remain where it was on the first load,
               but on second load the same collection is injected to the end of second loaded page
               this is causing virtual feed to refresh and scroll up (see comment for `ids` prop of InfiniteListV2 component)

               if you want to try turning it back to virtualized, make sure to also test this over production API,
               as the bug may be not present in dev
            */}
            {isLoadedPinned && (
              <InfiniteListV2
                virtual={false}
                items={items}
                render={RenderItem}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
              />
            )}
          </Box>
        </Box>
      </Layout>
    </>
  );
};

const RenderItem = ({ item }: { item: unknown }) => {
  if (Array.isArray(item)) {
    return <PinnedPosts pinnedPosts={item} />
  }

  return <FeedItem item={item} />
}

