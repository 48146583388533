import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import PageHeader from '../../components/Header/PageHeader';
import { Page } from '../../components/Page/Page';
import Switch from '../../components/Switch';
import pageBg from '../../img/home-page-bg.png';
import { Helmet } from 'react-helmet';

const settings = [
  {
    name: 'Paused All',
    enabled: false,
  },
  {
    name: 'New Post',
    enabled: true,
  },
  {
    name: 'Live Videos',
    enabled: true,
  },
  {
    name: 'Events',
    enabled: true,
  },
];

const NotificationSettings = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Welcome | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />

      </Helmet>
      <Page
        sx={{ backgroundImage: `url(${pageBg})`, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}
      >
        <PageHeader />
        <Box sx={{ pt: 2, pb: 2 }}>
          {settings.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  pt: 2,
                  pb: 2,
                }}
              >
                <Box sx={{ pl: 2, flex: '1' }}>
                  <Typography variant="h6">{item.name}</Typography>
                </Box>
                <Box>
                  <Switch inputProps={{ 'aria-label': item.name }} checked={item.enabled} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Page>
    </>
  );
};

export default NotificationSettings;
