import { Box, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import ExploreLogo1 from "../../img/explore-logo1.png";
import TetonRidgePlus from "../../img/title-logo-black.png";
import ExploreLogo3 from "../../img/explore-logo3.png";
import ExploreLogo4 from "../../img/explore-logo4.png";
import ExploreLogo5 from "../../img/explore-logo5.png";
import ExploreLogo6 from "../../img/explore-logo6.png";

const images = [ExploreLogo1, TetonRidgePlus, ExploreLogo3, ExploreLogo4, ExploreLogo5, ExploreLogo6];

const LogoWrapper = styled(Box)(({ theme }) => ({
  padding: "5px",
  height: 90,
  display: "flex",
  backgroundColor: theme.palette.darkPallet.main,
  border: `2px solid ${theme.palette.primary.main}`,
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "4px",
  backgroundImage: "linear-gradient(180deg, #423C37 0%, #000000 100%)",
}));

const ExploreLogos = () => {
  return (
    <Grid container spacing={2}>
      {images.map((img, index) => {
        return (
          <Grid item xs={4} key={index}>
            <LogoWrapper>
              <img src={img} alt="" />
            </LogoWrapper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ExploreLogos;
