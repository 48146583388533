import { Button, Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Layout } from "../../components/Layout/Layout";
import VideoJS from '../../components/Videojs/videojs';
import Api from "../../net/api";
import posterImg from "../../img/TR-loading-screen.png"
// import cls from "./Live.module.css"
// import hyerBootsBanner from "../../img/live/Web-Banner-HYER-970-250.jpg";



const Live = () => {
  const { id } = useParams();
  const [videos, setVideos] = useState<any>();
  const videoUrl = id ? videos?.attributes?.LiveVideos?.find((video: any) => video.id === ~~id)?.Url : videos?.attributes?.LiveVideos?.[0]?.Url;
  const videoTitle = id ? videos?.attributes?.LiveVideos?.find((video: any) => video?.id === ~~id)?.Title : videos?.attributes?.LiveVideos?.[0]?.Title;
  const [menu, setMenu] = useState<any>(videoUrl);

  const handleChange = (event: SelectChangeEvent) => {
    setMenu(event.target.value as string);
  };

  useEffect(() => {
    const getVideos = async () => {
      const res = await Api.liveVideos();
      setVideos(res.data);
    }
    getVideos();
  }, [])

  const videoJsOptions = {
    controls: true,
    autoplay: true,
    liveui: true,
    fluid: true,
    trackingThreshold: 2,
    preloadWebComponents: true,
    // techOrder: ['chromecast', 'html5'],
    // plugins: {
    //   chromecast: {
    //     showButton: true,
    //     addButtonToControlBar: true
    //   }
    // },
    poster: posterImg,
    // techOrder: ['chromecast', 'html5'],
    // plugins: {
    //   chromecast: {}
    // },
    // playbackRates: [0.5, 1, 1.25, 1.5, 2],
    sources: [
      { src: menu?.Url, type: 'application/x-mpegURL' },
      { src: videoUrl, type: 'application/x-mpegURL' },
    ],
  };

  return (
    <Layout header={{ backIcon: true }}>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Live Streaming | TetonRidge+"  />
        <meta name="twitter:description" content="Live Streaming provided by TetonRidge+" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'/>

        <meta property="og:title" content="Live Streaming | TetonRidge+" />
        <meta property="og:description" content="Live Streaming provided by TetonRidge+" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Live Streaming</title>
      </Helmet>
      <Box sx={{ height: '100%', overflow: 'auto', pb: 4 }}>
        {(menu?.Url || videoUrl) && <VideoJS options={videoJsOptions} />}
        <Box sx={{ pt: 4, pb: '200px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h3' color='primary.main' textTransform='uppercase' align='center' sx={{ pb: 2 }}>
            {menu?.Title ? menu?.Title : videoTitle}
          </Typography>
          <span style={{ fontFamily: 'BourbonW00-Regular', fontSize: 19, paddingBottom: 2, paddingLeft: '5vw' }}>
            SELECT A VIEW
          </span>
          <FormControl sx={{ width: '90%', align: 'center', margin: '0px auto 0px' }} fullWidth>
            <InputLabel sx={{ textTransform: 'capitalize', paddingTop: 2, fontFamily: 'sans-serif', fontWeight: 300, fontSize: 17 }} id="demo-simple-select-label">
              {menu?.Title ? 'Live' : videoTitle}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={menu?.Title}
              label="Menu"
              onChange={handleChange}
              sx={{ marginTop: 2.5 }}
            >
              {videos?.attributes?.LiveVideos?.map((video: any) => {
                return (
                  <MenuItem value={video}>{video.Title}</MenuItem>
                )
              })
              }
            </Select>
          </FormControl>
          <Box sx={{ pt: 4, pb: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {videos?.attributes?.ExternalLinks?.map((link: any) => {
              return (
                <Button onClick={() => window.open(link?.Url)} variant="outlined" component="label" style={{ cursor: 'pointer', padding: '5px 10px', fontSize: '12px' }}>
                  {(link?.Title != null) ? link?.Title : "Watch external link"}
                </Button>
              )
            })
            }
          </Box>

        </Box>
      </Box>
    </Layout >
  );
};


export default Live;
