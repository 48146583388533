import { Typography, Box, Divider, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Header from '../../components/Header';
import { Page } from '../../components/Page/Page';
import ropingImg1 from '../../img/roping-cover-1.png';
import ropingImg2 from '../../img/roping-cover-2.png';
import ropingImg3 from '../../img/roping-cover-3.png';
import pessangerLogo1 from '../../img/present-1.png';
import pessangerLogo2 from '../../img/present-2.png';

const ridingList = [
  {
    title: 'Bareback riding',
    presented: 'Utah Office of Turism',
    media: ropingImg1,
    list: [
      {
        serial: 1,
        items: [{ number: 'BB33', name: 'RC Ladingham', club: 'Hat Creek, CA' }],
      },
      {
        serial: 2,
        items: [
          {
            number: 'BB37',
            name: 'Tim O’connell',
            club: 'Zwingle, IA',
          },
        ],
      },
      {
        serial: 3,
        items: [{ number: 'BB34', name: 'Thompson', club: 'Altmont, UT' }],
      },
      {
        serial: 4,
        items: [{ number: 'BB34', name: 'Ben Kramer', club: 'Max, ND' }],
      },
      {
        serial: 5,
        items: [{ number: 'BB39', name: 'Ty Blessing', club: 'Eudora, KS' }],
      },
      {
        serial: 6,
        items: [{ number: 'BB34', name: 'Donny Proffit', club: 'Diamonville, WY' }],
      },
      {
        serial: 7,
        items: [{ number: 'BB34', name: 'Estherwood,LA', club: 'Estherwood,LA' }],
      },
      {
        serial: 8,
        items: [{ number: 'BB39', name: 'George Gillespie Darby', club: 'MT, Rerides' }],
      },
    ],
  },
  {
    title: 'Steer Wrestling',
    presented: 'Intermountain Healthcare',
    media: ropingImg2,
    list: [
      {
        serial: 1,
        items: [{ number: 'SW33', name: 'Tanner Brunner', club: 'Ramona, CA' }],
      },
      {
        serial: 2,
        items: [{ number: 'SW34', name: 'Mason Couch', club: 'Cassville, MO' }],
      },
      {
        serial: 3,
        items: [{ number: 'SW35', name: 'Riley Duvall', club: 'Checotah, OK' }],
      },
      {
        serial: 4,
        items: [{ number: 'SW36', name: 'Bridger Anderson', club: 'Carringtone, NV' }],
      },
      {
        serial: 5,
        items: [{ number: 'SW37', name: 'Tristan Martin', club: 'Sulpur, TX' }],
      },
      {
        serial: 6,
        items: [{ number: 'SW38', name: 'Joel Nelson', club: 'Alexander, ND' }],
      },
      {
        serial: 7,
        items: [{ number: 'SW39', name: 'Cash Robb', club: 'Altonah, UT' }],
      },
      {
        serial: 8,
        items: [{ number: 'SW40', name: 'Sam Powers', club: 'Sonora, TX' }],
      },
    ],
  },
  {
    title: 'Team roping',
    presented: 'Dept. of Cultural & Community Engagement',
    media: ropingImg3,
    list: [
      {
        serial: 1,
        items: [
          { number: 'HD33', name: 'Brady Tryan', club: 'Huntley, MT' },
          { number: 'HL33', name: 'Jake Long', club: 'Coffeyville, KS' },
        ],
      },
      {
        serial: 2,
        items: [
          { number: 'HD34', name: 'Kaleb Driggers', club: 'Hoboken, GA' },
          { number: 'HHL34D34', name: 'Junior Nogueira', club: 'Presidente Prudente, BR' },
        ],
      },
      {
        serial: 3,
        items: [
          { number: 'HD35', name: 'Clay Tryan', club: 'Billings, MT' },
          { number: 'HL35', name: 'Jade Corkill', club: 'Lipan, TX' },
        ],
      },
      {
        serial: 4,
        items: [
          { number: 'HD36', name: 'Payden Emmett', club: 'Stephenville, TX' },
          { number: 'HL36', name: 'Lucas Falconer', club: 'Mormangee, TX' },
        ],
      },
    ],
  },
];

const PresenterLogo = styled(Box)(() => ({
  position: 'relative',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const RidingItem = (props: { title: string; media: string; presented: string; list: any }) => {
  return (
    <Box sx={{ backgroundColor: 'darkPallet.main', borderRadius: '6px', p: 1, mb: 2, position: 'relative' }}>
      <Box sx={{ position: 'relative', mb: 2, '& img': { position: 'relative', width: '100%', objectFit: 'cover' } }}>
        <img src={props.media} alt={props.title} />
      </Box>
      <Typography variant="h3" color="primary.main" textTransform="uppercase">
        {props.title}
      </Typography>
      <Typography variant="caption">Presented by {props.presented}</Typography>
      <Divider sx={{ mt: 2, mb: 2, ml: -1, mr: -1 }} />
      {props.list.map((match: any, index: number) => {
        return (
          <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', pb: 1 }}>
            <Typography
              variant="h2"
              component="span"
              color="primary.main"
              sx={{ lineHeight: '42px !important', fontSize: '44px !important' }}
            >
              {match.serial}
            </Typography>
            <Box sx={{ flex: 1, ml: 2 }}>
              {match.items.map((item: any, key: number) => {
                return (
                  <Box key={key} sx={{ pb: 1 }}>
                    <Typography variant="subtitle1" fontWeight="700">
                      {item.number} | {item.name}
                    </Typography>
                    <Typography variant="caption">{item.club}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const EventDayShift = () => {
  return (
    <Page>
      <Header disableSearch backIcon />
      <Box sx={{ pt: 5, pb: 2 }}>
        <Typography variant="h2" align="center" textTransform="uppercase" color="primary.main" sx={{ pb: 2 }}>
          Gold medal round
        </Typography>
        <Typography variant="overline" display="block" align="center" fontWeight="700">
          5th Performance | Monday | July 25 | 7:30 PM
        </Typography>
      </Box>
      <Box>
        {ridingList.map((rider, index) => {
          return <RidingItem {...rider} key={index} />;
        })}
      </Box>
      <Typography variant="caption" align="center" display="block" sx={{ pb: 2, pt: 2 }}>
        Presented by
      </Typography>
      <Box sx={{ width: '300px', margin: '0 auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo1} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo2} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo1} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo2} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo1} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
          <Grid item xs={6}>
            <PresenterLogo>
              <img src={pessangerLogo2} alt="Pessanger Logo" />
            </PresenterLogo>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default EventDayShift;
