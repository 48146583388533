import { Box } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  height?: string;
  width?: string;
  center?: boolean;
  src: string;
  TrashIcon?: React.ReactNode;
}

const Media = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& img': {
    width: '100%',
    position: 'static',
    height: '100%',
    objectFit: 'cover',
  },
}));

const ProfileImage = (props: Props) => {
  const { TrashIcon, src, width, height, center } = props;
  return (
    <Box
      sx={{
        width: width || '186px',
        height: height || '186px',
        position: 'relative',
        margin: center ? 'auto' : 0,
      }}
    >
      {TrashIcon && TrashIcon}
      <Box
        sx={{
          width: width || '186px',
          height: height || '186px',
          overflow: 'hidden',
          borderRadius: '100%',
          position: 'relative',
          border: (theme) => `2px solid ${theme.palette.primary.main}`,
        }}
      >
        <Media>
          <img src={src} alt="Profile" />
        </Media>
      </Box>
    </Box>
  );
};

export default ProfileImage;
