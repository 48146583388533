import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useGamingPostsInfiniteQuery } from '../../../api/gaming.api';
// import { CollectionFeed } from '../../../api/collections.api';
import { useCustomFeedQuery } from '../../../api/customFeed.api';
// import { InfiniteVariableSizeList } from './InfiniteVariableSizeList';
import { Layout } from "../../../components/Layout/Layout";
import { LoadingScreen } from '../../../components/Loading/LoadingScreen';
// import useWindowDimentions from '../../../hooks/useWindowDimentions';
import Api from "../../../net/api";
// import { FeedTypeEnum } from '../../../pages/Feeds/feedTypes';
// import { FeedItem } from './GameItem';
import { AnimatePresence, motion } from "framer-motion"
import { GameItem } from './GameMenu';
import "./index.css";
import { EventGameDetail } from "../GameDetail/GameDetailEvent";

const perPage = 8;

export const GamesFeedPage = () => { 
  useEffect(() => {
    if (navigator.cookieEnabled) {
      localStorage.removeItem("filterTags")
      localStorage.removeItem("athleteFilters")
    }
  }, [])
  const [games, setGames] = useState<{ name: string, image?: string }[]>([]);
  // const [gamesView, setGamesView] = useState(true);

  // const handleScroll = (e: React.UIEvent<HTMLElement>) => {
  //   if (e.currentTarget.scrollTop === 0) {
  //     setGamesView(true);
  //   }
  //   else {
  //     setGamesView(false);
  //   }
  // }

  useEffect(() => {
    const getGames = async () => {
      const res = await Api.games();
      const games = res.data as EventGameDetail[]
      setGames(
        games.map((game) => ({
          name: game.attributes.Name,
          image: game.attributes.Image?.data?.attributes?.url,
          url: game.attributes.Url,
        })),
      );
    }
    getGames();
  }, [])

  // const { width } = useWindowDimentions();

  const { data: articles } = useGamingPostsInfiniteQuery({
    limit: perPage,
    filterParams: []
  })

  const { data: customFeed } = useCustomFeedQuery()
  // const virtualLoaderRef = useCallback((node: any) => {
  //   if (node !== null && !!parseInt(window?.history?.state?.usr?.row)) {
  //     node._listRef.scrollToItem(window?.history?.state?.usr?.row, "smart");
  //   }
  // }, []);

  // const flatArticles = useMemo(() => {
  //   return articles?.pages.flat() || []
  // }, [articles])

  // const removeLastCollectionRecursive = (items: (GamingArticle | CollectionFeed)[]): (GamingArticle | CollectionFeed)[] => {
  //   if (items.length === 0) return []
  //   if (!isFetchingNextPage) return items
  //   const lastItem = items[items.length - 1]
  //   if (lastItem.feedType === FeedTypeEnum.COLLECTION) {
  //     return removeLastCollectionRecursive(items.slice(0, items.length - 1))
  //   }
  //   return items
  // }
  // const articlesWithCollections = useMemo(() => {
  //   const items: (GamingArticle | CollectionFeed)[] = [...flatArticles]
  //   items.sort((a, b) => {
  //     if (a.publishedAt > b.publishedAt) return -1
  //     if (a.publishedAt < b.publishedAt) return 1
  //     return 0
  //   })
  //   return removeLastCollectionRecursive(items)
  // }, [flatArticles])

  // const bundleWithAds = useMemo(() => {
  //   if (!customFeed) return []
  //   let customFeedIndex = 0
  //   return articlesWithCollections.reduce((arr: (GamingArticle | (CustomFeed & { isCustomFeed: true }) | CollectionFeed)[], articleOrCollection, i) => {
  //     arr.push(articleOrCollection)
  //     if (i !== 0 && i % 8 === 0) {
  //       const item = customFeed[customFeedIndex++]
  //       if (item) {
  //         arr.push({ ...item, isCustomFeed: true })
  //       }
  //     }
  //     return arr
  //   }, [])
  // }, [articlesWithCollections, customFeed])


  if (!articles || !customFeed) {
    return <LoadingScreen />
  }

  // const hasNextPage = articles?.pages.length ? articles.pages[articles.pages.length - 1].length === perPage : true
  // const total = bundleWithAds.length + (hasNextPage ? perPage : 0)

  return (
    <Layout contentSx={{ pl: 0, overflow: 'hidden', overflowX: 'hidden', maxWidth: '100%' }}>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Games | Teton Ridge+" />
        <meta name="twitter:description" content="Play online rodeo games and stay up to date with content around games like Over/Under, Pro Rodeo Fantasy, Fan Favorite and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Games | Teton Ridge+" />
        <meta property="og:description" content="Play online rodeo games and stay up to date with content around games like Over/Under, Pro Rodeo Fantasy, Fan Favorite and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>More</title>
      </Helmet>
      <Box
        // onScroll={handleScroll}
        sx={{ display: 'flex', flexGrow: 1, height: '100%', flexDirection: 'column', overflowY: 'scroll', overflowX: 'hidden' }}>
        <Box sx={{ flexGrow: 0, pt: 4, pb: 4 }}>
          <Typography
            // onClick={() => {
            //   setGamesView((value) => !value)
            // }}
            variant='h3' color='primary.main' textTransform='uppercase' align='center' sx={{ pb: 2, cursor: 'default' }}>
            Exclusive Offers
          </Typography>
          <AnimatePresence>
            {/* {gamesView && ( */}
            {(
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.2 }}
              >
                <Grid container spacing={1} direction={'column'}>
                  {games.map((game) =>
                    <Grid item xs={4} >
                      <GameItem url={''} {...game} />
                    </Grid>
                  )}
                </Grid>
              </motion.div>
            )}
          </AnimatePresence>
          {/* <AnimatePresence>
            {!gamesView && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.6 }}
              >
                <Grid container direction={'row'}>
                  {games.map((game) =>
                    <Grid item xs={true} paddingBottom='2rem'>
                      <GameItem {...game} compactView />
                    </Grid>
                  )}
                </Grid>
              </motion.div>
            )}
          </AnimatePresence> */}
          {/* <Typography variant='h3' color='primary.main' textTransform='uppercase' align='center' sx={{ pb: 2, mt: '2rem' }}>
            What's new?
          </Typography>
          <InfiniteVariableSizeList
            className='infinite-list'
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            items={bundleWithAds}
            perPage={perPage}
            total={total}
            virtualLoaderRef={virtualLoaderRef}
            spaceY={width > 600 ? 70 : 50}
            element={FeedItem}
          /> */}
          <br />
          <br />
          <br />
        </Box>
      </Box>
    </Layout >
  );
};
