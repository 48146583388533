import { Typography, Autocomplete, TextField, Box, InputLabel } from '@mui/material';
import partnerImg1 from '../../img/partner-1.png';
import partnerImg2 from '../../img/partner-2.png';

const partners = [
  {
    name: 'Charles Macmillan',
    avatar: partnerImg1,
  },
  {
    name: 'Charles Mcgonagall',
    avatar: partnerImg2,
  },
  {
    name: 'Charles Jhonson',
    avatar: partnerImg1,
  },
];

const PartnerSelectionFirstStep = () => {
  return (
    <>
      <Typography variant="h3" color="primary.main" sx={{ pt: 2, pb: 2 }}>
        Add your partner information
      </Typography>
      <Box>
        <InputLabel>Name</InputLabel>
        <Autocomplete
          id="country-select-demo"
          options={partners}
          autoHighlight
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img loading="lazy" width="30" src={option.avatar} alt="" />
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Type Name"
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
      </Box>
      <Typography sx={{ pt: 2 }} variant="overline" display="block">
        An email will be send to: macmillancharles@gmail.com providing instructions and links for registering the event.
      </Typography>
    </>
  );
};

export default PartnerSelectionFirstStep;
