import { Avatar, Typography, Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import RadioLogo from '../../img/radio-logo.png';
import { TwitterIcon, UserIcon, RightChevronIcon } from '../../components/Icons';

const tweets = [
  {
    avatar: RadioLogo,
    fullName: 'The American Rodeo',
    username: '@TheAmericanTR',
    contents: 'Hey! I have some great news about an upcoming thing happening in my life. Stay tuned!',
    date: '11:22 AM - May 13, 2022',
  },
  {
    avatar: RadioLogo,
    fullName: 'The American Rodeo',
    username: '@TheAmericanTR',
    contents: 'Hey! I have some great news about an upcoming thing happening in my life. Stay tuned!',
    date: '11:22 AM - May 13, 2022',
  },
];

const TwitterButton = styled(Button)(() => ({
  borderColor: '#60A3E9',
  color: '#60A3E9',
  '&:hover': {
    borderColor: '#60A3E9',
  },
  '&:active': {
    borderColor: '#60A3E9',
  },
  '&:focus': {
    boxShadow: 'none',
  },
  '& svg': {
    fill: '#60A3E9',
    height: '15px',
    width: '15px',
  },
}));

const SliderItem = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#171717',
          width: '320px',
          m: 1,
          borderRadius: 2,
          border: '1px solid #b1b3b391',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const UserAvatar = styled('div')(() => ({
  '& svg': {
    fill: '#60A3E9',
    height: '10px',
    width: '10px',
  },
}));

const TweetsItem = (props: { avatar: string; fullName: string; username: string; contents: string; date: string }) => {
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Avatar src={props.avatar} alt={props.fullName} sx={{ height: '30px', width: '30px', mr: 1 }} />
            <Box>
              <Typography variant='subtitle2'>{props.fullName}</Typography>
              <Typography variant='caption'>{props.username}</Typography>
            </Box>
          </Box>
          <TwitterButton variant='outlined' size='extraSmall' startIcon={<TwitterIcon />}>
            Follow
          </TwitterButton>
        </Box>
        <Box sx={{ pt: 2, pb: 1 }}>
          <Typography>{props.contents}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          borderTop: '1px solid #b1b3b391',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          cursor: 'pointer',
          p: 1,
          pt: 2,
          pb: 2,
        }}
      >
        <UserAvatar>
          <UserIcon />
        </UserAvatar>
        <Typography variant='caption' color='#60A3E9' fontWeight='500' sx={{ flex: 1, pl: 1 }}>
          See The American Rodeo’s other Tweets
        </Typography>
        <UserAvatar>
          <RightChevronIcon />
        </UserAvatar>
      </Box>
    </>
  );
};

const TweetsSlider = () => {
  const settings = { dots: false, arrows: false, variableWidth: true, slidesToShow: 1 };
  return (
    <Box sx={{ pb: 3, marginLeft: '-15px', marginRight: '-15px' }}>
      <Slider {...settings}>
        {tweets.map((item, index) => {
          return (
            <SliderItem key={index}>
              <TweetsItem {...item} />
            </SliderItem>
          );
        })}
      </Slider>
    </Box>
  );
};

export default TweetsSlider;
