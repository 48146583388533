import DashboardHeader from './DashboardHeader';
import { Page } from '../../components/Page/Page';
import cls from './Dashboard.module.css';
import DashboardMenu from './DashboardMenu';
import { Helmet } from 'react-helmet';
import { CurrentUser } from "../../api/auth.api";

const Dashboard = ({ user }: { user: CurrentUser }) => {
  return (
    <>
      <Helmet>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dashboard | Teton Ridge+" />
        <meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta name="twitter:image:src" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />

        <meta property="og:title" content="Dashboard | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content='https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg' />
        <title>Dashboard</title>
      </Helmet>
      <Page className={cls['dashboard']}>
        <DashboardHeader />
        <DashboardMenu user={user} />
      </Page>
    </>
  );
};

export default Dashboard;
