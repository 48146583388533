import DefaultTheme from './defaultTheme';
const { breakpoints } = DefaultTheme;

const Typography = {
  fontFamily: 'Gotham, BourbonW00-Regular',
  fontSize: 16,
  lineHeight: '18px',
  fontWeight: '500',
  h1: {
    fontFamily: 'BourbonW00-Regular',
    fontSize: 80,
    lineHeight: '89px',
    letterSpacing: '-1.5px',
    [breakpoints.down('md')]: {
      fontSize: 58,
      lineHeight: '63px',
    },
  },
  h2: {
    fontFamily: 'BourbonW00-Regular',
    fontSize: 60,
    lineHeight: '67px',
    letterSpacing: '-0.5px',
    [breakpoints.down('md')]: {
      fontSize: 38,
      lineHeight: '42px',
    },
  },
  h3: {
    fontFamily: 'BourbonW00-Regular',
    fontSize: 48,
    lineHeight: '54px',
    letterSpacing: '0px',
    [breakpoints.down('md')]: {
      fontSize: 26,
      lineHeight: '28px',
    },
  },
  h4: {
    //styleName: H4 Headline;
    fontSize: 32,
    lineHeight: '41px',
    letterSpacing: '0.25px',
  },
  h5: {
    //styleName: H5 Headline;
    fontSize: 24,
    lineHeight: '29px',
    letterSpacing: '0px',
  },
  h6: {
    //styleName: H6 Headline;
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
  },
  subtitle1: {
    //styleName: Subtitle 1;
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.15000000596046448px',
  },
  subtitle2: {
    //styleName: Subtitle 2;
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.10000000149011612px',
  },
  body1: {
    //styleName: Body 1;
    fontSize: 16,
    lineHeight: '28px',
    letterSpacing: '0.4444443881511688px',
  },
  body2: {
    //styleName: Body 2;
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },

  button: {
    //styleName: Button;
    fontSize: 16,
    fontWeight: 350,
    lineHeight: '19px',
    letterSpacing: '0em',
  },
  caption: {
    //styleName: Caption;
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.4000000059604645px',
  },
  overline: {
    //styleName: Overline;
    fontSize: 10,
    lineHeight: '16px',
    letterSpacing: '1.5px',
  },
};

export default Typography;
