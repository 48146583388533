import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import Header, { CustomIconButton } from '../../components/Header';
import { MapIcon, EventIcon } from '../../components/Icons';
import eventImg from '../../img/card-bg-img.png';
import logo from '../../img/radio-logo.png';
import Chip from '@mui/material/Chip';

const ViewsChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: '#ffffff',
  fontSize: '12px',
  borderColor: theme.palette.primary.main,
  fontWeight: '700',
  '& .MuiChip-label': {
    paddingLeft: '3px',
  },
  '& .MuiChip-icon': { height: '20px', width: '20px', marginRight: '4px' },
}));

const EventHeader = () => {
  return (
    <>
      <Header
        RightComponent={
          <>
            <CustomIconButton>
              <MapIcon />
            </CustomIconButton>
            <CustomIconButton>
              <EventIcon />
            </CustomIconButton>
          </>
        }
      />
      <Box
        sx={{
          position: 'relative',
          ml: '-1rem',
          mr: '-1rem',
          '& img': { position: 'static', height: '100%', width: '100%', objectFit: 'cover', opacity: 0.4 },
        }}
      >
        <img src={eventImg} alt="Event img" />
        <Box sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, pl: '1rem', pr: '1rem', pb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ViewsChip icon={<img src={logo} alt="" />} label="QUALIFIER" variant="outlined" />
            <Typography variant="body2" sx={{ pl: 2 }}>
              Region: Central
            </Typography>
          </Box>
          <Typography variant="h3" textTransform={'uppercase'} color="primary.main">
            American Rodeo Classic
          </Typography>
          <Typography variant="caption" fontWeight="700">
            July 12 - July 15 | AT Stadium 1 AT&T Way, Arlington, TX 7601, Dallas Texas
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default EventHeader;
