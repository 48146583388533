export const routes = {
  feed: '/feed',
  athleteAuth: {
    welcome: '/athlete-registration',
    phoneVerification: '/athlete-registration/phone',
    profileSetup: '/athlete-registration/profile-setup',
    stepTwoSignup: '/athlete-registration/step-2',
    stepThreeSignup: '/athlete-registration/step-3',
    interests: '/athlete-registration/interests',
    disciplines: '/athlete-registration/disciplines',
  },
  athletes: `/athletes`,
  athleteView: (uuid?: string) => `/athletes/${uuid ?? ':id'}`,
  athleteEdit: (uuid?: string) => `/athletes/${uuid ?? ':id'}/edit`,
  animals: `/animals`,
  animalView: (uuid?: string) => `/animal-profile/${uuid ?? ':uuid'}`,
  standings: '/results',
  standingView: (fileId?: string) => `/results/${fileId ?? ':id'}`,
  standingCategoryView: (fileId?: string, category?: string) =>
    `/results/${fileId ?? ':id'}/${category ?? ':category'}`,
  live: '/watch-live',
  games: {
    index: '/more',
    gameDetails: (gameName?: string) =>
      `/more/${gameName || ':gameName'}`,
    play: (gameName?: string) =>
      `/more/${gameName || ':gameName'}/play`
  }
};
