import { buildApiInfiniteQueryHook, buildApiMutationHook, buildApiQueryHook } from "../utils/api.utils";
import request from "../utils/request";
import { queryClient } from "../config/queryClient";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

export type AnimalInList = {
  uuid: string
  stockName?: string
  animalType: string
  bio?: string
  delivery?: string
  registeredCode: string
  registeredName: string
  isFollowed?: boolean
}

export const useAnimalsInfiniteQuery = buildApiInfiniteQueryHook<{ limit: number }, AnimalInList[]>(
  'livestocks',
  ({ limit, pageParam }) => request.get('/livestock', {
    params: {
      limit,
      offset: (pageParam - 1) * limit,
    },
  })
)

export const useFollowAnimalMutation = buildApiMutationHook<{ livestockUUID: string, follow: boolean }, void>(
  ({ livestockUUID, follow }) => request.post(`/livestock/${livestockUUID}/toggle-follow`, {
    follow
  }),
  (options) => ({
    ...options,
    onSuccess(data, params, rest) {
      options?.onSuccess?.(data, params, rest)

      const { follow, livestockUUID } = params

      // update `isFollowed` in a users cache
      queryClient.setQueriesData(['livestocks'], (data) => {
        const result = data as UseInfiniteQueryResult<AnimalInList[]>['data']
        if (!result) return result;

        return {
          ...result,
          pages: result.pages.map((page) => page.map((item) =>
            item.uuid === livestockUUID
              ? { ...item, isFollowed: follow }
              : item
          ))
        }
      })
    }
  })
)

export type AnimalFull = Omit<AnimalInList, 'isFollowed'> & {
  bbrId?: string
  brandId?: string
  buckingType?: string
  color?: string
  createdAt: string
  dam?: string
  damId?: string
  damSire?: string
  damSireId?: string
  dataSourceId?: string
  effectiveData?: string
  foalDate?: string
  foalYear?: string
  homeLocation?: string
  info?: {
    animalName: string
    animalType: string
    location?: string
    uuid: string
  }
  membershipId?: string
  notes?: string
  ownerName?: string
  owners?: string[]
  pastPerformance: unknown[]
  paymentId?: string
  renewalId?: string
  riders: unknown[]
  season?: string
  sex?: string
  sire?: string
  stockContractorId?: string
  upcomingEvents?: string[]
  updatedAt: string
}

export const useGetAnimalByUUID = buildApiQueryHook<{ uuid: string }, AnimalFull>(
  'livestocks',
  ({ uuid }) => request.get(`/livestock/${uuid}`)
)
