import CardMedia from "@mui/material/CardMedia";
import MuxVideo from "@mux/mux-video-react";

export const Video = ({
  isMux,
  media,
  title,
}: {
  isMux?: boolean
  media?: string
  title?: string
}) => {
  const metadata = {
    video_title: title
  }

  if (isMux) {
    const style = {
      height: '100%',
      width: '100%',
    }

    // show preview image only on iOS as it fails to load video preview
    const iOS = navigator.userAgent.includes("iPhone")
    if (iOS) {
      Object.assign(style, {
        position: 'absolute',
        left: '0',
        top: '0',
      })
    }

    return (
      <>
        {iOS && (
          <CardMedia
            component='img'
            image={`https://image.mux.com/${media}/thumbnail.jpg`}
          />
        )}
        <MuxVideo
          playbackId={media}
          metadata={metadata}
          style={style}
          controls
          onPlay={() => {
            window.dataLayer.push({
              event: 'play-video',
            });
          }}
        />
      </>
    )
  }

  return (
    <CardMedia
      component="iframe"
      image={media}
      height='100%'
      width='100%'
      frameBorder='0'
      // allowFullScreen
    />
  )
}
