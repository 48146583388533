import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; //useLocation
import EventList from '../../../components/EventList';
import Header, { CustomIconButton } from '../../../components/Header';
import { EventIcon, MapIcon } from '../../../components/Icons';
import Interest from '../../../components/Interest';
import SectionTitle from '../../../components/SectionTitle';
import { useInterestsQuery } from "../../../api/interests.api";
// import EventPost from '../../../components/Post/EventPost';
// import Post from '../../../components/Post/Post';
// import AnimalSliders from '../../../components/RiderSliders';
// import SectionTitle from '../../../components/SectionTitle';
// import BuffaloImage from '../../../img/animals/buffalo.png';
// import HoresImage from '../../../img/animals/horse.png';
// import ContentLogo from '../../../img/content-logo.png';
// import PostImage from '../../../img/content-post.png';
// import EventImg from '../../../img/events-img.png';
// import EventImg from '../../../img/events-img.png';
// import ContentLogo from '../../../img/content-logo.png';
// import PostImage from '../../../img/content-post.png';
// import Post from '../../../components/Post/Post';
// import Events from '../../../pages/Events';
import { formatCurrency } from '../../../utils/index';
// import { PencilIcon } from '../../../components/Icons';
// import IconButton from '@mui/material/IconButton';
import setIcon from '../../../utils/setIcon';
import athletePlaceholder from "../../../img/cowboy-placeholder.png";
import { Helmet } from 'react-helmet';
import { LoadingScreen } from "../../../components/Loading/LoadingScreen";
import { CurrentUser } from '../../../api/auth.api';
import { subscribeUser } from '../../../subscription';
import { InterestItem } from '../../../components/Interest/InterestItem';
import { useAthleteQuery } from "../../../api/athletes.api";


// const ProfileButton = styled(IconButton)(({ theme }) => ({
// 	backgroundColor: theme.palette.darkPallet.main,
// 	border: `2px solid ${theme.palette.primary.main}`,
// 	'& svg': {
// 		width: '20px',
// 		height: '20px',
// 		stroke: theme.palette.primary.main,
// 	},
// }));

const EarningBox = styled(Box)(({ theme }) => ({
	backgroundImage: 'linear-gradient(106.32deg, #BD9B60 8.31%, #8A7144 45.26%, #E0B975 79.36%, #BD9B60 95.84%)',
	border: `4px solid ${theme.palette.primary.main}`,
	borderRadius: '6px',
	height: '200px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

// const ridingItems = [
// 	{
// 		title: 'WORLD Bull Riding',
// 		serial: 1,
// 	},
// 	{
// 		title: 'WORLD Breakaway',
// 		serial: 5,
// 	},
// ];

// const RidingItem = (props: { title: string; serial: number; }) => {
// 	return (
// 		<Box
// 			sx={{
// 				display: 'flex',
// 				backgroundColor: 'darkPallet.light',
// 				borderLeft: (theme) => `5px solid ${theme.palette.primary.main}`,
// 				alignItems: 'center',
// 				justifyContent: 'space-between',
// 				borderRadius: 2,
// 				p: 2,
// 				mb: 2,
// 			}}
// 		>
// 			<Typography variant="h2" sx={{ pr: 2, color: 'primary.main' }}>
// 				#{props.serial}
// 			</Typography>
// 			<Typography variant="h5" color="primary.main" fontSize="20px" sx={{ flex: 1 }}>
// 				{props.title}
// 			</Typography>
// 		</Box>
// 	);
// };

// const postItem = [
// 	{
// 		title: 'Richest Single-Day Rodeo in Western Sports Awards $3 Million across eigth...',
// 		logo: ContentLogo,
// 		media: PostImage,
// 		comments: 3,
// 		user: 'John Deer',
// 		category: 'Post Category',
// 	},
// 	{
// 		title: 'Richest Single-Day Rodeo in Western Sports Awards $3 Million across eigth...',
// 		logo: ContentLogo,
// 		media: PostImage,
// 		user: 'John Deer',
// 		category: 'Post Category',
// 	},
// ];

// type EventList = Array<object>;

type discipline = {
	competitionName?: string
	name: string
	registeredAt: string
	startAt?: string
	uuid: string
}

type event = {
	description?: string,
	disciplines: discipline[]
	earningsTotal: number
	entryFeePaidTotal: number
	name: string
	startAt: string
	uuid: string
}

type pastEvent = {
	logo: string
	title: string
	list: event[]
}

interface events {
	[key: string]: pastEvent
}

const generateAthleteAddress = (city?: string | null, state?: string | null) => {
	let address;

	switch (true) {
		case !!city && !!state:
			address = `${city}, ${state}`;
			break;
		case !!city && !state:
			address = city;
			break;
		case !!state && !city:
			address = state;
			break;
		default:
			break;
	}

	return address;
};

const AthleteProfile = ({ user }: { user: CurrentUser }) => {
	const login = true;
	const params = useParams<{ id: string }>();
	const pageRef = useRef<HTMLDivElement>(null)

	const { data: athlete } = useAthleteQuery({ uuid: params.id as string })
	const { data: interests } = useInterestsQuery()

	const hasAboutData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.heightFeet || !!athleteProfile.heightInches || !!athleteProfile.weight || !!athleteProfile.city || !!athleteProfile.state || !!athleteProfile.location)
	}

	const hasFamilyData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.status || !!athleteProfile.partnerName || !!athleteProfile.children || !!athleteProfile.motherName || !!athleteProfile.fatherName)
	}

	const hasSchoolData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.college || !!athleteProfile.degree)
	}

	const hasQualificationsData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.nfrQualificationsYear?.length && athleteProfile?.nfrQualificationsYear[0] !== '' && !!athleteProfile.nfrQualificationsYear?.length && athleteProfile?.nfrQualificationsYear[0] !== null || !!athleteProfile.worldTitlesYear?.length && athleteProfile?.worldTitlesYear[0] !== '' && !!athleteProfile.worldTitlesYear?.length && athleteProfile?.worldTitlesYear[0] !== null || !!athleteProfile.biggestRodeoWin || !!athleteProfile.highestScoreYear || !!athleteProfile.highestScoreLoc)
	}

	const hasFavData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.favoriteHorseDraw || !!athleteProfile.rankestHorse || !!athleteProfile.scariestHorse)
	}

	const hasCurrentData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.travellingPartners || !!athleteProfile.rodeoRig)
	}

	const hasQuestionsData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.qstSuccessfulEvent || !!athleteProfile.qstEventRodeo)
	}

	const hasHobbyData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && (!!athleteProfile.qstOccupation || !!athleteProfile.favoriteFood || !!athleteProfile.favoriteBeverage || !!athleteProfile.favoriteMovie || !!athleteProfile.favoriteSeries || !!athleteProfile.favoriteSong || !!athleteProfile.favoriteQuote || !!athleteProfile.favoriteSportTeam || !!athleteProfile.favoriteAthlete)
	}

	const hasHorseData = () => {
		if (!athlete) return false;
		const { athleteProfile } = athlete;
		return athleteProfile && !!athleteProfile.horses && athleteProfile.horses?.length > 0
	}

	// const [standings, setStandings] = useState<standings>({});
	// const [upcomingEventsList, setUpcomingEventsList] = useState<EventList>([]);
	const navigate = useNavigate();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const [pastRow, setPastRow] = useState("");

	useEffect(() => {
		setPastRow(searchParams.get('row') || "");
		subscribeUser(user.uuid);
	}, []);

	// const getEvents = async () => {
	// 	const { data: eventData } = await request.get(`users/${params.id}/events`);
	// 	const pastEvents: any = pastEventsList;
	// 	const upcomingEvents: Array<object> = [];

	// 	eventData.forEach((event: any) => {
	// 		const today = new Date().getTime();
	// 		const eventStart = new Date(event.start_at);
	// 		const eventEnd = new Date(event.end_at);
	// 		const eventStartTime = eventStart.getTime();

	// 		const payouts = event.disciplines[0].prize.payouts[0]
	// 		const fees = event.fees[0]

	// 		const formatStartDate = eventStart.toDateString().split(' ').slice(1, 3).join(' ');
	// 		const formatEndDate = eventEnd.toDateString().split(' ').slice(1, 4).join(' ');
	// 		const formatDate = `${formatStartDate} - ${formatEndDate}`;

	// 		if (today < eventStartTime) {
	// 			const eventData = {
	// 				title: event.name,
	// 				location: `AT ${event.venue.address}, ${event.venue.city}, ${event.venue.zip}, ${event.venue.city} ${event.venue.state}`,
	// 				date: formatDate,
	// 				media: EventImg,
	// 				price: payouts?.amount_type && payouts?.amount ? `${payouts.amount_type}${payouts.amount}` : `$0`,
	// 				startPrice: fees?.amount_type && fees?.amount ? `${fees.amount_type}${fees.amount}` : `$0`,
	// 				slots: 20,
	// 				registrationAvailable: '6/10, 6:00 am to 18/10, 6:00 pm',
	// 				link: event.website_link,
	// 			}

	// 			upcomingEvents.push(eventData)
	// 		} else {

	// 			const eventData = {
	// 				name: event.name,
	// 				place: '11th',
	// 				date: formatDate,
	// 			}

	// 			switch (event.disciplines[0].type) {
	// 				case 'Bull Riding':
	// 					pastEvents.bullRiding.list.push(eventData);
	// 					break;
	// 				case 'Barrel Racing':
	// 					pastEvents.barrelRacing.list.push(eventData);
	// 					break;
	// 				case 'Breakaway':
	// 					pastEvents.breakAway.list.push(eventData);
	// 					break;
	// 				case 'Reigning':
	// 					pastEvents.reigning.list.push(eventData);
	// 					break;
	// 				case 'Reigned Cow Horse':
	// 					pastEvents.reignedCowHorse.list.push(eventData);
	// 					break;
	// 				case 'Cutting':
	// 					pastEvents.cutting.list.push(eventData);
	// 					break;
	// 				case 'Tie Down':
	// 					pastEvents.tieDown.list.push(eventData);
	// 					break;
	// 				case 'Team Roping':
	// 					pastEvents.teamRoping.list.push(eventData);
	// 					break;
	// 				case 'Steer Wrestling':
	// 					pastEvents.steerWrestling.list.push(eventData);
	// 					break;
	// 				case 'Bareback':
	// 					pastEvents.bareback.list.push(eventData);
	// 					break;
	// 				case 'Saddle Bronc':
	// 					pastEvents.saddleBronc.list.push(eventData);
	// 					break;
	// 				c`ase 'Ridge Riders':
	// 					pastEvents.saddleBronc.list.push(eventData);
	// 					break;
	// 			}
	// 		}
	// 	})

	// 	setPastEventsList(pastEvents);
	// 	setUpcomingEventsList(upcomingEvents);
	// }

	// const renderUpcomingEvents = upcomingEventsList.map((event: any, index) => (
	// 	<EventPost.Upcoming
	// 		key={index}
	// 		title={event.title}
	// 		price={event.price}
	// 		slots={event.slots}
	// 		date={event.date}
	// 		media={event.media}
	// 		{...event}
	// 	/>
	// ))

	// const renderLivestock = athlete?.livestock?.map((animal: any) => {
	// 	const mediaImg = animal.animalType === 'Horse' ? HoresImage : BuffaloImage;

	// 	return {
	// 		tagName: animal.discipline_name,
	// 		title: animal.registered_name,
	// 		link: '/',
	// 		media: mediaImg,
	// 	};
	// });

	if (!athlete) {
		return <LoadingScreen />
	}

	let renderInterests = (<></>);
	const renderPastEvents = [];

	const interestList: any[] = (athlete.athleteProfile && athlete.athleteProfile.disciplines) ? athlete.athleteProfile.disciplines.map((disc, index) => {
		const name = interests?.find((i) => i.uuid === disc)?.name
		return {
			name: name || '',
			id: index,
			iconUrl: setIcon(name || ''),
			selected: false
		}
	}) : athlete.disciplines?.map((disc, index) => {
		return {
			name: disc,
			id: index,
			iconUrl: setIcon(disc),
			selected: false
		}
	});

	if (interestList) {
		// We need to compare login data with athlete data - so only the athlete can edit its profile
		renderInterests = login ? (
			<Box sx={{ width: '100%', margin: '10px 0px', padding: '20px 10px' }}>
				{interestList.length > 0 && <Typography variant="overline" fontWeight={600}>Competes in:</Typography>}
				<Interest.Slider list={interestList.sort((a: InterestItem, b: InterestItem) => a.name.localeCompare(b.name))} />
			</Box>
		) : (
			<Interest.Slider list={interestList.sort((a: InterestItem, b: InterestItem) => a.name.localeCompare(b.name))} />
		);
	}

	let events: events = {}

	athlete.events?.forEach((event) => {
		if (event.disciplines) {
			event.disciplines.forEach(disc => {
				const discName = disc.name;

				if (events[discName]) {
					events = {
						...events,
						[discName]: {
							logo: setIcon(discName) || '',
							title: discName,
							list: [
								...events[discName].list,
								event
							]
						}
					}
				} else {
					events = {
						...events,
						[discName]: {
							logo: setIcon(discName) || '',
							title: discName,
							list: [
								event
							]
						}
					}
				}
			})
		}
	})

	for (const event in events) {
		renderPastEvents.push(
			<>
				<EventList {...events[event]} />
				<Box sx={{ pb: 3 }} />
			</>
		)
	}
	const PageWrapper = styled('div')(() => ({
		flex: 1,
		height: '100%',
		overflow: 'auto',
	}))
	return (
		<>
			<Helmet>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content="Athlete Profile | Teton Ridge+" />
				<meta name="twitter:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
				<meta name="twitter:image:src" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />

				<meta property="og:title" content="Athlete Profile | Teton Ridge+" />
				<meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content={'https://cdn.tetonridge.io/OG_Image_a1db3629f6.jpg'} />
				<title>Athlete Profile | {athlete.firstName} {athlete.lastName}</title>
			</Helmet>
			<PageWrapper ref={pageRef}>
				{!login && (
					<Header
						RightComponent={
							<>
								<CustomIconButton>
									<MapIcon />
								</CustomIconButton>
								<CustomIconButton>
									<EventIcon />
								</CustomIconButton>
							</>
						}
						backTo="/athletes"
					/>
				)}
				<Box
					sx={{
						position: 'relative',
						'& img': { position: 'static', height: '100%', width: '100%', objectFit: 'cover' },
					}}
				>
					{login && (
						<Box sx={{ position: 'absolute', width: '100%', top: 0 }}>
							<Header
								backIcon
								backTo={() => navigate('/athletes', { state: { row: pastRow } })}
							// RightComponent={
							// 	<CustomIconButton stroke={true} fill={false} onClick={onPage(routes.athleteEdit(params.id))}>
							// 		<PencilIcon />
							// 	</CustomIconButton>
							// }
							/>
						</Box>
					)}
					{athlete.avatar ? (
						<img src={athlete.avatar} alt="" />
					) :
						<Box
							sx={{
								backgroundColor: 'darkPallet.main',
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								width: "100%",
								minHeight: 400
							}}
						>
							<img src={athletePlaceholder} alt="steer" style={{ width: '50%', height: 'auto', marginTop: '-20%' }} />
						</Box>
					}
					<Box
						sx={{
							backgroundImage: 'linear-gradient(0.15deg, #000000 11.96%, rgba(23, 23, 23, 0.15) 69.32%)',
							position: 'absolute',
							bottom: '0',
							top: '0',
							left: 0,
							right: 0,
							display: 'flex',
							alignItems: 'end',
							p: 2,
						}}
					>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
							<Box sx={{ flex: 1 }}>
								<Typography variant="subtitle1" fontWeight="700" textTransform="uppercase">
									Athlete Profile
								</Typography>
								<Typography variant="h2" color="primary.main" textTransform="uppercase">
									{athlete.firstName.toUpperCase()} {athlete.lastName.toUpperCase()}
								</Typography>
								<Typography variant="overline">
									{generateAthleteAddress(athlete?.city || athlete?.athleteProfile?.city, athlete?.state || athlete?.athleteProfile?.state)}
								</Typography>
							</Box>
							{/* <Box>
							<ProfileButton onClick={onPage(routes.athleteEdit(params.id))}>
								<UserIcon />
							</ProfileButton>
						</Box> */}
						</Box>
					</Box>
				</Box>
				<Box sx={{ lineHeight: 1.5, mx: 2 }}>
					{athlete.bio || athlete?.athleteProfile?.bio}
				</Box>
				{interestList.length > 0 && <Box sx={{ mx: 2 }}>
					{renderInterests}
				</Box>}
				{athlete?.athleteProfile &&
					<Box sx={{ mx: 2 }}>
						{(hasAboutData() || hasFamilyData() || hasSchoolData()) && <>	<SectionTitle title="ABOUT" hasLine />
							{(athlete.athleteProfile.heightFeet && athlete.athleteProfile.heightFeet > 0) ? <Box>
								<Typography variant="subtitle1" fontWeight={600}>Height</Typography>
								<Typography>{athlete.athleteProfile.heightFeet ? `${athlete.athleteProfile.heightFeet}'` : ''} {athlete.athleteProfile.heightInches ? `${athlete.athleteProfile.heightInches}''` : ''}</Typography>
							</Box> : null}
							{athlete.athleteProfile?.weight && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Weight</Typography>
								<Typography>{athlete.athleteProfile.weight}lbs</Typography>
							</Box>}
							{athlete.athleteProfile?.city && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Hometown city</Typography>
								<Typography>{athlete.athleteProfile.city}</Typography>
							</Box>}
							{athlete.athleteProfile?.state && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>State</Typography>
								<Typography>{athlete.athleteProfile.state}</Typography>
							</Box>}
							{athlete.athleteProfile?.location && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Current location</Typography>
								<Typography>{athlete.athleteProfile.location}</Typography>
							</Box>}
							{(hasFamilyData() || hasSchoolData()) && hasAboutData() && <hr
								style={{
									width: '100%',
									height: '1px',
									backgroundColor: 'var(--gray_400)',
									border: 'none',
									margin: '30px 0px '
								}}
							/>}</>}
						{hasFamilyData() && <>
							{athlete.athleteProfile?.status && <Box>
								<Typography variant="subtitle1" fontWeight={600}>Status</Typography>
								<Typography>{athlete.athleteProfile.status}</Typography>
							</Box>}
							{athlete.athleteProfile?.partnerName && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Partner name</Typography>
								<Typography>{athlete.athleteProfile.partnerName}</Typography>
							</Box>}
							{athlete?.athleteProfile?.children !== 0 && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Children</Typography>
								<Typography>{athlete.athleteProfile.children}</Typography>
							</Box>}
							{athlete.athleteProfile?.motherName && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Mother</Typography>
								<Typography>{athlete.athleteProfile.motherName}</Typography>
							</Box>}
							{athlete.athleteProfile?.fatherName && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Father</Typography>
								<Typography>{athlete.athleteProfile.fatherName}</Typography>
							</Box>}
							{hasSchoolData() && <hr
								style={{
									width: '100%',
									height: '1px',
									backgroundColor: 'var(--gray_400)',
									border: 'none',
									margin: '30px 0px '
								}}
							/>}</>}
						{hasSchoolData() && <>
							{athlete.athleteProfile?.college && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>College attended</Typography>
								<Typography>{athlete.athleteProfile.college}</Typography>
							</Box>}
							{athlete.athleteProfile?.degree && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Degree</Typography>
								<Typography>{athlete.athleteProfile.degree}</Typography>
							</Box>}
						</>}
						{(hasQualificationsData() || hasHorseData() || hasFavData() || hasCurrentData()) && <Box sx={{ marginTop: 3 }}>
							<SectionTitle title="competition background" hasLine />
							{athlete.athleteProfile?.nfrQualificationsYear?.length && athlete.athleteProfile?.nfrQualificationsYear[0] !== '' && <Box>
								<Typography variant="subtitle1" fontWeight={600}>NFR Qualifications</Typography>
								<Typography>{athlete.athleteProfile.nfrQualificationsYear.join(', ')}</Typography>
							</Box>}
							{athlete.athleteProfile?.worldTitlesYear?.length && athlete.athleteProfile?.worldTitlesYear[0] !== '' && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>World titles</Typography>
								<Typography>{athlete.athleteProfile.worldTitlesYear.join(', ')}</Typography>
							</Box>}
							{athlete.athleteProfile?.biggestRodeoWin && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Biggest Rodeo win</Typography>
								<Typography>{athlete.athleteProfile.biggestRodeoWin}</Typography>
							</Box>}
							{(athlete.athleteProfile?.highestScoreYear || athlete.athleteProfile?.highestScoreLoc) && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Fastest time or highest score</Typography>
								<Typography>{athlete.athleteProfile?.highestScoreYear} {athlete.athleteProfile?.highestScoreLoc}</Typography>
							</Box>}
							{(hasHorseData() || hasFavData() || hasCurrentData()) && hasQualificationsData() && <hr
								style={{
									width: '100%',
									height: '1px',
									backgroundColor: 'var(--gray_400)',
									border: 'none',
									margin: '30px 0px '
								}}
							/>}
						</Box>}
						{hasHorseData() && <>
							<Typography variant="subtitle1" fontSize={18} sx={{ opacity: 0.8, marginTop: 2 }} fontWeight={600}>Horses</Typography>
							{athlete.athleteProfile?.horses?.map((item) => <Box sx={{ marginTop: 2, marginBottom: 4 }}>
								<Typography variant="subtitle1" fontWeight={600}>{item.name}</Typography>
								<Typography>{item.age} years old</Typography>
							</Box>)}
						</>}
						{hasFavData() && <>
							<Typography variant="subtitle1" fontSize={18} sx={{ opacity: 0.8 }} fontWeight={600}>Horses and Bulls</Typography>
							{athlete.athleteProfile?.favoriteHorseDraw && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite to draw</Typography>
								<Typography>{athlete.athleteProfile.favoriteHorseDraw}</Typography>
							</Box>}
							{athlete.athleteProfile?.rankestHorse && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Rankest</Typography>
								<Typography>{athlete.athleteProfile.rankestHorse}</Typography>
							</Box>}
							{athlete.athleteProfile?.scariestHorse && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Scariest</Typography>
								<Typography>{athlete.athleteProfile.scariestHorse}</Typography>
							</Box>}
						</>}
						{(hasCurrentData() || hasQuestionsData()) && (hasFavData() || hasHorseData()) && <hr
							style={{
								width: '100%',
								height: '1px',
								backgroundColor: 'var(--gray_400)',
								border: 'none',
								margin: '30px 0px '
							}}
						/>}
						{
							hasCurrentData() && <>
								{athlete.athleteProfile?.travellingPartners && <Box sx={{ marginTop: 2 }}>
									<Typography variant="subtitle1" fontWeight={600}>Current traveling partners</Typography>
									<Typography>{athlete.athleteProfile.travellingPartners}</Typography>
								</Box>}
								{athlete.athleteProfile?.rodeoRig && <Box sx={{ marginTop: 2 }}>
									<Typography variant="subtitle1" fontWeight={600}>Rodeo rig</Typography>
									<Typography>{athlete.athleteProfile.rodeoRig}</Typography>
								</Box>}
								{hasQuestionsData() && <hr
									style={{
										width: '100%',
										height: '1px',
										backgroundColor: 'var(--gray_400)',
										border: 'none',
										margin: '30px 0px '
									}}
								/>}
							</>
						}
						{
							hasQuestionsData() && <>
								{athlete.athleteProfile?.qstSuccessfulEvent && <Box>
									<Typography variant="subtitle1" fontWeight={600}>Why you picked your chosen event in rodeo?</Typography>
									<Typography>{athlete.athleteProfile.qstSuccessfulEvent}</Typography>
								</Box>}
								{athlete.athleteProfile?.qstEventRodeo && <Box sx={{ marginTop: 2 }}>
									<Typography variant="subtitle1" fontWeight={600}>What other event in rodeo do you think you could be successful at if you haven’t tried?</Typography>
									<Typography>{athlete.athleteProfile.qstEventRodeo}</Typography>
								</Box>}
							</>
						}
						{hasHobbyData() && <Box sx={{ marginTop: 3, marginBottom: 3 }}> <SectionTitle title="Hobbies & interests" hasLine />
							{athlete.athleteProfile?.qstOccupation && <Box>
								<Typography variant="subtitle1" fontWeight={600}>if you weren’t rodeoing what occupation would you want to do?</Typography>
								<Typography>{athlete.athleteProfile.qstOccupation}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteFood && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite food</Typography>
								<Typography>{athlete.athleteProfile.favoriteFood}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteBeverage && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite beverage</Typography>
								<Typography>{athlete.athleteProfile.favoriteBeverage}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteMovie && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite movie</Typography>
								<Typography>{athlete.athleteProfile.favoriteMovie}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteSeries && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite bringe series</Typography>
								<Typography>{athlete.athleteProfile.favoriteSeries}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteSong && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite song</Typography>
								<Typography>{athlete.athleteProfile.favoriteSong}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteSportTeam && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite sport team</Typography>
								<Typography>{athlete.athleteProfile.favoriteSportTeam}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteAthlete && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite athlete</Typography>
								<Typography>{athlete.athleteProfile.favoriteAthlete}</Typography>
							</Box>}
							{athlete.athleteProfile?.favoriteQuote && <Box sx={{ marginTop: 2 }}>
								<Typography variant="subtitle1" fontWeight={600}>Favorite quote</Typography>
								<Typography>{athlete.athleteProfile.favoriteQuote}</Typography>
							</Box>}
						</Box>}
					</Box>
				}
				{!!athlete.careerEarnings && (
					<Box sx={{ marginBottom: "70px", mx: 2 }}>
						<EarningBox>
							<Typography variant="caption" fontWeight="700" display="block" textTransform="uppercase">
								Career Earnings
							</Typography>
							<Typography variant="h1">
								{formatCurrency.format(athlete.careerEarnings)}
							</Typography>
						</EarningBox>
					</Box>
				)}
			</PageWrapper>
		</>
	);
};

export default AthleteProfile;
