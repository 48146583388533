import { Box, Typography } from '@mui/material';
// import { useNavigate } from 'react-router';

export const GameItem = ({
  name,
  image,
  url,
  compactView,
}: {
  name: string
  image?: string
  url: string
  compactView?: boolean
}) => {
  // const navigate = useNavigate();
  const openGame = () => {
    // this bypasses the intersticial page and opens the url in a new window
    window.open(`${url}`, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        flexDirection: 'column',
        margin: '0px 10px',
        cursor: 'pointer',
        justifyContent: compactView ? 'center' : 'left',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          zIndex: 1,
        }}
        onClick={openGame}
      />
      <div style={{
        border: '1px solid #E0B975',
        borderRadius: 6,
        padding: 10,
        display: 'flex',
        justifyContent: compactView ? 'center' : 'left',
        width: compactView ? undefined : '95%',
      }}>
        <Box
          sx={{
            position: 'relative',
            borderRadius: '6px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '88px',
            height: '88px',
            '& img': { width: '100%', height: '100%', position: 'static', objectFit: 'cover' },
          }}

        >
          <img src={image} alt={name} />
        </Box>
        {!compactView && (
          <Typography fontFamily='Gotham' display='flex' align='center' color='000' fontSize='16px' fontWeight='700' lineHeight='140%' pt='4px' alignItems='center' marginLeft='1rem'>
            {name}
          </Typography>
        )}
      </div>
    </Box>
  );
};
