import { buildApiMutationHook, buildApiQueryHook } from '../utils/api.utils';
import { AthleteProfile, CurrentUser } from './auth.api';
import request from '../utils/request';
import { queryClient } from '../config/queryClient';
import { fixAvatar } from './users.api';
import { Horse } from '../features/Auth/SignUpFlow/AthleteSetup/CompetitionBackgroundSetupPage';
import { StringDecoder } from 'string_decoder';

export type Athlete = {
  uuid: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  bio?: string;
  careerEarnings?: number;
  gender?: string;
  city?: string;
  state?: string;
  disciplines: string[];
  events: UserEvent[];
  horses: Horse[];
  athleteProfile?: AthleteProfile;
};

export type UserEvent = {
  uuid: string;
  description?: string;
  disciplines: Discipline[];
  earningsTotal: number;
  entryFeePaidTotal: number;
  name: string;
  startAt: string;
};

export type Discipline = {
  competitionName?: string;
  name: string;
  registeredAt: string;
  startAt?: string;
  uuid: string;
};

export const useAthleteQuery = buildApiQueryHook<{ uuid: string }, Athlete>(
  'user',
  ({ uuid }) => request(`/users/${uuid}`),
  {
    select(data) {
      if (data.avatar) {
        data.avatar = fixAvatar(data.avatar);
      }
      return data;
    },
  },
);

type UpdateUserParams = {
  uuid: string;
  firstName?: string;
  lastName?: string;
  isProfileSetUp?: boolean;
  email?: string;
  profileImage?: string | StringDecoder;
  bio?: string;
  preferredName?: string;
  phone?: string;
  emergencyFirstName?: string;
  emergencyLastName?: string;
  emergencyEmail?: string;
  emergencyPhone?: string;
  emergencyPhone2?: string;
  heightFeet?: number;
  heightInches?: number;
  weight?: number;
  city?: string;
  state?: string;
  location?: string;
  status?: string;
  rodeoWin?: string;
  degree?: string;
  college?: string;
  children?: number;
  fatherName?: string;
  motherName?: string;
  partnerName?: string;
  nfrQualificationsYear?: string[];
  worldTitlesYear?: string[];
  biggestRodeoWin?: string;
  highestScoreYear?: string;
  highestScoreLoc?: string;
  sponsors?: string;
  favoriteHorseDraw?: string;
  scariestHorse?: string;
  rankestHorse?: string;
  travellingPartners?: string;
  rodeoRig?: string;
  qstEventRodeo?: string;
  qstSuccessfulEvent?: string;
  favoriteFood?: string;
  favoriteBeverage?: string;
  favoriteMovie?: string;
  qstOccupation?: string;
  favoriteSeries?: string;
  favoriteQuote?: string;
  favoriteAthlete?: string;
  favoriteSportTeam?: string;
  favoriteSong?: string;
  horses?: Horse[];
  interests?: string[];
  disciplines?: string[];
};

export const useUpdateAthleteMutation = buildApiMutationHook<UpdateUserParams, CurrentUser>(
  ({ uuid, ...data }) => request.patch(`/athlete`, { uuid, ...data }),
  (options) => ({
    ...options,
    onSuccess(updatedUser, ...rest) {
      // send GA event
      window.dataLayer.push({
        event: 'edit-profile',
      });

      // currentUser query has request parameters, so setQueriesData (plural) must be used
      queryClient.setQueriesData(['currentUser'], updatedUser);

      options?.onSuccess?.(updatedUser, ...rest);
    },
  }),
);
