import { Box, styled } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { FacebookShareButton } from "react-share";
import { FacebookLineIcon, TwitterLineIcon } from "../../components/Icons";

interface ButtonProps {
  filled?: string;
  stroke?: string;
}

const IconButton = styled(Button)<ButtonProps>(({ filled, stroke, theme }) => ({
  marginRight: '5px',
  padding: 0,
  minWidth: '30px',
  '& svg': {
    fill: filled ? theme.palette.grey['500'] : 'none',
    stroke: stroke ? theme.palette.grey['500'] : 'none',
    height: '25px',
    width: '25px',
  },
}));

export const ShareMedias = ({ saved = false }: { saved?: boolean; }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: saved ? 'end' : 'start', alignItems: 'center' }}>
      <Typography variant="caption" fontSize="16px" sx={{ pr: 2 }}>
        Share this article
      </Typography>
      <a className="twitter-share-button"
        href={`https://twitter.com/intent/tweet?text=${window.location.href}`}
        data-size="large">
        <IconButton stroke="true" variant="text" size="small">
          <TwitterLineIcon />
        </IconButton>
      </a>
      <FacebookShareButton url={window.location.href}>
        <IconButton stroke="true" variant="text" size="small">
          <FacebookLineIcon />
        </IconButton>
      </FacebookShareButton>
    </Box>
  );
};
