import Axios from 'axios';
import { configure } from 'axios-hooks';
import { CMS_BASE_URL } from '../config/constants';

const instance = Axios.create({
  baseURL: CMS_BASE_URL + 'api',
});

instance.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error instanceof Axios.Cancel && !error.message) {
      error.message = 'Request cancelled';
    }
    const errorStatusCode = error?.response?.status;
    if (errorStatusCode === 401 && error?.response?.data?.sessionExpired) {
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  },
);

configure({ axios: instance });

export default instance;
