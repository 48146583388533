import { styled } from '@mui/system';
import { Button, Chip } from '@mui/material';
import Header from '../../components/Header';
import { Page } from '../../components/Page/Page';
import Interest from '../../components/Interest';
import ProductImg from '../../img/product-img.png';
import { ProductsGrid } from '../../components/Products';
import Fixedbox from '../../components/Fixedbox';
import { ShopIcon } from '../../components/Icons';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

const interestList = [
  {
    name: 'All',
    uuid: '0001',
    selected: true,
  },
  {
    name: 'Film',
    uuid: '001',
    selected: true,
  },
  {
    name: 'TV',
    uuid: '002',
  },
  {
    name: 'Documentaries',
    uuid: '003',
  },
  {
    name: 'Podcast',
    uuid: '004',
    selected: true,
  },
  {
    name: 'Action Movie',
    uuid: '005',
  },
  {
    name: 'Dram Series',
    uuid: '006',
    selected: true,
  },
];

const ProductList = [
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    oldPrice: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '01',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$129.95',
    media: ProductImg,
    category: 'Unisex',
    id: '02',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '03',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '04',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '05',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '06',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '07',
  },
  {
    title: 'Pro Barrel Racing T-shirt',
    price: '$29.95',
    media: ProductImg,
    category: 'Unisex',
    id: '08',
  },
];

const ViewsChip = styled(Chip)(({ theme }) => {
  return {
    backgroundColor: theme.palette.darkPallet.darker,
    color: theme.palette.lightPallet.lighter,
    fontSize: '12px',
    borderColor: 'transparent',
    borderRadius: '100%',
    height: '30px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiChip-label': {
      paddingLeft: '0',
      paddingRight: '0',
      fontWeight: '700',
    },
  };
});

const AllShop = () => {
  return (
    <>
      <Helmet>
        <meta property="og:title" content="All Shop | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>All Shop</title>
      </Helmet>
      <Page>
        <Header backIcon={true} />
        <Interest.Slider list={interestList} />
        <ProductsGrid list={ProductList} />
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<ShopIcon />}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Typography variant="caption" fontSize="16px" fontWeight="700">
                Cart
              </Typography>
              <ViewsChip label="1" variant="filled" size="small" />
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default AllShop;
