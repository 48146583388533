import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Fixedbox from '../../../../components/Fixedbox';
import { Page } from '../../../../components/Page/Page';
import { DeleteIcon } from '../../../../components/Icons';
import cls from './Profile.module.css';
import { z } from 'zod'
import { FormTextField } from "../../../../components/Form/FormTextField";
import { useNavigate } from "react-router-dom";
import { useSignUpContext } from "../SignUpContext";
import { useZodForm } from "../../../../utils/useZodForm";
import { Auth0User } from "../../auth.service";
import { ImagePicker } from '../../../../components/ImagePicker/ImagePicker';
import { DEFAULT_LOGO } from '../../../../utils/images';
import { Helmet } from 'react-helmet';

const schema = z.object({
  firstName: z.string({ required_error: 'First name is required' }).min(3, { message: "First name must contain at least 3 character(s)" }),
  lastName: z.string({ required_error: 'Last name is required' }).min(3, { message: "First name must contain at least 3 character(s)" }),
  email: z.string({ required_error: 'Email is required' }).email(),
  profileImage: z.string(),
});

const ProfileSetupPage = ({ user }: { user: Auth0User }) => {
  const form = useZodForm(schema, {
    defaultValues: {
      email: user.email,
      profileImage: DEFAULT_LOGO
    }
  });

  const [, setContext] = useSignUpContext()

  const navigate = useNavigate()

  const submit = () => {
    window.dataLayer.push({
      event: 'signup-profile-complete',
      value: {
        firstName: form.getValues().firstName,
        lastName: form.getValues().lastName,
        email: form.getValues().email,
      }
    });
    setContext(form.getValues())
    navigate('/verify-number')
  }
  return (
    <>
      <Helmet>
        <meta property="og:title" content="Profile Setup | Teton Ridge+" />
        <meta property="og:description" content="Your Western Sports Destination | Live results, updated standings, insider info and more!" />
        <meta property="og:type" content="website" />
        <title>Profile Setup</title>
      </Helmet>
      <Page component='form' onSubmit={form.handleSubmit(submit)} sx={{ pt: 5 }}>
        <Typography variant="h3" align="center" color="primary.main">
          SETUP YOUR PROFILE
        </Typography>
        <Typography
          variant="subtitle2"
          align="center"
          textTransform="uppercase"
          fontWeight="500"
          sx={{ pr: 5, pl: 5, pt: 2, pb: 3 }}
        >
          Start exploring the world of Teton Ridge
        </Typography>
        <div className={cls['profile-media-section']}>
          <div className={cls['profile-picture-container']}>
            {form.watch('profileImage') && form.formState.defaultValues?.profileImage !== form.watch('profileImage') && <span className={cls['trash-icon']} onClick={() => form.setValue('profileImage', `${form.formState.defaultValues?.profileImage}`)}>
              <DeleteIcon />
            </span>}
            <div className={cls['media']}>
              {form.watch('profileImage') && <img src={form.watch('profileImage')} alt="Profile" />}
            </div>
          </div>
          <ImagePicker label="Update Profile Picture" form={form} name="profileImage" />
          <Typography color="grey.400" className={cls['image-size']}>
            Max: 3MB (.png,.jpg)
            <br/>
            Use a portrait style photo for the best look.
          </Typography>
        </div>
        <Box sx={{ mt: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>First Name</InputLabel>
              <FormTextField requiredChar={true} fullWidth id="user-first-name" placeholder="Teton" form={form} name='firstName' />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ fontWeight: '700' }}>Last Name</InputLabel>
              <FormTextField requiredChar={true} fullWidth id="user-last-name" placeholder="Ridge" form={form} name='lastName' />
            </Grid>
            <Grid item xs={12}>
              <InputLabel sx={{ fontWeight: '700' }}>Email Address</InputLabel>
              <FormTextField disabled type="email" id="user-email-id" fullWidth placeholder="howdy@emailaddress.com" form={form} name='email' />
            </Grid>
          </Grid>
        </Box>
        <div style={{ height: '100px' }}>
          <Fixedbox>
            <Button type='submit' variant="contained" color="primary" fullWidth>
              Next
            </Button>
          </Fixedbox>
        </div>
      </Page>
    </>
  );
};

export default ProfileSetupPage;
