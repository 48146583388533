import { NavLink } from 'react-router-dom';
import { Link, SxProps } from '@mui/material';
import { Typography } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Box } from '@mui/system';
import { FeedIcon, AwardIcon, AmericanIcon, SheildIcon, AthleteIcon } from '../Icons';
// import { routes } from "../../routes";

const navMenuList = [
  {
    name: 'Feed',
    Icon: FeedIcon,
    link: '/feed',
  },
  {
    name: 'Pro Fantasy Rodeo',
    Icon: AthleteIcon,
    link: 'https://www.profantasyrodeo.com/',
    target: '_blank',
  },
  {
    name: 'Results',
    Icon: AwardIcon,
    link: "https://americanrodeo.com/results/",
    target: '_blank',
    // link: routes.standings,
  },
  // {
  //   name: 'Live',
  //   Icon: AwardIcon,
  //   link: routes.live,
  // },
  {
    name: 'The American',
    Icon: AmericanIcon,
    link: 'https://www.americanrodeo.com',
    target: '_blank',
  },
  {
    name: 'More',
    Icon: SheildIcon,
    link: '/more',
  },
];

const Navbar = ({ sx, offset = true }: { sx?: SxProps, offset?: boolean }) => {
  const renderLinks = navMenuList.map(({ link, Icon, name, target }, index) => (
    <Link
      key={link}
      component={target === '_blank' ? 'a' : NavLink}
      to={link}
      href={link}
      target={target}
      sx={{
        width: '75px',
        height: '72px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '&.active': {
          backgroundImage: (theme) => theme.palette.background.gradient,
          boxShadow: 'inset 0px 6px 6px rgba(0, 0, 0, 0.4)',
        },
        '& svg': {
          fill: (theme) => theme.palette.darkPallet.dark,
          height: '25px',
          width: '25px',
        },
      }}
    >
      <BottomNavigationAction
        key={`${name}-${index}`}
        label={name}
        icon={<Icon />}
        showLabel={true}
        sx={{
          display: 'block'
        }}
        component={() => (
          <>
            <Icon />
            <Typography
              variant="caption"
              display="block"
              color="darkPallet.main"
              fontWeight="700"
              fontSize="10px"
              textTransform="uppercase"
              textAlign="center"
              sx={{ pt: 1 }}
              lineHeight='12px'
            >
              {name}
            </Typography>
          </>)
        } /> </Link>
  ))

  const style = {
    position: "fixed",
    height: 'min-content',
    width: '100vw',
    backgroundImage: (theme: any) => theme.palette.background.gradient,
    justifyContent: "space-between",
    zIndex: 10000,
    pr: 0,
    pl: 0,
    bottom: 0,
    top: 'auto',
    flexShrink: 0,
  } as const;

  return (
    <>
      {offset && <Box sx={{ pb: 15 }} />}
      <BottomNavigation
        sx={{ ...style, ...sx }}
      >
        {renderLinks}
      </BottomNavigation>
    </>
  );
};

export default Navbar;
