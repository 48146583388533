import cmsRequest from '../utils/cmsRequest';
import { buildApiQueryHook } from '../utils/api.utils';
import { CmsImage, CmsPaginated } from './cms.api';
import { FeedBase, FeedTypeEnum } from '../pages/Feeds/feedTypes';
type CollectionFromApi = {
  id: number;
  attributes: {
    Collection_Image: {
      data: CmsImage<['thumbnail']>;
    };
    Collection_Title: string;
    article_posts: {
      data?: any[];
    };
    video_posts: {
      data?: any[];
    };
    createdAt: string;
    publishedAt: string;
    updatedAt: string;
    Featured: boolean;
  };
};

export interface CollectionFeed extends FeedBase {
  id: number;
  image?: CmsImage<['thumbnail']>;
  title: string;
  url?: string;
  videoQuantity: number;
  articleQuantity: number;
  publishedAt: string;
  feedType: FeedTypeEnum.COLLECTION;
  Featured?: boolean;
}

const mapCollectionFeed = (item: CollectionFromApi): CollectionFeed => ({
  id: item.id,
  image: item.attributes.Collection_Image.data,
  title: item.attributes.Collection_Title,
  url: item.attributes.Collection_Image.data?.attributes?.url || undefined,
  videoQuantity: item.attributes.video_posts.data?.length || 0,
  articleQuantity: item.attributes.article_posts.data?.length || 0,
  feedType: FeedTypeEnum.COLLECTION,
  publishedAt: item.attributes.publishedAt,
  Featured: item.attributes.Featured,
});

export const useCollectionFeedQuery = buildApiQueryHook<void, CmsPaginated<CollectionFromApi>, CollectionFeed[]>(
  '/custom-collections',
  () =>
    cmsRequest.get(
      '/custom-collections?populate[article_posts][filters][publishedAt][$notNull]=true&populate[article_posts][populate]=*&populate[video_posts][filters][publishedAt][$notNull]=true&populate[video_posts][populate]=*&populate[Collection_Image]=*',
    ),
  {
    select: (data) => data.data.map(mapCollectionFeed),
  },
);
